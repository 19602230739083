import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPeople } from "../../../store/actions/peopleAction";
import { getCircle } from "../../../store/actions/circleAction";
import { getProject } from "../../../store/actions/projectAction";
// import { getMember } from "../../../store/actions/memberAction";
import { getPFMember } from "../../../store/actions/pfmemberAction";
import { getState } from "../../../store/actions/stateAction";
import jwtDecode from "jwt-decode";
import {
  aggrigatedPersonFTE,
  GetPersonAvailability,
  GetTotalWorkLoad,
  sumFTEBody_Focus,
} from "../../../HelperFunctions/FTE_cal";
import PillComponent from "../Body/PillComponent";
import WorkLoadPeople from "../Body/Model/WorkLoadPeople";
import { GetAliasesName } from "../../../HelperFunctions/GetAliasesName";

export default function Reports({
  setData,
  openReportPeople,
  closReportPeople,
  openReportSkill,
  closReportSkill,
  openReportRole,
  closReportRole,
  openReportDomain,
  closReportDomain,
  openReportLink,
  closReportLink,
  openReportCircle,
  closReportCircle,
  openReportProject,
  closReportProject,
  filterReport,
  setFilterReport,
  openInfo,
  closeInfo,
  isInfos,
  teams
}) {
  const dispatch = useDispatch();
  const {
    error,
    successMessage,
    reportInfo,
    reportTeam,
    reportPeople,
    reportSkill,
    reportRole,
    reportDomain,
    reportLink,
    reportCircle,
    reportProject,
    reportMeeting,
  } = useSelector((state) => state.authReport);
  const { peoples, cuPeopleId, peopleMessagee, peopleError } = useSelector(
    (state) => state.peoples
  );

  const { members, memberMessagee, memberError, memberMessage } = useSelector(
    (state) => state.members
  );

  const { noteData, noteMessage, noteMessagee, noteError } = useSelector(
    (state) => state.notes
  );
  const { states } = useSelector((state) => state.states);

  const { skills } = useSelector((state) => state.skills);
  const { roles } = useSelector((state) => state.roles);
  const { domains } = useSelector((state) => state.domains);
  const { links } = useSelector((state) => state.links);

  const reportTokens = localStorage.getItem("reportTokens");
  useEffect(() => {
    if (reportTokens && reportTokens !== "") {
      const myInfo = jwtDecode(reportTokens);
      // console.log(myInfo);
      dispatch(getCircle({ adminId: myInfo.userId, teamId: myInfo.teamId }));
      dispatch(getProject({ adminId: myInfo.userId, teamId: myInfo.teamId }));
      dispatch(getPFMember({ adminId: myInfo.userId, teamId: myInfo.teamId }));
      dispatch(getState({ teamId: myInfo.teamId }));
    }
  }, [reportTokens]);

  let setReportPeopleData = (data) => {
    setData(data);
    openReportPeople();
  };

  let setReportSkillData = (data) => {
    setData(data);
    openReportSkill();
  };

  let setReportRoleData = (data) => {
    setData(data);
    openReportRole();
    // console.log(data);
  };

  let setReportDomainData = (data) => {
    setData(data);
    openReportDomain();
    // console.log(data);
  };

  let setReportLinkData = (data) => {
    setData(data);
    openReportLink();
  };

  let setReportCircleData = (data) => {
    setData(data);
    openReportCircle();
  };

  let setReportProjectData = (data) => {
    setData(data);
    openReportProject();
  };

  //people edit by ranjit
  let rPeopleData = [];
  if (reportPeople && reportPeople.length > 0) {
    rPeopleData = reportPeople.filter((e) => {
      return e._id.toString() === reportInfo.peopleId;
    });
  }

  //mentor edit by ranjit
  let rPeopleMData = [];
  if (
    reportPeople &&
    reportPeople.length > 0 &&
    rPeopleData &&
    rPeopleData.length > 0 &&
    rPeopleData[0].mentor
  ) {
    rPeopleMData = reportPeople.filter((e) => {
      return e._id.toString() === rPeopleData[0].mentor;
    });
  }

  //mentees edit by ranjit
  let rPeopleMMData = [];

  if (rPeopleData && rPeopleData.length > 0 && rPeopleData[0].mentees) {
    let arr = rPeopleData[0].mentees;
    for (let i = 0; i < arr.length; i++) {
      let temp = reportPeople.filter((e) => {
        return e._id.toString() === arr[i];
      });
      rPeopleMMData.push(temp);
    }
  }

  //skills
  let ReportSkill = [];
  if (reportSkill && reportSkill.length > 0) {
    for (let i = 0; i < reportSkill.length; i++) {
      // loop through all skills in this team
      let reportSkillData = reportSkill[i].owners;
      for (let j = 0; j < reportSkillData.length; j++) {
        if (reportSkillData[j] && reportInfo && reportInfo.peopleId && reportSkillData[j].toString() === reportInfo.peopleId.toString()) {
          ReportSkill.push(reportSkill[i]);
        }
      }
    }
  }

  ReportSkill = new Set(ReportSkill);
  ReportSkill = Array.from(ReportSkill);

  //role
  let ReportRole = [];
  if (reportRole && reportRole.length > 0) {
    for (let i = 0; i < reportRole.length; i++) {
      let reportRoleData = reportRole[i].owners;
      for (let j = 0; j < reportRoleData.length; j++) {
        if (reportRoleData[j].toString() === reportInfo.peopleId.toString()) {
          ReportRole.push(reportRole[i]);
        }
      }
    }
  }

  ReportRole = new Set(ReportRole);
  ReportRole = Array.from(ReportRole);

  //Links
  let ReportLink = [];
  if (reportLink && reportLink.length > 0) {
    for (let i = 0; i < reportLink.length; i++) {
      let reportLinkData = reportLink[i].owner;
      if (
        reportLinkData &&
        reportInfo.peopleId &&
        reportLinkData.toString() === reportInfo.peopleId.toString()
      ) {
        ReportLink.push(reportLink[i]);
      }
    }
  }

  ReportLink = new Set(ReportLink);
  ReportLink = Array.from(ReportLink);

  //domain
  let ReportDomain = [];
  if (reportDomain && reportDomain.length > 0) {
    for (let i = 0; i < reportDomain.length; i++) {
      let reportDomainData = reportDomain[i].owners.owner;
      // console.log(reportDomainData.toString() === peopleId );
      if (
        reportDomainData &&
        reportInfo.peopleId &&
        reportDomainData.toString() === reportInfo.peopleId.toString()
      ) {
        ReportDomain.push(reportDomain[i]);
      }

      if (
        reportDomain[i].standIn &&
        reportDomain[i].standIn.toString() === reportInfo.peopleId.toString()
      ) {
        ReportDomain.push(reportDomain[i]);
      }
    }
  }

  ReportDomain = new Set(ReportDomain);
  ReportDomain = Array.from(ReportDomain);

  const [noteSaveData, setNoteSaveData] = useState({
    People: "",
    Skills: "",
    Roles: "",
    Domains: "",
    Links: "",
    Circles: "",
    Projects: "",
  });

  useEffect(() => {
    let obj = {
      People: "",
      Skills: "",
      Roles: "",
      Domains: "",
      Links: "",
      Circles: "",
      Projects: "",
    };
    if (noteData && noteData.length > 0) {
      for (let i = 0; i < noteData.length; i++) {
        // console.log(noteData[i].noteCard)
        obj[noteData[i].noteCard] = noteData[i].noteMessage;
      }
      setNoteSaveData(obj);
    }
  }, [noteData]);

  // hide info created by ranjit
  const hideInfo = (i) => {
    const updatedData = [...filterReport];
    updatedData[i].isChecked = !updatedData[i].isChecked;
    setFilterReport(updatedData);
  };

  // console.log(196, reportRole);
  const [dRoles, setDRole] = useState([]);
  // console.log(199, reportPeople)
  // useEffect(() => {
  //   if (reportCircle && reportCircle.length > 0) {
  //     for (let i = 0; i < reportCircle.length; i++) {
  //       if (reportCircle[i]._doc.lead === reportInfo.peopleId) {
  //         let item = reportRole.find(r => r.roleName === 'Circle Lead');
  //         if (item && !dRoles.find(dr => dr.roleName === 'Circle Lead')) {
  //           setDRole([...dRoles, item]);
  //           break;
  //         }
  //       }
  //     }
  //   }
  // }, [reportCircle, dRoles]);

  // useEffect(() => {
  //   if (reportDomain && reportDomain.length > 0) {
  //     for (let i = 0; i < reportDomain.length; i++) {
  //       if (reportDomain[i].owners.owner === reportInfo.peopleId) {
  //         let item = reportRole.find(r => r.roleName === 'Domain Owner');
  //         if (item && !dRoles.find(dr => dr.roleName === 'Domain Owner')) {
  //           setDRole([...dRoles, item]);
  //           break;
  //         }
  //       }
  //     }
  //   }
  // }, [reportDomain, dRoles]);

  useEffect(() => {
    if (reportCircle && reportCircle.length > 0) {
      for (let i = 0; i < reportCircle.length; i++) {
        if (reportCircle[i]._doc.lead === reportInfo.peopleId) {
          let item = reportRole.find((r) => r.roleName === "Circle Lead");
          if (item && !dRoles.find((dr) => dr.roleName === "Circle Lead")) {
            setDRole([...dRoles, item]);
            break;
          }
        }
      }
    }
    if (reportDomain && reportDomain.length > 0) {
      for (let i = 0; i < reportDomain.length; i++) {
        if (reportDomain[i].owners.owner === reportInfo.peopleId) {
          let item = reportRole.find((r) => r.roleName === "Domain Owner");
          if (item && !dRoles.find((dr) => dr.roleName === "Domain Owner")) {
            setDRole([...dRoles, item]);
            break;
          }
        }
      }
    }
    if (reportPeople && reportPeople.length > 0) {
      for (let i = 0; i < reportPeople.length; i++) {
        if (reportPeople[i].mentor === reportInfo.peopleId) {
          let item = reportRole.find((r) => r.roleName === "Mentor");
          if (item && !dRoles.find((dr) => dr.roleName === "Mentor")) {
            setDRole([...dRoles, item]);
            break;
          }
        }
      }
    }
    if (reportLink && reportLink.length > 0) {
      for (let i = 0; i < reportLink.length; i++) {
        if (reportLink[i].owner === reportInfo.peopleId) {
          let item = reportRole.find((r) => r.roleName === "Link Owner");
          if (item && !dRoles.find((dr) => dr.roleName === "Link Owner")) {
            setDRole([...dRoles, item]);
            break;
          }
        }
      }
    }
    if (reportProject && reportProject.length > 0) {
      for (let i = 0; i < reportProject.length; i++) {
        if (reportProject[i]._doc.lead === reportInfo.peopleId) {
          let item = reportRole.find((r) => r.roleName === "Project Lead");
          if (item && !dRoles.find((dr) => dr.roleName === "Project Lead")) {
            setDRole([...dRoles, item]);
            break;
          }
        }
      }
    }
  }, [
    reportPeople,
    reportDomain,
    reportLink,
    reportCircle,
    reportProject,
    dRoles,
  ]);

  // useEffect(() => {
  //   if (reportLink && reportLink.length > 0) {
  //     for (let i = 0; i < reportLink.length; i++) {
  //       if (reportLink[i].owner === reportInfo.peopleId) {
  //         let item = reportRole.find(r => r.roleName === 'Link Owner');
  //         if (item && !dRoles.find(dr => dr.roleName === 'Link Owner')) {
  //           setDRole([...dRoles, item]);
  //           break;
  //         }
  //       }
  //     }
  //   }
  // }, [reportLink, dRoles]);

  // useEffect(() => {
  //   if (reportProject && reportProject.length > 0) {
  //     for (let i = 0; i < reportProject.length; i++) {
  //       if (reportProject[i]._doc.lead === reportInfo.peopleId) {
  //         let item = reportRole.find(r => r.roleName === 'Project Lead');
  //         if (item && !dRoles.find(dr => dr.roleName === 'Project Lead')) {
  //           setDRole([...dRoles, item]);
  //           break;
  //         }
  //       }
  //     }
  //   }

  // }, [reportProject, dRoles]);

  // console.log(dRoles);

  useEffect(() => {
    if (filterReport.length > 0) {
      let checkedInitialObject = [
        { name: "people", isChecked: false },
        { name: "skills", isChecked: false },
        { name: "roles", isChecked: false },
        { name: "domain", isChecked: false },
        { name: "links", isChecked: false },
        { name: "circle", isChecked: false },
        { name: "project", isChecked: false },
        { name: "mettings", isChecked: false },
      ];
      // if (rPeopleMData.length === 0 && rPeopleMMData.length === 0) {
      //   checkedInitialObject[0] = { name: "people", isChecked: true };
      // }
      if (ReportSkill.length === 0) {
        checkedInitialObject[1] = { name: "skills", isChecked: true };
      }
      if (ReportRole.length === 0 && dRoles.length === 0) {
        checkedInitialObject[2] = { name: "roles", isChecked: true };
      }
      if (ReportDomain.length === 0) {
        checkedInitialObject[3] = { name: "domain", isChecked: true };
      }
      if (ReportLink.length === 0) {
        checkedInitialObject[4] = { name: "links", isChecked: true };
      }
      if (reportCircle.length === 0) {
        checkedInitialObject[5] = { name: "circle", isChecked: true };
      }
      if (reportProject.length === 0) {
        checkedInitialObject[6] = { name: "project", isChecked: true };
      }
      if (reportMeeting.length === 0) {
        checkedInitialObject[7] = { name: "mettings", isChecked: true };
      }
      setFilterReport(checkedInitialObject);
    }
  }, [
    reportPeople,
    reportSkill,
    reportRole,
    reportDomain,
    reportLink,
    reportCircle,
    reportProject,
    reportMeeting,
  ]);

  document.body.style = "";
  document.body.classList.remove("no-app-pages");
  document.body.classList.remove(`purpose-tree`);
  
  const checkIsExternal = (e, cardType) => {
    // console.log(e, cardType);

    let owners = [];
    switch (cardType) {
      case "skill":
      case "role":
        owners = e.owners;
        break;

      case "domain":
        if (e.owners) {
          owners = [e.owners.owner];
        }
        break;

      case "link":
        owners = [e.owner];
        break;

      default:
        break;
    }

    // console.log(reportPeople, owners);

    let external = true;
    if (owners && owners.length > 0) {
      for (let i = 0; i < owners.length; i++) {
        if (owners[i] && owners[i] !== undefined) {
          const ownerPerson = peoples.filter((item) => item._id === owners[i]);
          if (
            ownerPerson &&
            ownerPerson[0] &&
            ownerPerson[0].workRole !== "External"
          ) {
            external = false;
            break;
          }
        } else {
          return false;
        }
      }
    } else {
      return false;
    }

    return external;
  };

  const whoIsOwnerType = (e) => {
    // console.log(e, reportSkill);
    if (e.owners && e.owners.type && e.owners.type.length > 0) {
      for (let i = 0; i < reportSkill.length; i++) {
        if (e.owners.type === reportSkill[i]._id) {
          return {
            cardId: reportSkill[i]._id,
            name: reportSkill[i].skillName + ` (${GetAliasesName(teams, "Skills")})`,
            type: "Skill",
          };
        }
      }

      for (let i = 0; i < reportRole.length; i++) {
        if (e.owners.type === reportRole[i]._id) {
          return {
            cardId: reportRole[i]._id,
            name: reportRole[i].roleName + ` (${GetAliasesName(teams, "Roles")})`,
            type: "Role",
          };
        }
      }
    }
    return {};
  };

  const whoIsStandIn = (e) => {
    if (e.standIn && e.standIn.toString() === reportInfo.peopleId.toString()) {
      return true;
    }
    return false;
  };

  const areObjectsEqual = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }

    return true;
  };

  const removeDuplicates = (array) => {
    const uniqueArray = [];

    array.forEach((obj) => {
      if (!uniqueArray.some((uniqueObj) => areObjectsEqual(obj, uniqueObj))) {
        uniqueArray.push(obj);
      }
    });

    return uniqueArray;
  };

  const getPills = (e) => {
    // console.log(e._doc, members);
    var pills = [];
    let memberObject = members.filter(
      (member) => member.memberType === e._doc._id
    );

    // console.log(memberObject);

    if (memberObject[0] && memberObject[0].coreMembers) {
      let coreMembers = memberObject[0].coreMembers;
      // console.log(reportInfo.peopleId, coreMembers);

      if (coreMembers.People.length > 0) {
        coreMembers.People.map((member, index) => {
          if (reportInfo.peopleId === member) {
            pills.push({ type: "person", name: "Added as person", xtd: false });
          }
        });
      }

      // console.log(reportSkill);

      if (coreMembers.Skills.length > 0) {
        coreMembers.Skills.map((member, index) => {
          // console.log(member)
          for (let i = 0; i < reportSkill.length; i++) {
            if (
              reportSkill[i] &&
              reportSkill[i].owners &&
              reportSkill[i].owners.length > 0 &&
              reportSkill[i].owners.includes(reportInfo.peopleId) &&
              member === reportSkill[i]._id
            ) {
              pills.push({
                type: "skill",
                name: `${reportSkill[i].skillName} (${GetAliasesName(teams, "Skills")})`,
                xtd: false,
              });
            }
          }
        });
      }

      if (coreMembers.Roles.length > 0) {
        coreMembers.Roles.map((member, index) => {
          for (let i = 0; i < reportRole.length; i++) {
            if (
              reportRole[i] &&
              reportRole[i].owners &&
              reportRole[i].owners.length > 0 &&
              reportRole[i].owners.includes(reportInfo.peopleId) &&
              member === reportRole[i]._id
            ) {
              pills.push({
                type: "role",
                name: `${reportRole[i].roleName} (${GetAliasesName(teams, "Roles")})`,
                xtd: false,
              });
            }
          }
        });
      }

      if (coreMembers.Domains.length > 0) {
        coreMembers.Domains.map((member, index) => {
          for (let i = 0; i < reportDomain.length; i++) {
            if (
              reportDomain[i] &&
              reportDomain[i].owners &&
              reportDomain[i].owners.owner &&
              reportDomain[i].owners.owner.length > 0 &&
              reportDomain[i].owners.owner.includes(reportInfo.peopleId) &&
              member === reportDomain[i]._id
            ) {
              pills.push({
                type: "domain",
                name: `${reportDomain[i].domainName} (${GetAliasesName(teams, "Domains")})`,
                xtd: false,
              });
            }
          }
        });
      }

      if (coreMembers.Links.length > 0) {
        coreMembers.Links.map((member, index) => {
          for (let i = 0; i < reportLink.length; i++) {
            if (
              reportLink[i] &&
              reportLink[i].owner &&
              reportLink[i].owner.length > 0 &&
              reportLink[i].owner.includes(reportInfo.peopleId) &&
              member === reportLink[i]._id
            ) {
              pills.push({
                type: "link",
                name: `${reportLink[i].linkName} (${GetAliasesName(teams, "Links")})`,
                xtd: false,
              });
            }
          }
        });
      }

      let extendedMembers = memberObject[0].extendedMembers;
      // console.log(reportInfo.peopleId, extendedMembers);
      if (extendedMembers.People.length > 0) {
        extendedMembers.People.map((member, index) => {
          if (reportInfo.peopleId === member) {
            pills.push({ type: "person", name: "Added as person", xtd: true });
          }
        });
      }

      if (extendedMembers.Skills.length > 0) {
        extendedMembers.Skills.map((member, index) => {
          for (let i = 0; i < reportSkill.length; i++) {
            if (
              reportSkill[i] &&
              reportSkill[i].owners &&
              reportSkill[i].owners.length > 0 &&
              reportSkill[i].owners.includes(reportInfo.peopleId) &&
              member === reportSkill[i]._id
            ) {
              pills.push({
                type: "skill",
                name: `${reportSkill[i].skillName} (${GetAliasesName(teams, "Skills")})`,
                xtd: true,
              });
            }
          }
        });
      }

      if (extendedMembers.Roles.length > 0) {
        extendedMembers.Roles.map((member, index) => {
          for (let i = 0; i < reportRole.length; i++) {
            if (
              reportRole[i] &&
              reportRole[i].owners &&
              reportRole[i].owners.length > 0 &&
              reportRole[i].owners.includes(reportInfo.peopleId) &&
              member === reportRole[i]._id
            ) {
              pills.push({
                type: "role",
                name: `${reportRole[i].roleName} (${GetAliasesName(teams, "Roles")})`,
                xtd: true,
              });
            }
          }
        });
      }

      if (extendedMembers.Domains.length > 0) {
        extendedMembers.Domains.map((member, index) => {
          for (let i = 0; i < reportDomain.length; i++) {
            if (
              reportDomain[i] &&
              reportDomain[i].owners &&
              reportDomain[i].owners.owner &&
              reportDomain[i].owners.owner.length > 0 &&
              reportDomain[i].owners.owner.includes(reportInfo.peopleId) &&
              member === reportDomain[i]._id
            ) {
              pills.push({
                type: "domain",
                name: `${reportDomain[i].domainName} (${GetAliasesName(teams, "Domains")})`,
                xtd: true,
              });
            }
          }
        });
      }

      if (extendedMembers.Links.length > 0) {
        extendedMembers.Links.map((member, index) => {
          for (let i = 0; i < reportLink.length; i++) {
            if (
              reportLink[i] &&
              reportLink[i].owner &&
              reportLink[i].owner.length > 0 &&
              reportLink[i].owner.includes(reportInfo.peopleId) &&
              member === reportLink[i]._id
            ) {
              pills.push({
                type: "link",
                name: `${reportLink[i].linkName} (${GetAliasesName(teams, "Links")})`,
                xtd: true,
              });
            }
          }
        });
      }
    }

    if (e._doc.lead) {
      // console.log(e._doc.circleName, e._doc.lead, reportInfo.peopleId)
      if (e._doc.lead === reportInfo.peopleId) {
        // console.log('lead is the person in focus')
        pills.push({ type: "direct-lead", name: `LEAD` });
      } else {
        reportSkill.forEach((rs) => {
          if (
            rs._id === e._doc.lead &&
            rs.owners.includes(reportInfo.peopleId)
          ) {
            pills.push({ type: "indirect-lead", name: `LEAD` });
          }
        });

        reportRole.forEach((rr) => {
          if (
            rr._id === e._doc.lead &&
            rr.owners.includes(reportInfo.peopleId)
          ) {
            pills.push({ type: "indirect-lead", name: `LEAD` });
          }
        });
      }
    }
    // console.log(e._doc.circleName, pills)

    const uniqueArray = removeDuplicates(pills);

    // console.log(uniqueArray)
    return uniqueArray;
  };

  // console.log(reportCircle)

  const isSkillRoleLead = (data) => {
    // console.log(data, reportCircle)
    let flag = false;
    reportCircle.forEach((rc) => {
      if (rc._doc.lead && rc._doc.lead === data._id) {
        // console.log(rc._doc.circleName, rc._doc.lead, data._id);
        flag = true;
      }
    });

    reportProject.forEach((rp) => {
      if (rp._doc.lead && rp._doc.lead === data._id) {
        // console.log(rp._doc.circleName, rp._doc.lead, data._id);
        flag = true;
      }
    });
    return flag;
  };

  const getAdminTasks = (data) => {
    let adminTasks = [];
    if (reportInfo && reportInfo.peopleId) {
      adminTasks =
        data._doc.administration &&
        data._doc.administration.filter(
          (admin) => admin.owner === reportInfo.peopleId
        );
    }
    // console.log(adminTasks)
    return adminTasks;
  };

  //===================================================PEOPLE REPORT=============================================

  const PRPeople = () => (
    <div className="board-col " data-board_id="people">
      <div className="list" data-list_id="people">
        <div className="dropdown" style={{ position: "static" }}>
          <h4 className="list-title">
            <img alt="" src="../images/icon-people.png" /> {/* People */}{GetAliasesName(teams, "People")}
            <a
              className="list-menu dropdown-toggle"
              data-toggle="dropdown"
              href="#"
            >
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation" onClick={() => isInfos("People")}>
                <a role="menuitem" href="#" className="mytpt-list-info">
                  Info
                </a>
              </li>
              <li role="presentation" onClick={() => hideInfo(0)}>
                <a role="menuitem" href="#" className="hide_list">
                  Hide list
                </a>
              </li>
            </ul>
          </h4>
        </div>

        <div className="card-list scrollbar">
          {noteSaveData.People ? (
            <div className="form-group notes">
              <textarea
                className="notes"
                value={noteSaveData.People}
                readOnly={true}
                name="People"
                placeholder="Notes"
                maxLength="100"
              ></textarea>
            </div>
          ) : null}

          <h6 style={{ borderBottom: "1px solid #000" }}>Mentor</h6>
          {rPeopleMData && rPeopleMData.length > 0
            ? rPeopleMData.map((e, index) => {
                return (
                  <div key={index}>
                    {e && Object.keys(e).length > 0 ? (
                      <div
                        className="card readonly"
                        data-list_id="people"
                        data-history=""
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setReportPeopleData(e);
                          }
                        }}
                      >
                        {e.workRole === "External" ? (
                          <span className="custom-badge blue ext-owner-pill">
                            EXT
                          </span>
                        ) : null}

                        <div className="static-relations">
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, indexs) => (
                                <span
                                  key={indexs}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}
                        </div>
                        <a
                          // onClick={() => setReportPeopleData(e)}
                          className="card-edit"
                        >{`${e.fname} ${e.lname}`}</a>
                      </div>
                    ) : null}
                  </div>
                );
              })
            : null}

          <h6 style={{ borderBottom: "1px solid #000" }}>Mentees</h6>
          {rPeopleMMData && rPeopleMMData.length > 0
            ? rPeopleMMData.map((e, index) => {
                // let isExternal = sSIsExtrnal(e)
                // console.log(e);
                return (
                  <div key={index}>
                    {e && Object.keys(e).length > 0 ? (
                      <div className="card readonly" data-list_id="people">
                        {e && e.length > 0
                          ? e.map((r, rindex) => (
                              <>
                                {r.workRole === "External" ? (
                                  <span
                                    key={rindex}
                                    className="custom-badge blue ext-owner-pill"
                                  >
                                    EXT
                                  </span>
                                ) : null}
                              </>
                            ))
                          : null}

                        <div className="static-relations">
                          {e && e.length > 0
                            ? e.map((r, index) =>
                                r.tags.map((tag, tindex) => (
                                  <>
                                    <span
                                      key={tindex}
                                      className="custom-badge green tags"
                                    >
                                      {tag}
                                    </span>
                                  </>
                                ))
                              )
                            : null}
                        </div>
                        {e && e.length > 0
                          ? e.map((r, index) => (
                              <a
                                key={index}
                                onClick={() => setReportPeopleData(r)}
                                className="card-edit"
                              >{`${r.fname} ${r.lname}`}</a>
                            ))
                          : null}
                      </div>
                    ) : null}
                  </div>
                );
              })
            : null}
          {/* {console.log(reportInfo, rPeopleMData)} */}

          <h6 style={{ borderBottom: "1px solid #000" }}>Workload</h6>
          <div className="workload-report-wrapper">
            {peoples && peoples.length > 0 && (
              <WorkLoadPeople
                handleChange={null}
                availability={GetPersonAvailability(
                  peoples,
                  reportInfo.peopleId
                )}
                plannedValue={aggrigatedPersonFTE(
                  skills,
                  roles,
                  domains,
                  links,
                  reportInfo.peopleId
                )}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );

  const PRSkill = () => (
    <div className="board-col" data-board_id="skills">
      <div className="list list-bg-blue" data-list_id="skills">
        <div className="dropdown">
          <h4 className="list-title">
            <img alt="" src="../images/icon-skill.png" /> {/* SKILL */}{GetAliasesName(teams, "Skills")}
            <a className="list-menu refresh-list hide" href="#">
              <i className="fas fa-sync-alt"></i>
            </a>
            <a
              className="list-menu dropdown-toggle"
              data-toggle="dropdown"
              href="#"
            >
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation" onClick={() => isInfos("Skills")}>
                <a role="menuitem" href="#" className="mytpt-list-info">
                  Info
                </a>
              </li>
              {/* <!-- <li role="presentation"><a role="menuitem" href="#" data-toggle="modal" data-target="#skillsModal">Add new {GetAliasesName(teams,"Skills").toLowerCase()}</a></li> --> */}
              <li role="presentation" onClick={() => hideInfo(1)}>
                <a role="menuitem" href="#" className="hide_list">
                  Hide list
                </a>
              </li>
            </ul>
          </h4>
        </div>
        <div className="card-list scrollbar">
          {noteSaveData.Skills ? (
            <div className="form-group notes">
              <textarea
                className="notes"
                value={noteSaveData.Skills}
                readOnly={true}
                name="People"
                placeholder="Notes"
                maxLength="100"
              ></textarea>
            </div>
          ) : null}

          {ReportSkill && ReportSkill.length > 0
            ? ReportSkill.map((e, index) => {
                let isExternal = checkIsExternal(e, "skill");
                let isSRLead = isSkillRoleLead(e);
                return (
                  <div
                    key={index}
                    className="card readonly"
                    data-history=""
                    onClick={(event) => {
                      if (
                        event.target.classList.contains("card") ||
                        event.target.classList.contains("card-edit")
                      ) {
                        setReportSkillData(e);
                      }
                    }}
                  >
                    {states.workLoad === true && (
                      <PillComponent
                        occupied={sumFTEBody_Focus(
                          e.workload,
                          reportInfo.peopleId
                        )}
                        // total={GetPersonAvailability(peoples,focused.id)}
                        // total={GetTotalWorkLoad(e, "SKILLS")}
                        total={e.planTotal}
                        type="TEXT"
                      />
                    )}
                    {isExternal ? (
                      <span className="custom-badge blue ext-owner-pill">
                        EXT
                      </span>
                    ) : null}

                    {e.ownerType.length > 0 &&
                    e.ownerType === "Single owner" ? (
                      <span className="custom-badge blue-o single-owner-pill single-owner-pill">
                        1
                      </span>
                    ) : null}

                    {isSRLead ? (
                      <span className="custom-badge red-o direct-lead">
                        LEAD
                      </span>
                    ) : null}

                    <div className="static-relations">
                      {e.tags && e.tags.length > 0
                        ? e.tags.map((r, rindex) => (
                            <span
                              key={rindex}
                              className="custom-badge green tags"
                            >
                              {r}
                            </span>
                          ))
                        : null}
                    </div>
                    <a
                      // onClick={() => setReportSkillData(e)}
                      className="card-edit"
                    >
                      {e.skillName}
                    </a>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );

  const PRRole = () => (
    <div className="board-col" data-board_id="roles">
      <div className="list list-bg-blue" data-list_id="roles">
        <div className="dropdown">
          <h4 className="list-title">
            <img alt="" src="../images/icon-role.png" /> {/* Roles*/}{GetAliasesName(teams, "Roles")}
            <a className="list-menu refresh-list hide" href="#">
              <i className="fas fa-sync-alt"></i>
            </a>
            <a
              className="list-menu dropdown-toggle"
              data-toggle="dropdown"
              href="#"
            >
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation" onClick={() => isInfos("Roles")}>
                <a role="menuitem" href="#" className="mytpt-list-info">
                  Info
                </a>
              </li>
              <li role="presentation" onClick={() => hideInfo(2)}>
                <a role="menuitem" href="#" className="hide_list">
                  Hide list
                </a>
              </li>
            </ul>
          </h4>
        </div>

        <div className="card-list scrollbar">
          {noteSaveData.Roles ? (
            <div className="form-group notes">
              <textarea
                className="notes"
                value={noteSaveData.Roles}
                readOnly={true}
                name="People"
                placeholder="Notes"
                maxLength="100"
              ></textarea>
            </div>
          ) : null}

          {ReportRole.filter((sr)=> sr.defaultRole !== true).length > 0
            ? ReportRole.filter((sr)=> sr.defaultRole !== true).map((e, index) => {
                let isExternal = checkIsExternal(e, "role");
                let isSRLead = isSkillRoleLead(e);
                return (
                  <div
                    key={index}
                    className="card readonly"
                    data-list_id="roles"
                    onClick={(event) => {
                      if (
                        event.target.classList.contains("card") ||
                        event.target.classList.contains("card-edit")
                      ) {
                        setReportRoleData(e);
                      }
                    }}
                  >
                    {states.workLoad === true && (
                      <PillComponent
                        occupied={sumFTEBody_Focus(
                          e.workload,
                          reportInfo.peopleId
                        )}
                        // total={GetPersonAvailability(peoples,focused.id)}
                        // total={GetTotalWorkLoad(e, "ROLES")}
                        total={e.planTotal}
                        type="TEXT"
                      />
                    )}
                    {isExternal ? (
                      <span className="custom-badge blue ext-owner-pill">
                        EXT
                      </span>
                    ) : null}

                    {e.ownerRole.length > 0 &&
                    e.ownerRole === "Single owner" ? (
                      <span className="custom-badge blue-o single-owner-pill">
                        1
                      </span>
                    ) : null}

                    {isSRLead ? (
                      <span className="custom-badge red-o direct-lead">
                        LEAD
                      </span>
                    ) : null}
                    <div className="static-relations">
                      {e.tags && e.tags.length > 0
                        ? e.tags.map((r, index) => (
                            <span
                              key={index}
                              className="custom-badge green tags"
                            >
                              {r}
                            </span>
                          ))
                        : null}
                    </div>
                    <a
                      // onClick={() => setReportRoleData(e)}
                      className="card-edit"
                    >
                      {e.roleName}
                    </a>
                  </div>
                );
              })
            : null}

          {dRoles.length > 0
            ? dRoles.map((e, index) => {
                let isExternal = checkIsExternal(e, "role");
                let isSRLead = isSkillRoleLead(e);
                return (
                  <div
                    key={index}
                    className="card default-role readonly"
                    data-list_id="roles"
                  >
                    {/* {isExternal ? (<span className="custom-badge blue ext-owner-pill">EXT</span>) : null} */}
                    <div className="static-relations">
                      
                    {states.workLoad === true && (
                      <PillComponent
                        occupied={sumFTEBody_Focus(
                          e.workload,
                          reportInfo.peopleId
                        )}
                        // total={GetPersonAvailability(peoples,focused.id)}
                        // total={GetTotalWorkLoad(e, "ROLES")}
                        total={e.planTotal}
                        type="TEXT"
                      />
                    )}
                    
                    {e.tags && e.tags.length > 0
                        ? e.tags.map((r, index) => (
                            <span
                              key={index}
                              className="custom-badge green tags"
                            >
                              {r}
                            </span>
                          ))
                        : null}
                    </div>
                    <a
                      onClick={() => setReportRoleData(e)}
                      className="card-edit"
                    >
                      {e.roleName}
                    </a>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );

  const PRDomain = () => (
    <div className="board-col" data-board_id="domain">
      <div className="list list-bg-blue" data-list_id="domains">
        <div className="dropdown">
          <h4 className="list-title">
            <img alt="" src="../images/icon-domain.png" /> {/* Domains */}{GetAliasesName(teams, "Domains")}
            <a className="list-menu refresh-list hide" href="#">
              <i className="fas fa-sync-alt"></i>
            </a>
            <a
              className="list-menu dropdown-toggle"
              data-toggle="dropdown"
              href="#"
            >
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation" onClick={() => isInfos("Domains")}>
                <a role="menuitem" href="#" className="mytpt-list-info">
                  Info
                </a>
              </li>
              <li role="presentation" onClick={() => hideInfo(3)}>
                <a role="menuitem" href="#" className="hide_list">
                  Hide list
                </a>
              </li>
            </ul>
          </h4>
        </div>

        <div className="card-list scrollbar">
          {noteSaveData.Domains ? (
            <div className="form-group notes">
              <textarea
                className="notes"
                value={noteSaveData.Domains}
                readOnly={true}
                name="People"
                placeholder="Notes"
                maxLength="100"
              ></textarea>
            </div>
          ) : null}

          {ReportDomain.length > 0
            ? ReportDomain.map((e, index) => {
                let isExternal = checkIsExternal(e, "domain");
                let ownerType = whoIsOwnerType(e);
                let isStandIn = whoIsStandIn(e);
                return (
                  <div
                    key={index}
                    className="card readonly"
                    data-list_id="domain"
                    data-history=""
                    onClick={(event) => {
                      if (
                        event.target.classList.contains("card") ||
                        event.target.classList.contains("card-edit")
                      ) {
                        setReportDomainData(e);
                      }
                    }}
                  >
                    <div className="static-relations">
                      {states.workLoad === true && (
                        <PillComponent
                          occupied={sumFTEBody_Focus(
                            e.workload,
                            reportInfo.peopleId
                          )}
                          // total={GetPersonAvailability(peoples,focused.id)}
                          // total={GetTotalWorkLoad(e, "DOMAINS")}
                          total={e.planTotal}
                          type="TEXT"
                        />
                      )}

                      {isExternal ? (
                        <span className="custom-badge blue ext-owner-pill">
                          EXT
                        </span>
                      ) : null}

                      {isStandIn ? (
                        <span className="custom-badge gray-o">STAND IN</span>
                      ) : null}

                      {ownerType && Object.keys(ownerType).length > 0 ? (
                        <span
                          // onClick={() => openSkillAndRoleByDPill(ownerType)}
                          className="custom-badge lightgray"
                        >
                          {ownerType.name}
                        </span>
                      ) : null}
                      
                      {e.tags && e.tags.length > 0
                        ? e.tags.map((r, index) => (
                            <span
                              key={index}
                              className="custom-badge green tags"
                            >
                              {r}
                            </span>
                          ))
                        : null}
                    </div>
                    <a
                      /* onClick={() => setReportDomainData(e)} */ className="card-edit"
                    >
                      {e.domainName}
                    </a>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );

  const PRLink = () => (
    <div className="board-col" data-board_id="links">
      <div className="list list-bg-blue" data-list_id="links">
        <div className="dropdown">
          <h4 className="list-title">
            <img alt="" src="../images/icon-link.png" /> {/* Links */}{GetAliasesName(teams, "Links")}
            <a className="list-menu refresh-list hide" href="#">
              <i className="fas fa-sync-alt"></i>
            </a>
            <a
              className="list-menu dropdown-toggle"
              data-toggle="dropdown"
              href="#"
            >
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation" onClick={() => isInfos("Links")}>
                <a role="menuitem" href="#" className="mytpt-list-info">
                  Info
                </a>
              </li>
              <li role="presentation" onClick={() => hideInfo(4)}>
                <a role="menuitem" href="#" className="hide_list">
                  Hide list
                </a>
              </li>
            </ul>
          </h4>
        </div>

        <div className="card-list scrollbar">
          {noteSaveData.Links ? (
            <div className="form-group notes">
              <textarea
                className="notes"
                value={noteSaveData.Links}
                readOnly={true}
                name="People"
                placeholder="Notes"
                maxLength="100"
              ></textarea>
            </div>
          ) : null}

          {ReportLink && ReportLink.length > 0
            ? ReportLink.map((e, index) => {
                let isExternal = checkIsExternal(e, "link");
                return (
                  <div
                    key={index}
                    className="card readonly"
                    data-list_id="links"
                    data-history=""
                    onClick={(event) => {
                      if (
                        event.target.classList.contains("card") ||
                        event.target.classList.contains("card-edit")
                      ) {
                        setReportLinkData(e);
                      }
                    }}
                  >
                    {states.workLoad === true && (
                      <PillComponent
                        occupied={sumFTEBody_Focus(
                          e.workload,
                          reportInfo.peopleId
                        )}
                        // total={GetPersonAvailability(peoples,focused.id)}
                        // total={GetTotalWorkLoad(e, "LINKS")}
                        total={e.planTotal}
                        type="TEXT"
                      />
                    )}
                    {isExternal ? (
                      <span className="custom-badge blue ext-owner-pill">
                        EXT
                      </span>
                    ) : null}
                    <div className="static-relations">
                      {e.tags && e.tags.length > 0
                        ? e.tags.map((r, index) => (
                            <span
                              key={index}
                              className="custom-badge green tags"
                            >
                              {r}
                            </span>
                          ))
                        : null}
                    </div>
                    <a
                      // onClick={() => setReportLinkData(e)}
                      className="card-edit"
                    >
                      {e.linkName}
                    </a>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );

  const PRCircle = () => (
    <div className="board-col" data-board_id="circles">
      <div className="list list-bg-red" data-list_id="circles">
        <div className="dropdown">
          <h4 className="list-title">
            <img alt="" src="../images/icon-circle.png" /> {/* Circles */}{GetAliasesName(teams, "Circles")}
            <a className="list-menu refresh-list hide" href="#">
              <i className="fas fa-sync-alt"></i>
            </a>
            <a
              className="list-menu dropdown-toggle"
              data-toggle="dropdown"
              href="#"
            >
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation" onClick={() => isInfos("Circles")}>
                <a role="menuitem" href="#" className="mytpt-list-info">
                  Info
                </a>
              </li>
              <li role="presentation" onClick={() => hideInfo(5)}>
                <a role="menuitem" href="#" className="hide_list">
                  Hide list
                </a>
              </li>
            </ul>
          </h4>
        </div>

        <div className="card-list scrollbar">
          {noteSaveData.Circles ? (
            <div className="form-group notes">
              <textarea
                className="notes"
                value={noteSaveData.Circles}
                readOnly={true}
                name="People"
                placeholder="Notes"
                maxLength="100"
              ></textarea>
            </div>
          ) : null}

          {reportCircle && reportCircle.length > 0
            ? reportCircle.map((e, index) => {
                let greyPills = getPills(e);
                let adminTasks = getAdminTasks(e);

                return (
                  <div
                    key={e._doc._id}
                    className={
                      e._doc && e._doc.defaultCircle
                        ? "card default-circle"
                        : "card readonly"
                    }
                    data-list_id="circles"
                    onClick={(event) => {
                      if (
                        event.target.classList.contains("card") ||
                        event.target.classList.contains("card-edit")
                      ) {
                        setReportCircleData(e._doc);
                      }
                    }}
                  >
                    <div className="static-relations">
                      {e._doc.tags && e._doc.tags.length > 0
                        ? e._doc.tags.map((r, rindex) => (
                            <span
                              key={rindex}
                              className="custom-badge green tags"
                            >
                              {r}
                            </span>
                          ))
                        : null}

                      {greyPills && greyPills.length > 0
                        ? greyPills.map((pill, pindex) =>
                            pill.type === "person" ? (
                              <span
                                key={pindex}
                                className="custom-badge lightgray text-red person-pill"
                              >
                                {pill.xtd ? (
                                  <span className="text-red">XTD</span>
                                ) : null}{" "}
                                {pill.name}
                              </span>
                            ) : pill.type === "skill" ? (
                              <span
                                key={pindex}
                                className="custom-badge lightgray"
                              >
                                {pill.xtd ? (
                                  <span className="text-red">XTD</span>
                                ) : null}{" "}
                                {pill.name}
                              </span>
                            ) : pill.type === "role" ? (
                              <span
                                key={pindex}
                                className="custom-badge lightgray"
                              >
                                {pill.xtd ? (
                                  <span className="text-red">XTD</span>
                                ) : null}{" "}
                                {pill.name}
                              </span>
                            ) : pill.type === "domain" ? (
                              <span
                                key={pindex}
                                className="custom-badge lightgray"
                              >
                                {pill.xtd ? (
                                  <span className="text-red">XTD</span>
                                ) : null}{" "}
                                {pill.name}
                              </span>
                            ) : pill.type === "link" ? (
                              <span
                                key={pindex}
                                className="custom-badge lightgray"
                              >
                                {pill.xtd ? (
                                  <span className="text-red">XTD</span>
                                ) : null}{" "}
                                {pill.name}
                              </span>
                            ) : pill.type === "direct-lead" ? (
                              <span
                                key={pindex}
                                className="custom-badge red-o direct-lead"
                              >
                                {pill.name}
                              </span>
                            ) : pill.type === "indirect-lead" ? (
                              <span
                                key={pindex}
                                className="custom-badge gray-o indirect-lead"
                              >
                                {pill.name}
                              </span>
                            ) : null
                          )
                        : null}

                      {/* admin stats owned by the person in focus to show here */}
                      {adminTasks && adminTasks.length > 0
                        ? adminTasks.map((aTask, atindex) => {
                            return (
                              <span
                                key={atindex}
                                className="custom-badge green-o"
                              >
                                {aTask.statusReport}
                              </span>
                            );
                          })
                        : null}
                    </div>
                    <a
                      // onClick={() => setReportCircleData(e._doc)}
                      className="card-edit"
                    >
                      {e._doc.circleName}
                    </a>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );

  const PRProject = () => (
    <div className="board-col" data-board_id="projects">
      <div className="list list-bg-red" data-list_id="projects">
        <div className="dropdown">
          <h4 className="list-title">
            <img alt="" src="../images/icon-project.png" /> {/* Projects */}{GetAliasesName(teams, "Projects")}
            <a className="list-menu refresh-list hide" href="#">
              <i className="fas fa-sync-alt"></i>
            </a>
            <a
              className="list-menu dropdown-toggle"
              data-toggle="dropdown"
              href="#"
            >
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation" onClick={() => isInfos("Projects")}>
                <a role="menuitem" href="#" className="mytpt-list-info">
                  Info
                </a>
              </li>
              <li role="presentation" onClick={() => hideInfo(6)}>
                <a role="menuitem" href="#" className="hide_list">
                  Hide list
                </a>
              </li>
            </ul>
          </h4>
        </div>

        <div className="card-list scrollbar">
          {noteSaveData.Projects ? (
            <div className="form-group notes">
              <textarea
                className="notes"
                value={noteSaveData.Projects}
                readOnly={true}
                name="People"
                placeholder="Notes"
                maxLength="100"
              ></textarea>
            </div>
          ) : null}

          {reportProject && reportProject.length > 0
            ? reportProject.map((e, index) => {
                let greyPills = getPills(e);

                return (
                  <div
                    key={index}
                    className="card readonly"
                    data-list_id="projects"
                    onClick={(event) => {
                      if (
                        event.target.classList.contains("card") ||
                        event.target.classList.contains("card-edit")
                      ) {
                        setReportProjectData(e._doc);
                      }
                    }}
                  >
                    <div className="static-relations">
                      {e._doc.tags && e._doc.tags.length > 0
                        ? e._doc.tags.map((r, rindex) => (
                            <span
                              key={rindex}
                              className="custom-badge green tags"
                            >
                              {r}
                            </span>
                          ))
                        : null}

                      {greyPills && greyPills.length > 0
                        ? greyPills.map((pill, pindex) =>
                            pill.type === "person" ? (
                              <span
                                key={pindex}
                                className="custom-badge lightgray text-red person-pill"
                              >
                                {pill.xtd ? (
                                  <span className="text-red">XTD</span>
                                ) : null}{" "}
                                {pill.name}
                              </span>
                            ) : pill.type === "skill" ? (
                              <span
                                key={pindex}
                                className="custom-badge lightgray"
                              >
                                {pill.xtd ? (
                                  <span className="text-red">XTD</span>
                                ) : null}{" "}
                                {pill.name}
                              </span>
                            ) : pill.type === "role" ? (
                              <span
                                key={pindex}
                                className="custom-badge lightgray"
                              >
                                {pill.xtd ? (
                                  <span className="text-red">XTD</span>
                                ) : null}{" "}
                                {pill.name}
                              </span>
                            ) : pill.type === "domain" ? (
                              <span
                                key={pindex}
                                className="custom-badge lightgray"
                              >
                                {pill.xtd ? (
                                  <span className="text-red">XTD</span>
                                ) : null}{" "}
                                {pill.name}
                              </span>
                            ) : pill.type === "link" ? (
                              <span
                                key={pindex}
                                className="custom-badge lightgray"
                              >
                                {pill.xtd ? (
                                  <span className="text-red">XTD</span>
                                ) : null}{" "}
                                {pill.name}
                              </span>
                            ) : pill.type === "direct-lead" ? (
                              <span
                                key={pindex}
                                className="custom-badge red-o direct-lead"
                              >
                                {pill.name}
                              </span>
                            ) : pill.type === "indirect-lead" ? (
                              <span
                                key={pindex}
                                className="custom-badge gray-o indirect-lead"
                              >
                                {pill.name}
                              </span>
                            ) : null
                          )
                        : null}
                    </div>

                    <a
                      // onClick={() => setReportProjectData(e._doc)}
                      className="card-edit"
                    >
                      {e._doc.projectName}
                    </a>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );

  const PRMeeting = () => (
    <div
      className="board-col board-col-meetings default"
      data-board_id="meetings"
    >
      <div className="list default-circle" data-list_id="meetings">
        <div className="dropdown">
          <h4 className="list-title">
            My Meetings
            <a className="list-menu refresh-list hide" href="#">
              <i className="fas fa-sync-alt"></i>
            </a>
          </h4>
        </div>

        <div className="card-list scrollbar">
          {reportMeeting && reportMeeting.length > 0
            ? reportMeeting.map((e, index) => {
                return (
                  <div key={index} className="card meeting-card">
                    <p>
                      <span className="custom-badge lightgray">{e.name}</span>
                      <br />
                      <span className="fas fa-users"></span>
                      <b> {e.meetingsName}</b>
                    </p>
                    <p>{e.meetingsPurpose}</p>
                    <p>
                      Every {`${e.recurrence} ${e.recurrenceType}`} for{" "}
                      {`${e.duration} ${e.recurrenceType}`}
                    </p>
                    {e.XTD ? <p>incl. XTD</p> : null}
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );

  let reportList = [
    PRPeople,
    PRSkill,
    PRRole,
    PRDomain,
    PRLink,
    PRCircle,
    PRProject,
    PRMeeting,
  ];

  //create by ranjit
  let arr = [];
  for (let i = 0; i < filterReport.length; i++) {
    if (filterReport[i].isChecked === false) {
      arr.push(reportList[i]);
    }
  }

  // edit by ranjit
  return (
    <section className="board-body">
      <div className="wrap-lists" id="my-report">
        {arr && arr.length > 0
          ? arr.map((Item, index) => {
              return <Item key={index} />;
            })
          : null}
      </div>
    </section>
  );
}
