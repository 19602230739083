export const STATE_CREATE_SUCCESS = 'STATE_CREATE_SUCCESS'
export const STATE_CREATE_FAIL = 'STATE_CREATE_FAIL'
export const STATE_GET_SUCCESS = 'STATE_GET_SUCCESS'
export const STATE_GET_FAIL = 'STATE_GET_FAIL'
export const STATE_DELETE_SUCCESS = 'STATE_DELETE_SUCCESS'
export const STATE_UPDATE_SUCCESS = 'STATE_UPDATE_SUCCESS'
export const STATE_UPDATE_FAIL = 'STATE_UPDATE_FAIL'
export const STATE_DELETE_FAIL = 'STATE_DELETE_FAIL'

export const STATELM_SET_SUCCESS = 'STATELM_SET_SUCCESS'
export const STATELM_SET_FAIL = 'STATELM_SET_FAIL'

export const STATE_SUCCESS_MESSAGE_CLEAR = 'STATE_SUCCESS_MESSAGE_CLEAR'
export const STATE_ERROR_CLEAR = 'STATE_ERROR_CLEAR'
export const SIGN_OUT = 'SIGN_OUT'

export const STATES_MODIFICATIONS = "STATES_MODIFICATIONS"