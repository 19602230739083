import React from 'react';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';
// import { colourOptions } from '../data';
import { useDispatch, useSelector } from 'react-redux';


const animatedComponents = makeAnimated();


export default function Selectize({ data, ownerType, handleChange }) {


  const { peoples, peopleMessagee, peopleError } = useSelector(state => state.peoples)

  // console.log(data)


  var cumentor = []


  if (data && data.mentees.length > 0) {
    for (let i = 0; i < peoples.length; i++) {
      let inc = data.mentees.includes(peoples[i]._id)
      if (inc) {
        cumentor.push({ value: `${peoples[i]._id}`, label: `${peoples[i].fname} ${peoples[i].lname}` })
      }
    }
  }




  //options
  var arr = []
  peoples.map(e => {
    arr.push({ value: `${e._id}`, label: `${e.fname} ${e.lname}` })
  })

  const options = arr



  const [value, setValue] = React.useState(cumentor);
  
  let mentorData = []
  if(value){
    for(let i = 0; i < value.length; i++){
      mentorData.push(value[i].value)
    }
    data.mentees = mentorData
  }


  // console.log(value)
  // console.log(mentorData)

  // console.log(ownerType)

  return (
    <div>
      {ownerType == "Single owner" ?
        (<Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          onChange={(newValue) => setValue(newValue)}
          defaultValue={[...cumentor]}
          options={options}
        />) : (
          <Select
            closeMenuOnSelect={false}
            components={animatedComponents}
            onChange={(newValue) => setValue(newValue)}
            defaultValue={[...cumentor]}
            isMulti
            options={options}
          />
        )
      }
    </div>
  )

};

