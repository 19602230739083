import axios from 'axios'
import { SERVER_URI,CLIENT_MODE,CLIENT_URL } from '../../config/keys'
import {
    REPORT_AUTH_SUCCESS, REPORT_AUTH_FAIL, REPORT_TEAM_GET_SUCCESS, REPORT_TEAM_GET_ERROR,
    REPORT_PEOPLE_GET_SUCCESS, REPORT_PEOPLE_GET_ERROR, REPORT_SKILL_GET_ERROR, REPORT_SKILL_GET_SUCCESS, REPORT_ROLE_GET_SUCCESS
    , REPORT_ROLE_GET_ERROR, REPORT_CIRCLE_GET_SUCCESS, REPORT_DOMAIN_GET_SUCCESS, REPORT_LINK_GET_SUCCESS, REPORT_CIRCLE_GET_ERROR
,REPORT_PROJECT_GET_SUCCESS, REPORT_PROJECT_GET_ERROR, REPORT_MEETING_GET_SUCCESS, REPORT_MEETING_GET_ERROR, ALL_REPORT_GET_SUCCESS, ALL_REPORT_GET_ERROR,REPORT_REQUEST_SENT_SUCCESS,REPORT_REQUEST_SENT_ERROR }
    from '../types/reportType'

export const reportAuth = (datas) => {
    return async (dispatch) => {
        try {
            const response = await axios.post(`${SERVER_URI}/user/report/report-auth`, datas)
            // console.log(response.data.data.tokenId)
            localStorage.setItem('reportTokens', response.data.data.tokenId)

            dispatch({
                type: REPORT_AUTH_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                    token: response.data.data.tokenId,
                    peopleId: response.data.data.peopleId,
                    teamId: response.data.data.teamId
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: REPORT_AUTH_FAIL,
                    payload: {
                        reportError: data
                    }
                })
            }else if(error.message){
                dispatch({
                    type: REPORT_AUTH_FAIL,
                    payload: {
                        reportError: error.message
                    }
                })
            }

        }
    }
}

export const  reportAction = (datas) => {
    return async (dispatch) => {
        try {
            // console.log(datas)
            const response = await axios.post(`${SERVER_URI}/user/report/create`, datas);
            // console.log(response, response.data,response.data.message);
            dispatch({
                type: REPORT_REQUEST_SENT_SUCCESS,
                payload: {
                    successMessage: response.data.message
                }
            })

        } catch (error) {
            let data = error.response.data.message
            // console.log(error)
            dispatch({
                type: REPORT_REQUEST_SENT_ERROR,
                payload: {
                    errorMessage: data
                }
            })

        }
    }
}

export const reportGetAdmin = (datas) => {
    return async (dispatch) => {
        try {
            // console.log(datas)
            const response = await axios.post(`${SERVER_URI}/user/report/get-report-by-admin`, datas)
            // console.log(response.data.data)
            // window.location = response.data.data
            if (CLIENT_MODE === "staging"){
                let responseUrl = response.data.data;
                let newUrl = responseUrl.replace("https://app.teamdecoder.com/",CLIENT_URL);
                window.open(newUrl, '_blank', 'noreferrer');
            }else{
                window.open(response.data.data, '_blank', 'noreferrer');
            }

        } catch (error) {
            // let data = error.response.data.message
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: REPORT_AUTH_FAIL,
                    payload: {
                        reportError: data
                    }
                })
            }else if(error.message){
                dispatch({
                    type: REPORT_AUTH_FAIL,
                    payload: {
                        reportError: error.message
                    }
                })
            }

        }
    }
}

export const reportGetTeam = (datas) => {
    return async (dispatch) => {
        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'teamId': datas.teamId
            }
        }
        // console.log(config)
        try {
            const response = await axios.get(`${SERVER_URI}/user/report/get-report-team`, config)
            dispatch({
                type: REPORT_TEAM_GET_SUCCESS,
                payload: {
                    reportTeam: response.data.data
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: REPORT_TEAM_GET_ERROR,
                    payload: {
                        reportError: data
                    }
                })
            }else if(error.message){
                dispatch({
                    type: REPORT_TEAM_GET_ERROR,
                    payload: {
                        reportError: error.message
                    }
                })
            }
        }
    }
}

export const reportGetPeople = (datas) => {
    return async (dispatch) => {
        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'teamId': datas.teamId,
                'peopleId': datas.peopleId
            }
        }
        // console.log(config)
        try {
            const response = await axios.get(`${SERVER_URI}/user/report/get-report-people`, config)
            // console.log(response)
            dispatch({
                type: REPORT_PEOPLE_GET_SUCCESS,
                payload: {
                    reportPeople: response.data.data
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: REPORT_PEOPLE_GET_ERROR,
                    payload: {
                        reportError: data
                    }
                })
            }else if(error.message){
                dispatch({
                    type: REPORT_PEOPLE_GET_ERROR,
                    payload: {
                        reportError: error.message
                    }
                })
            }
        }
    }
}

export const reportGetSkill = (datas) => {
    return async (dispatch) => {
        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'teamId': datas.teamId,
                'peopleId': datas.peopleId
            }
        }
        // console.log(config)
        try {
            const response = await axios.get(`${SERVER_URI}/user/report/get-report-skill`, config)
            // console.log(response.data.data)
            dispatch({
                type: REPORT_SKILL_GET_SUCCESS,
                payload: {
                    reportSkill: response.data.data
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: REPORT_SKILL_GET_ERROR,
                    payload: {
                        reportError: data
                    }
                })
            }else if(error.message){
                dispatch({
                    type: REPORT_SKILL_GET_ERROR,
                    payload: {
                        reportError: error.message
                    }
                })
            }
        }
    }
}

export const reportGetRole = (datas) => {
    return async (dispatch) => {
        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'teamId': datas.teamId,
                'peopleId': datas.peopleId
            }
        }
        // console.log(config)
        try {
            const response = await axios.get(`${SERVER_URI}/user/report/get-report-role`, config)
            dispatch({
                type: REPORT_ROLE_GET_SUCCESS,
                payload: {
                    reportRole: response.data.data
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: REPORT_ROLE_GET_ERROR,
                    payload: {
                        reportError: data
                    }
                })
            }else if(error.message){
                dispatch({
                    type: REPORT_ROLE_GET_ERROR,
                    payload: {
                        reportError: error.message
                    }
                })
            }
        }
    }
}

export const reportGetDomain = (datas) => {
    return async (dispatch) => {
        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'teamId': datas.teamId,
                'peopleId': datas.peopleId
            }
        }
        // console.log(config)
        try {
            const response = await axios.get(`${SERVER_URI}/user/report/get-report-domain`, config)
            dispatch({
                type: REPORT_DOMAIN_GET_SUCCESS,
                payload: {
                    reportDomain: response.data.data
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: REPORT_ROLE_GET_ERROR,
                    payload: {
                        reportError: data
                    }
                })
            }else if(error.message){
                dispatch({
                    type: REPORT_ROLE_GET_ERROR,
                    payload: {
                        reportError: error.message
                    }
                })
            }
        }
    }
}

export const reportGetLink = (datas) => {
    return async (dispatch) => {
        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'teamId': datas.teamId,
                'peopleId': datas.peopleId
            }
        }
        // console.log(config)
        try {
            const response = await axios.get(`${SERVER_URI}/user/report/get-report-link`, config)
            // console.log(response)
            dispatch({
                type: REPORT_LINK_GET_SUCCESS,
                payload: {
                    reportLink: response.data.data
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: REPORT_ROLE_GET_ERROR,
                    payload: {
                        reportError: data
                    }
                })
            }else if(error.message){
                dispatch({
                    type: REPORT_ROLE_GET_ERROR,
                    payload: {
                        reportError: error.message
                    }
                })
            }
        }
    }
}

export const reportGetCircle = (datas) => {
    return async (dispatch) => {
        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'teamId': datas.teamId,
                'peopleId': datas.peopleId,
            }
        }
        // console.log(config)
        try {
            const response = await axios.get(`${SERVER_URI}/user/report/get-report-circle`, config)
            dispatch({
                type: REPORT_CIRCLE_GET_SUCCESS,
                payload: {
                    reportCircle: response.data.data
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: REPORT_CIRCLE_GET_ERROR,
                    payload: {
                        reportError: data
                    }
                })
            }else if(error.message){
                dispatch({
                    type: REPORT_CIRCLE_GET_ERROR,
                    payload: {
                        reportError: error.message
                    }
                })
            }
        }
    }
}

export const reportGetProject = (datas) => {
    return async (dispatch) => {
        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'teamId': datas.teamId,
                'peopleId': datas.peopleId,
            }
        }
        // console.log(config)
        try {
            const response = await axios.get(`${SERVER_URI}/user/report/get-report-project`, config)
            dispatch({
                type: REPORT_PROJECT_GET_SUCCESS,
                payload: {
                    reportProjet: response.data.data
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: REPORT_PROJECT_GET_ERROR,
                    payload: {
                        reportError: data
                    }
                })
            }else if(error.message){
                dispatch({
                    type: REPORT_PROJECT_GET_ERROR,
                    payload: {
                        reportError: error.message
                    }
                })
            }
        }
    }
}

export const reportGetMeeting = (datas) => {
    return async (dispatch) => {
        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'teamId': datas.teamId,
                'peopleId': datas.peopleId,
            }
        }
        // console.log(config)
        try {
            const response = await axios.get(`${SERVER_URI}/user/report/get-report-meeting`, config)
            dispatch({
                type: REPORT_MEETING_GET_SUCCESS,
                payload: {
                    reportMeeting: response.data.data
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: REPORT_MEETING_GET_ERROR,
                    payload: {
                        reportError: data
                    }
                })
            }else if(error.message){
                dispatch({
                    type: REPORT_MEETING_GET_ERROR,
                    payload: {
                        reportError: error.message
                    }
                })
            }
        }
    }
}

export const allReportGet = (datas) => {
    return async (dispatch) => {
        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'teamId': datas.teamId,
                'peopleId': datas.peopleId,
            }
        }
        // console.log(config)
        try {
            const response = await axios.get(`${SERVER_URI}/user/report/get-all-report`, config)
            dispatch({
                type: ALL_REPORT_GET_SUCCESS,
                payload: {
                    allReport: response.data.data
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: ALL_REPORT_GET_ERROR,
                    payload: {
                        reportError: data
                    }
                })
            }else if(error.message){
                dispatch({
                    type: ALL_REPORT_GET_ERROR,
                    payload: {
                        reportError: error.message
                    }
                })
            }
        }
    }
}