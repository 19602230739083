export const CIRCLE_CREATE_SUCCESS = 'CIRCLE_CREATE_SUCCESS'
export const CIRCLE_CREATE_FAIL = 'CIRCLE_CREATE_FAIL'
export const CIRCLE_GET_SUCCESS = 'CIRCLE_GET_SUCCESS'
export const CIRCLE_GET_FAIL = 'CIRCLE_GET_FAIL'
export const CIRCLE_DELETE_SUCCESS = 'CIRCLE_DELETE_SUCCESS'
export const CIRCLE_UPDATE_SUCCESS = 'CIRCLE_UPDATE_SUCCESS'
export const CIRCLE_UPDATE_FAIL = 'CIRCLE_UPDATE_FAIL'
export const CIRCLE_DELETE_FAIL = 'CIRCLE_DELETE_FAIL'
export const CIRCLE_DROP_UPDATE_SUCCESS = 'CIRCLE_DROP_UPDATE_SUCCESS'
export const CIRCLE_DROP_UPDATE_FAIL = 'CIRCLE_DROP_UPDATE_FAIL'

export const CIRCLE_ID_CLEAR = 'CIRCLE_ID_CLEAR'

export const CIRCLE_SUCCESS_MESSAGE_CLEAR = 'CIRCLE_SUCCESS_MESSAGE_CLEAR'
export const CIRCLE_ERROR_CLEAR = 'CIRCLE_ERROR_CLEAR'
export const SIGN_OUT = 'SIGN_OUT'