import {
    MEETINGS_CREATE_SUCCESS, MEETINGS_CREATE_FAIL, MEETINGS_GET_SUCCESS, MEETINGS_DELETE_SUCCESS,
    MEETINGS_UPDATE_SUCCESS, MEETINGS_UPDATE_FAIL, MEETINGS_DELETE_FAIL, MEETINGS_GET_FAIL,  
    MEETINGS_SUCCESS_MESSAGE_CLEAR,  MEETINGS_ERROR_CLEAR, SIGN_OUT}
        from '../types/meetingType'


const userState = {
   meetings: [],
   meetingsMessage: '',
   meetingsMessagee: '',
   meetingsError: '',
   meetingId : ''
}


export const meetingReducer = (state = userState, action) => {
   const { type, payload } = action
   // const {users, successMessage } = payload
   // console.log(payload.users)

   if (type === MEETINGS_CREATE_SUCCESS) {
       return {
           ...state,
        //    meetings: payload.meetings,
           meetingsMessagee: payload.meetingsSuccessMessage,
           meetingId: payload.meetingId,
           meetingsError: ""
       }
   }
   if (type === MEETINGS_GET_SUCCESS) {
       return {
           ...state,
           meetings: payload.meetings,
           meetingsMessage: payload.meetingsSuccessMessage,
           meetingsError : ""
       }
   }
   if (type === MEETINGS_DELETE_SUCCESS) {
       return {
           ...state,
           meetingsMessagee: payload.meetingsSuccessMessage,
           meetingsError : ""
       }
   }
   if (type === MEETINGS_UPDATE_SUCCESS) {
       return {
           ...state,
           meetingsMessagee: payload.meetingSuccessMessage,
           meetingsError : ""
       }
   }
   if (type === MEETINGS_CREATE_FAIL) {
       return {
           ...state,
           meetingsError: payload.meetingsError
       }
   }
   if (type === MEETINGS_DELETE_FAIL) {
       return {
           ...state,
           meetingsError: payload.error
       }
   }
   if (type === MEETINGS_UPDATE_FAIL) {
       return {
           ...state,
           meetingsError: payload.error
       }
   }
   if (type === MEETINGS_GET_FAIL) {
       return {
           ...state,
           meetingsError: payload.error
       }
   }
   if (type ===MEETINGS_SUCCESS_MESSAGE_CLEAR) {
    return{
        ...state,
        meetingsMessagee: '',
        meetingId: ''
    }
}
if (type === MEETINGS_ERROR_CLEAR) {
    return {
        ...state,
        meetingsError: ''
    }
}

if (type === SIGN_OUT) {
    return {
        ...state,
        links: [],
        linkMessagee: '',
        linkMessage: '',
        linkError: ''
    }
}


   return state;
}