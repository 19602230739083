import React from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PillComponent from '../PillComponent';

export default function FPProjectsList({ tagFilteredMegedData, handleAliesChange, GetAliasesName, teams, curPefProjects, getSortIcon, isInfos, setProjects, pCardHideOnchange, handleDragStart, handleDragEndPF, note, handleSave, noteSaveData, isTag, tagFilteredObj, memberInDatas, focused, pfProjectLead, getAdminTasks, ScrollIntoView, setProject, toggleTagCards, temporarilyMovedItem, fpPrref, projectsfSearch, setProjectsfSearch, filteredPrFData, pfcircleOrProjectModes, lFromState, projectSubmit, prref, handleProjectChange, setProjectName, projectsFrom, cardOpens, PfToCAndP, removeCircleFCoreOrExt, project }) {
    return (
        <div
            className={
                Object.keys(tagFilteredMegedData).length > 0 &&
                    tagFilteredMegedData.project &&
                    tagFilteredMegedData.project.length === 0
                    ? "board-col hide"
                    : "board-col"
            }
            data-board_id="projects"
        >
            <div className="list list-bg-red" data-list_id="projects">
                <div className="dropdown">
                    <h4 className="list-title">
                        <img alt="" src="images/icon-project.png" /> {/* {GetAliasesName(teams, "Projects")} (
            <span>{}</span>){" "} */}
                        <span contentEditable={true}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault(); // Prevent new line
                                }
                            }} data-name="projects" onBlur={(e) => handleAliesChange(e)}>{GetAliasesName(teams, "Projects")}</span>
                        <span>{` (${curPefProjects.length})`}</span>{" "}
                        <a className="list-menu refresh-list hide" href="#">
                            <i className="fas fa-sync-alt"></i>
                        </a>{" "}
                        <a
                            className="list-menu dropdown-toggle"
                            data-toggle="dropdown"
                            href="#"
                        >
                            {getSortIcon("Projects")}
                            <i className="fas fa-ellipsis-v"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-right" role="menu">
                            <li role="presentation">
                                <a
                                    onClick={() => isInfos("Projects")}
                                    role="menuitem"
                                    className="mytpt-list-info"
                                >
                                    Info
                                </a>
                            </li>
                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    data-toggle="modal"
                                    data-target="#projectsModal"
                                    onClick={() => setProjects()}
                                >
                                    Add new {GetAliasesName(teams, "Projects").toLowerCase()}
                                </a>
                            </li>
                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    onClick={() => pCardHideOnchange("Projects")}
                                    className="hide_list"
                                >
                                    Hide list
                                </a>
                            </li>
                            <li role="presentation">
                                <a role="menuitem" style={{ paddingRight: "5px" }}>
                                    Show Notes
                                    <label className="switch">
                                        <input
                                            name="show_notes_people"
                                            className="dd-switch"
                                            type="checkbox"
                                        />
                                        <span className="slider"></span>
                                    </label>
                                </a>
                            </li>
                            <li role="presentation" className="hide">
                                <a role="menuitem" className="show-external">
                                    Show external people
                                </a>
                            </li>
                            <li role="presentation" className="hide">
                                <a role="menuitem" className="show-noowners">
                                    Show items without owners
                                </a>
                            </li>
                        </ul>
                    </h4>
                </div>

                <DragDropContext
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEndPF}
                >
                    <div className="card-list scrollbar">
                        <div
                            className="form-group notes"
                            style={note.Projects ? { display: "block" } : { display: "none" }}
                        >
                            <textarea
                                className="notes"
                                name="Projects"
                                // defaultValue=''
                                // value={noteSaveData.Projects}
                                //   onChange={(e) => handleSave(e)}
                                onBlur={(e) => handleSave(e)}
                                placeholder="Notes"
                                maxLength="100"
                                defaultValue={noteSaveData.Projects}
                            ></textarea>
                        </div>

                        <Droppable
                            className="panel panel-default"
                            droppableId="projects-core"
                            direction="vertical"
                        >
                            {(projectsProvidedPf) => (
                                <div
                                    ref={projectsProvidedPf.innerRef}
                                    {...projectsProvidedPf.droppableProps}
                                    id="core"
                                    className="panel-body box-container"
                                >
                                    {curPefProjects && curPefProjects.length > 0
                                        ? curPefProjects.map((e, index) => {

                                            if (
                                                isTag &&
                                                Object.keys(tagFilteredObj).length > 0 &&
                                                tagFilteredObj.project &&
                                                tagFilteredObj.project.length > 0 &&
                                                !tagFilteredObj.project.includes(e._id)
                                            ) {
                                                return null;
                                            }
                                            let MemData = [];
                                            for (let i = 0; i < memberInDatas.length; i++) {
                                                if (memberInDatas[i].memberId === e._id) {
                                                    MemData.push(memberInDatas[i]);
                                                }
                                            }
                                            let flag = false;
                                            if (
                                                focused &&
                                                focused.card.length > 0 &&
                                                focused.card === "People"
                                            ) {
                                                flag = pfProjectLead(e);
                                            }
                                            let isAddAsP = false;
                                            if (MemData && MemData.length === 1) {
                                                if (MemData[0].cardType === "People") {
                                                    isAddAsP = true;
                                                }
                                            }
                                            let curpOIndex = null;
                                            if (MemData && MemData.length > 0) {
                                                for (let i = 0; i < MemData.length; i++) {
                                                    if (MemData[i].cardType == "People") {
                                                        curpOIndex = i;
                                                        break;
                                                    }
                                                }
                                            }

                                            let adminTasks = getAdminTasks(e);

                                            return (
                                                <Draggable
                                                    key={e.id}
                                                    draggableId={`${e._id}`}
                                                    index={index}
                                                    isDragDisabled={
                                                        (MemData.length === 1 ||
                                                            isAddAsP ||
                                                            curpOIndex !== null) &&
                                                            (!flag || isAddAsP)
                                                            ? false
                                                            : true
                                                    }
                                                >
                                                    {(projectsProvidedPf) => (
                                                        <div
                                                            ref={projectsProvidedPf.innerRef}
                                                            {...projectsProvidedPf.draggableProps}
                                                            {...projectsProvidedPf.dragHandleProps}
                                                            // onDragStart={(e) => projectsDragStart(e, index)}
                                                            // onDragEnter={(e) => projectsDragEnter(e, index)}
                                                            // onDragEnd={projectsDrop}
                                                            // draggable
                                                            key={e._id}
                                                            className="card"
                                                            data-list_id="projects"
                                                            onClick={(event) => {
                                                                ScrollIntoView();
                                                                if (
                                                                    event.target.classList.contains("card") ||
                                                                    event.target.classList.contains("card-edit")
                                                                ) {
                                                                    setProject(e, curPefProjects, index);
                                                                }
                                                            }}
                                                        >
                                                            <div className="static-relations">
                                                                {e.tags && e.tags.length > 0
                                                                    ? e.tags.map((r, tindex) => (
                                                                        <span
                                                                            onClick={() => toggleTagCards(r)}
                                                                            key={tindex}
                                                                            className="custom-badge green tags"
                                                                        >
                                                                            {r}
                                                                        </span>
                                                                    ))
                                                                    : null}

                                                                {flag && flag === "direct" ? (
                                                                    <span className="custom-badge direct-lead red-o">
                                                                        LEAD
                                                                    </span>
                                                                ) : flag && flag === "indirect" ? (
                                                                    <span className="custom-badge direct-lead gray-o">
                                                                        LEAD
                                                                    </span>
                                                                ) : null}

                                                                {Object.keys(focused).length > 0 && focused.id && e.standIn &&
                                                                    focused.id === e.standIn &&
                                                                    <span class="custom-badge gray-o">LEAD (Stand-In)</span>
                                                                }

                                                                {MemData && MemData.length > 0
                                                                    ? MemData.map((m, index) => {
                                                                        return (
                                                                            <span
                                                                                onClick={(e) => cardOpens(m)}
                                                                                className={
                                                                                    m.cardType == "People"
                                                                                        ? "custom-badge lightgray text-red person-pill"
                                                                                        : "custom-badge lightgray"
                                                                                }
                                                                            >
                                                                                {m.XTD && m.XTD.length > 0 ? (
                                                                                    <span className="text-red">
                                                                                        XTD{" "}
                                                                                    </span>
                                                                                ) : null}
                                                                                {m.name}
                                                                            </span>
                                                                        );
                                                                    })
                                                                    : null}

                                                                {adminTasks && adminTasks.length > 0
                                                                    ? adminTasks.map((aTask, atindex) => {
                                                                        return (
                                                                            <span
                                                                                key={atindex}
                                                                                className="custom-badge green-o"
                                                                            >
                                                                                {aTask.statusReport}
                                                                            </span>
                                                                        );
                                                                    })
                                                                    : null}
                                                            </div>
                                                            <a
                                  /* onClick={() => setProject(e, curPefProjects, index)} */ className="card-edit"
                                                            >
                                                                {e.projectName}
                                                            </a>
                                                            <a className="card-focus">
                                                                <img
                                                                    onClick={() => PfToCAndP("Projects", e)}
                                                                    src="./images/newicons/focus.svg"
                                                                />
                                                            </a>
                                                            {(MemData.length === 1 ||
                                                                isAddAsP ||
                                                                curpOIndex !== null) &&
                                                                (!flag || isAddAsP) ? (
                                                                <a
                                                                    className="card-unlink"
                                                                    onClick={() =>
                                                                        removeCircleFCoreOrExt(
                                                                            MemData[
                                                                            curpOIndex !== null ? curpOIndex : 0
                                                                            ]
                                                                        )
                                                                    }
                                                                    style={{ display: "block", color: "red" }}
                                                                >
                                                                    <i className="icon far fa-times-circle"></i>
                                                                </a>
                                                            ) : null}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })
                                        : null}

                                    {temporarilyMovedItem &&
                                        temporarilyMovedItem.cardType === "Project" &&
                                        Object.keys(temporarilyMovedItem.card).length > 0
                                        ? (() => {
                                            let flag = false;
                                            if (
                                                focused &&
                                                focused.card.length > 0 &&
                                                focused.card === "People"
                                            ) {
                                                flag = pfProjectLead(temporarilyMovedItem.card); // Pass the temporarilyMovedItem to the function
                                            }

                                            return (
                                                <div
                                                    key={temporarilyMovedItem.card._id}
                                                    className="card move-up-down"
                                                    style={{
                                                        border: "1px dashed #000",
                                                        opacity: "0.7",
                                                    }}
                                                >
                                                    <span className="static-relations">
                                                        {temporarilyMovedItem.card.tags &&
                                                            temporarilyMovedItem.card.tags.length > 0
                                                            ? temporarilyMovedItem.card.tags.map(
                                                                (r, indexs) => (
                                                                    <span
                                                                        onClick={() => toggleTagCards(r)}
                                                                        key={indexs}
                                                                        className="custom-badge green tags"
                                                                    >
                                                                        {r}
                                                                    </span>
                                                                )
                                                            )
                                                            : null}

                                                        {flag && flag === "direct" ? (
                                                            <span className="custom-badge direct-lead red-o">
                                                                LEAD
                                                            </span>
                                                        ) : flag && flag === "indirect" ? (
                                                            <span className="custom-badge direct-lead gray-o">
                                                                LEAD
                                                            </span>
                                                        ) : null}
                                                    </span>
                                                    <a className="card-edit">
                                                        {temporarilyMovedItem.card.projectName}
                                                    </a>
                                                </div>
                                            );
                                        })()
                                        : null}

                                    {projectsProvidedPf.placeholder}
                                </div>
                            )}
                        </Droppable>

                        <span className="clearable2">
                            <input
                                type="text"
                                ref={fpPrref}
                                style={{ color: "black" }}
                                value={projectsfSearch}
                                onChange={(e) => setProjectsfSearch(e.target.value)}
                                className="form-control search_list2 transparent"
                                name="search_list"
                                placeholder="Search to select more"
                                autoComplete="off"
                            />
                            {projectsfSearch.length > 0 ? (
                                <i
                                    className="clearable__clear"
                                    onClick={() => setProjectsfSearch("")}
                                    style={{ display: "inline", color: "black" }}
                                >
                                    ×
                                </i>
                            ) : null}
                        </span>

                        <Droppable
                            className="panel panel-default"
                            droppableId="projects-notmatched"
                            direction="vertical"
                        >
                            {(projectsProvidedPf) => (
                                <div
                                    ref={projectsProvidedPf.innerRef}
                                    {...projectsProvidedPf.droppableProps}
                                    id="notmatched"
                                    className="panel-body box-container"
                                >
                                    {filteredPrFData && filteredPrFData.length > 0
                                        ? filteredPrFData.map((e, index) => {
                                            if (
                                                isTag &&
                                                Object.keys(tagFilteredObj).length > 0 &&
                                                tagFilteredObj.project &&
                                                tagFilteredObj.project.length > 0 &&
                                                !tagFilteredObj.project.includes(e._id)
                                            ) {
                                                return null;
                                            }
                                            if (
                                                temporarilyMovedItem &&
                                                temporarilyMovedItem.cardType === "Project" &&
                                                temporarilyMovedItem.card._id === e._id
                                            )
                                                return;

                                            return (
                                                <Draggable
                                                    key={e.id}
                                                    draggableId={`${e._id}`}
                                                    index={index}
                                                >
                                                    {(projectsProvidedPf) => (
                                                        <div
                                                            ref={projectsProvidedPf.innerRef}
                                                            {...projectsProvidedPf.draggableProps}
                                                            {...projectsProvidedPf.dragHandleProps}
                                                            // onDragStart={(e) => projectsDragStart(e, index)}
                                                            // onDragEnter={(e) => projectsDragEnter(e, index)}
                                                            // onDragEnd={() => pfcircleOrProjectModes(e, "Projects")}
                                                            // draggable
                                                            key={e._id}
                                                            className={`card notmatched ${localStorage.getItem("latest-created") ===
                                                                e._id
                                                                ? " latest-created "
                                                                : ""
                                                                }`}
                                                            data-list_id="projects"
                                                        >
                                                            <div className="static-relations">
                                                                {e.tags && e.tags.length > 0
                                                                    ? e.tags.map((r, tindex) => (
                                                                        <span
                                                                            key={tindex}
                                                                            className="custom-badge green tags"
                                                                        >
                                                                            {r}
                                                                        </span>
                                                                    ))
                                                                    : null}
                                                            </div>
                                                            <a
                                                                onClick={() =>
                                                                    setProject(e, filteredPrFData, index)
                                                                }
                                                                className="card-edit"
                                                            >
                                                                {e.projectName}
                                                            </a>
                                                            <a
                                                                className="card-link"
                                                                variant="success"
                                                                style={{ display: "block" }}
                                                            >
                                                                <i
                                                                    onClick={() =>
                                                                        pfcircleOrProjectModes(e, "Projects")
                                                                    }
                                                                    className="icon fa fa-plus-circle"
                                                                ></i>
                                                            </a>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })
                                        : null}
                                    {projectsProvidedPf.placeholder}
                                </div>
                            )}
                        </Droppable>

                        <div
                            className="card2"
                            style={
                                lFromState.Projects ? { display: "block" } : { display: "none" }
                            }
                        >
                            <form
                                className="form-new-card"
                                role="form"
                                onSubmit={projectSubmit}
                            >
                                <div className="form-group">
                                    <input
                                        type="text"
                                        ref={prref}
                                        name="card_name"
                                        required
                                        placeholder={`+ Add ${GetAliasesName(teams, "Projects").toLowerCase()} name`}
                                        className="form-control"
                                        value={project}
                                        onChange={handleProjectChange}
                                        autoComplete="off"
                                    />
                                </div>
                                <div className="form-group" style={{ marginTop: "10px" }}>
                                    <button type="submit" className="btn btn-mytpt add-quick">
                                        Save
                                    </button>
                                    <a
                                        href="#"
                                        type="button"
                                        onClick={() => {
                                            setProjects({ projectName: project });
                                            setProjectName("");
                                            lFromState.Projects = false;
                                        }}
                                        className="btn btn-link underline open-tool"
                                    >
                                        Edit details
                                    </a>
                                    <a
                                        href="#"
                                        onClick={() => projectsFrom()}
                                        className="btn btn-link underline close-add-card"
                                    >
                                        Close
                                    </a>
                                </div>
                            </form>
                        </div>
                    </div>
                </DragDropContext>
                <a
                    className="btn-list add-card"
                    onClick={() => projectsFrom()}
                    data-list_id="projects"
                    style={
                        !lFromState.Projects ? { display: "block" } : { display: "none" }
                    }
                >
                    + Add new {/* project */} {GetAliasesName(teams, "Projects").toLowerCase()}
                </a>
            </div>
        </div>
    )
}
