import axios from 'axios'
import {ACCESS_MANAGEMENT_GET_SUCCESS, ACCESS_MANAGEMENT_GET_FAIL, ACCESS_MANAGEMENT_CREATE_SUCCESS, ACCESS_MANAGEMENT_CREATE_FAIL ,  ACCESS_MANAGEMENT_DELETE_SUCCESS , ACCESS_MANAGEMENT_DELETE_FAIL,
     ACCESS_MANAGEMENT_UPDATE_SUCCESS , ACCESS_MANAGEMENT_UPDATE_FAIL, 
    ACCESS_MANAGEMENT_SUCCESS_MESSAGE_CLEAR ,ACCESS_MANAGEMENT_ERROR_MESSAGE_CLEAR} from '../types/accessManagementTypes.js';


// const token = localStorage.getItem("authToken");
import {SERVER_URI} from '../../config/keys.js';
import {DispatchBlocker} from "../DispatchBlocker/DispatchBlocker.js"

export const userAuth = (token)=>{
    axios.interceptors.request.use(
        config => {
          config.headers.authorization = `Bearer ${token}`;
          return config;
        },
        error => {
          return Promise.reject(error)
        }
      )
}

export const getAccessManagemenet = (datas) =>{
    return async (dispatch) =>{
        const config = {
            headers : {
                'Content-Type': 'application/josn',
                'url' : `${SERVER_URI}/user/get-access-management`
            }
        }
        try{
            const response = await axios.post(`${SERVER_URI}/user/get-access-management`,datas)
            
            dispatch({
                type : ACCESS_MANAGEMENT_GET_SUCCESS,
                payload : {
                    successMessage : response.data.message,
                    data : response.data.accessManagementData
                }
            })

        }catch(error){
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: ACCESS_MANAGEMENT_GET_FAIL,
                    payload: {
                        tagsError: data
                    }
                })
            }else if(error.message){
                dispatch({
                    type: ACCESS_MANAGEMENT_GET_FAIL,
                    payload: {
                        tagsError: error.message
                    }
                })
            }
        }
    }
}

export const updateAccessManagemenet = (datas) =>{
    return async (dispatch,getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        try{
            const token = localStorage.getItem("authToken");
            userAuth(token)
            const response = await axios.patch(`${SERVER_URI}/user/update-access-management`, datas)
            // console.log(response.data.message)
            // localStorage.setItem('authToken', response.data.data.token)
            
            dispatch({
                type : ACCESS_MANAGEMENT_UPDATE_SUCCESS,
                payload : {
                    successMessage : response.data.message
                }
            })

        }catch(error){
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: ACCESS_MANAGEMENT_UPDATE_FAIL,
                    payload: {
                        error: data
                    }
                })
            }else if(error.message){
                dispatch({
                    type: ACCESS_MANAGEMENT_UPDATE_FAIL,
                    payload: {
                        error: error.message
                    }
                })
            }
        }
    }
}

export const CancelAddOnSubcription = (datas) =>{
    return async (dispatch,getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        try{
            const token = localStorage.getItem("authToken");
            userAuth(token)
            const response = await axios.post(`${SERVER_URI}/user/cancel-add-on-subscription`, datas)
            // console.log(response.data)
            // localStorage.setItem('authToken', response.data.data.token)
            
            dispatch({
                type : ACCESS_MANAGEMENT_UPDATE_SUCCESS,
                payload : {
                    successMessage : response.data.message
                }
            })

        }catch(error){
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: ACCESS_MANAGEMENT_UPDATE_FAIL,
                    payload: {
                        error: data
                    }
                })
            }else if(error.message){
                dispatch({
                    type: ACCESS_MANAGEMENT_UPDATE_FAIL,
                    payload: {
                        error: error.message
                    }
                })
            }
        }
    }
}

// export const deleteTags = (datas) => {
//     return async (dispatch,getState) => {
//         let proceed = DispatchBlocker(getState);
//         if(!proceed) return;
//         let token = localStorage.getItem('authToken')
//         const config = {
//             headers: {
//                 'Content-Type': 'application/josn',
//                 'url': `${SERVER_URI}/user/tags/delete`,
//                 'adminId': datas.adminId,
//                 'teamId': datas.teamId,
//                 'tagsId': datas.tagsId
//             }
//         }
//         // console.log(datas.adminId);
//         try {
//             // console.log(datas)
//             userAuth(token)
//             const response = await axios.delete(`${SERVER_URI}/user/tags/delete`, config)

//             dispatch({
//                 type: ACCESS_MANAGEMENT_DELETE_SUCCESS,
//                 payload: {
//                     tagsSuccessMessage: response.data.message,
//                 }
//             })

//         } catch (error) {
//             if (error && error.response && error.response.data && error.response.data.message) {
//                 let data = error.response.data.message
//                 dispatch({
//                     type: ACCESS_MANAGEMENT_DELETE_FAIL,
//                     payload: {
//                         tagsError: data
//                     }
//                 })
//             }else if(error.message){
//                 dispatch({
//                     type: ACCESS_MANAGEMENT_DELETE_FAIL,
//                     payload: {
//                         tagsError: error.message
//                     }
//                 })
//             }
//         }
//     }
// }