export const INFO_GET_SUCCESS = "INFO_GET_SUCCESS"
export const INFO_GET_FAIL = "INFO_GET_FAIL"
export const INFO_CREATE_SUCCESS = "INFO_GET_SUCCESS"
export const INFO_CREATE_FAIL = "INFO_GET_FAIL"
export const INFO_DELETE_SUCCESS = 'INFO_DELETE_SUCCESS'
export const INFO_UPDATE_SUCCESS = 'INFO_UPDATE_SUCCESS'
export const INFO_UPDATE_FAIL = 'INFO_UPDATE_FAIL'
export const INFO_DELETE_FAIL = 'INFO_DELETE_FAIL'

export const INFO_SUCCESS_MESSAGE_CLEAR = 'INFO_SUCCESS_MESSAGE_CLEAR'
export const INFO_ERROR_CLEAR = 'INFO_ERROR_CLEAR'