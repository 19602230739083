export const PEOPLE_CREATE_SUCCESS = 'PEOPLE_CREATE_SUCCESS'
export const PEOPLE_CREATE_FAIL = 'PEOPLE_CREATE_FAIL'
export const PEOPLE_GET_SUCCESS = 'PEOPLE_GET_SUCCESS'
export const PEOPLE_GET_FAIL = 'PEOPLE_GET_FAIL'
export const PEOPLE_DELETE_SUCCESS = 'PEOPLE_DELETE_SUCCESS'
export const PEOPLE_UPDATE_SUCCESS = 'PEOPLE_UPDATE_SUCCESS'
export const PEOPLE_UPDATE_FAIL = 'PEOPLE_UPDATE_FAIL'
export const PEOPLE_DELETE_FAIL = 'PEOPLE_DELETE_FAIL'
export const PEOPLE_DROP_UPDATE_SUCCESS = 'PEOPLE_DROP_UPDATE_SUCCESS'
export const PEOPLE_DROP_UPDATE_FAIL = 'PEOPLE_DROP_UPDATE_FAIL'

export const PEOPLE_ID_CLEAR = 'PEOPLE_ID_CLEAR'

export const PEOPLE_SUCCESS_MESSAGE_CLEAR = 'PEOPLE_SUCCESS_MESSAGE_CLEAR'
export const PEOPLE_ERROR_CLEAR = 'PEOPLE_ERROR_CLEAR'
export const SIGN_OUT = 'SIGN_OUT'