import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { useSelector } from "react-redux";
import { StructureDataForPurposeTree } from "./DataFormater";
import { ROLE_ERROR_CLEAR, ROLE_ID_CLEAR, ROLE_SUCCESS_MESSAGE_CLEAR } from "../../../store/types/roleType";
import Swal from "sweetalert2";
import { getState } from "../../../store/actions/stateAction";
import { useDispatch } from "react-redux";
import { SKILL_ERROR_CLEAR, SKILL_ID_CLEAR, SKILL_SUCCESS_MESSAGE_CLEAR } from "../../../store/types/skillType";
import { LINK_ERROR_CLEAR, LINK_ID_CLEAR, LINK_SUCCESS_MESSAGE_CLEAR } from "../../../store/types/linkType";
import { DOMAIN_ERROR_CLEAR, DOMAIN_ID_CLEAR, DOMAIN_SUCCESS_MESSAGE_CLEAR } from "../../../store/types/domainType";
import { PROJECT_ERROR_CLEAR, PROJECT_ID_CLEAR, PROJECT_SUCCESS_MESSAGE_CLEAR } from "../../../store/types/projectType";
import { CIRCLE_ERROR_CLEAR, CIRCLE_ID_CLEAR, CIRCLE_SUCCESS_MESSAGE_CLEAR } from "../../../store/types/circleType";
import { CloseFullscreen } from "@mui/icons-material";

export const options = {
  allowHtml: true,
};

export default function PurposeTree({
  teams,
  circles,
  dCircles,
  projects,
  skills,
  roles,
  dRoles,
  domains,
  links,
  setSkill,
  setRole,
  setDomain,
  setLink,
  setCircle,
  setProject,
  openTeam,
  indexSet,
  indexInSet,
  purposetreeloader,
  setPurposetreeLoader,
  openCircle,
  openProject,
  openSkill,
  openRole,
  openDomain,
  openLink,
  CloseStateHandel
}) {

  const dispatch = useDispatch();
  let [treeData, setTreeData] = useState([]);
  let [chartReady, setChartReady] = useState(false);

  // let [purposeTreeLoader, setPurposeLoader] = useState(false);
  const { loading, successMessage, error, authenticate, myInfo, curUserData } = useSelector((state) => state.auth);
  const { members,/*  memberMessagee, memberError, memberMessage */ } = useSelector((state) => state.members);
  /********************************/
  const { /* skills, */cuSkillId, nDaoamins, skillsMessagee, skillError, skillsMessage } = useSelector((state) => state.skills);
  const { /* roles,  */roleMessagee, roleError, cuRoleId } = useSelector((state) => state.roles);
  const { /* domains, */ domainMessagee, domainError, cuDomainId } = useSelector((state) => state.domains);
  const { /* links, */ linkMessagee, linkError, cuLinkId } = useSelector((state) => state.links);
  const { /* projects, */ cuProjectId, projectMessagee, projectError } = useSelector((state) => state.projects);
  const { /* circles, dCircles, */ cuCircleId, circleMessagee, circleError } = useSelector((state) => state.circles);
  /*********************************** */

  const isLoading = Object.values(purposetreeloader).some(value => value === true);

  /* Data formatting set data in purpose tree format */
  useEffect(() => {
    /* Apply the current wallpaper class */
    document.body.classList.add(`purpose-tree`);

    let data = StructureDataForPurposeTree(
      teams,
      dCircles[0],
      circles,
      projects,
      skills,
      [...roles, ...dRoles],
      domains,
      links,
      members
    );
    data.unshift(["Name", "Manager", "ToolTip"])
    setTreeData(data);
  }, [teams, circles, projects, skills, roles, domains, links]);

  const chartEvents = [
    {
      eventName: "select",
      callback({ chartWrapper }) {
        if (chartWrapper.getChart().getSelection().length === 0) {
          console.log("no selection found");
          return;
        }
        handleClick(chartWrapper.getChart().getSelection());
        // console.log("Selected ", chartWrapper.getChart().getSelection());
      },
    },
    {
      eventName: "ready",
      callback({ chartWrapper }) {
        setChartReady(true);
        // console.log("Chart ready. ", chartWrapper.getChart());
        setTimeout(() => {
          document.querySelectorAll("tr").forEach((row, index, rows) => {
            // Check if the current <tr> has a <td> with the target node class
            const hasTargetClass = Array.from(row.children).some(
              (td) =>
                td.classList.contains("google-visualization-orgchart-node") &&
                td.classList.contains("google-visualization-orgchart-node-medium")
            );

            if (hasTargetClass && index > 0) {
              // Get the previous <tr>
              const previousRow = rows[index - 1];
              // console.log({ previousRow });

              // Add 'add-before' class to each <td> with specific classes in the previous row
              previousRow
                .querySelectorAll(
                  "td.google-visualization-orgchart-linenode.google-visualization-orgchart-lineleft"
                )
                .forEach((td) => {
                  td.classList.add("add-before");
                });
            }
          });

          // Select all divs with the class "green"
          const greenDivs = document.querySelectorAll("div.green, div.blue");
          // console.log(greenDivs)
          greenDivs.forEach((div) => {
            // Set the height of the div to match its parent <td>
            const td = div.closest("td");
            if (td) {
              div.style.height = `${td.offsetHeight}px`;
            }
          });
        }, 300);

      },
    },
    {
      eventName: "error",
      callback(args) {
        setChartReady(false);
        console.log("Chart errored. ", args);
      },
    },
  ];

  /* OnClick Event Handler */
  const handleClick = (selectedRow) => {
    if (!chartReady) {
      alert("not Ready");
      return;
    }
    // console.log({ isLoading })
    // if (isLoading) {
      console.log(isLoading,purposetreeloader,"here is loading")
    //   return;
    // }
    // console.log(treeData);
    // console.log(selectedRow);
    console.log(treeData[selectedRow[0].row + 1]);
    const [vf, name, t] = treeData[selectedRow[0].row + 1];
    // console.log(vf);
    let [i, type, id] = vf.v.split("_");
    // console.log({ i, type, id });
    setChartReady(false);

    /* TEAM */
    if (type === "Team") {
      setPurposetreeLoader((prev => ({ ...prev, circle: true })));
      // openTeam();
      let DcIndex = dCircles.findIndex((cc) => cc._id === id);
      console.log(DcIndex)
      if (DcIndex !== -1) {
        let targetCircle = dCircles[DcIndex];
        indexSet(targetCircle);
        // setCircle(targetCircle, dCircles, DcIndex);
        openCircle();
      } else {
        console.log("Circle with the specified ID not found");
      }
    }

    /* CIRCLE */
    if (type === "circle") {
      setPurposetreeLoader((prev => ({ ...prev, circle: true })));
      let index = circles.findIndex((cc) => cc._id === id);
      // console.log(index);
      if (index !== -1) {
        let targetCircle = circles[index];
        indexSet(targetCircle);
        // setCircle(targetCircle, circles, index);
        openCircle();
      } else {
        console.log("Circle with the specified ID not found");
      }
    }

    /* PROJECT */
    if (type === "project") {
      setPurposetreeLoader((prev => ({ ...prev, project: true })));
      let index = projects.findIndex((pp) => pp._id === id);
      if (index !== -1) {
        let targetProject = projects[index];
        indexSet(targetProject);
        // setProject(targetProject, projects, index);
        openProject();
      } else {
        console.log("Project with the specified ID not found");
      }
    }

    /* SKILL */
    if (type === "skill") {
      setPurposetreeLoader((prev => ({ ...prev, skill: true })));
      let index = skills.findIndex((ss) => ss._id === id);
      if (index !== -1) {
        let targetSkill = skills[index];
        indexSet(targetSkill);
        // setSkill(targetSkill, skills, index);
        openSkill();
      } else {
        console.log("Skill with the specified ID not found");
      }
    }

    /* ROLE */
    if (type === "role") {
      setPurposetreeLoader((prev => ({ ...prev, role: true })));
      let index = roles.findIndex((rr) => rr._id === id);
      if (index !== -1) {
        let targetRole = roles[index];
        indexSet(targetRole);
        // setRole(targetRole, roles, index);
        openRole();
      } else {
        let DcIndex = dRoles.findIndex((cc) => cc._id === id);
        if (DcIndex !== -1) {
          let targetRole = dRoles[DcIndex];
          indexSet(targetRole);
          // setRole(targetRole, dRoles, DcIndex);
          openRole();
        } else {
          console.log("Role with the specified ID not found");
        }
      }
    }

    /* DOMAIN */
    if (type === "domain") {
      setPurposetreeLoader((prev => ({ ...prev, domain: true })));
      let index = domains.findIndex((dd) => dd._id === id);
      if (index !== -1) {
        let targetDomain = domains[index];
        indexSet(targetDomain);
        // setDomain(targetDomain, domains, index);
        openDomain();
      } else {
        console.log("Domain with the specified ID not found");
      }
    }

    /* LINK */
    if (type === "link") {
      setPurposetreeLoader((prev => ({ ...prev, link: true })));
      let index = links.findIndex((ll) => ll._id === id);
      if (index !== -1) {
        let targetLink = links[index];
        indexSet(targetLink);
        // setLink(targetLink, links, index);
        openLink();
      } else {
        console.log("Link with the specified ID not found");
      }
    }
  }

  /* for skill outside update */
  useEffect(() => {
    setPurposetreeLoader((prev => ({ ...prev, skill: false })));
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    if (skillsMessagee && skillsMessagee.length > 0) {
      dispatch(getState(obj));
      localStorage.setItem("latest-created", cuSkillId);
      dispatch({ type: SKILL_ID_CLEAR });
      dispatch({ type: SKILL_SUCCESS_MESSAGE_CLEAR });
    }

    if (skillError) {
      Swal.fire({
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: skillError,
      });
      dispatch({ type: SKILL_ERROR_CLEAR });
    }
  }, [skillsMessagee, skillError, cuSkillId]);

  /* for role outside update */
  useEffect(() => {
    setPurposetreeLoader((prev => ({ ...prev, role: false })));
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    if (roleMessagee && roleMessagee.length > 0) {
      dispatch(getState(obj));
      localStorage.setItem("latest-created", cuRoleId);
      dispatch({ type: ROLE_ID_CLEAR });
      dispatch({ type: ROLE_SUCCESS_MESSAGE_CLEAR });
    }

    if (roleError) {
      Swal.fire({
        title: "Error",
        showClass: {
          popup: "animate_animated animate_fadeInDown",
        },
        hideClass: {
          popup: "animate_animated animate_fadeOutUp",
        },
        text: roleError,
      });
      dispatch({ type: ROLE_ERROR_CLEAR });
    }
  }, [roleMessagee, roleError, cuRoleId]);

  /* for domain outside update */
  useEffect(() => {
    setPurposetreeLoader((prev => ({ ...prev, domain: false })));
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    if (domainMessagee) {
      dispatch(getState(obj));
      localStorage.setItem("latest-created", cuDomainId);
      dispatch({ type: DOMAIN_ID_CLEAR });
      dispatch({ type: DOMAIN_SUCCESS_MESSAGE_CLEAR });
      dispatch({ type: DOMAIN_SUCCESS_MESSAGE_CLEAR });
    }

    if (domainError) {
      Swal.fire({
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: domainError,
      });
      dispatch({ type: DOMAIN_ERROR_CLEAR });
    }
  }, [domainMessagee, domainError, cuDomainId]);

  /* for link outside update */
  useEffect(() => {
    setPurposetreeLoader((prev => ({ ...prev, link: false })));
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;

    if (linkMessagee) {
      dispatch(getState(obj));
      localStorage.setItem("latest-created", cuLinkId);
      dispatch({ type: LINK_ID_CLEAR });
      dispatch({ type: LINK_SUCCESS_MESSAGE_CLEAR });
      dispatch({ type: LINK_SUCCESS_MESSAGE_CLEAR });
    }

    if (linkError) {
      Swal.fire({
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: linkError,
      });
      dispatch({ type: LINK_ERROR_CLEAR });
    }
  }, [linkMessagee, linkError, cuLinkId]);

  /* for project outside update */
  useEffect(() => {
    setPurposetreeLoader((prev => ({ ...prev, project: false })));
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    if (projectMessagee && projectMessagee.length > 0) {
      dispatch(getState(obj));
      localStorage.setItem("latest-created", cuProjectId);
      dispatch({ type: PROJECT_ID_CLEAR });
      dispatch({ type: PROJECT_SUCCESS_MESSAGE_CLEAR });
      dispatch({ type: PROJECT_SUCCESS_MESSAGE_CLEAR });
    }

    if (projectError) {
      Swal.fire({
        title: "Error",
        showClass: {
          popup: "animate_animated animate_fadeInDown",
        },
        hideClass: {
          popup: "animate_animated animate_fadeOutUp",
        },
        text: projectError,
      });
      dispatch({ type: PROJECT_ERROR_CLEAR });
    }
  }, [projectMessagee, projectError, cuProjectId]);

  /* for circle outside update */
  useEffect(() => {
    setPurposetreeLoader((prev => ({ ...prev, circle: false })));
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;

    if (circleMessagee && circleMessagee.length > 0) {
      dispatch(getState(obj));
      localStorage.setItem("latest-created", cuCircleId);
      dispatch({ type: CIRCLE_ID_CLEAR });
      dispatch({ type: CIRCLE_SUCCESS_MESSAGE_CLEAR });
      dispatch({ type: CIRCLE_SUCCESS_MESSAGE_CLEAR });
    }

    if (circleError) {
      Swal.fire({
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: circleError,
      });
      dispatch({ type: CIRCLE_ERROR_CLEAR });
    }
  }, [circleMessagee, circleError, cuCircleId]);

  const overlay = document.querySelector('.ReactModal__Overlay');
  const modalContent = document.querySelector('.modal-dialog');
  if (overlay && modalContent) {
    overlay.addEventListener('click', (event) => {
      if (!modalContent.contains(event.target)) {
        console.log('Click in between');
        console.log(purposetreeloader);
        if(purposetreeloader.skill){
          CloseStateHandel("Skills");
        }
        if(purposetreeloader.role){
          CloseStateHandel("Roles");
        }
        if(purposetreeloader.domain){
          CloseStateHandel("Domains");
        }
        if(purposetreeloader.link){
          CloseStateHandel("Links");
        }        
        if(purposetreeloader.circle){
          CloseStateHandel("Circles");
        }
        if(purposetreeloader.project){
          CloseStateHandel("Projects");
        }
      }
    });
  }


  return (
    <Chart
      chartType="OrgChart"
      data={treeData}
      options={options}
      width="100%"
      height="100%"
      chartEvents={chartEvents}
      style={{
        clickEvent: null,
        pointerEvents: null
      }}
    />
  );
}
