export const MEMBER_CREATE_SUCCESS = 'MEMBER_CREATE_SUCCESS'
export const MEMBER_CREATE_FAIL = 'MEMBER_CREATE_FAIL'
export const MEMBER_GET_SUCCESS = 'MEMBER_GET_SUCCESS'
export const MEMBER_GET_FAIL = 'MEMBER_GET_FAIL'
export const MEMBER_DELETE_SUCCESS = 'MEMBER_DELETE_SUCCESS'
export const MEMBER_UPDATE_SUCCESS = 'MEMBER_UPDATE_SUCCESS'
export const MEMBER_UPDATE_FAIL = 'MEMBER_UPDATE_FAIL'
export const MEMBER_DELETE_FAIL = 'MEMBER_DELETE_FAIL'


export const MEMBER_SUCCESS_MESSAGE_CLEAR = 'MEMBER_SUCCESS_MESSAGE_CLEAR'
export const MEMBER_ERROR_CLEAR = 'MEMBER_ERROR_CLEAR'
export const SIGN_OUT = 'SIGN_OUT'

export const MEMBER_MODIFICATIONS = 'MEMBER_MODIFICATIONS'