import React, { useState, useEffect, useRef } from "react";
import Tags from "../Tags";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useDispatch, useSelector } from "react-redux";
// import { TagsInput } from "react-tag-input-component";
import TextareaAutosize from "react-textarea-autosize";
import {
  createSkills,
  updateSkills,
  getSkills,
  deleteSkills,
} from "../../../../store/actions/skillAction";
import {
  SKILL_SUCCESS_MESSAGE_CLEAR,
  SKILL_ERROR_CLEAR,
  SKILL_ID_CLEAR,
} from "../../../../store/types/skillType";
import {
  ROLE_SUCCESS_MESSAGE_CLEAR,
  ROLE_ID_CLEAR,
} from "../../../../store/types/roleType";
import Swal from "sweetalert2";
import { SERVER_URI } from "../../../../config/keys";
import { TAGS_SUCCESS_MESSAGE_CLEAR } from "../../../../store/types/tagsType";
import { getTags, tagCreate } from "../../../../store/actions/tagsAction";
import {
  updateMember,
  getMember,
} from "../../../../store/actions/memberAction";
import { createRole, getRole } from "../../../../store/actions/roleAction";
import { updateState, getState } from "../../../../store/actions/stateAction";
import {
  historyGet,
  historyCreat,
  historyCreate,
} from "../../../../store/actions/historyAction";
import {
  HISTORY_MESSAGE_CLEAR,
  HISTORY_DATAS_CLEAR,
} from "../../../../store/types/historyType";
import {
  updateDomain,
  getDomain,
} from "../../../../store/actions/domainAction";
import {
  createProject,
  getProject,
} from "../../../../store/actions/projectAction";
import {
  createCircle,
  getCircle,
} from "../../../../store/actions/circleAction";
import CreatableSelect from "react-select/creatable";
import {
  freezeWindow,
  unfreezeWindow,
} from "../../../../../src/HelperFunctions/Overlay";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional

import {
  DOMAIN_SUCCESS_MESSAGE_CLEAR,
  DOMAIN_ERROR_CLEAR,
  DOMAIN_ID_CLEAR,
} from "../../../../store/types/domainType";
import Objectives from "./Objectives";
import Tasks from "./Tasks";
import WorkLoadSRDL from "./WorkLoadSRDL";
import { sumFTE } from "../../../../HelperFunctions/FTE_cal";
import { numberInputOnWheelPreventChange } from "../../../../HelperFunctions/StopInputScroll";
import Documents from "./Documents";
import { GetAliasesName } from "../../../../HelperFunctions/GetAliasesName";

export default function SkillModal({
  data,
  closeSkill,
  setSkills,
  CloseState,
  stateCloseState,
  isNToP,
  focusMode,
  focused,
  setFocused,
  compareMode,
  teams,
  setPurposetreeLoader
}) {
  const animatedComponents = makeAnimated();
  const dispatch = useDispatch();
  const selectInputRefs = useRef([]);

  const { loading, successMessage, error, authenticate, myInfo } = useSelector(
    (state) => state.auth
  );
  const { peoples, peopleMessagee, peopleError } = useSelector(
    (state) => state.peoples
  );
  const { domains, dDomains, domainMessage, domainError } = useSelector(
    (state) => state.domains
  );
  // console.log(dDomains)
  const { roles, cuRoleId, roleMessagee, roleError } = useSelector(
    (state) => state.roles
  );
  const {
    skills,
    cuSkillId,
    nDaoamins,
    skillsMessagee,
    skillError,
    skillsMessage,
  } = useSelector((state) => state.skills);
  const { members, memberMessagee, memberError, memberMessage } = useSelector(
    (state) => state.members
  );
  const { circles, circleMessage, circleError } = useSelector(
    (state) => state.circles
  );
  const { projects, projectMessagee, projectError } = useSelector(
    (state) => state.projects
  );
  const { states, cuStateId, statesMessagee, statesError, statesMessage } =
    useSelector((state) => state.states);
  const { historys, historyMessagee, historyError, historyMessage } =
    useSelector((state) => state.historys);
  const { trashData } = useSelector((state) => state.states);

  let adminId = myInfo.userId;
  let teamId = localStorage.getItem("teamId");
  let url = `${SERVER_URI}/user/skill/create`;
  let urls = `${SERVER_URI}/user/skill/update`;

  // Skill data set for create or update

  var arr = {
    _id: data && data._id ? data._id : null,
    teamId: data && data.teamId ? data.teamId : teamId,
    skillName: data && data.skillName ? data.skillName : "",
    purpose: data && data.purpose ? data.purpose : "",
    tasks: data && data.tasks ? data.tasks : [],
    objectives: data && data.objectives ? data.objectives : [],
    ownerType: data && data.ownerType ? data.ownerType : "Multiple owners",
    owners: data && data.owners ? data.owners : [],
    domains: data && data.domains ? data.domains : [],
    memberIn: data && data.memberIn ? data.memberIn : [],
    tags: data && data.tags ? data.tags : [],
    newTag: [],
    adminId: adminId ? adminId : null,
    url: data && data._id ? urls : url,
    planTotal: data && data.planTotal ? data.planTotal : "",
    workload: data && data.workload ? data.workload : [],
    notes: data && data.notes ? data.notes : "",
  };

  // Skill data set in useState and also change state

  const [skill, setSkill] = useState(arr);

  /*   const handleChange = (e) => {
      setSkill({
        ...skill,
        [e.target.name]: e.target.value,
      });
    }; */

  const handleChange = (e) => {
    // console.log(e.target.name)
    if (e.target.name === "ownerType" && e.target.value === "Multiple owners") {
      Swal.fire({
        title: "Are you sure?",
        text: "This will reset the lead of circles and projects where this skill is a lead!",
        // icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.isConfirmed) {
          setSkill({
            ...skill,
            [e.target.name]: e.target.value,
          });
        } else {
          /* var singleOwnerSkillCheckbox =
            document.getElementById("single_owner_skill");
          singleOwnerSkillCheckbox.checked = true;
          var inputEvent = new Event("input", { bubbles: true });
          singleOwnerSkillCheckbox.dispatchEvent(inputEvent); */
          setSkill({
            ...skill,
            [e.target.name]: "Single owner",
          });
        }
      });
    }
    // else if (e.target.name === "planTotal") {
    //   console.log({planTotal: e.target.value})
    //   setSkill({
    //     ...skill,
    //     [e.target.name]: e.target.value,
    //   });
    // } 
    else {
      setSkill({
        ...skill,
        [e.target.name]: e.target.value,
      });
    }
  };

  /* document.addEventListener("input", function (e) {
    if (
      e.target &&
      e.target.type === "radio" &&
      e.target.name === "ownerType"
    ) {
      handleChange(e);
    }
  }); */

  //added by ranjit
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const centeredPlaceholderStyle = {
    lineHeight: "3rem",
    height: "2rem",
    padding: "0",
  };
  //

  // Store all members in the array memberIds

  let memberIds = [];
  if (data) {
    members.map((e, index) => {
      let inc = e.coreMembers.Skills.includes(data._id);
      let incs = e.extendedMembers.Skills.includes(data._id);
      if (inc) {
        memberIds.push({ memberId: e.memberType, XTD: "" });
      }
      if (incs) {
        memberIds.push({ memberId: e.memberType, XTD: "XTD" });
      }
    });
  }

  if (data && memberIds.length > 0) {
    for (let i = 0; i < memberIds.length; i++) {
      for (let j = 0; j < circles.length; j++) {
        if (memberIds[i].memberId === circles[j]._id) {
          memberIds[i].memberName = circles[j].circleName + " " + `(${GetAliasesName(teams, "Circles").toLowerCase()})`/* "(circle)" */;
        }
      }
      for (let k = 0; k < projects.length; k++) {
        if (memberIds[i].memberId === projects[k]._id) {
          memberIds[i].memberName = projects[k].projectName + " " + `(${GetAliasesName(teams, "Projects").toLowerCase()})`/* "(project)" */;
        }
      }
    }
  }

  // Member action on skills modal

  const cFskillSub = (data, popoverId, type) => {
    if (
      type === "core" &&
      popoverId &&
      popoverId.coreMembers &&
      popoverId.coreMembers.Skills
    ) {
      let inc = popoverId.coreMembers.Skills.includes(data._id);
      if (inc) {
        popoverId.coreMembers.Skills = popoverId.coreMembers.Skills.filter(
          (e) => {
            return e !== data._id;
          }
        );
      }
    } else if (
      type === "extend" &&
      popoverId &&
      popoverId.extendedMembers &&
      popoverId.extendedMembers.Skills
    ) {
      let incs = popoverId.extendedMembers.Skills.includes(data._id);
      if (incs) {
        popoverId.extendedMembers.Skills =
          popoverId.extendedMembers.Skills.filter((e) => {
            return e !== data._id;
          });
      }
    }
    if (popoverId) {
      popoverId.adminId = myInfo.userId;
      popoverId.url = `${SERVER_URI}/user/member/update`;
      let obj = {};
      obj.userId = myInfo.userId;
      obj.teamId = popoverId.teamId;
      dispatch(updateMember(popoverId)).then(() => {
        // dispatch(getMember(obj));
      });
    }
  };

  const memberHandel = (elements) => {
    let popoverId = {};
    if (elements && elements.memberId.length > 0) {
      for (let i = 0; i < members.length; i++) {
        if (members[i].memberType === elements.memberId) {
          let arr = {
            teamId: members[i].teamId,
            memberType: members[i].memberType,
            coreMembers: {
              People: members[i].coreMembers.People,
              Skills: members[i].coreMembers.Skills,
              Roles: members[i].coreMembers.Roles,
              Links: members[i].coreMembers.Links,
              Domains: members[i].coreMembers.Domains,
              Circles: members[i].coreMembers.Circles,
              Projects: members[i].coreMembers.Projects,
            },
            extendedMembers: {
              People: members[i].extendedMembers.People,
              Skills: members[i].extendedMembers.Skills,
              Roles: members[i].extendedMembers.Roles,
              Links: members[i].extendedMembers.Links,
              Domains: members[i].extendedMembers.Domains,
              Circles: members[i].extendedMembers.Circles,
            },
          };
          if (arr && arr.teamId.length > 0) {
            popoverId = arr;
          }
          break;
        }
      }
    }
    if (
      popoverId &&
      popoverId.memberType &&
      popoverId.memberType.length > 0 &&
      elements.XTD === "XTD"
    ) {
      cFskillSub(skill, popoverId, "extend");
    }
    if (
      popoverId &&
      popoverId.memberType &&
      popoverId.memberType.length > 0 &&
      elements.XTD !== "XTD"
    ) {
      cFskillSub(skill, popoverId, "core");
    }
  };

  // set all skills owners options in skill modal

  //options skills
  var Data = [];
  peoples.map((e) => {
    Data.push({ value: `${e._id}`, label: `${e.fname} ${e.lname}` });
  });

  // set all skills domain options in skill modal

  //options domains
  var Domains = [];
  const chekIfSoftDeleted = (id) => {
    const {
      deletedPeoples,
      deletedRoles,
      deletedSkills,
    } = trashData;
    let val = false;
    let target = [...deletedPeoples, ...deletedSkills, ...deletedRoles].find((se) => se._id.toString() == id);
    if (target) {
      // if (target.softDeleted) {
      val = true;
      // }
    }
    return val;
  }
  domains.map((e) => {
    if (
      e.owners &&
      Object.keys(e.owners).length > 0 &&
      (e.owners.owner === null || !e.owners.owner /* || chekIfSoftDeleted(e.owners.owner) */|| chekIfSoftDeleted(e.owners.type)) &&
      (e.owners.type === null || !e.owners.type || chekIfSoftDeleted(e.owners.type))
    ) {
      Domains.push({ value: `${e._id}`, label: `${e.domainName}` });
    }
  });

  dDomains.map((e) => {
    if (
      e.owners &&
      Object.keys(e.owners).length > 0 &&
      (e.owners.owner === null || !e.owners.owner) &&
      (e.owners.type === null || !e.owners.type)
    ) {
      Domains.push({ value: `${e._id}`, label: `${e.domainName}` });
    }
  });

  const options = Data;

  // current mentors set for domain

  var cumentor = [];
  if (data && data.owners) {
    if (data && data.owners.length > 0) {
      for (let i = 0; i < peoples.length; i++) {
        let inc = data.owners.includes(peoples[i]._id);
        if (inc) {
          cumentor.push({
            value: `${peoples[i]._id}`,
            label: `${peoples[i].fname} ${peoples[i].lname}`,
          });
        }
      }
    }
  }

  // set and store owner datas in skill state

  const [value, setValue] = React.useState(cumentor);

  const handelSelect = (value) => {
    if (value === null) {
      setValue([]);
      return;
    }
    let allSkillOwnerIds = value && Array.isArray(value) ? value.map((sv) => sv.value) : [value].map((sv) => sv.value);
    // console.log(allSkillOwnerIds); /* ['66790a33274014cd15730c10', '66790a33274014cd15730c12'] */
    // console.log(domainsValue);
    /*  
  const [domainsValue, setDomainsValue] = React.useState(curDomains);

    [
      {
        domain: { value: '66790a3a274014cd15730c5a', label: 'mytpt' },
        owner: { value: '66790a33274014cd15730c14', label: 'Quinn ' }
      }
    ] -- we have to remove that obj in domainValue whose domain.value is not present in allSkillOwnerIds and set it in setDomainsValue
    */

    // Function to filter domainsValue
    const updatedDomainsValue = domainsValue.map(domainItem => {
      if (!allSkillOwnerIds.includes(domainItem.owner.value)) {
        return {
          ...domainItem,
          owner: { value: null, label: null }
        };
      }
      return domainItem;
    });
    setDomainsValue(updatedDomainsValue);

    if (skill.ownerType === "Single owner") {
      let mDatas = [];
      if (value) {
        if (Data && Data.length > 0) {
          for (let j = 0; j < Data.length; j++) {
            if (value.value === Data[j].value) {
              mDatas.push(Data[j]);
              break;
            }
          }
        }
      }
      setValue(mDatas);
    } else {
      let mDatas = [];
      if (value) {
        if (value && value.length > 0 && Data && Data.length > 0) {
          for (let i = 0; i < value.length; i++) {
            for (let j = 0; j < Data.length; j++) {
              if (value[i].value === Data[j].value) {
                mDatas.push(Data[j]);
                break;
              }
            }
          }
        }
      }
      setValue(mDatas);
    }

    // console.log(291, value);
    if (value && value.length > 0) {
      // console.log(293, value);
      let domainOwners = [];
      peoples.map((e) => {
        let inc = value.some((item) => item.value === e._id);
        if (inc) {
          domainOwners.push({
            value: `${e._id}`,
            label: `${e.fname} ${e.lname}`,
          });
        }
      });
      setDomainOwners(domainOwners);
    } else {
      // console.log(306, value);
      setDomainOwners({});
    }
  };

  // console.log(285, value)

  useEffect(() => {
    var ownersData = [];
    if (skill.ownerType === "Single owner") {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          ownersData.push(value[0].value);
        }
      } else {
        if (value) {
          ownersData.push(value.value);
        }
      }
    } else if (skill.ownerType === "Multiple owners") {
      if (value.length > 0) {
        for (let i = 0; i < value.length; i++) {
          ownersData.push(value[i].value);
        }
      }
    }
    if (ownersData) {
      setSkill({
        ...skill,
        ["owners"]: ownersData,
      });
    }
  }, [value, setValue]);

  const [domainOwners, setDomainOwners] = useState([]);

  useEffect(() => {
    //domain owner options
    // console.log(330, skill.owners, value);
    /* if (skill.owners) {
      let domainOwners = [];
      peoples.map((e) => {
        let inc = skill.owners.includes(e._id);
        if (inc) {
          domainOwners.push({
            value: `${e._id}`,
            label: `${e.fname} ${e.lname}`,
          });
        }
      });
      setDomainOwners(domainOwners)
    } */

    if (value && value.length > 0) {
      let domainOwners = [];
      peoples.map((e) => {
        let inc = value.some((item) => item.value === e._id);
        if (inc) {
          domainOwners.push({
            value: `${e._id}`,
            label: `${e.fname} ${e.lname}`,
          });
        }
      });
      setDomainOwners(domainOwners);
    }
  }, [value, setValue]);

  // current domain set in skill modal

  let curDomains = [];
  if (data && data.domains && data.domains.length > 0) {
    for (let i = 0; i < data.domains.length; i++) {
      for (let j = 0; j < domains.length; j++) {
        if (data.domains[i].domain) {
          let inc = [data.domains[i].domain].includes(domains[j]._id);
          if (inc) {
            if (data.domains[i].owner) {
              for (let k = 0; k < peoples.length; k++) {
                let incs = [data.domains[i].owner].includes(peoples[k]._id);
                if (incs) {
                  curDomains.push({
                    domain: {
                      value: domains[j]._id,
                      label: domains[j].domainName,
                    },
                    owner: {
                      value: peoples[k]._id,
                      label: `${peoples[k].fname} ${peoples[k].lname}`,
                    },
                  });
                  break;
                }
              }
            } else {
              curDomains.push({
                domain: {
                  value: domains[j]._id,
                  label: domains[j].domainName,
                },
                owner: {
                  value: "",
                  label: "",
                },
              });
              break;
            }
            break;
          }

          let dDomainFound = dDomains.find(
            (dd) =>
              dd &&
              data &&
              data.domains[j] &&
              data.domains[j].domain &&
              dd._id.toString() === data.domains[j].domain.toString()
          );
          let dDomainOwnerFound = peoples.find(
            (p) =>
              p &&
              data &&
              data.domains[j] &&
              data.domains[j].owner &&
              p._id.toString() === data.domains[j].owner.toString()
          );
          // console.log({dDomainFound, dDomainOwnerFound});

          if (dDomainFound) {
            // console.log({dDomainFound, dDomainOwnerFound});
            curDomains.push({
              domain: {
                value: dDomainFound._id,
                label: dDomainFound.domainName,
              },
              owner: {
                value: dDomainOwnerFound._id,
                label: `${dDomainOwnerFound.fname} ${dDomainOwnerFound.lname}`,
              },
            });
          }
        }
      }
    }
  }

  if (curDomains.length === 0) {
    curDomains = [
      { domain: { value: "", label: "" }, owner: { value: "", label: "" } },
    ];
  }

  const [domainsValue, setDomainsValue] = React.useState(curDomains);

  // console.log(domainsValue)

  let handleDomainsForm = (i, e) => {
    // console.log(i, e)
    handleDomainsFormClear(i);
    let newFormValues = [...domainsValue];
    newFormValues[i].domain = e;
    setDomainsValue(newFormValues);
  };

  let handleDomainsFormClear = (index) => {
    // console.log(index);
    let updatedValues = [...domainsValue];

    let updateOpDomain = [...opDoamin];
    // console.log(updatedValues);
    if (updatedValues[index] && updatedValues[index].domain) {
      updatedValues[index].domain.value &&
        updateOpDomain.push(updatedValues[index].domain);

      let setData = new Set(updateOpDomain);

      // console.log(491, updatedValues[index].domain, setData);

      setopDoamin(Array.from(setData));

      updatedValues[index].domain = null;
      setDomainsValue(updatedValues);
    }
  };

  let handleOwnerForm = (i, e) => {
    let newFormValues = [...domainsValue];
    newFormValues[i].owner = e;
    setDomainsValue(newFormValues);
  };

  let addFormDomainsFields = () => {
    let len = domainsValue.length;
    if (
      domainsValue[len - 1] &&
      domainsValue[len - 1].domain &&
      domainsValue[len - 1].domain.value.length > 0
    ) {
      setDomainsValue([
        ...domainsValue,
        { domain: { value: "", label: "" }, owner: { value: "", label: "" } },
      ]);
      // console.log(domainsValue[len - 1])
    }
  };

  let removeFormDomainsFields = (i) => {
    let newFormDomainValues = [...domainsValue];
    if (domainsValue.length > 1) {
      handleDomainsFormClear(i);
      newFormDomainValues.splice(i, 1);
      setDomainsValue(newFormDomainValues);
    } else if (domainsValue.length == 1) {
      setDomainsValue([
        { domain: { value: "", label: "" }, owner: { value: "", label: "" } },
      ]);
    }
  };

  // Domain values set for domain and owners

  let fDomains = [];
  // console.log('domains', domains)
  // console.log('domainsValue', domainsValue)
  if (domainsValue.length > 0) {
    for (let i = 0; i < domainsValue.length; i++) {
      if (
        domainsValue[i].domain &&
        domainsValue[i].domain.value &&
        domainsValue[i].domain.value.length > 0 &&
        domainsValue[i].owner
      ) {
        fDomains.push({
          domain: domainsValue[i].domain.value,
          owner:
            domainsValue[i].owner && domainsValue[i].owner.value
              ? domainsValue[i].owner.value
              : null,
        });
      }
    }
  }

  // store domain and owners vaule in skill state

  if (fDomains.length > 0) {
    skill.domains = fDomains;
  } else {
    skill.domains = [];
  }

  // domainOwners

  // console.log('Domains:', Domains, fDomains);
  // let opDoamin = [...Domains];
  const [opDoamin, setopDoamin] = useState();
  useEffect(() => {
    // console.log(563, Domains);

    let newOpDoamin = [...Domains];

    // console.log(newOpDoamin);

    if (Domains.length > 0) {
      for (let i = 0; i < fDomains.length; i++) {
        for (let j = 0; j < newOpDoamin.length; j++) {
          if (fDomains[i].domain === newOpDoamin[j].value) {
            newOpDoamin = newOpDoamin.filter((e) => e !== newOpDoamin[j]);
          }
        }
      }
    }
    // console.log(newOpDoamin);
    setopDoamin(newOpDoamin);
  }, []);

  // console.log('opDoamin: ', opDoamin)
  // if (Domains.length > 0) {
  //   for (let i = 0; i < fDomains.length; i++) {
  //     for (let j = 0; j < opDoamin.length; j++) {
  //       if (fDomains[i].domain === opDoamin[j].value) {
  //         opDoamin = opDoamin.filter((e) => e !== opDoamin[j]);
  //       }
  //     }
  //   }
  // }
  // console.log('opDoamin 2: ', opDoamin)

  // const [addTaska, setaddTaska] = useState(skill.tasks || []);

  // set tasks datas in state

  let taskData = [];
  if (skill.tasks && skill.tasks.length > 0) {
    skill.tasks.map((e) => {
      taskData.push({ tasks: e });
    });
    taskData.push({ tasks: "" });
  }

  if (taskData.length === 0) {
    taskData.push({ tasks: "" });
  }

  const [formValues, setFormValues] = useState(taskData);

  let handleForm = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    let len = formValues.length;
    if (len > 0) {
      if (formValues[len - 1].tasks.length > 0) {
        setFormValues([...formValues, { tasks: "" }]);
      }
    }
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const [isClicking, setIsCliking] = useState(false);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addFormFields(e);
      setIsCliking(true);
    }
    // e.preventDefault()
  };

  useEffect(() => {
    const textarea = document.getElementById(`task${formValues.length - 1}`);
    if (textarea && isClicking) {
      textarea.focus();
      setIsCliking(false);
    }
  }, [formValues, setFormValues]);

  let newTasks = [];
  if (formValues.length > 0) {
    formValues.map((e) => {
      if (e.tasks.length > 0) {
        newTasks.push(e.tasks);
      }
    });
  }

  skill.tasks = newTasks;

  let curTag = [];
  if (skill.newTag && skill.newTag.length > 0) {
    for (let i = 0; i < skill.newTag.length; i++) {
      curTag.push({ userId: myInfo.userId, tags: [skill.newTag[i]] });
    }
  }

  // get current time with formatting
  const currentTime = () => {
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var d = new Date();
    var day = days[d.getDay()];
    var hr = d.getHours();
    var min = d.getMinutes();
    if (min < 10) {
      min = "0" + min;
    }
    var ampm = "AM";
    if (hr > 12) {
      hr -= 12;
      ampm = "PM";
    }
    var date = d.getDate();
    var month = months[d.getMonth()];
    var year = d.getFullYear();
    let curTime =
      month + " " + date + ", " + year + " " + hr + ":" + min + " " + ampm;
    return curTime;
  };

  // store history data for any field change
  let historyState = [];

  if (data && Object.keys(data).length > 0) {
    if (data.skillName || skill.skillName) {
      if (data.skillName !== skill.skillName) {
        if (data.skillName.length > 0 && skill.skillName.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Skill Name",
            prev: data.skillName,
            next: skill.skillName,
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        }
      }
    }
    if (data.purpose || skill.purpose) {
      if (data.purpose !== skill.purpose) {
        if (!data.purpose && skill.purpose.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Purpose",
            prev: data.purpose,
            next: skill.purpose,
            hcTime: currentTime(),
            cardStatus: "added",
          };
          historyState.push(obj);
        } else if (data.purpose.length > 0 && skill.purpose.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Purpose",
            prev: data.purpose,
            next: skill.purpose,
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        } else if (data.purpose.length > 0 && !skill.purpose) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Purpose",
            prev: data.purpose,
            next: skill.purpose,
            hcTime: currentTime(),
            cardStatus: "removed",
          };
          historyState.push(obj);
        }
      }
    }
    if (data.tasks || skill.tasks) {
      if (
        data.tasks &&
        data.tasks.join() !== skill.tasks &&
        skill.tasks.join()
      ) {
        if (
          data.tasks &&
          data.tasks.length === 0 &&
          skill.tasks &&
          skill.tasks.length > 0
        ) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tasks",
            prev: data.tasks.join(),
            next: skill.tasks.join(),
            hcTime: currentTime(),
            cardStatus: "added",
          };
          historyState.push(obj);
        } else if (
          data.tasks &&
          data.tasks.join().length > 0 &&
          skill.tasks &&
          skill.tasks.join().length > 0
        ) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tasks",
            prev: data.tasks.join(),
            next: skill.tasks.join(),
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        } else if (
          data.tasks &&
          data.tasks.join().length > 0 &&
          skill.tasks &&
          skill.tasks.join() === ""
        ) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tasks",
            prev: data.tasks.join(),
            next: skill.tasks.join(),
            hcTime: currentTime(),
            cardStatus: "removed",
          };
          historyState.push(obj);
        }
      }
    }
    if (data.ownerType || skill.ownerType) {
      if (
        data.ownerType &&
        skill.ownerType &&
        data.ownerType !== skill.ownerType
      ) {
        if (
          data.ownerType &&
          data.ownerType.length > 0 &&
          skill.ownerType &&
          skill.ownerType.length > 0
        ) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Owner Type",
            prev: data.ownerType,
            next: skill.ownerType,
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        }
      }
    }
    if (data.owners || skill.owners) {
      let count = 0;
      let flag = false;
      if (
        data.owners &&
        data.owners.length > 0 &&
        skill.owners &&
        skill.owners.length > 0
      ) {
        for (let i = 0; i < data.owners.length; i++) {
          for (let j = 0; j < skill.owners.length; j++) {
            if (data.owners[i] === skill.owners[j]) {
              count++;
            }
          }
        }
      }
      if (
        (skill.owners &&
          data.owners &&
          data.owners.length === skill.owners.length) ||
        (data.owners && count !== data.owners.length) ||
        (data.owners &&
          skill.owners &&
          data.owners.length !== skill.owners.length)
      ) {
        flag = true;
      }
      let oldOwners = "";
      let newOwners = "";
      if (peoples && peoples.length > 0 && flag) {
        for (let i = 0; i < peoples.length; i++) {
          for (let j = 0; j < data.owners.length; j++) {
            if (peoples[i]._id === data.owners[j]) {
              if (j === 0) {
                oldOwners = `${peoples[i].fname} ${peoples[i].lname}`;
              } else if (j > 0) {
                oldOwners =
                  oldOwners + ", " + `${peoples[i].fname} ${peoples[i].lname}`;
              }
            }
          }
          for (let j = 0; j < skill.owners.length; j++) {
            if (peoples[i]._id === skill.owners[j]) {
              if (j === 0) {
                newOwners = `${peoples[i].fname} ${peoples[i].lname}`;
              } else if (j > 0) {
                newOwners =
                  newOwners + ", " + `${peoples[i].fname} ${peoples[i].lname}`;
              }
            }
          }
        }
      }
      if (oldOwners !== newOwners && flag) {
        if (oldOwners === "" && newOwners.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Owners",
            prev: oldOwners,
            next: newOwners,
            hcTime: currentTime(),
            cardStatus: "added",
          };
          historyState.push(obj);
        } else if (oldOwners.length > 0 && newOwners.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Owners",
            prev: oldOwners,
            next: newOwners,
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        } else if (oldOwners.length > 0 && !newOwners) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Owners",
            prev: oldOwners,
            next: newOwners,
            hcTime: currentTime(),
            cardStatus: "removed",
          };
          historyState.push(obj);
        }
      }
    }
    if (
      (data.tags && data.tags.length > 0) ||
      (skill.tags && skill.tags.length > 0)
    ) {
      if (data.tags && skill.tags && data.tags.join() !== skill.tags.join()) {
        if (data.tags.join() === "" && skill.tags.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tags",
            prev: data.tags.join(),
            next: skill.tags.join(),
            hcTime: currentTime(),
            cardStatus: "added",
          };
          historyState.push(obj);
        } else if (data.tags.length > 0 && skill.tags.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tags",
            prev: data.tags.join(),
            next: skill.tags.join(),
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        } else if (data.tags.length > 0 && skill.tags.length === 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tags",
            prev: data.tags.join(),
            next: skill.tags.join(),
            hcTime: currentTime(),
            cardStatus: "removed",
          };
          historyState.push(obj);
        }
      }
    }
  }

  const handelUpdate = async () => {
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    //update
    if (skill._id !== null) {
      let skillSort = localStorage.getItem("skillSort");
      dispatch(updateSkills({ ...skill, skillSort })).then(() => {
        let curDomain = localStorage.getItem("domainSort");
        dispatch(getDomain({ ...obj, curdomain: curDomain }));
        dispatch(historyCreate(historyState));
      });
    }
    dispatch({ type: HISTORY_DATAS_CLEAR });
    return true;
  };

  // next and previous use for changes skill data in current modal
  const handlePreviosNext = async (data) => {
    dispatch({ type: HISTORY_DATAS_CLEAR });
    let curUpdateData = [];
    if (skills && skills.length > 0 && isNToP && isNToP.length > 0) {
      for (let i = 0; i < isNToP.length; i++) {
        for (let j = 0; j < skills.length; j++) {
          if (skills[j]._id === isNToP[i]._id) {
            curUpdateData.push(skills[j]);
            break;
          }
        }
      }
    }

    if (data === "prev") {
      if (curUpdateData && curUpdateData.length > 0) {
        for (let i = 0; i < curUpdateData.length; i++) {
          if (curUpdateData[i]._id === skill._id && i >= 0) {
            localStorage.setItem("npState", "Skill");
            let index = i === 0 ? curUpdateData.length - 1 : i - 1;
            await handelUpdate().then(() => {
              setSkills(curUpdateData[i - 1], curUpdateData, 0, "Skill");
            });
          }
        }
      }
    } else if (data === "next") {
      if (curUpdateData && curUpdateData.length > 0) {
        for (let i = 0; i < curUpdateData.length; i++) {
          if (
            curUpdateData[i]._id === skill._id &&
            i <= curUpdateData.length - 1
          ) {
            localStorage.setItem("npState", "Skill");
            let index = i < curUpdateData.length - 1 ? i + 1 : 0;
            await handelUpdate().then(() => {
              setSkills(curUpdateData[index], curUpdateData, 0, "Skill");
            });
          }
        }
      }
    }
  };

  // copy current skill
  const skillCopy = (e) => {
    e.preventDefault();
    dispatch({ type: HISTORY_DATAS_CLEAR });
    setSkill({
      ...skill,
      _id: null,
      skillName: skill.skillName + " (Copy)",
    });
    // dispatch(createSkills(datas))
  };

  // create or update skill data
  const [documentData, setDocument] = useState();
  const skillsSubmit = (e) => {
    e.preventDefault();
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    localStorage.setItem("npState", "");
    //update
    // console.log(skill)
    // return;

    let skillSort = localStorage.getItem("skillSort");
    let domainSort = localStorage.getItem("domainSort");
    let circleSort = localStorage.getItem("circleSort");
    let projectSort = localStorage.getItem("projectSort");
    skill.documents = documentData;
    if (skill._id !== null) {
      dispatch(
        updateSkills({
          ...skill,
          skillSort,
          domainSort,
          circleSort,
          projectSort,
        })
      ).then(() => {
        dispatch(historyCreate(historyState));
        dispatch(getDomain({ ...obj, domainSort: domainSort }));
        dispatch(getCircle({ ...obj, curcircle: circleSort }));
        dispatch(getProject({ ...obj, curproject: projectSort }));
      });
    } else {
      //create
      dispatch(
        createSkills({
          ...skill,
          skillSort,
          domainSort,
          circleSort,
          projectSort,
        })
      ).then(() => {
        // dispatch(getDomain({ ...obj, domainSort: domainSort }));
      });
    }
    dispatch({ type: HISTORY_DATAS_CLEAR });
  };

  useEffect(() => {
    if (CloseState && CloseState === "Skills") {
      stateCloseState("");
      //update
      let obj = {};
      obj.teamId = localStorage.getItem("teamId");
      obj.userId = myInfo.userId;
      localStorage.setItem("npState", "");
      //update
      let skillSort = localStorage.getItem("skillSort");
      let domainSort = localStorage.getItem("domainSort");
      let circleSort = localStorage.getItem("circleSort");
      let projectSort = localStorage.getItem("projectSort");
      if (skill._id !== null) {
        skill.documents = documentData;

        dispatch(
          updateSkills({
            ...skill,
            skillSort,
            domainSort,
            circleSort,
            projectSort,
          })
        ).then(() => {
          // dispatch(getDomain({ ...obj, curdomain: skillSort }));
          dispatch(historyCreate(historyState));
        });
      } else {
        //create
        skill.documents = documentData;

        dispatch(
          createSkills({
            ...skill,
            skillSort,
            domainSort,
            circleSort,
            projectSort,
          })
        ).then(() => {
          // dispatch(getDomain({ ...obj, curdomain: skillSort }));
        });
      }
      dispatch({ type: HISTORY_DATAS_CLEAR });
    }
  }, [CloseState, stateCloseState]);

  //Skill to Role
  const SConToRole = (data) => {
    if (data._id && data._id.length > 0) {
      let objData = {
        teamId: data.teamId,
        roleName: data.skillName,
        purpose: data.purpose,
        tasks: data.tasks,
        ownerRole: data.ownerType,
        owners: data.owners,
        skillId: data._id,
        domains: data.domains,
        tags: data.tags,
        url: `${SERVER_URI}/user/role/create`,
        adminId: adminId,
      };

      let objsData = {};
      objsData.teamId = localStorage.getItem("teamId");
      objsData.userId = myInfo.userId;

      let obj = {};
      obj.teamId = localStorage.getItem("teamId");
      obj.adminId = skill.adminId;
      obj.url = `${SERVER_URI}/user/skill/delete`;
      obj.skillId = data._id;

      let objs = {};
      objs.teamId = localStorage.getItem("teamId");
      objs.userId = myInfo.userId;
      let cuSkills = states.Skills.filter((e) => {
        return e !== obj.skillId;
      });

      freezeWindow(false);
      dispatch(createRole({ ...objData, transfer: true })).then(() => {
        dispatch(deleteSkills(obj)).then(() => {
          dispatch({ type: ROLE_SUCCESS_MESSAGE_CLEAR });
          dispatch({ type: HISTORY_DATAS_CLEAR });
          unfreezeWindow();
        });
      });
    }
  };

  // delete current skills
  const skillDelete = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You can still restore this from trash!",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let obj = {};
        obj.teamId = localStorage.getItem("teamId");
        obj.adminId = skill.adminId;
        obj.url = `${SERVER_URI}/user/skill/delete`;
        obj.skillId = skill._id;
        obj.softDelete = true;

        let objs = {};
        objs.teamId = localStorage.getItem("teamId");
        objs.userId = myInfo.userId;
        // let domainSort = localStorage.getItem("domainSort")

        // return;
        dispatch(deleteSkills(obj)).then(() => {
          // dispatch(getState({ userId: myInfo.userId, teamId: obj.teamId }));
          dispatch({ type: SKILL_ID_CLEAR });
          dispatch({ type: HISTORY_DATAS_CLEAR });
          closeSkill();
        });
        // closeSkill()
      }
    });
  };

  useEffect(() => {
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    // console.log(skillsMessage);//states get ssucessfully
    if (skillsMessagee) {
      let curdomain = localStorage.getItem("domainSort");
      let curdomains = curdomain.split(",");
      // console.log(nDaoamins);
      if (skill.domains.length > 0 && nDaoamins && nDaoamins.length === 0) {
        // for (let i = 0; i < skill.domains.length; i++) {
        //   console.log(1130, skill.domains[i].domain)
        //   console.log(skill.domains[i])
        //   if (
        //     skill.domains[i].owner !== null &&
        //     skill.domains[i].domain !== null
        //   ) {
        //     let targetDomain = domains.find((d) => d._id === skill.domains[i].domain);
        //     console.log("target domain :",targetDomain);
        //     if (targetDomain) {
        //       dispatch(
        //         updateDomain({
        //           adminId: myInfo.userId,
        //           teamId: obj.teamId,
        //           url: `${SERVER_URI}/user/domain/update`,
        //           _id: skill.domains[i].domain,
        //           owners: {
        //             type:
        //               skill._id && skill._id.length > 0 ? skill._id : cuSkillId,
        //             owner:
        //               skill.domains[i] &&
        //                 skill.domains[i].owner &&
        //                 skill.domains[i].owner.length > 0
        //                 ? skill.domains[i].owner
        //                 : null,
        //           },
        //           domainName: targetDomain.domainName
        //         })
        //       ).then(() => {
        //         // dispatch(getDomain({ ...obj, curdomain: curdomains }));
        //         dispatch({ type: DOMAIN_SUCCESS_MESSAGE_CLEAR });
        //       });
        //     }
        //   }
        // }
      } else if (
        skill.domains.length > 0 &&
        nDaoamins &&
        nDaoamins.length > 0
      ) {
        for (let i = 0; i < skill.domains.length; i++) {
          if (
            nDaoamins[i] &&
            skill.domains[i].owner !== null &&
            skill.domains[i].domain !== null
          ) {
            // console.log(nDaoamins[i]);
            let targetDomain = domains.find((d) => d._id === skill.domains[i].domain);
            console.log(targetDomain);
            if (targetDomain) {
              dispatch(
                updateDomain({
                  adminId: myInfo.userId,
                  teamId: obj.teamId,
                  url: `${SERVER_URI}/user/domain/update`,
                  _id: nDaoamins[i],
                  owners: {
                    type:
                      skill._id && skill._id.length > 0 ? skill._id : cuSkillId,
                    owner:
                      skill.domains[i] &&
                        skill.domains[i].owner &&
                        skill.domains[i].owner.length > 0
                        ? skill.domains[i].owner
                        : null,
                  },
                  domainName: targetDomain.domainName
                })
              ).then(() => {
                // dispatch(getDomain({ ...obj, curdomain: curdomains }));
                dispatch({ type: DOMAIN_SUCCESS_MESSAGE_CLEAR });
              });
            }
          }
        }
      }
    }

    dispatch({ type: SKILL_ID_CLEAR });

    if (skillsMessagee) {
      let cuSkills = localStorage.getItem("skillSort");
      let curSkills = cuSkills.split(",");
      /* if (curTag && curTag.length > 0) {
        dispatch(
          tagCreate({
            adminId: myInfo.userId,
            teamId: obj.teamId,
            url: `${SERVER_URI}/user/tags/update`,
            tags: curTag
          })
        ).then(() => {
          dispatch({ type: TAGS_SUCCESS_MESSAGE_CLEAR });
          dispatch(getTags(obj));
        });
      } */
      // dispatch(getSkills({ ...obj, curskill: curSkills }));
      dispatch({ type: SKILL_SUCCESS_MESSAGE_CLEAR });
      let npSatate = localStorage.getItem("npState");
      // console.log(1288, npSatate);
      if (npSatate !== "Skill") {
        closeSkill();
      }
    }

    if (skillError) {
      Swal.fire({
        // icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: skillError,
      });
      // alert.error(peopleError)
      dispatch({ type: SKILL_ERROR_CLEAR });
      // dispatch(getSkills(obj));
      // closeSkill();
    }
    dispatch(getTags({ userId: myInfo.userId }));
  }, [skillsMessagee, skillError, cuSkillId]);

  // get current skills history
  const historysGet = (data) => {
    if (historys && historys.length === 0) {
      dispatch(historyGet({ cardId: data }));
    } else {
      dispatch({ type: HISTORY_DATAS_CLEAR });
    }
  };

  useEffect(() => {
    if (historyMessage) {
      dispatch({ type: HISTORY_MESSAGE_CLEAR });
    }
  }, [historyMessage && historys]);

  // Set skills history messages
  let historysMessage = [];

  if (historys && historys.length > 0) {
    for (let i = 0; i < historys.length; i++) {
      if (historys[i].cardStatus === "created") {
        let str = `<i>${historys[i].hcTime}</i> : Created`;
        historysMessage.push(str);
      } else if (historys[i].cardStatus === "added") {
        let str =
          `<i>${historys[i].hcTime}</i> :  ${historys[i].field} added ` +
          " (" +
          `<b>${historys[i].next}</b>` +
          ")";
        historysMessage.push(str);
      } else if (historys[i].cardStatus === "changed") {
        let str =
          `<i>${historys[i].hcTime}</i> :  ${historys[i].field} changed from ` +
          `<b>${historys[i].prev}</b>` +
          " to " +
          `<b>${historys[i].next}</b>`;
        historysMessage.push(str);
      } else if (historys[i].cardStatus === "removed") {
        let str =
          `<i>${historys[i].hcTime}</i> :  ${historys[i].field} removed ` +
          " (previously: " +
          `<b>${historys[i].next}</b>` +
          ")";
        historysMessage.push(str);
      }
    }
  }

  const ref = useRef(null);

  const handleClick = () => {
    ref.current.focus();
  };

  const CloseSkillModal = (modal) => {
    setPurposetreeLoader((prev => ({ ...prev, skill: false })));
    dispatch({ type: HISTORY_DATAS_CLEAR });
    closeSkill();
  };

  //added by ranjit

  const onDragStart = (e, index) => {
    e.dataTransfer.setData("index", index);
  };

  const onDragOver = (e, index) => {
    e.preventDefault();
  };

  const onDrop = (e, index) => {
    const draggedIndex = e.dataTransfer.getData("index");
    const items = [...formValues];
    const draggedItem = items[draggedIndex];
    items.splice(draggedIndex, 1);
    items.splice(index, 0, draggedItem);
    setFormValues(items);
  };

  const CustomOption = ({ innerProps, label, value }) => {
    let flag = false;
    if (peoples && peoples.length > 0) {
      for (let i = 0; i < peoples.length; i++) {
        if (peoples[i]._id === value && peoples[i].workRole === "External") {
          flag = true;
          break;
        }
      }
    }
    let data = ``;
    if (flag) {
      data += `<span class="custom-badge blue">EXT</span>`;
    }
    data += label;
    return (
      <div {...innerProps} className="tdListItem">
        <span dangerouslySetInnerHTML={{ __html: data }} />
      </div>
    );
  };

  const isExtPerson = (Id) => {
    if (peoples && peoples.length > 0) {
      let flag = false;
      for (let i = 0; i < peoples.length; i++) {
        if (peoples[i]._id === Id && peoples[i].workRole === "External") {
          flag = true;
          break;
        }
      }
      return flag;
    }
  };

  const outerBoxClick = () => {
    if (
      formValues &&
      formValues.length > 0 &&
      formValues[formValues.length - 1].tasks &&
      formValues[formValues.length - 1].tasks.length > 0
    ) {
      addFormFields();
      setIsCliking(true);
    }
  };

  // const initWorkLoadState = value.map((sv) => ({ ownerId: sv.value, plan: 0 }));
  // value && value.length >0 ? value.map((sv) => ({ ownerId: sv.value, plan: 0 })):[]

  const [workloadState, setWorkLoadState] = useState(skill.workload || []);

  const handleWorkloadChange = (sv, inputVal) => {
    if (inputVal > 1) {
      inputVal = 1
    } else if (inputVal < 0) {
      inputVal = 0
    }
    // Find the index of the item in workloadState that matches the ownerId (sv.value)
    const existingIndex = workloadState.findIndex(
      (item) => item && item.ownerId === sv.value
    );

    // Create a new copy of the workloadState array
    const updatedWorkloadState = [...workloadState];
    if (existingIndex !== -1) {
      // If the item already exists, update its plan
      updatedWorkloadState[existingIndex] = {
        ...updatedWorkloadState[existingIndex],
        plan: inputVal,
      };
    } else {
      // If the item does not exist, add a new item
      updatedWorkloadState.push({
        ownerId: sv.value,
        plan: inputVal,
      });
    }

    setSkill({ ...skill, workload: updatedWorkloadState });
    // Update the state with the new workloadState array
    setWorkLoadState(updatedWorkloadState);
  };

  const HandleSecretDownloadTool = () => {

    // Create the content of the file
    const fileContent =
      `Name: ${skill.skillName}\nPurpose: ${skill.purpose}\nTasks: ${skill.tasks.join(',')}\nOwners: ${value.map(sv => sv.label).filter(Boolean).join(',')}\nTags: ${skill.tags.join(',')}`;

    // Create a Blob object from the content with file content and file type
    const blob = new Blob([fileContent], { type: 'text/plain' });

    // Create a URL for the Blob
    const fileUrl = URL.createObjectURL(blob);

    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = fileUrl;

    // Set the download attribute with a default file name
    link.download = `${skill.skillName}_skill.txt`;

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up the object URL
    URL.revokeObjectURL(fileUrl);
  };

  return (
    <div
      id="skillModal"
      onClick={() => outerBoxClick()}
      role="dialog"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div className="modal-dialog modal-lg modal-dialog-scrollable">
        <form className="form" onSubmit={(e) => skillsSubmit(e)}>
          <div className="modal-content">
            <div className="modal-header header-blue">
              <div className="row">
                <div className="col-xs-8 text-left">
                  <h4 className="modal-title">
                    <img
                      src="images/icon-skill.png"
                      style={{
                        width: "17px",
                        marginTop: "-3px",
                        marginRight: "5px",
                      }}
                      onClick={HandleSecretDownloadTool}
                    />{" "}
                    {/* SKILL */}{GetAliasesName(teams, "Skills").toUpperCase()}
                    <span className="move-to" onClick={() => SConToRole(skill)}>
                      Convert To {/* Role */}{GetAliasesName(teams, "Roles")}
                    </span>
                  </h4>
                </div>
                {compareMode.active || (focusMode && focusMode.length > 0) ? null : (
                  <div className="col-xs-4 text-right">
                    <Tippy content={<span>Previous {/* Skill */} {GetAliasesName(teams, "Skills")}</span>}>
                      <button
                        style={{ marginRight: "10px", cursor: "pointer" }}
                        type="button"
                        className="btn btn-mytpt-gray show-prev"
                        onClick={() => handlePreviosNext("prev")}
                      >
                        <i className="fa fa-angle-left"></i>
                      </button>
                    </Tippy>
                    <Tippy content={<span>Next {/* Skill */}{GetAliasesName(teams, "Skills")}</span>}>
                      <button
                        style={{ cursor: "pointer" }}
                        type="button"
                        className="btn btn-mytpt-gray show-next"
                        onClick={() => handlePreviosNext("next")}
                      >
                        <i className="fa fa-angle-right"></i>
                      </button>
                    </Tippy>
                  </div>
                )}
              </div>
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <TextareaAutosize
                      type="text"
                      ref={ref}
                      name="skillName"
                      value={skill.skillName}
                      onKeyDown={handleKeyDown}
                      onChange={handleChange}
                      className="form-control tool-name logit"
                      placeholder={`+ Add ${GetAliasesName(teams, "Skills").toLowerCase()} name`}
                      style={centeredPlaceholderStyle}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <h4>Purpose</h4>
                    <TextareaAutosize
                      // cacheMeasurements
                      type="text"
                      value={skill.purpose}
                      onChange={handleChange}
                      className="form-control purpose logit"
                      name="purpose"
                      placeholder="+ Add purpose"
                    />
                  </div>

                  <div className="form-group tasks">
                    <Tasks
                      formValues={formValues}
                      onDragStart={onDragStart}
                      onDragOver={onDragOver}
                      onDrop={onDrop}
                      handleForm={handleForm}
                      handleKeyPress={handleKeyPress}
                      removeFormFields={removeFormFields}
                    />
                  </div>

                  <div className="form-group objectives">
                    <Objectives data={skill} skill={skill} />
                  </div>

                  <WorkLoadSRDL
                    handleChange={handleChange}
                    planTotal={Number(parseFloat(skill.planTotal).toString().replace(",", "."))}
                    status={Number(sumFTE(skill.workload, value).toString().replace(",", "."))}
                  />

                  <div className="form-group">
                    <h4>Notes</h4>
                    <TextareaAutosize
                      type="text"
                      className="form-control purpose logit"
                      name="notes"
                      defaultValue=''
                      value={skill.notes}
                      onChange={handleChange}
                      placeholder="+ Add notes"
                      maxLength="100"
                      autoFocus={null}
                    />
                  </div>

                </div>
                {/* </div> */}

                <div className="col-md-6">
                  <div className="form-group skill-type">
                    <input
                      type="radio"
                      className="logit"
                      id="single_owner_skill"
                      name="ownerType"
                      value="Single owner"
                      checked={
                        skill.ownerType === "Single owner" ? true : false
                      }
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="single_owner_skill"
                      className="inline-label"
                    >
                      Single owner
                    </label>

                    <input
                      type="radio"
                      className="logit"
                      id="multiple_owner_skill"
                      name="ownerType"
                      value="Multiple owners"
                      checked={
                        skill.ownerType === "Multiple owners" ? true : false
                      }
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="multiple_owner_skill"
                      className="inline-label"
                    >
                      Multiple owners
                    </label>

                    {skill.ownerType === "Single owner" ? (
                      <div
                        className="form-group flag"
                        style={{ display: "block" }}
                      >
                        <label>
                          Single owner skill can be Circle/ Project lead
                        </label>
                      </div>
                    ) : null}
                  </div>

                  {skill.ownerType === "Single owner" ? (
                    <>
                      <div className="form-group skill-owners ss">
                        <div className="row">
                          <div className="col-xs-9" style={{ paddingRight: 0 }}>
                            <h4>Owner</h4>
                          </div>
                          <div className="col-xs-3">
                            <h4>FTE</h4>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xs-9" style={{ paddingRight: 0 }}>
                            <Select
                              closeMenuOnSelect={true}
                              classNamePrefix="select"
                              className="single-select-container"
                              // components={animatedComponents}
                              isClearable
                              isSearchable
                              onChange={(newValue) => handelSelect(newValue)}
                              defaultValue={
                                value && value.length > 0
                                  ? value.map((value, cmindex) => {
                                    return {
                                      label: (
                                        <div style={{ fontSize: "14px" }}>
                                          {/* {value.value &&
                                            isExtPerson(value.value) ? (
                                            <span className="custom-badge blue">
                                              EXT
                                            </span>
                                          ) : null}
                                          {value.label} */}
                                          {value.value &&
                                            isExtPerson(value.value) ? (
                                            <>
                                              <span
                                                className="custom-badge blue"
                                                style={{
                                                  display: "inline-block",
                                                }}
                                              >
                                                EXT
                                              </span>{" "}
                                              {value.label}
                                            </>
                                          ) : (
                                            value.label
                                          )}
                                        </div>
                                      ),
                                      value: value.value,
                                    };
                                  })
                                  : null
                              }
                              options={options}
                              placeholder="+ Add owner"
                              components={{ Option: CustomOption }}
                            // menuIsOpen={true}
                            />
                          </div>
                          <div className="col-xs-3">
                            {value && value.length > 0 ? (
                              value.map((sv, index) => {
                                if (index > 0) return null;

                                // Find the corresponding plan value from workloadState based on ownerId (sv.value)
                                const matchingWorkload = workloadState.find(
                                  (item) => item.ownerId === sv.value
                                );

                                // Extract the plan value or use an empty string if it doesn't exist
                                const planValue = matchingWorkload
                                  ? matchingWorkload.plan
                                  : "";

                                return (
                                  <input
                                    type="number"
                                    id={`plan-${index}`}
                                    className="form-control fte"
                                    name={`plan-${index}`}
                                    placeholder="Max: 1"
                                    value={planValue} // Bind the input value to planValue
                                    step="any"
                                    onChange={(e) =>
                                      handleWorkloadChange(sv, e.target.value)
                                    }
                                    onWheel={numberInputOnWheelPreventChange}
                                    key={index}
                                  />
                                );
                              })
                            ) : (
                              <input
                                type="number"
                                id="plan-0"
                                className="form-control fte"
                                placeholder="Max: 1"
                                name="plan-0"
                                defaultValue=""
                                disabled
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="form-group skill-owners ms">
                        <div className="row">
                          <div className="col-xs-9" style={{ paddingRight: 0 }}>
                            <h4>Owners</h4>
                          </div>
                          <div className="col-xs-3">
                            <h4>FTE</h4>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xs-9" style={{ paddingRight: 0 }}>
                            <Select
                              closeMenuOnSelect={false}
                              classNamePrefix="select"
                              className="multiple-select-container"
                              // components={animatedComponents}
                              isClearable
                              isSearchable
                              onChange={(newValue) => handelSelect(newValue)}
                              defaultValue={
                                value && value.length > 0
                                  ? value.map((value, cmindex) => {
                                    return {
                                      label: (
                                        <div style={{ fontSize: "14px" }}>
                                          {/* {value.value &&
                                            isExtPerson(value.value) ? (
                                            <span className="custom-badge blue">
                                              EXT
                                            </span>
                                          ) : value.label}
                                          {value.label} */}

                                          {value.value &&
                                            isExtPerson(value.value) ? (
                                            <>
                                              <span
                                                className="custom-badge blue"
                                                style={{
                                                  display: "inline-block",
                                                }}
                                              >
                                                EXT
                                              </span>{" "}
                                              {value.label}
                                            </>
                                          ) : (
                                            value.label
                                          )}
                                        </div>
                                      ),
                                      value: value.value,
                                    };
                                  })
                                  : null
                              }
                              isMulti
                              options={options}
                              components={{ Option: CustomOption }}
                            // placeholder="+ Add owners"
                            // menuIsOpen={true}
                            />
                          </div>
                          <div className="col-xs-3">
                            {(value ? [...value, {}] : [{}]).map(
                              (sv, index) => {
                                // Find the corresponding plan value from workloadState based on ownerId (sv.value)
                                const matchingWorkload = workloadState.find(
                                  (item) => item && item.ownerId === sv.value
                                );

                                // Extract the plan value or use an empty string if it doesn't exist
                                const planValue = matchingWorkload
                                  ? matchingWorkload.plan
                                  : "";

                                return (
                                  <input
                                    key={index}
                                    // style={{ margin: "2px 2px 5px 0" }}
                                    type="number"
                                    id={`plan-${index}`}
                                    className="form-control fte"
                                    name={`plan-${index}`}
                                    placeholder="Max: 1"
                                    value={
                                      index < value.length ? planValue : ""
                                    } // Bind the input value to planValue or empty for the new input
                                    step="any"
                                    disabled={
                                      index === value.length ? true : false
                                    } // Make the additional field read-only initially
                                    onFocus={(e) =>
                                      index === value.length &&
                                      e.target.removeAttribute("readOnly")
                                    }
                                    onChange={(e) => {
                                      const newValue = e.target.value;
                                      if (newValue && index === value.length) {
                                        // Update the value state to include the new entry
                                        const newValues = [
                                          ...value,
                                          { value: newValue },
                                        ];
                                        setValue(newValues);
                                        handleWorkloadChange(
                                          { value: newValue },
                                          newValue
                                        );
                                      } else {
                                        handleWorkloadChange(sv, newValue);
                                      }
                                    }}
                                    onWheel={numberInputOnWheelPreventChange}
                                  />
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {value && value.length === 0 ? (
                    <div
                      className="form-group flag"
                      style={{ display: "block" }}
                    >
                      <label>
                        Skills without owners will be flagged:{" "}
                        <span className="custom-badge red">NO</span>
                      </label>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="form-group domains">
                    <h4>{GetAliasesName(teams, "Domains")}</h4>

                    <div className="row">
                      <div className="col-xs-5">
                        <div className="flag">
                          <label>This {/* skill */ GetAliasesName(teams, "Skills").toLowerCase()} own these {/* domains */}{GetAliasesName(teams, "Domains").toLowerCase()}:</label>
                        </div>
                      </div>

                      <div className="col-xs-5">
                        <div className="flag">
                          <label>Responsible:</label>
                        </div>
                      </div>

                      <div className="col-xs-2"></div>
                    </div>

                    <div className="domain-list domain-container">
                      {domainsValue.map((element, index) => {
                        // console.log(element)
                        let curDomain = [];
                        let curOwner = [];
                        if (
                          element.domain &&
                          element.domain.value &&
                          element.domain.value.length > 0
                        ) {
                          curDomain.push(element.domain);
                        }
                        if (
                          element.owner &&
                          element.owner.value &&
                          element.owner.value.length > 0
                        ) {
                          curOwner.push(element.owner);
                        }
                        // console.log(index, domainsValue[index].domain)
                        return (
                          <>
                            <div className="domain" key={index}>
                              <div
                                className="choose-domain ss"
                                style={{ paddingRight: "5px" }}
                              >
                                <CreatableSelect
                                  ref={(ref) =>
                                    (selectInputRefs.current[index] = ref)
                                  }
                                  components={animatedComponents}
                                  isClearable
                                  isSearchable={
                                    domainsValue[index].domain &&
                                      domainsValue[index].domain.value
                                      ? false
                                      : true
                                  }
                                  classNamePrefix="select"
                                  onChange={(e) => handleDomainsForm(index, e)}
                                  // onMenuOpen={() => handleDomainsFormClear(index)}
                                  value={curDomain}
                                  options={opDoamin}
                                  placeholder={`+ Add ${GetAliasesName(teams, "Domains").toLowerCase()}`}
                                  openMenuOnClick={
                                    domainsValue[index].domain &&
                                      domainsValue[index].domain.value
                                      ? false
                                      : true
                                  }
                                />
                              </div>

                              <div
                                className="pick-owner ss"
                                style={{ paddingRight: "0px" }}
                              >
                                <Select
                                  isClearable
                                  isSearchable
                                  classNamePrefix="select"
                                  onChange={(e) => handleOwnerForm(index, e)}
                                  value={
                                    curOwner &&
                                      curOwner.length > 0 &&
                                      curOwner[0].value &&
                                      curOwner[0].value.length > 0
                                      ? [
                                        {
                                          value: curOwner[0].value,
                                          label: (
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "left",
                                              }}
                                            >
                                              {/* {curOwner[0].value && isExtPerson(curOwner[0].value) ?
                                          <span className="custom-badge blue"  >
                                            EXT
                                          </span>
                                          : null} */}
                                              {curOwner[0].label}
                                            </div>
                                          ),
                                        },
                                      ]
                                      : []
                                  }
                                  options={domainOwners}
                                  placeholder="+ Pick owner"
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      borderColor: state.isFocused
                                        ? "gray"
                                        : "white",
                                    }),
                                  }}
                                  components={{ Option: CustomOption }}
                                />
                              </div>

                              <div
                                className="domain-actions"
                                style={{ paddingLeft: "3px" }}
                              >
                                {index !== domainsValue.length ? (
                                  <button
                                    type="button"
                                    onClick={() =>
                                      removeFormDomainsFields(index)
                                    }
                                    className="button btn-mytpt-gray remove-attribution btn btn-remove"
                                  >
                                    <span
                                      style={{ paddingLeft: "1px" }}
                                      className="fa fa-times"
                                      aria-hidden="true"
                                    ></span>
                                  </button>
                                ) : null}
                              </div>
                            </div>
                          </>
                        );
                      })}

                      <button
                        type="button"
                        onClick={() => addFormDomainsFields()}
                        className="btn btn-mytpt attribute-domain pull-right"
                        data-modal_id="skillsModal"
                        style={{ width: "maxContent" }}
                      >
                        <span className="fa fa-plus"> </span>
                      </button>
                    </div>
                  </div>

                  <div className="form-group member-in">
                    <h4>Member In</h4>
                    <div className="flag">
                      <label>This {/* skill */GetAliasesName(teams, "Skills").toLowerCase()} is currently member in:</label>
                    </div>
                    <div
                      className="row"
                      style={{ marginRight: 0, marginLeft: 0 }}
                    >
                      {memberIds && memberIds.length > 0
                        ? memberIds.map((e, index) => {
                          if (
                            !e.memberName ||
                            e.memberName === "" ||
                            e.memberName === null
                          )
                            return;

                          return (
                            <div key={index} className="col-xs-12">
                              {e.memberName}
                              {e.XTD.length > 0 && e.XTD === "XTD" ? (
                                <span
                                  style={{
                                    display: "inlineBlock",
                                    marginBottom: "0",
                                  }}
                                  className="custom-badge lightgray person-pill text-red"
                                >
                                  <span className="text-red">XTD</span>
                                </span>
                              ) : null}
                              <a
                                className="remove"
                                data-member_list_id="circles"
                              >
                                <span
                                  onClick={() => memberHandel(e)}
                                  className="fa fa-times"
                                ></span>
                              </a>
                            </div>
                          );
                        })
                        : null}
                    </div>
                  </div>
                  <Documents data={data} skill={skill} setDocumentData={setDocument} />
                  <div className="form-group tags">
                    <h4>Tags</h4>
                    <Tags
                      data={arr}
                      onChange={handleChange}
                      skill={skill}
                      name="tags"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <div className="row">
                <div className="col-xs-4 text-left">
                  {localStorage.getItem("mode") !== "View" && (
                    <Tippy content={<span>Copy This {/* Skill */}{GetAliasesName(teams, "Skills")}</span>}>
                      <button
                        type="button"
                        onClick={(e) => skillCopy(e)}
                        className="btn btn-mytpt-gray clone"
                        data-id="skillsModal"
                      >
                        <img alt="" src="./images/newicons/copy.svg" />
                      </button>
                    </Tippy>
                  )}

                  {localStorage.getItem("mode") !== "View" && skill._id ? (
                    <Tippy content={<span>Delete This {/* Skill */} {GetAliasesName(teams, "Skills")}</span>}>
                      <button
                        type="button"
                        onClick={skillDelete}
                        className="btn btn-mytpt-gray delete"
                        data-id="skillsModal"
                        data-post_type="skill"
                      >
                        <img alt="" src="./images/newicons/delete.svg" />
                      </button>
                    </Tippy>
                  ) : null}

                  <Tippy content={<span>View History</span>}>
                    <button
                      type="button"
                      onClick={() => historysGet(skill._id)}
                      style={
                        historysMessage.length > 0
                          ? { backgroundColor: "#F3D068" }
                          : null
                      }
                      className="btn btn-mytpt-gray history"
                    >
                      <img alt="" src="./images/newicons/history.svg" />
                    </button>
                  </Tippy>
                </div>

                <div className="col-xs-8 text-right">
                  <Tippy content={<span>Close Tool</span>}>
                    <button
                      type="button"
                      onClick={CloseSkillModal}
                      className="btn btn-mytpt-red"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </Tippy>
                  {localStorage.getItem("mode") !== "View" && (
                    <Tippy content={<span>Save And Close</span>}>
                      <button
                        type="submit"
                        className="btn btn-mytpt save-close"
                        data-modal_id="skillsModal"
                      >
                        <i className="fa fa-check"></i>
                      </button>
                    </Tippy>
                  )}
                </div>
              </div>

              {historysMessage.length > 0 ? (
                <div className="row activities">
                  <div className="col-xs-12">
                    <h4>History</h4>
                    {historysMessage.reverse().map((e, index) => {
                      return (
                        <p
                          key={index}
                          dangerouslySetInnerHTML={{ __html: e }}
                        ></p>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
