import axios from 'axios'
import {
    LINK_CREATE_SUCCESS, LINK_CREATE_FAIL, LINK_GET_SUCCESS, LINK_DELETE_SUCCESS,
    LINK_UPDATE_SUCCESS, LINK_UPDATE_FAIL, LINK_DELETE_FAIL, LINK_GET_FAIL,
    LINK_DROP_UPDATE_SUCCESS, LINK_DROP_UPDATE_FAIL
} from '../types/linkType'


// const token = localStorage.getItem("authToken");
import { SERVER_URI } from '../../config/keys'

import { isCardExist } from '../../HelperFunctions/CardExist'
import { DispatchBlocker } from '../DispatchBlocker/DispatchBlocker'

export const userAuth = (token) => {
    axios.interceptors.request.use(
        config => {
            config.headers.authorization = `Bearer ${token}`;
            return config;
        },
        error => {
            return Promise.reject(error)
        }
    )
}

export const createLink = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        const currentState = getState();
        if(isCardExist(currentState.links.links, datas.linkName, 'Link')){
            dispatch({
                type: LINK_CREATE_FAIL,
                payload: {
                    linkError: `Link with this name already exist!`
                }
            })
            return;
        }

        // const url = 'http://localhost:8080'
        let token = localStorage.getItem('authToken')
        try {
            userAuth(token)
            // console.log(datas)
            const response = await axios.post(`${SERVER_URI}/user/link/create`, datas)
            // console.log(response.data)

            dispatch({
                type: LINK_CREATE_SUCCESS,
                payload: {
                    links: response.data.links,
                    linkSuccessMessage: response.data.message,
                    cuLinkId: response.data.data._id
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: LINK_CREATE_FAIL,
                    payload: {
                        linkError: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: LINK_CREATE_FAIL,
                    payload: {
                        linkError: error.message
                    }
                })
            }
        }
    }
}

export const getLink = (datas) => {
    return async (dispatch) => {
        // const url = 'http://localhost:8080'
        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'adminId': datas.userId,
                'teamId': datas.teamId,
                'curlink': datas.curlink,
                'linksData': datas.linksData,
                'url': `${SERVER_URI}/user/link/get`
            }
        }
        // console.log(config)
        try {
            const token = localStorage.getItem("authToken");
            // console.log(token)
            userAuth(token)
            const response = await axios.get(`${SERVER_URI}/user/link/get`, config)
            // console.log( response.data)
            // localStorage.setItem('authToken', response.data.data.token)

            dispatch({
                type: LINK_GET_SUCCESS,
                payload: {
                    linkSuccessMessage: response.data.message,
                    links: response.data.data
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: LINK_GET_FAIL,
                    payload: {
                        linkError: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: LINK_GET_FAIL,
                    payload: {
                        linkError: error.message
                    }
                })
            }
        }
    }
}

export const updateLink = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        // Accessing the current state
        const currentState = getState();
        if(isCardExist(currentState.links.links, datas.linkName, 'Link', datas._id)){
            dispatch({
                type: LINK_UPDATE_FAIL,
                payload: {
                    linkError: `Link with this name already exist!`
                }
            })
            return;
        }

        // const url = 'http://localhost:8080'
        let token = localStorage.getItem('authToken')

        try {
            // console.log(datas)
            userAuth(token)
            const response = await axios.put(`${SERVER_URI}/user/link/update`, datas)
            // console.log("f3", response.data.message);
            dispatch({
                type: LINK_UPDATE_SUCCESS,
                payload: {
                    links: response.data.links,
                    linkSuccessMessage: response.data.message,
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: LINK_UPDATE_FAIL,
                    payload: {
                        linkError: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: LINK_UPDATE_FAIL,
                    payload: {
                        linkError: error.message
                    }
                })
            }
        }
    }
}

export const dropUpdateLink = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        // const url = 'http://localhost:8080'
        let token = localStorage.getItem('authToken')

        try {
            // console.log(datas)
            userAuth(token)
            const response = await axios.put(`${SERVER_URI}/user/link/update`, datas)

            dispatch({
                type: LINK_DROP_UPDATE_SUCCESS,
                payload: {
                    links: response.data.links,
                    linkSuccessMessage: response.data.message,
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: LINK_DROP_UPDATE_FAIL,
                    payload: {
                        linkError: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: LINK_DROP_UPDATE_FAIL,
                    payload: {
                        linkError: error.message
                    }
                })
            }
        }
    }
}

export const deleteLink = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        // const url = 'http://localhost:8080'
        let token = localStorage.getItem('authToken')
        let linkSort = localStorage.getItem('linkSort')

        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'adminId': datas.adminId,
                'teamId': datas.teamId,
                'url': `${SERVER_URI}/user/link/delete`,
                'linkId': datas.linkId,
                'linkSort': linkSort,
                "softdelete": datas.softdelete
            }
        }
        try {
            // console.log(datas)
            userAuth(token)
            const response = await axios.delete(`${SERVER_URI}/user/link/delete`, config)

            dispatch({
                type: LINK_DELETE_SUCCESS,
                payload: {
                    links: response.data.links,
                    linkSuccessMessage: response.data.message,
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: LINK_DELETE_FAIL,
                    payload: {
                        linkError: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: LINK_DELETE_FAIL,
                    payload: {
                        linkError: error.message
                    }
                })
            }
        }
    }
}