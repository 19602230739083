import axios from 'axios'
import { SERVER_URI } from '../config/keys';

export const loggedInAxios = axios.create({
    baseURL: SERVER_URI,
});

export const userAuth = (token) => {
    loggedInAxios.interceptors.request.use(
        config => {
            config.headers.authorization = `Bearer ${token}`;
            return config;
        },
        error => {
            return Promise.reject(error)
        }
    )
}