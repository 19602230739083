import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTags,
  updateTags,
  deleteTags,
} from "../../../../store/actions/tagsAction";
import { SERVER_URI } from "../../../../config/keys";
import {
  TAGS_GET_SUCCESS,
  TAGS_GET_FAIL,
  TAGS_DELETE_SUCCESS,
  TAGS_DELETE_FAIL,
  TAGS_UPDATE_SUCCESS,
  TAGS_UPDATE_FAIL,
  TAGS_SUCCESS_MESSAGE_CLEAR,
  TAGS_ERROR_CLEAR,
} from "../../../../store/types/tagsType";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import { getState } from "../../../../store/actions/stateAction";

export default function SettingModal({ closeSetting }) {
  const { loading, successMessage, error, authenticate, myInfo } = useSelector(
    (state) => state.auth
  );
  const { tags, tagsMessagee, tagsError } = useSelector((state) => state.tags);
  const [checked, setChecked] = React.useState(false);
  let adminId = myInfo.userId;
  let teamId = localStorage.getItem("teamId");
  let urls = `${SERVER_URI}/admin/update`;
  const dispatch = useDispatch();

  
  // console.log(tags);
  const [value, setValue] = useState([]);
  // console.log(value);

  const handleChange = (p, index) => {
    let data = [...value];
    data[index][p.target.name] = p.target.checked;
    setValue(data);
  };

  let curTags = [];

  if (value.length > 0) {
    for (let i = 0; i < value.length; i++) {
      if (value[i].checked) {
        curTags.push(value[i].Id);
      }
    }
  }
  // console.log(curTags);

  const tagsDelete = (e) => {
    e.preventDefault();
    if (curTags.length === 0) {
      Swal.fire({
        title: "Error",
        text: "Select atleast one tag!",
      });
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        var obj = {};
        obj.teamId = localStorage.getItem("teamId");
        obj.adminId = myInfo.userId;
        obj.url = `${SERVER_URI}/user/tags/delete`;
        // obj.tagsId = tags._id
        let objs = {};
        objs.teamId = localStorage.getItem("teamId");
        objs.userId = myInfo.userId;

        // console.log(objs)
        // for (let i = 0; i < curTags.length; i++) {
        //   obj.  = curTags[i];
        //   dispatch(deleteTags(obj)).then(() => {
        //     dispatch(getTags(objs));
        //   });
        // }

        let stateObj = {};
        stateObj.userId = myInfo.userId;
        stateObj.teamId = localStorage.getItem("teamId");

        obj.tagsIds = curTags;
        dispatch(deleteTags(obj)).then(() => {
          dispatch(getTags(objs));
          dispatch(getState(objs));
        });

        // dispatch(deleteTags(obj)).then(() => {
        //     dispatch(getTags(objs));
        //     closeSetting()
        // })
      }
    });
  };

  useEffect(()=>{
    dispatch(getTags({ userId: myInfo.userId }));
  },[])

  useEffect(()=>{
    if (tags.length > 0) {
      setValue(tags.map(e => ({ tagName: e.tags[0], Id: e._id, checked: false })));
    }
  },[tags]);

  useEffect(() => {
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    if (tagsMessagee) {
      Swal.fire({
        // position: 'top-end',
        title: "Success",
        text: tagsMessagee,
        showConfirmButton: true,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        // timer: 1500,
      }).then((result) => {
        // if (result.isConfirmed) {
        dispatch({ type: TAGS_SUCCESS_MESSAGE_CLEAR });
        dispatch(getTags(obj));
        closeSetting();
        // }
      });
    }

    if (tagsError) {
      Swal.fire({
        // icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: tagsError,
      });
      // alert.error(peopleError)
      dispatch({ type: TAGS_ERROR_CLEAR });
      // dispatch(getDomain(obj));
      closeSetting();
    }
  }, [tagsMessagee, tagsError]);

  return (
    <div id="tagsModal">
      <div className="modal-dialog modal-md modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">MANAGE TAGS</h4>
          </div>
          <div className="modal-body">
            <form className="form">
              <h4>Delete Tags</h4>
              <p>
                This action will delete the selected tags from your account and
                will also remove the deleted tags from all the cards (person,
                skill, role, domain, link, circle, project) if assigned.
              </p>
              <div className="row">
                <div className="col-xs-12">
                  <label>
                    <strong>Select Tags</strong>
                  </label>
                </div>
              </div>
              <div className="row">
                {value.map((e, index) => {
                  return (
                    <div className="col-xs-12" key={index}>
                      <label class="checkbox-inline">
                        <input
                          type="checkbox"
                          name="checked"
                          className="tagCheckbox"
                          checked={e.checked}
                          onChange={(p) => handleChange(p, index)}
                        />
                        {e.tagName}
                      </label>
                    </div>
                  );
                })}
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <div className="row">
              <div className="col-md-12 text-right">
                <Tippy content={<span>Close Tool</span>}>
                  <button
                    type="button"
                    onClick={closeSetting}
                    data-tippy-content="Close Tool"
                    className="btn btn-mytpt btn-mytpt-red"
                    data-dismiss="modal"
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </Tippy>

                {/* <!-- <button type="button" data-tippy-content="Save And Close" className="btn btn-mytpt save-close"><i className="fa fa-check"></i></button> --> */}
                <input
                  type="button"
                  onClick={tagsDelete}
                  id="mytpt-delete-tags"
                  className="btn btn-mytpt"
                  value="Delete Selected Tags"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
