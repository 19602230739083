import axios from 'axios'
import {
    PFMEMBER_CREATE_SUCCESS, PFMEMBER_CREATE_FAIL, PFMEMBER_GET_SUCCESS, PFMEMBER_DELETE_SUCCESS,
    PFMEMBER_UPDATE_SUCCESS, PFMEMBER_UPDATE_FAIL, PFMEMBER_DELETE_FAIL, PFMEMBER_GET_FAIL
} from '../types/pfmemberType'


// const token = localStorage.getItem("authToken");
import {SERVER_URI} from '../../config/keys'
import { DispatchBlocker } from '../DispatchBlocker/DispatchBlocker'

export const userAuth = (token) => {
    axios.interceptors.request.use(
        config => {
            config.headers.authorization = `Bearer ${token}`;
            return config;
        },
        error => {
            return Promise.reject(error)
        }
    )
}


export const createAndUpdateMember = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        let token = localStorage.getItem('authToken')
        try {
            userAuth(token)
            // console.log(datas)
            const response = await axios.post(`${SERVER_URI}/user/pfmember/create`, datas)
            // console.log(response.data)

            dispatch({
                type: PFMEMBER_CREATE_SUCCESS,
                payload: {
                    membersuccessMessage: response.data.message,
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: PFMEMBER_CREATE_FAIL,
                    payload: {
                        pfmemberError: data
                    }
                })
            }else if(error.message){
                dispatch({
                    type: PFMEMBER_CREATE_FAIL,
                    payload: {
                        pfmemberError: error.message
                    }
                })
            }
        }
    }
}

export const getPFMember = (datas) => {
    return async (dispatch) => {
        // const url = 'http://localhost:8080'
        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'adminId': datas.userId,
                'teamId': datas.teamId,
                'url': `${SERVER_URI}/user/pfmember/get`
            }
        }
        try {
            const token = localStorage.getItem("authToken");
            // console.log(token)
            userAuth(token)
            const response = await axios.get(`${SERVER_URI}/user/pfmember/get`, config)
            // console.log( response.data.data)
            // localStorage.setItem('authToken', response.data.data.token)

            dispatch({
                type: PFMEMBER_GET_SUCCESS,
                payload: {
                    membersuccessMessage: response.data.message,
                    members: response.data.data
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: PFMEMBER_GET_FAIL,
                    payload: {
                        pfmemberError: data
                    }
                })
            }else if(error.message){
                dispatch({
                    type: PFMEMBER_GET_FAIL,
                    payload: {
                        pfmemberError: error.message
                    }
                })
            }
        }
    }
}




// export const deleteMember = (datas) => {
//     return async (dispatch) => {
//         // const url = 'http://localhost:8080'
//         let token = localStorage.getItem('authToken')
//         const config = {
//             headers: {
//                 'Content-Type': 'application/josn',
//                 'adminId': datas.adminId,
//                 'teamId': datas.teamId,
//                 'url': `${SERVER_URI}/user/person/delete`,
//                 'personId': datas.personId,
             
//             }
//         }
//         try {
//             // console.log(datas)
//             userAuth(token)
//             const response = await axios.delete(`${SERVER_URI}/user/person/delete`, config)

//             dispatch({
//                 type: PFMEMBER_DELETE_SUCCESS,
//                 payload: {
//                     membersuccessMessage: response.data.message,
//                 }
//             })

//         } catch (error) {
//             let data = error.response.data.message
//             // console.log(data)
//             dispatch({
//                 type: PFMEMBER_DELETE_FAIL,
//                 payload: {
//                     errorMember: data
//                 }
//             })
// if (error && error.response && error.response.data && error.response.data.message) {
//     let data = error.response.data.message
//     dispatch({
//         type: PFMEMBER_GET_FAIL,
//         payload: {
//             pfmemberError: data
//         }
//     })
// }else if(error.message){
//     dispatch({
//         type: PFMEMBER_GET_FAIL,
//         payload: {
//             pfmemberError: error.message
//         }
//     })
// }
//         }
//     }
// }