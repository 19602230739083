import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const SkillRoleComapreMode = ({
  key,
  item,
  itemType,
  index,
  handleModalOpen,
  ownerNameArray
}) => {
  return (
    <div className="board-col" data-board_id="skills" key={key}>
      <div
        className="list list-bg-blue"
        data-list_id="skills"
        onClick={() => handleModalOpen(item)}
      >
        <div
          className="dropdown"
          style={{ position: "static", cursor: "pointer" }}
        >
          <h4 className="list-title">
            {/* <img alt="" src="images/icon-skill.png" /> */}
            {item[`${itemType}Name`]}
            <a
              className="list-menu"
              href="#"
            >
              <i className="fas fa-plus"></i>
            </a>
          </h4>
          {
            ownerNameArray && ownerNameArray.length > 0 &&
            <p className="compare-mode-owner-list">{ownerNameArray.join(", ")}</p>
          }
          {
            item.tags && item.tags.length > 0 && item.tags.map((st) => {
              return (
                <span className="custom-badge green tags" style={{marginLeft:"2px"}}>{st}</span>
              );              
            })
          }

        </div>
        <Droppable
          droppableId={`${itemType}-${item._id}-index-${index}`}
          direction="vertical"
        >
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="card-list sortable-card-list scrollbar"
            >
              {item.tasks.length > 0
                ? item.tasks.map((e, i) => {
                  return (
                    <Draggable
                      key={i}
                      draggableId={`skillRole-${item._id
                        }-${itemType}-id-${null}-task-${i}`}
                      index={i}
                    // isDragDisabled={
                    //   statesLM.Skills !== "Parsonal" ? true : false
                    // }
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          // onDragStart={(e) => skillsDragStart(e, index)}
                          // onDragEnter={(e) => skillsDragEnter(e, index)}
                          // onDragEnd={skillsDrop}
                          key={i}
                          className="card"
                        // onClick={(event) => {
                        //   if (
                        //     event.target.classList.contains("card") ||
                        //     event.target.classList.contains("card-edit")
                        //   ) {
                        //     setSkill(e, filteredSData, index);
                        //   }
                        // }}
                        >
                          {/* {ss.tasks.length > 0 && ss.tasks.map((st) => {
                                                            return ( */}
                          <a className="card-edit">{e}</a>
                          {/* )
                                                        })} */}
                        </div>
                      )}
                    </Draggable>
                  );
                })
                : null}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </div>
  );
};

export default SkillRoleComapreMode;
