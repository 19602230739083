import axios from 'axios'
import {
    ROLE_CREATE_SUCCESS, ROLE_CREATE_FAIL, ROLE_GET_SUCCESS, ROLE_DELETE_SUCCESS,
    ROLE_UPDATE_SUCCESS, ROLE_UPDATE_FAIL, ROLE_DELETE_FAIL, ROLE_GET_FAIL,
    ROLE_DROP_UPDATE_SUCCESS, ROLE_DROP_UPDATE_FAIL
} from '../types/roleType'

import { DOMAIN_GET_SUCCESS } from '../types/domainType'

import { CIRCLE_GET_SUCCESS } from '../types/circleType'

import { PROJECT_GET_SUCCESS } from '../types/projectType'
import { MEMBER_GET_SUCCESS } from '../types/memberType'
import { PFMEMBER_GET_SUCCESS } from '../types/pfmemberType'

// const token = localStorage.getItem("authToken");
import { SERVER_URI } from '../../config/keys'

import { isCardExist } from '../../HelperFunctions/CardExist'
import { DispatchBlocker } from '../DispatchBlocker/DispatchBlocker'

export const userAuth = (token) => {
    axios.interceptors.request.use(
        config => {
            config.headers.authorization = `Bearer ${token}`;
            return config;
        },
        error => {
            return Promise.reject(error)
        }
    )
}

export const createRole = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        // Accessing the current state
        const currentState = getState();
        if (isCardExist(currentState.roles.roles, datas.roleName, 'Role')) {
            dispatch({
                type: ROLE_CREATE_FAIL,
                payload: {
                    roleError: `Role with this name already exist!`
                }
            })
            return;
        }

        // const url = 'http://localhost:8080'
        let token = localStorage.getItem('authToken')
        try {
            userAuth(token)
            const response = await axios.post(`${SERVER_URI}/user/role/create`, datas)
            // console.log(response.data)

            dispatch({
                type: ROLE_CREATE_SUCCESS,
                payload: {
                    roleSuccessMessage: response.data.message,
                    cuRoleId: response.data.data._id,
                    nDaoamins: response.data.nDaoamins,
                    roles: response.data.roles,
                    dRoles: response.data.fData
                }
            })

            dispatch({
                type: DOMAIN_GET_SUCCESS,
                payload: {
                    domains: response.data.domains,
                    dDomains: response.data.fDomains,
                }
            })

            dispatch({
                type: CIRCLE_GET_SUCCESS,
                payload: {
                    circles: response.data.circles,
                    dCircles: response.data.fCircles
                }
            })

            dispatch({
                type: PROJECT_GET_SUCCESS,
                payload: {
                    projects: response.data.projects,
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: ROLE_CREATE_FAIL,
                    payload: {
                        roleError: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: ROLE_CREATE_FAIL,
                    payload: {
                        roleError: error.message
                    }
                })
            }
        }
    }
}

export const getRole = (datas) => {
    return async (dispatch) => {
        // const url = 'http://localhost:8080'
        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'adminId': datas.userId,
                'teamId': datas.teamId,
                'currole': datas.currole,
                'rolesData': datas.rolesData,
                'url': `${SERVER_URI}/user/role/get`
            }
        }
        // console.log(config)
        try {
            const token = localStorage.getItem("authToken");
            // console.log(token)
            userAuth(token)
            const response = await axios.get(`${SERVER_URI}/user/role/get`, config)
            // console.log( response.data)
            // localStorage.setItem('authToken', response.data.data.token)

            dispatch({
                type: ROLE_GET_SUCCESS,
                payload: {
                    roleSuccessMessage: response.data.message,
                    roles: response.data.data,
                    dRoles: response.data.fData
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: ROLE_GET_FAIL,
                    payload: {
                        roleError: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: ROLE_GET_FAIL,
                    payload: {
                        roleError: error.message
                    }
                })
            }
        }
    }
}

export const updateRole = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        // Accessing the current state
        const currentState = getState();
        if (isCardExist(currentState.roles.roles, datas.roleName, 'Role', datas._id)) {
            dispatch({
                type: ROLE_UPDATE_FAIL,
                payload: {
                    roleError: `Role with this name already exist!`
                }
            })
            return;
        }

        // const url = 'http://localhost:8080'
        let token = localStorage.getItem('authToken')
        try {
            // console.log(datas)
            userAuth(token)
            const response = await axios.put(`${SERVER_URI}/user/role/update`, datas)

            dispatch({
                type: ROLE_UPDATE_SUCCESS,
                payload: {
                    roleSuccessMessage: response.data.message,
                    nDaoamins: response.data.nDaoamins,
                    roles: response.data.roles,
                }
            })

            dispatch({
                type: DOMAIN_GET_SUCCESS,
                payload: {
                    domains: response.data.domains,
                    dDomains: response.data.fDomains,
                }
            })

            dispatch({
                type: CIRCLE_GET_SUCCESS,
                payload: {
                    circles: response.data.circles,
                    dCircles: response.data.fCircles
                }
            })

            dispatch({
                type: PROJECT_GET_SUCCESS,
                payload: {
                    projects: response.data.projects,
                }
            })

            dispatch({
                type: MEMBER_GET_SUCCESS,
                payload: {
                    membersuccessMessage: response.data.message,
                    members: response.data.members
                }
            })

            dispatch({
                type: PFMEMBER_GET_SUCCESS,
                payload: {
                    membersuccessMessage: response.data.message,
                    members: response.data.pfmembers
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: ROLE_UPDATE_FAIL,
                    payload: {
                        roleError: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: ROLE_UPDATE_FAIL,
                    payload: {
                        roleError: error.message
                    }
                })
            }
        }
    }
}

export const dropUpdateRole = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        let token = localStorage.getItem('authToken')
        try {
            // console.log(datas)
            userAuth(token)
            const response = await axios.put(`${SERVER_URI}/user/role/update`, datas)

            dispatch({
                type: ROLE_DROP_UPDATE_SUCCESS,
                payload: {
                    roleSuccessMessage: response.data.message,
                    roles: response.data.roles,
                }
            })

            dispatch({
                type: DOMAIN_GET_SUCCESS,
                payload: {
                    domains: response.data.domains,
                    dDomains: response.data.fDomains,
                }
            })

            dispatch({
                type: CIRCLE_GET_SUCCESS,
                payload: {
                    circles: response.data.circles,
                    dCircles: response.data.fCircles
                }
            })

            dispatch({
                type: PROJECT_GET_SUCCESS,
                payload: {
                    projects: response.data.projects,
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: ROLE_DROP_UPDATE_FAIL,
                    payload: {
                        roleError: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: ROLE_DROP_UPDATE_FAIL,
                    payload: {
                        roleError: error.message
                    }
                })
            }
        }
    }
}

export const deleteRole = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        let token = localStorage.getItem('authToken')
        let roleSort = localStorage.getItem('roleSort')
        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'adminId': datas.adminId,
                'teamId': datas.teamId,
                'url': `${SERVER_URI}/user/role/delete`,
                'roleId': datas.roleId,
                'roleSort': roleSort,
                "softdelete": datas.softdelete
            }
        }
        try {
            // console.log(datas)
            userAuth(token)
            const response = await axios.delete(`${SERVER_URI}/user/role/delete`, config)

            dispatch({
                type: ROLE_DELETE_SUCCESS,
                payload: {
                    roleSuccessMessage: response.data.message,
                    roles: response.data.roles,
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: ROLE_DELETE_FAIL,
                    payload: {
                        roleError: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: ROLE_DELETE_FAIL,
                    payload: {
                        roleError: error.message
                    }
                })
            }
        }
    }
}