import {
  TEAM_CREATE_SUCCESS,
  TEAM_CREATE_FAIL,
  TEAM_GET_SUCCESS,
  TEAM_DELETE_SUCCESS,
  TEAM_UPDATE_SUCCESS,
  TEAM_UPDATE_FAIL,
  TEAM_DELETE_FAIL,
  TEAM_GET_FAIL,
  SIGN_OUT,
  TEAM_SUCCESS_CLEAR,
  TEAM_ERROR_CLEAR,
  TEAM_UPDATE_MANY_SUCCESS,
  TEAM_UPDATE_MANY_FAIL,
  GET_TEMPLATE_SUCC,
  GET_TEMPLATE_ERR,
} from "../types/teamType";

const userState = {
  teams: [],
  sMessage: "",
  errors: "",
};

export const teamReducer = (state = userState, action) => {
  const { type, payload } = action;

  if (type === TEAM_CREATE_SUCCESS) {
    return {
      ...state,
      // teams: payload.teams,
      sMessage: payload.successMessage,
    };
  }
  if (type === TEAM_GET_SUCCESS) {
    return {
      ...state,
      teams: payload.teams,
      // sMessage: payload.successMessage
    };
  }
  if (type === TEAM_DELETE_SUCCESS) {
    return {
      ...state,
      message: payload.message,
      teams: payload.teams,
    };
  }
  if (type === TEAM_UPDATE_SUCCESS) {
    return {
      ...state,
      message: payload.message,
    };
  }
  if (type === TEAM_CREATE_FAIL) {
    return {
      ...state,
      errors: payload.error,
    };
  }
  if (type === TEAM_DELETE_FAIL) {
    return {
      ...state,
      errors: payload.error,
    };
  }
  if (type === TEAM_UPDATE_FAIL) {
    return {
      ...state,
      errors: payload.error,
    };
  }
  if (type === TEAM_GET_FAIL) {
    return {
      ...state,
      errors: payload.error,
    };
  }

  if (type === TEAM_SUCCESS_CLEAR) {
    return {
      ...state,
      sMessage: "",
    };
  }

  if (type === TEAM_ERROR_CLEAR) {
    return {
      ...state,
      errors: "",
    };
  }
  if (type === TEAM_UPDATE_MANY_SUCCESS) {
    return {
      ...state,
      sMessage: payload.sMessage,
    };
  }
  if (type === GET_TEMPLATE_SUCC) {
    return {
      ...state,
      template: payload.templetesData,
    };
  }
  if (type === GET_TEMPLATE_ERR) {
    return {
      ...state,
      errors: payload.message,
    };
  }

  if (type === TEAM_UPDATE_MANY_FAIL) {
    return {
      ...state,
      errors: payload.errors,
    };
  }

  if (type === SIGN_OUT) {
    return {
      ...state,
      teams: [],
      sMessage: "",
      errors: "",
    };
  }

  return state;
};
