import React from "react";
import { numberInputOnWheelPreventChange } from "../../../../HelperFunctions/StopInputScroll";

const WorkLoadPeople = ({ handleChange, availability, plannedValue }) => {
  const colorCode = (availability, plannedValue) => {
    if (availability && availability > plannedValue) {
      return { "backgroundColor": "#f2de9e", "color": "#bea252"};
    } else if (availability && availability === plannedValue) {
      return { "backgroundColor": "#9de7d3", "color": "#498287" };
    } else if (availability && availability < plannedValue) {
      return { "backgroundColor": "#f0a69e", "color": "#c86053" };
    }
  };
  return (
    <>
      <h4 className="workload-heading">Workload</h4>
      <div className="row workload-people">
        <div className="col-xs-9">
          <h4>Availability</h4>
          <p>FTE (Max: 1)</p>
        </div>
        <div className="col-xs-3" style={{paddingTop: '15px'}}>
        <div className="form-group">
          <input
            type="number"
            name="availability"
            readOnly={handleChange ? false : true}
            // placeholder="max val 1"
            // value={availability}
            placeholder={availability === 0 || availability === "" ? handleChange ?'Type Value': '':''}
            value={availability !== 0 ? availability : ""}
            onChange={handleChange || (() => {})}
            className="form-control logit"
            autoComplete="off"
            step="any"
            onWheel={numberInputOnWheelPreventChange}
            disabled = {handleChange ? false : true}
          />
          </div>
        </div>
      </div>
      <div className="row workload-people">
        <div className="col-xs-9">
          <h4>Planned</h4>
          <p>FTE in skills, roles, domains, links</p>
        </div>
        <div className="col-xs-3" style={{paddingTop: '15px'}}>
        <div className="form-group">
          <input
            type="number"
            name="plannedValue"
            readOnly={true}
            value={plannedValue.toFixed(2)}
            className="form-control logit"
            autoComplete="off"
            style={colorCode(availability,plannedValue)}
            onWheel={numberInputOnWheelPreventChange}
          />
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkLoadPeople;
