import React from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PillComponent from '../PillComponent';

export default function DomainList({ tagFilteredMegedData, handleAliesChange, GetAliasesName, filteredDData, clickedOnList, toggleEXTnNOCards, getSortIcon, isInfos, setDomains, teams, pCardHideOnchange, noteOnchange, note, handeSorts, setCombinedRefs, handleSave, noteSaveData, sDref, setDomainSearch, domainSearch, isTag, isEXT, isNO, TagWiseSorting, IsOwnerExternal, dWhoIsOwnerType, statesLM, setDomain, parentDomainRef, setCardRef, workloadToggle, sumFTEBody, cardOpensCfDomain, isExistOwnerType, toggleTagCards, dDomains, domainSubmit, dref, handleDomainChange, compareMode, tagFilteredObj, extNoFilteredObj, allSearch, lFromState, domain, setDomainName, domainsFrom }) {
    // Conditional rendering based on compareMode.active
    if (!compareMode.active) {
        return (
            <div
                className={
                    !clickedOnList.listName.includes("domain") && Object.keys(tagFilteredMegedData).length > 0 &&
                        tagFilteredMegedData.domain &&
                        tagFilteredMegedData.domain.length === 0
                        ? "board-col hide"
                        : "board-col"
                }
                data-board_id="domains"
            >
                <div className="list list-bg-blue" data-list_id="domains">
                    <div className="dropdown">
                        <h4 className="list-title">
                            <img alt="" src="images/icon-domain.png" />
                            <span contentEditable={true}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault(); // Prevent new line
                                    }
                                }} data-name="domains" onBlur={(e) => handleAliesChange(e)}>{GetAliasesName(teams, "Domains")}</span>
                            <span>{` (${filteredDData.length})`}</span>{" "}
                            <a
                                className={
                                    clickedOnList.isClicked &&
                                        clickedOnList.listName.includes("domain")
                                        ? "list-menu refresh-list"
                                        : "list-menu refresh-list hide"
                                }
                                // className="list-menu refresh-list hide"
                                href="#"
                                onClick={() => {
                                    toggleEXTnNOCards("domains", "", true, "list");
                                }}
                            >
                                <i className="fas fa-sync-alt"></i>
                            </a>{" "}
                            <a
                                className={
                                    clickedOnList.isClicked &&
                                        clickedOnList.listName.includes("domain")
                                        ? "list-menu dropdown-toggle hide"
                                        : "list-menu dropdown-toggle"
                                }
                                // className="list-menu dropdown-toggle"
                                data-toggle="dropdown"
                                href="#"
                            >
                                {getSortIcon("Domains")}
                                <i className="fas fa-ellipsis-v"></i>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-right" role="menu">
                                <li role="presentation">
                                    <a
                                        onClick={() => isInfos("Domains")}
                                        role="menuitem"
                                        className="mytpt-list-info"
                                    >
                                        Info
                                    </a>
                                </li>
                                <li role="presentation">
                                    <a role="menuitem" onClick={() => setDomains()}>
                                        Add new {/* domain */}{GetAliasesName(teams, "Domains").toLowerCase()}
                                    </a>
                                </li>
                                <li role="presentation">
                                    <a
                                        role="menuitem"
                                        onClick={() => pCardHideOnchange("Domains")}
                                        className="hide_list"
                                    >
                                        Hide list
                                    </a>
                                </li>
                                <li role="presentation">
                                    <a role="menuitem" style={{ paddingRight: "5px" }}>
                                        Show Notes
                                        <label className="switch">
                                            <input
                                                name="Domains"
                                                onChange={(e) => noteOnchange(e)}
                                                checked={note.Domains}
                                                className="dd-switch"
                                                type="checkbox"
                                            />
                                            <span className="slider"></span>
                                        </label>
                                    </a>
                                </li>
                                <li role="presentation" className="divider"></li>
                                <li className="dropdown-header">Sort by:</li>

                                <li role="presentation">
                                    <a
                                        role="menuitem"
                                        className="sortcards"
                                        data-sort_order="n"
                                        onClick={() => handeSorts("NewToOld", "Domains")}
                                    >
                                        New to Old
                                    </a>
                                </li>
                                <li role="presentation">
                                    <a
                                        role="menuitem"
                                        className="sortcards"
                                        data-sort_order="nd"
                                        onClick={() => handeSorts("OldToNew", "Domains")}
                                    >
                                        Old to New
                                    </a>
                                </li>
                                <li role="presentation">
                                    <a
                                        role="menuitem"
                                        className="sortcards"
                                        data-sort_order="asc"
                                        onClick={() => handeSorts("AtoZ", "Domains")}
                                    >
                                        A-Z
                                    </a>
                                </li>
                                <li role="presentation">
                                    <a
                                        role="menuitem"
                                        className="sortcards"
                                        data-sort_order="desc"
                                        onClick={() => handeSorts("ZtoA", "Domains")}
                                    >
                                        Z-A
                                    </a>
                                </li>

                                <li role="presentation" className="divider"></li>
                                <li role="presentation" className="divider"></li>
                                <li role="presentation">
                                    <a
                                        role="menuitem"
                                        className="show-external"
                                        onClick={() => {
                                            toggleEXTnNOCards("domains", "EXT", false, "list");
                                        }}
                                    >
                                        Show items owned by external
                                    </a>
                                </li>
                                <li role="presentation">
                                    <a
                                        role="menuitem"
                                        className="show-noowners"
                                        onClick={() => {
                                            toggleEXTnNOCards("domains", "NO", false, "list");
                                        }}
                                    >
                                        Show items without owners
                                    </a>
                                </li>
                            </ul>
                        </h4>
                    </div>
                    <Droppable droppableId="domains" direction="vertical">
                        {(domainsProvided) => (
                            <div
                                // ref={domainsProvided.innerRef}
                                ref={setCombinedRefs(domainsProvided.innerRef, parentDomainRef)}
                                {...domainsProvided.droppableProps}
                                className="card-list sortable-card-list scrollbar"
                            >
                                <div
                                    className="form-group notes"
                                    style={
                                        note.Domains ? { display: "block" } : { display: "none" }
                                    }
                                >
                                    <textarea
                                        className="notes"
                                        name="Domains"
                                        onBlur={(e) => handleSave(e)}
                                        placeholder="Notes"
                                        maxLength="100"
                                        defaultValue={noteSaveData.Domains}
                                    ></textarea>
                                </div>
                                <span className="clearable">
                                    <input
                                        type="text"
                                        style={{ color: "black" }}
                                        ref={sDref}
                                        onChange={(e) => setDomainSearch(e.target.value)}
                                        value={domainSearch}
                                        className="form-control search_list transparent"
                                        name="search_list"
                                        placeholder="Start typing to search..."
                                        autoComplete="off"
                                    />
                                    {domainSearch.length > 0 ? (
                                        <i
                                            className="clearable__clear"
                                            onClick={() => setDomainSearch("")}
                                            style={{ display: "inline", color: "black" }}
                                        >
                                            ×
                                        </i>
                                    ) : null}
                                </span>

                                {filteredDData && filteredDData.length > 0
                                    ? filteredDData.map((e, index) => {
                                        if (isTag || isEXT || isNO) {
                                            let Proceed = TagWiseSorting(
                                                e,
                                                isTag,
                                                isEXT,
                                                isNO,
                                                tagFilteredObj,
                                                extNoFilteredObj,
                                                "domain"
                                            );
                                            if (!Proceed) return;
                                        }

                                        let isExternal = IsOwnerExternal(e, "Domain");
                                        let whoIsOwnerType = dWhoIsOwnerType(e);
                                        if (e.defaultDomain) return;

                                        return (
                                            <Draggable
                                                key={index}
                                                draggableId={`${e._id}`}
                                                index={index}
                                                isDragDisabled={
                                                    statesLM.Domains !== "Parsonal" ? true : false
                                                }
                                            >
                                                {(domainsProvided) => (
                                                    <div
                                                        ref={domainsProvided.innerRef}
                                                        {...domainsProvided.draggableProps}
                                                        {...domainsProvided.dragHandleProps}
                                                        // onDragStart={(e) => domainsDragStart(e, index)}
                                                        // onDragEnter={(e) => domainsDragEnter(e, index)}
                                                        // onDragEnd={domainsDrop}

                                                        key={index}
                                                        className={`card ${localStorage.getItem("latest-created") ===
                                                            e._id
                                                            ? "latest-created"
                                                            : ""
                                                            }`}
                                                        data-list_id="domains"
                                                        onClick={(event) => {
                                                            if (
                                                                event.target.classList.contains("card") ||
                                                                event.target.classList.contains("card-edit")
                                                            ) {
                                                                setDomain(e, filteredDData, index);
                                                            }
                                                            const scrollTop =
                                                                parentDomainRef.current.scrollTop;
                                                            setCardRef(prevCardRef =>
                                                                prevCardRef.map(item =>
                                                                    item.type === "domain" ? { ...item, val: scrollTop } : item
                                                                )
                                                            );
                                                        }}
                                                    >
                                                        <div className="static-relations">
                                                            {workloadToggle === true &&
                                                                e &&
                                                                e.owners &&
                                                                e.owners.owner && (
                                                                    <PillComponent
                                                                        occupied={sumFTEBody(e.workload, [
                                                                            e.owners.owner,
                                                                        ])}
                                                                        total={e.planTotal}
                                                                    />
                                                                )}

                                                            {isExternal ? (
                                                                <span
                                                                    onClick={() => {
                                                                        toggleEXTnNOCards(
                                                                            e._id,
                                                                            "EXT",
                                                                            false,
                                                                            "pill"
                                                                        );
                                                                    }}
                                                                    className="custom-badge blue ext-owner-pill"
                                                                >
                                                                    EXT
                                                                </span>
                                                            ) : null}

                                                            {whoIsOwnerType &&
                                                                Object.keys(whoIsOwnerType).length > 0 ? (
                                                                <span
                                                                    onClick={() =>
                                                                        cardOpensCfDomain(whoIsOwnerType)
                                                                    }
                                                                    className="custom-badge lightgray"
                                                                >
                                                                    {whoIsOwnerType.name}
                                                                </span>
                                                            ) : null}

                                                            {e.owners &&
                                                                e.owners.owner &&
                                                                e.owners.type &&
                                                                isExistOwnerType(e.owners.type) &&
                                                                e.owners.owner.length > 0 ? null : (
                                                                <span
                                                                    onClick={() => {
                                                                        toggleEXTnNOCards(
                                                                            e._id,
                                                                            "NO",
                                                                            false,
                                                                            "pill"
                                                                        );
                                                                    }}
                                                                    className="custom-badge no-owner-pill red"
                                                                >
                                                                    NO
                                                                </span>
                                                            )}

                                                            {e.tags && e.tags.length > 0
                                                                ? e.tags.map((r, indexs) => (
                                                                    <span
                                                                        onClick={() => toggleTagCards(r)}
                                                                        key={indexs}
                                                                        className="custom-badge green tags"
                                                                    >
                                                                        {r}
                                                                    </span>
                                                                ))
                                                                : null}
                                                        </div>
                                                        <a
                                      /* onClick={() => setDomain(e, filteredDData, index)} */ className="card-edit"
                                                        >
                                                            {e.domainName}
                                                        </a>
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    })
                                    : null}


                                {dDomains && dDomains.length > 0
                                    ? dDomains.map((d, index) => {
                                        if ((allSearch.length > 0 || domainSearch.length > 0) && !filteredDData.find((dd) => dd._id.toString() === d._id.toString())) return;

                                        return (
                                            <div
                                                key={index}
                                                className="card default-domain"
                                                data-default="true"
                                                data-html2canvas-ignore="true"
                                            >
                                                <div className="static-relations">
                                                    {workloadToggle === true &&
                                                        d &&
                                                        d.owners &&
                                                        d.owners.owner && (
                                                            <PillComponent
                                                                occupied={sumFTEBody(d.workload, [
                                                                    d.owners.owner,
                                                                ])}
                                                                total={d.planTotal}
                                                            />
                                                        )}
                                                    {d.tags && d.tags.length > 0
                                                        ? d.tags.map((r, indexs) => (
                                                            <span
                                                                onClick={() => toggleTagCards(r)}
                                                                key={indexs}
                                                                className="custom-badge green tags"
                                                            >
                                                                {r}
                                                            </span>
                                                        ))
                                                        : null}
                                                </div>
                                                <a
                                                    onClick={() => {
                                                        setDomain(d, dDomains);
                                                        const scrollTop =
                                                            parentDomainRef.current.scrollTop;
                                                        setCardRef(prevCardRef =>
                                                            prevCardRef.map(item =>
                                                                item.type === "domain" ? { ...item, val: scrollTop } : item
                                                            )
                                                        );
                                                    }}
                                                    className="card-edit"
                                                >
                                                    {d.domainName}
                                                </a>
                                            </div>
                                        );
                                    })
                                    : null}

                                {domainsProvided.placeholder}

                                <div
                                    className="card2"
                                    style={
                                        lFromState.Domains
                                            ? { display: "block" }
                                            : { display: "none" }
                                    }
                                >
                                    <form
                                        className="form-new-card"
                                        role="form"
                                        onSubmit={domainSubmit}
                                    >
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                ref={dref}
                                                name="card_name"
                                                required
                                                value={domain}
                                                onChange={handleDomainChange}
                                                placeholder={`Add ${GetAliasesName(teams, "Domains").toLowerCase()} name`}
                                                className="form-control"
                                                autoComplete="off"
                                            />
                                        </div>
                                        <div className="form-group" style={{ marginTop: "10px" }}>
                                            <button
                                                type="submit"
                                                className="btn btn-mytpt add-quick close-add-card"
                                            >
                                                Save
                                            </button>
                                            <a
                                                href="#"
                                                type="button"
                                                onClick={() => {
                                                    setDomains({ domainName: domain });
                                                    setDomainName("");
                                                    lFromState.Domains = false;
                                                }}
                                                className="btn btn-link underline open-tool"
                                            >
                                                Edit details
                                            </a>
                                            <a
                                                href="#"
                                                onClick={() => domainsFrom()}
                                                className="btn btn-link underline close-add-card"
                                            >
                                                Close
                                            </a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        )}
                    </Droppable>
                    <a
                        className="btn-list add-card"
                        onClick={() => domainsFrom()}
                        data-list_id="domain"
                        style={
                            !lFromState.Domains ? { display: "block" } : { display: "none" }
                        }
                    >
                        + Add new {/* domain */}{GetAliasesName(teams, "Domains").toLowerCase()}
                    </a>
                </div>
            </div>
        );
    } else {
        return <></>;
    }
}