export const ACCESS_MANAGEMENT_GET_SUCCESS = "ACCESS_MANAGEMENT_GET_SUCCESS"
export const ACCESS_MANAGEMENT_GET_FAIL = "ACCESS_MANAGEMENT_GET_FAIL"

export const ACCESS_MANAGEMENT_CREATE_SUCCESS = "ACCESS_MANAGEMENT_GET_SUCCESS"
export const ACCESS_MANAGEMENT_CREATE_FAIL = "ACCESS_MANAGEMENT_GET_FAIL"

export const ACCESS_MANAGEMENT_DELETE_SUCCESS = 'ACCESS_MANAGEMENT_DELETE_SUCCESS'
export const ACCESS_MANAGEMENT_DELETE_FAIL = 'ACCESS_MANAGEMENT_DELETE_FAIL'

export const ACCESS_MANAGEMENT_UPDATE_SUCCESS = 'ACCESS_MANAGEMENT_UPDATE_SUCCESS'
export const ACCESS_MANAGEMENT_UPDATE_FAIL = 'ACCESS_MANAGEMENT_UPDATE_FAIL'

export const ACCESS_MANAGEMENT_SUCCESS_MESSAGE_CLEAR = 'ACCESS_MANAGEMENT_SUCCESS_MESSAGE_CLEAR'
export const ACCESS_MANAGEMENT_ERROR_MESSAGE_CLEAR = 'ACCESS_MANAGEMENT_ERROR_MESSAGE_CLEAR'