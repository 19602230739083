import {
    LINK_CREATE_SUCCESS, LINK_CREATE_FAIL, LINK_GET_SUCCESS, LINK_DELETE_SUCCESS,
    LINK_UPDATE_SUCCESS, LINK_UPDATE_FAIL, LINK_DELETE_FAIL, LINK_GET_FAIL,
    LINK_SUCCESS_MESSAGE_CLEAR, LINK_ERROR_CLEAR, SIGN_OUT, LINK_DROP_UPDATE_SUCCESS, LINK_DROP_UPDATE_FAIL,
    LINK_ID_CLEAR, LINKS_MODIFICATIONS
}
    from '../types/linkType'

import { areArraysEqual } from '../../HelperFunctions/ArraysAreEqual';

const linkState = {
    links: [],
    cuLinkId: "",
    //linkMessagee: ''
    linkMessagee: null,
    linkMessage: '',
    linkError: ''
}

export const linkReducer = (state = linkState, action) => {
    const { type, payload } = action
    // const {users, successMessage } = payload
    // console.log(payload.users)

    if (type === LINK_CREATE_SUCCESS) {
        return {
            ...state,
            links: payload.links,
            linkMessagee: payload.linkSuccessMessage,
            cuLinkId: payload.cuLinkId,
            errors: ""
        }
    }
    if (type === LINK_GET_SUCCESS) {
        const result = areArraysEqual(state.links, payload.links);

        return {
            ...state,
            links: result ? state.links : payload.links,
            linksMessage: payload.linkSuccessMessage,
            linkError: ""
        }
    }
    if (type === LINK_DELETE_SUCCESS) {
        return {
            ...state,
            links: payload.links,
            linkMessagee: payload.linkSuccessMessage,
            linkError: ""
        }
    }
    if (type === LINK_UPDATE_SUCCESS) {
        // console.log("LINK_UPDATE_SUCCESS", payload.linkSuccessMessage)
        return {
            ...state,
            links: payload.links,
            linkMessagee: payload.linkSuccessMessage,// payload.linkSuccessMessage,
            linkError: ""
        }
    }
    if (type === LINK_DROP_UPDATE_SUCCESS) {
        const result = areArraysEqual(state.links, payload.links);

        return {
            ...state,
            links: result ? state.links : payload.links,
            linkMessagee: "",// payload.linkSuccessMessage,
            linkError: ""
        }
    }
    if (type === LINK_CREATE_FAIL) {
        return {
            ...state,
            linkError: payload.linkError
        }
    }
    if (type === LINK_DELETE_FAIL) {
        return {
            ...state,
            linkError: payload.linkError
        }
    }
    if (type === LINK_UPDATE_FAIL) {
        return {
            ...state,
            linkError: payload.linkError, // payload.error
        }
    }
    if (type === LINK_DROP_UPDATE_FAIL) {
        return {
            ...state,
            linkError: "", // payload.error
        }
    }
    if (type === LINK_GET_FAIL) {
        return {
            ...state,
            linkError: payload.linkError
        }
    }
    if (type === LINK_SUCCESS_MESSAGE_CLEAR) {
        // console.log("==== clring link sm")
        return {
            ...state,
            //linkMessagee: ''
            linkMessagee: null,
        }
    }
    if (type === LINK_ERROR_CLEAR) {
        return {
            ...state,
            linkError: ''
        }
    }
    if (type === LINK_ID_CLEAR) {
        return {
            ...state,
            cuLinkId: ''
        }
    }

    if (type === SIGN_OUT) {
        return {
            ...state,
            links: [],
            //linkMessagee: ''
            linkMessagee: null,
            linkMessage: '',
            linkError: ''
        }
    }

    if (type === LINKS_MODIFICATIONS) {
        // console.log(payload)
        return {
            ...state,
            links: payload,
        }
    }

    return state;
}