import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTags,
  updateTags,
  deleteTags,
  updateDeleteFromTrash,
} from "../../../../store/actions/tagsAction";
import { SERVER_URI } from "../../../../config/keys";
import {
  TAGS_GET_SUCCESS,
  TAGS_GET_FAIL,
  TAGS_DELETE_SUCCESS,
  TAGS_DELETE_FAIL,
  TAGS_UPDATE_SUCCESS,
  TAGS_UPDATE_FAIL,
  TAGS_SUCCESS_MESSAGE_CLEAR,
  TAGS_ERROR_CLEAR,
} from "../../../../store/types/tagsType";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import { getState } from "../../../../store/actions/stateAction";
import { CardArrayForm } from "../../../../HelperFunctions/CardArray";
import { GetAliasesName } from "../../../../HelperFunctions/GetAliasesName";

export default function TrashModal({ closeTrash }) {
  const { loading, successMessage, error, authenticate, myInfo } = useSelector(
    (state) => state.auth
  );
  const { teams, template, sMessage, errors } = useSelector(
    (state) => state.teams
  );
  const { tagsMessagee, tagsError } = useSelector((state) => state.tags);
  const { trashData } = useSelector((state) => state.states);

  const [trashArray, setTrashArray] = React.useState([]);
  const dispatch = useDispatch();

  const [value, setValue] = useState([]);
  const handleChange = (p, data) => {
    const isChecked = p.target.checked;
    if (isChecked) {
      setValue((prev) => [...prev, data]);
    } else {
      setValue((prev) => prev.filter((item) => item._id !== data._id));
    }
  };

  const retriveDeleteFromTrash = (e) => {
    e.preventDefault();

    if (!value.length) {
      Swal.fire({
        title: "Error",
        text: "Select at least one item!",
        showConfirmButton: true,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        // timer: 1500,
      });
      return;
    }

    const finalArray = CardArrayForm(value);
    const actionType = "retrive";

    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.adminId = myInfo.userId;
    obj.url = `${SERVER_URI}/user/tags/delete`;
    obj.cardArray = finalArray;
    obj.actionType = actionType;

    let objs = {};
    objs.teamId = localStorage.getItem("teamId");
    objs.userId = myInfo.userId;

    let circleSort = localStorage.getItem("circleSort");
    let projectSort = localStorage.getItem("projectSort");
    dispatch(updateDeleteFromTrash({ ...obj, circleSort })).then(() => {
      dispatch(getTags(objs));
    });
  };

  const deletePermanently = (e) => {
    e.preventDefault();
    if (!value.length) {
      Swal.fire({
        title: "Error",
        text: "Select at least one item!",
        showConfirmButton: true,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        // timer: 1500,
      });
      return;
    }
    const actionType = "delete";
    const finalArray = CardArrayForm(value);

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        var obj = {};
        obj.teamId = localStorage.getItem("teamId");
        obj.adminId = myInfo.userId;
        obj.url = `${SERVER_URI}/user/tags/update-delete`;
        obj.cardArray = finalArray;
        obj.actionType = actionType;
        let objs = {};
        objs.teamId = localStorage.getItem("teamId");
        objs.userId = myInfo.userId;

        dispatch(updateDeleteFromTrash(obj)).then(() => {
          dispatch(getTags(objs));
        });
      }
    });
  };

  useEffect(() => {
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    if (tagsMessagee) {
      dispatch({ type: TAGS_SUCCESS_MESSAGE_CLEAR });
      dispatch(getTags(obj));
      dispatch(getState(obj));
      closeTrash();
    }

    if (tagsError) {
      Swal.fire({
        // icon: "error", 
        title: "Error",
        showConfirmButton: true,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: tagsError,
        // timer: 1500,
      }).then(() => {
        dispatch({ type: TAGS_ERROR_CLEAR });
        closeTrash();
      });

    }
  }, [tagsMessagee, tagsError]);

  useEffect(() => {
    if (trashData && Object.keys(trashData).length) {
      const trashFinalData = [];
      const {
        deletedCircles,
        deletedDomains,
        deletedLinks,
        deletedPeoples,
        deletedProjects,
        deletedRoles,
        deletedSkills,
      } = trashData;

      const addToTrashFinalData = (items, type, valueKey) => {
        for (const item of items) {
          trashFinalData.push({
            _id: item._id,
            teamId: item.teamId,
            value: item[valueKey],
            type: type,
          });
        }
      };

      const trashDataMapping = [
        { items: deletedCircles, type: "Circle" },
        { items: deletedDomains, type: "Domain" },
        { items: deletedLinks, type: "Link" },
        { items: deletedPeoples, type: "People" },
        { items: deletedProjects, type: "Project" },
        { items: deletedRoles, type: "Role" },
        { items: deletedSkills, type: "Skill" },
      ];

      for (const { items, type } of trashDataMapping) {
        addToTrashFinalData(items, type, "loweredName");
      }

      // filtering duplicates data
      // const uniqueArray = trashFinalData.filter(
      //   (item, index, self) =>
      //     index === self.findIndex((t) => t._id === item._id)
      // );
      setTrashArray(trashFinalData);
    }
  }, [trashData]);

  return (
    <div id="tagsModal">
      <div className="modal-dialog modal-md modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">TRASH</h4>
          </div>
          <div className="modal-body">
            <form className="form">
              {/* <h4>Trash</h4> */}
              <p>Deleting from here is permanent and cannot be undone.</p>
              <div className="row">
                <div className="col-xs-12">
                  <label>
                    {/* <strong> */}
                      {trashArray && trashArray.length > 0
                        ? `Select Items`
                        : `There are no items in trash`}
                    {/* </strong> */}
                  </label>
                </div>
              </div>
              <div className="row">
                {trashArray &&
                  trashArray.length > 0 &&
                  trashArray.map((data, index) => {
                    return (
                      <div className="col-xs-12" key={index}>
                        <label class="checkbox-inline">
                          <input
                            type="checkbox"
                            name="checked"
                            className="tagCheckbox"
                            onChange={(p) => handleChange(p, data)}
                          />
                          {data.value}
                          {`(${GetAliasesName(teams,data.type)})`}
                        </label>
                      </div>
                    );
                  })}
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <div className="row">
              <div className="col-md-12 text-right">
                <Tippy content={<span>Close Tool</span>}>
                  <button
                    type="button"
                    onClick={closeTrash}
                    data-tippy-content="Close Tool"
                    className="btn btn-mytpt-link"
                    data-dismiss="modal"
                  >
                    {/* <i className="fa fa-times"></i> */}
                    Close
                  </button>
                </Tippy>

                {/* <!-- <button type="button" data-tippy-content="Save And Close" className="btn btn-mytpt save-close"><i className="fa fa-check"></i></button> --> */}
                <Tippy content={<span>Delete Selected</span>}>
                  <input
                    type="button"
                    onClick={deletePermanently}
                    id="mytpt-delete-tags"
                    // className="btn btn-mytpt btn-mytpt-red"
                    className="btn btn-mytpt-red"
                    value="Delete Selected"
                  />
                </Tippy>
                <Tippy content={<span>Restore Selected</span>}>
                  <input
                    type="button"
                    onClick={retriveDeleteFromTrash}
                    id="mytpt-delete-tags"
                    className="btn btn-mytpt"
                    value="Restore Selected"
                  />
                </Tippy>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
