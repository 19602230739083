function intersectArrays(arr1, arr2) {
  const set2 = new Set(arr2);
  return arr1.filter((item) => set2.has(item));
}

function findCommonData(obj1, obj2) {
  const commonData = {};

  const keys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);

  keys.forEach((key) => {
    if (obj1[key] && obj2[key]) {
      const commonElements = intersectArrays(obj1[key], obj2[key]);
      commonData[key] = commonElements.length > 0 ? commonElements : [];
    } else {
      commonData[key] = [];
    }
  });

  return commonData;
}

export const TagWiseSorting = (
  e,
  isTag,
  isEXT,
  isNO,
  tagFilteredObj,
  extNoFilteredObj,
  type
) => {

  let finalData = {};
  if (isTag && !isEXT && !isNO) {
    // console.log("regular Tags");
    finalData = tagFilteredObj;
  } else if (!isTag && (isEXT || isNO)) {
    // console.log("EXT and NO tags",extNoFilteredObj);
    finalData = extNoFilteredObj;
  } else if (isTag && (isEXT || isNO)) {
    let mergedData = findCommonData(tagFilteredObj, extNoFilteredObj);
    finalData = mergedData;
  }

  if (
    type &&
    Object.keys(finalData).length > 0 &&
    finalData[type] &&
    finalData[type].length >= 0 &&
    !finalData[type].includes(e._id)
  ) {
    return false;
  }
  return true;
};

export const GetTagFilteredSortedData = (
  isTag,
  isEXT,
  isNO,
  tagFilteredObj,
  extNoFilteredObj
) => {
  let finalData = {};
  if (isTag && !isEXT && !isNO) {
    finalData = tagFilteredObj;
  } else if (!isTag && (isEXT || isNO)) {
    finalData = extNoFilteredObj;
  } else if (isTag && (isEXT || isNO)) {
    let mergedData = findCommonData(tagFilteredObj, extNoFilteredObj);
    finalData = mergedData;
  }
  return finalData;
};

export const Get_ShowHideColumn_Project_Circle_Mode = (
  data,
  tagFilteredData,
  type
) => {
  if (tagFilteredData && Object.keys(tagFilteredData).length > 0) {
    const getIDs = (data) => {
      const result = {
        people: [],
        skill: [],
        role: [],
        domain: [],
        link: [],
        circle: [],
        project: [],
      };

      // console.log(data, data.extendedMembers);

      if (data.coreMembers) {
        if (data.coreMembers.People) {
          result.people = result.people.concat(
            data.coreMembers.People.map((person) => person._id)
          );
        }
        if (data.coreMembers.Skills) {
          result.skill = result.skill.concat(
            data.coreMembers.Skills.map((skill) => skill._id)
          );
        }
        if (data.coreMembers.Roles) {
          result.role = result.role.concat(
            data.coreMembers.Roles.map((role) => role._id)
          );
        }
        if (data.coreMembers.Domains) {
          result.domain = result.domain.concat(
            data.coreMembers.Domains.map((domain) => domain._id)
          );
        }
        if (data.coreMembers.Links) {
          result.link = result.link.concat(
            data.coreMembers.Links.map((link) => link._id)
          );
        }
        if (data.coreMembers.Circles) {
          result.circle = result.circle.concat(
            data.coreMembers.Circles.map((circle) => circle._id)
          );
        }
        if (data.coreMembers.Projects) {
          result.project = result.project.concat(
            data.coreMembers.Projects.map((project) => project._id)
          );
        }
      }

      if (data.extendedMembers) {
        if (data.extendedMembers.People) {
          result.people = result.people.concat(
            data.extendedMembers.People.map((person) => person._id)
          );
        }
        if (data.extendedMembers.Skills) {
          result.skill = result.skill.concat(
            data.extendedMembers.Skills.map((skill) => skill._id)
          );
        }
        if (data.extendedMembers.Roles) {
          result.role = result.role.concat(
            data.extendedMembers.Roles.map((role) => role._id)
          );
        }
        if (data.extendedMembers.Domains) {
          result.domain = result.domain.concat(
            data.extendedMembers.Domains.map((domain) => domain._id)
          );
        }
        if (data.extendedMembers.Links) {
          result.link = result.link.concat(
            data.extendedMembers.Links.map((link) => link._id)
          );
        }
      }

      return result;
    };

    const ids_Array = getIDs(data);
    // console.log("***3***", data.projectName, ids_Array, tagFilteredData);
    let mergedData = findCommonData(ids_Array, tagFilteredData);
    // console.log("****4*****", data.projectName, mergedData);
    // console.log(
    //   "---------------------------------**---------------------------------"
    // );

    const areAllValuesEmptyArrays = (obj) => {
      for (const key in obj) {
        if (obj[key].length !== 0) {
          return false;
        }
      }
      return true;
    };

    const result = areAllValuesEmptyArrays(mergedData);
    // console.log({ name: data.projectName || data.circleName, result });
    return result;
  }
};

export const Get_ShowHideColumn_Project_Circle_Mode_AddedAs_people = (
  data,
  tagFilteredData,
  type
) => {
  if (tagFilteredData && Object.keys(tagFilteredData).length > 0) {
    const getIDs = (data) => {
      const result = {
        people: [],
        // skill: [],
        // role: [],
        // domain: [],
        // link: [],
        // circle: [],
        // project: [],
      };

      if (data.coreMembers) {
        if (data.coreMembers.People) {
          result.people = result.people.concat(
            data.coreMembers.People.map((person) => person._id)
          );
        }
        // if (data.coreMembers.Skills) {
        //   result.skill = result.skill.concat(
        //     data.coreMembers.Skills.map((skill) => skill._id)
        //   );
        // }
        // if (data.coreMembers.Roles) {
        //   result.role = result.role.concat(
        //     data.coreMembers.Roles.map((role) => role._id)
        //   );
        // }
        // if (data.coreMembers.Domains) {
        //   result.domain = result.domain.concat(
        //     data.coreMembers.Domains.map((domain) => domain._id)
        //   );
        // }
        // if (data.coreMembers.Links) {
        //   result.link = result.link.concat(
        //     data.coreMembers.Links.map((link) => link._id)
        //   );
        // }
        // if (data.coreMembers.Circles) {
        //   result.circle = result.circle.concat(
        //     data.coreMembers.Circles.map((circle) => circle._id)
        //   );
        // }
        // if (data.coreMembers.Projects) {
        //   result.project = result.project.concat(
        //     data.coreMembers.Projects.map((project) => project._id)
        //   );
        // }
      }

      if (data.extendedMembers) {
        if (data.extendedMembers.People) {
          result.people = result.people.concat(
            data.extendedMembers.People.map((person) => person._id)
          );
        }
        // if (data.extendedMembers.Skills) {
        //   result.skill = result.skill.concat(
        //     data.extendedMembers.Skills.map((skill) => skill._id)
        //   );
        // }
        // if (data.extendedMembers.Roles) {
        //   result.role = result.role.concat(
        //     data.extendedMembers.Roles.map((role) => role._id)
        //   );
        // }
        // if (data.extendedMembers.Domains) {
        //   result.domain = result.domain.concat(
        //     data.extendedMembers.Domains.map((domain) => domain._id)
        //   );
        // }
        // if (data.extendedMembers.Links) {
        //   result.link = result.link.concat(
        //     data.extendedMembers.Links.map((link) => link._id)
        //   );
        // }
      }

      return result;
    };

    const ids_Array = getIDs(data);
    // console.log("***3***", data.projectName, ids_Array, tagFilteredData);
    let mergedData = findCommonData(ids_Array, tagFilteredData);
    // console.log("****4*****", data.projectName, mergedData);
    // console.log(
    //   "---------------------------------**---------------------------------"
    // );

    const areAllValuesEmptyArrays = (obj) => {
      for (const key in obj) {
        if (obj[key].length !== 0) {
          return false;
        }
      }
      return true;
    };

    const result = areAllValuesEmptyArrays(mergedData);
    // console.log({ name: data.projectName || data.circleName, result });
    return result;
  }
};
