import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import PillComponent from '../PillComponent';

export default function FPSkillsList({ tagFilteredMegedData, handleAliesChange, GetAliasesName, PfoSkills, clickedOnList, toggleEXTnNOCards, getSortIcon, isInfos, pCardHideOnchange, handleDragStart, handleDragEndPF, note, handleSave, noteSaveData, isTag, isEXT, isNO, TagWiseSorting, tagFilteredObj, extNoFilteredObj, IsOwnerExternal, isSkillRoleLead, setSkill, workloadToggle, sumFTEBody_Focus, OwnerExist, PSkillSub, sPSref, skillfSearch, setSkillfSearch, filteredSFData, PSkillAdd, lFromState, skillSubmit, sref, skill, handleSkillChange, setSkills, setSkillName, skillsFrom, teams, toggleTagCards, focused }) {
    return (
        <div
            className={
                !clickedOnList.listName.includes("skill") && Object.keys(tagFilteredMegedData).length > 0 &&
                    tagFilteredMegedData.skill &&
                    tagFilteredMegedData.skill.length === 0
                    ? "board-col hide"
                    : "board-col"
            }
            data-board_id="skills"
        >
            <div className="list list-bg-blue" data-list_id="skills">
                <div className="dropdown">
                    <h4 className="list-title">
                        <img alt="" src="images/icon-skill.png" /> {/* {GetAliasesName(teams, "Skills")} (
            <span>{PfoSkills.length}</span>){" "} */}
                        <span contentEditable={true}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault(); // Prevent new line
                                }
                            }} data-name="skills" onBlur={(e) => handleAliesChange(e)}>{GetAliasesName(teams, "Skills")}</span>
                        <span>{` (${PfoSkills.length})`}</span>
                        <a
                            className={
                                clickedOnList.isClicked &&
                                    clickedOnList.listName.includes("skill")
                                    ? "list-menu refresh-list"
                                    : "list-menu refresh-list hide"
                            }
                            href="#"
                            onClick={() => {
                                toggleEXTnNOCards("skills", "", true, "list");
                            }}
                        >
                            <i className="fas fa-sync-alt"></i>
                        </a>{" "}
                        <a
                            className={
                                clickedOnList.isClicked &&
                                    clickedOnList.listName.includes("skill")
                                    ? "list-menu dropdown-toggle hide"
                                    : "list-menu dropdown-toggle"
                            }
                            data-toggle="dropdown"
                            href="#"
                        >
                            {getSortIcon("Skills")}
                            <i className="fas fa-ellipsis-v"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-right" role="menu">
                            <li role="presentation">
                                <a
                                    onClick={() => isInfos("Skills")}
                                    role="menuitem"
                                    className="mytpt-list-info"
                                >
                                    Info
                                </a>
                            </li>
                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    data-toggle="modal"
                                    data-target="#skillsModal"
                                >
                                    Add new {GetAliasesName(teams, "Skills").toLowerCase()}
                                </a>
                            </li>
                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    onClick={() => pCardHideOnchange("Skills")}
                                    className="hide_list"
                                >
                                    Hide list
                                </a>
                            </li>
                            <li role="presentation">
                                <a role="menuitem" style={{ paddingRight: "5px" }}>
                                    Show Notes
                                    <label className="switch">
                                        <input
                                            name="show_notes_people"
                                            className="dd-switch"
                                            type="checkbox"
                                        />
                                        <span className="slider"></span>
                                    </label>
                                </a>
                            </li>
                            <li role="presentation" className="divider"></li>
                            <li
                                role="presentation"
                                onClick={() => {
                                    toggleEXTnNOCards("skills", "EXT", false);
                                }}
                            >
                                <a role="menuitem" className="show-external">
                                    Show items owned by external
                                </a>
                            </li>
                            <li
                                role="presentation"
                                onClick={() => {
                                    toggleEXTnNOCards("skills", "NO", false);
                                }}
                            >
                                <a role="menuitem" className="show-noowners">
                                    Show items without owners
                                </a>
                            </li>
                        </ul>
                    </h4>
                </div>

                <DragDropContext
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEndPF}
                >
                    <div className="card-list sortable-card-list scrollbar">
                        <div
                            className="form-group notes"
                            style={note.Skills ? { display: "block" } : { display: "none" }}
                        >
                            <textarea
                                className="notes"
                                name="Skills"
                                onBlur={(e) => handleSave(e)}
                                placeholder="Notes"
                                maxLength="100"
                                defaultValue={noteSaveData.Skills}
                            ></textarea>
                        </div>

                        <Droppable
                            className="panel panel-default"
                            droppableId="skills-core"
                            direction="vertical"
                        >
                            {(skillsProvidedPf) => (
                                <div
                                    ref={skillsProvidedPf.innerRef}
                                    {...skillsProvidedPf.droppableProps}
                                    id="core"
                                    className="panel-body box-container"
                                >
                                    {PfoSkills && PfoSkills.length > 0
                                        ? PfoSkills.map((e, index) => {
                                            if (isTag || isEXT || isNO) {
                                                let Proceed = TagWiseSorting(
                                                    e,
                                                    isTag,
                                                    isEXT,
                                                    isNO,
                                                    tagFilteredObj,
                                                    extNoFilteredObj,
                                                    "skill"
                                                );
                                                if (!Proceed) return;
                                            }
                                            let isExternal = IsOwnerExternal(e, "Skill");
                                            let isSRLead = isSkillRoleLead(e, "Skill");
                                            // console.log(isSRLead);
                                            return (
                                                <Draggable
                                                    key={e.id}
                                                    draggableId={`${e._id}`}
                                                    index={index}
                                                >
                                                    {(skillsProvidedPf) => (
                                                        <div
                                                            ref={skillsProvidedPf.innerRef}
                                                            {...skillsProvidedPf.draggableProps}
                                                            {...skillsProvidedPf.dragHandleProps}
                                                            // onDragStart={(e) => skillsDragStart(e, index)}
                                                            // onDragEnter={(e) => skillsDragEnter(e, index)}
                                                            // onDragEnd={() => skillsDrops(e, "corSkill")}
                                                            // draggable
                                                            key={e._id}
                                                            className="card"
                                                            data-list_id="skills"
                                                            onClick={(event) => {
                                                                if (
                                                                    event.target.classList.contains("card") ||
                                                                    event.target.classList.contains("card-edit")
                                                                ) {
                                                                    setSkill(e, PfoSkills, index);
                                                                }
                                                            }}
                                                        >
                                                            <div className="static-relations">
                                                                {workloadToggle === true && (
                                                                    <PillComponent
                                                                        occupied={sumFTEBody_Focus(
                                                                            e.workload,
                                                                            focused.id
                                                                        )}
                                                                        // total={GetPersonAvailability(peoples,focused.id)}
                                                                        // total={GetTotalWorkLoad(e, "SKILLS")}
                                                                        total={e.planTotal}
                                                                        type="TEXT"
                                                                    />
                                                                )}
                                                                {isExternal ? (
                                                                    <span
                                                                        onClick={() => {
                                                                            toggleEXTnNOCards(
                                                                                e._id,
                                                                                "EXT",
                                                                                false,
                                                                                "pill"
                                                                            );
                                                                        }}
                                                                        className="custom-badge blue ext-owner-pill"
                                                                    >
                                                                        EXT
                                                                    </span>
                                                                ) : null}
                                                                {e.owners.length === 0 ||
                                                                    !OwnerExist(e.owners) ? (
                                                                    <span
                                                                        onClick={() => {
                                                                            toggleEXTnNOCards(
                                                                                e._id,
                                                                                "NO",
                                                                                false,
                                                                                "pill"
                                                                            );
                                                                        }}
                                                                        className="custom-badge no-owner-pill red"
                                                                    >
                                                                        NO
                                                                    </span>
                                                                ) : null}
                                                                {e.ownerType.length > 0 &&
                                                                    e.ownerType === "Single owner" ? (
                                                                    <span className="custom-badge blue-o single-owner-pill">
                                                                        1
                                                                    </span>
                                                                ) : null}
                                                                {isSRLead ? (
                                                                    <span className="custom-badge direct-lead red-o">
                                                                        LEAD
                                                                    </span>
                                                                ) : null}
                                                                {e.tags && e.tags.length > 0
                                                                    ? e.tags.map((r, indexs) => (
                                                                        <span
                                                                            onClick={() => toggleTagCards(r)}
                                                                            key={indexs}
                                                                            className="custom-badge green tags"
                                                                        >
                                                                            {r}
                                                                        </span>
                                                                    ))
                                                                    : null}
                                                            </div>
                                                            <a
                                  /* onClick={() => setSkill(e, PfoSkills, index)} */ className="card-edit"
                                                            >
                                                                {e.skillName} {"  "}
                                                            </a>
                                                            <a
                                                                className="card-unlink"
                                                                style={{ display: "block", color: "red" }}
                                                            >
                                                                <i
                                                                    onClick={() => PSkillSub(e)}
                                                                    className="icon far fa-times-circle"
                                                                ></i>
                                                            </a>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })
                                        : null}
                                    {skillsProvidedPf.placeholder}
                                </div>
                            )}
                        </Droppable>

                        <span className="clearable2">
                            <input
                                type="text"
                                ref={sPSref}
                                style={{ color: "black" }}
                                value={skillfSearch}
                                onChange={(e) => setSkillfSearch(e.target.value)}
                                className="form-control search_list2 transparent"
                                placeholder="Start typing to search..."
                                name="searchP_list"
                            />
                            {skillfSearch.length > 0 ? (
                                <i
                                    className="clearable__clear"
                                    onClick={() => setSkillfSearch("")}
                                    style={{ display: "inline", color: "black" }}
                                >
                                    ×
                                </i>
                            ) : null}
                        </span>

                        <Droppable
                            className="panel panel-default"
                            droppableId="skills-notmatched"
                            direction="vertical"
                        >
                            {(skillsProvidedPf) => (
                                <div
                                    ref={skillsProvidedPf.innerRef}
                                    {...skillsProvidedPf.droppableProps}
                                    id="notmatched"
                                    className="panel-body box-container"
                                >
                                    {filteredSFData.length > 0
                                        ? filteredSFData.map((e, index) => {
                                            if (isTag || isEXT || isNO) {
                                                let Proceed = TagWiseSorting(
                                                    e,
                                                    isTag,
                                                    isEXT,
                                                    isNO,
                                                    tagFilteredObj,
                                                    extNoFilteredObj,
                                                    "skill"
                                                );
                                                if (!Proceed) return;
                                            }
                                            let isExternal = IsOwnerExternal(e, "Skill");
                                            return (
                                                <Draggable
                                                    key={e.id}
                                                    draggableId={`${e._id}`}
                                                    index={index}
                                                >
                                                    {(skillsProvidedPf) => (
                                                        <div
                                                            ref={skillsProvidedPf.innerRef}
                                                            {...skillsProvidedPf.draggableProps}
                                                            {...skillsProvidedPf.dragHandleProps}
                                                            // onDragStart={(e) => skillsDragStart(e, index)}
                                                            // onDragEnter={(e) => skillsDragEnter(e, index)}
                                                            // onDragEnd={() => skillsDrops(e, "defSkill")}
                                                            draggable
                                                            key={e._id}
                                                            className={`card notmatched ${localStorage.getItem("latest-created") ===
                                                                e._id
                                                                ? " latest-created "
                                                                : ""
                                                                }`}
                                                            data-list_id="skills"
                                                        >
                                                            <div className="static-relations">
                                                                {isExternal ? (
                                                                    <span
                                                                        onClick={() => {
                                                                            toggleEXTnNOCards(
                                                                                e._id,
                                                                                "EXT",
                                                                                false,
                                                                                "pill"
                                                                            );
                                                                        }}
                                                                        className="custom-badge blue ext-owner-pill"
                                                                    >
                                                                        EXT
                                                                    </span>
                                                                ) : null}
                                                                {e.owners.length === 0 ? (
                                                                    <span
                                                                        onClick={() => {
                                                                            toggleEXTnNOCards(
                                                                                e._id,
                                                                                "NO",
                                                                                false,
                                                                                "pill"
                                                                            );
                                                                        }}
                                                                        className="custom-badge no-owner-pill red"
                                                                    >
                                                                        NO
                                                                    </span>
                                                                ) : null}
                                                                {e.ownerType.length > 0 &&
                                                                    e.ownerType === "Single owner" ? (
                                                                    <span className="custom-badge blue-o single-owner-pill">
                                                                        1
                                                                    </span>
                                                                ) : null}
                                                                {e.tags && e.tags.length > 0
                                                                    ? e.tags.map((r, indexs) => (
                                                                        <span
                                                                            onClick={() => toggleTagCards(r)}
                                                                            key={indexs}
                                                                            className="custom-badge green tags"
                                                                        >
                                                                            {r}
                                                                        </span>
                                                                    ))
                                                                    : null}
                                                            </div>
                                                            <a
                                                                onClick={() =>
                                                                    setSkill(e, filteredSFData, index)
                                                                }
                                                                className="card-edit"
                                                            >
                                                                {e.skillName}
                                                            </a>
                                                            <a
                                                                className="card-link"
                                                                style={{ display: "block" }}
                                                            >
                                                                <i
                                                                    onClick={() => PSkillAdd(e)}
                                                                    className="icon fa fa-plus-circle"
                                                                ></i>
                                                            </a>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })
                                        : null}
                                    {skillsProvidedPf.placeholder}
                                </div>
                            )}
                        </Droppable>

                        <div
                            className="card2"
                            style={
                                lFromState.Skills ? { display: "block" } : { display: "none" }
                            }
                        >
                            <form
                                className="form-new-card"
                                role="form"
                                onSubmit={skillSubmit}
                            >
                                <div className="form-group">
                                    <input
                                        type="text"
                                        ref={sref}
                                        name="card_name"
                                        required
                                        placeholder={`Add ${GetAliasesName(teams, "Skills").toLowerCase()} name`}
                                        className="form-control"
                                        value={skill}
                                        onChange={handleSkillChange}
                                        autoComplete="off"
                                    />
                                </div>
                                <div className="form-group" style={{ marginTop: "10px" }}>
                                    <button type="submit" className="btn btn-mytpt add-quick">
                                        Save
                                    </button>
                                    <a
                                        href="#"
                                        type="button"
                                        onClick={() => {
                                            setSkills({ skillName: skill });
                                            setSkillName("");
                                            lFromState.Skills = false;
                                        }}
                                        className="btn btn-link underline open-tool"
                                    >
                                        Edit details
                                    </a>
                                    <a
                                        href="#"
                                        onClick={() => skillsFrom()}
                                        className="btn btn-link underline close-add-card"
                                    >
                                        Close
                                    </a>
                                </div>
                            </form>
                        </div>
                    </div>
                </DragDropContext>

                <a
                    className="btn-list add-card"
                    onClick={() => skillsFrom()}
                    data-list_id="skills"
                    style={
                        !lFromState.Skills ? { display: "block" } : { display: "none" }
                    }
                >
                    + Add new {/*skill */} {GetAliasesName(teams, "Skills").toLowerCase()}
                </a>
            </div>
        </div>
    )
}
