import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  CompareAndModifyStateData,
  GetPeopleDataDetails,
  GetRoleDataArray,
  GetSkillDataArray,
} from "../../../../HelperFunctions/CompareModeFunctions";
import PillComponent from "../PillComponent";
import { aggrigatedPersonFTE } from "../../../../HelperFunctions/FTE_cal";
import RenderItem from "./RenderItem";
import SkillRoleComapreMode from "./SkillRoleComapreMode";
import {
  createSkills,
  getSkills,
  updateSkills,
  dropUpdateSkills,
} from "../../../../store/actions/skillAction";
import { update } from "immutable";
import { updateRole } from "../../../../store/actions/roleAction";
import {
  createLink,
  getLink,
  updateLink,
  dropUpdateLink,
} from "../../../../store/actions/linkAction";
import {
  MakeDomainOwnerLess,
  updateDomain,
} from "../../../../store/actions/domainAction";

import {
  freezeWindow,
  unfreezeWindow,
} from "../../../../../src/HelperFunctions/Overlay";
import { getState } from "../../../../store/actions/stateAction";
import { PEOPLE_ERROR_CLEAR, PEOPLE_ID_CLEAR, PEOPLE_SUCCESS_MESSAGE_CLEAR } from "../../../../store/types/peopleType";
import { SKILL_ERROR_CLEAR, SKILL_ID_CLEAR, SKILL_SUCCESS_MESSAGE_CLEAR } from "../../../../store/types/skillType";
import { ROLE_ERROR_CLEAR, ROLE_ID_CLEAR, ROLE_SUCCESS_MESSAGE_CLEAR } from "../../../../store/types/roleType";
import { LINK_ERROR_CLEAR, LINK_ID_CLEAR, LINK_SUCCESS_MESSAGE_CLEAR } from "../../../../store/types/linkType";
import { DOMAIN_ERROR_CLEAR, DOMAIN_ID_CLEAR, DOMAIN_SUCCESS_MESSAGE_CLEAR } from "../../../../store/types/domainType";
import Swal from "sweetalert2";

const CompareModePage = ({
  workloadToggle,
  openPeople,
  closePeople,
  openSkill,
  closeSkill,
  openRole,
  closeRole,
  openLink,
  closeLink,
  openCircle,
  closeCircle,
  openProject,
  closeProject,
  openDomain,
  closeDomain,
  indexSet,
  index,
  list,
  focusMode,
  compareMode,
  setCompareMode,
  naveSort,
  allSearch,
  isModeSwitched,
  clickedOnList,
  setClickedOnList,
  setIsEXT,
  isEXT,
  isNO,
  setIsNO,
  isTag,
  setIsTag,
  isTagData,
  setIsTagData,
  tagFilteredObj,
  setTagFilteredObj,
  extNoFilteredObj,
  setExtNoFilteredObj,
  specialModeDataObj,
  setSpecialModeDataObj,
  listHide,
  setListHide,
  focused,
  setFocused,
  setPepole,
  setPepoles,
  setSkill,
  setSkills,
  setRole,
  setRoles,
  setDomain,
  setDomains,
  setLink,
  setLinks,
  setCircle,
  setCircles,
  setProject,
  setProjects,
  openInfo,
  closeInfo,
  isInfos,
  isActive,
  xlsData,
  focusList,
  setFList,
  fList,
  setXls,
  setXlsName,
  setXlsCM,
  note,
  setNote,
  noteOnchange,
  saveNote,
  setSaveNote,
  openPeopleModeTool,
  openCircleModeTool,
  setFocusedMode,
  temporarilyMovedItem,
  setTemporarilyMovedItem,
}) => {
  const dispatch = useDispatch();

  const { loading, successMessage, error, authenticate, myInfo } = useSelector(
    (state) => state.auth
  );

  const { peoples, cuPeopleId, peopleMessagee, peopleError } = useSelector(
    (state) => state.peoples
  );

  const {
    skills,
    cuSkillId,
    nDaoamins,
    skillsMessagee,
    skillError,
    skillsMessage,
  } = useSelector((state) => state.skills);
  const { roles, roleMessagee, roleError, cuRoleId } = useSelector((state) => state.roles);
  const { domains, domainMessagee, domainError, cuDomainId } = useSelector((state) => state.domains);
  const { links, linkMessagee, linkError, cuLinkId } = useSelector((state) => state.links);

  const [PeopleDataArray, setPeopleDataArray] = useState([]);
  const [SkillDataArray, setSkillDataArray] = useState([]);
  const [RoleDataArray, setRoleDataArray] = useState([]);

  useEffect(() => {
    let PeopleDataSetArray = GetPeopleDataDetails(
      compareMode.compareBetween,
      peoples,
      skills,
      roles,
      domains,
      links
    );
    setPeopleDataArray(PeopleDataSetArray);
  }, []);

  useEffect(() => {
    if (PeopleDataArray && PeopleDataArray.length > 0) {
      freezeWindow(false);
      for (let i = 0; i < PeopleDataArray.length; i++) {
        let modifiedPeopleDataData = CompareAndModifyStateData([PeopleDataArray[i].peopleData], peoples);
        modifiedPeopleDataData = modifiedPeopleDataData[0];
        let updatedPeopleDataArray = PeopleDataArray;
        updatedPeopleDataArray[i].peopleData = modifiedPeopleDataData;
        setPeopleDataArray(updatedPeopleDataArray)
      }
      unfreezeWindow();
    }
  }, [peoples]);
  useEffect(() => {
    let SkillDataSetArray = GetSkillDataArray(
      compareMode.compareBetween,
      skills
    );
    setSkillDataArray(SkillDataSetArray);
    if (PeopleDataArray.length > 0) {
      for (let i = 0; i < PeopleDataArray.length; i++) {
        let modifiedCardData = CompareAndModifyStateData(PeopleDataArray[i].cardData, skills);
        let updatedPeopleDataArray = PeopleDataArray;
        updatedPeopleDataArray[i].cardData = modifiedCardData;
        setPeopleDataArray(updatedPeopleDataArray)
      }
    }
    unfreezeWindow();
  }, [skills]);
  useEffect(() => {
    let RoleDataSetArray = GetRoleDataArray(compareMode.compareBetween, roles);
    setRoleDataArray(RoleDataSetArray);
    for (let i = 0; i < PeopleDataArray.length; i++) {
      let modifiedCardData = CompareAndModifyStateData(PeopleDataArray[i].cardData, roles);
      let updatedPeopleDataArray = PeopleDataArray;
      updatedPeopleDataArray[i].cardData = modifiedCardData;
      setPeopleDataArray(updatedPeopleDataArray)
    }
    unfreezeWindow();
  }, [roles]);
  useEffect(() => {
    for (let i = 0; i < PeopleDataArray.length; i++) {
      let modifiedCardData = CompareAndModifyStateData(PeopleDataArray[i].cardData, domains);
      let updatedPeopleDataArray = PeopleDataArray;
      updatedPeopleDataArray[i].cardData = modifiedCardData;
      setPeopleDataArray(updatedPeopleDataArray)
    }
    unfreezeWindow();
  }, [domains])
  useEffect(() => {
    for (let i = 0; i < PeopleDataArray.length; i++) {
      let modifiedCardData = CompareAndModifyStateData(PeopleDataArray[i].cardData, links);
      let updatedPeopleDataArray = PeopleDataArray;
      updatedPeopleDataArray[i].cardData = modifiedCardData;
      setPeopleDataArray(updatedPeopleDataArray)
    }
    unfreezeWindow();
  }, [links])

  /* peopleModal OPEN */
  const handleModalOpen = (data) => {
    indexSet(data);
    if (data.type === "people") {
      const indexWithinAllPeople = peoples.findIndex(person => person._id === data._id);
      setPepole(data, peoples, indexWithinAllPeople);
      // openPeople();
    }
    if (data.type === "skill") {
      const indexWithinAllSkill = skills.findIndex(s => s._id === data._id);
      setSkill(data, skills, indexWithinAllSkill);
      // openSkill();
    }
    if (data.type === "role") {
      const indexWithinAllRole = roles.findIndex(r => r._id === data._id);
      setRole(data, roles, indexWithinAllRole);
      // openRole();
    }
    if (data.type === "domain") {
      const indexWithinAllDomain = domains.findIndex(r => r._id === data._id);
      setDomain(data, domains, indexWithinAllDomain);
      // openDomain();
    }
    if (data.type === "link") {
      const indexWithinAllLink = links.findIndex(l => l._id === data._id);
      setLink(data, links, indexWithinAllLink);
      // openLink();
    }
  };

  const df = (dragDataId, dragId, dropId, dropIndex) => {
    // console.log(PeopleDataArray);
    let removed_SRDL = null;
    let updatedPeopleDataArray = PeopleDataArray.map((sp) => {
      if (sp.peopleId == dragId) {
        removed_SRDL = sp.cardData.filter((scd) => scd._id == dragDataId);
        removed_SRDL = removed_SRDL[0];
        return {
          ...sp,
          cardData: sp.cardData.filter((scd, index) => scd._id != dragDataId),
        };
      }
      return sp;
    });
    if (removed_SRDL && dropId) {
      if (removed_SRDL.type === "skill" || removed_SRDL.type === "role") {
        removed_SRDL = {
          ...removed_SRDL,
          owners: removed_SRDL.owners.filter((sowner) => sowner !== dragId),
        };
        if (!removed_SRDL.owners.includes(dropId)) {
          removed_SRDL.owners = [...removed_SRDL.owners, dropId];
        }
      }
      if (removed_SRDL.type === "domain") {
        if (removed_SRDL.owners.type === "63d3c91d40c4cf67373922e2") {
          removed_SRDL = {
            ...removed_SRDL,
            owners: {
              ...removed_SRDL.owners,
              owner: dropId,
            },
          };
        }
      }
      if (removed_SRDL.type === "link") {
        removed_SRDL = {
          ...removed_SRDL,
          owner: dropId,
        };
      }
      const updatedCardData_PeopleDataArray = updatedPeopleDataArray.map(
        (sp) => {
          const isAlraedy_present = sp.cardData.some(
            (spcd) => spcd._id == removed_SRDL._id
          );
          // console.log(sp.peopleId, dragId)

          if (sp.peopleId === dropId && removed_SRDL && !isAlraedy_present) {
            return {
              ...sp,
              // cardData: [...sp.cardData, removed_SRDL], /* i have to drop it in particular "dropIndex"..do not add in last*/
              cardData: [
                ...sp.cardData.slice(0, dropIndex),
                removed_SRDL,
                ...sp.cardData.slice(dropIndex)
              ],
            };
          }
          return sp;
        }
      );
      updatedPeopleDataArray = updatedCardData_PeopleDataArray;
    }
    setPeopleDataArray(updatedPeopleDataArray);
    /* api call wrt type ---> (owner is not change in state)*/
    /* find type and update call */
    if (removed_SRDL && removed_SRDL.type) {
      let adminId = myInfo.userId;
      if (removed_SRDL.type === "skill") {
        /* remove domain owner where owners.type === removed_SRDL._id */
        let allTargetedIds = domains
          .filter((sd) => sd.owners.type === removed_SRDL._id)
          .map((sd) => sd._id);
        dispatch(updateSkills({ ...removed_SRDL, adminId })).then(() => {
          dispatch(MakeDomainOwnerLess({ idArray: allTargetedIds }));
        });
      }
      if (removed_SRDL.type === "role") {
        /* remove domain owner where owner.type === removed_SRDL._id */
        let allTargetedIds = domains
          .filter((sd) => sd.owners.type === removed_SRDL._id)
          .map((sd) => sd._id);
        dispatch(updateRole({ ...removed_SRDL, adminId })).then(() => {
          dispatch(MakeDomainOwnerLess({ idArray: allTargetedIds }));
        });
      }
      if (removed_SRDL.type === "domain") {
        dispatch(updateDomain({ ...removed_SRDL, adminId }));
      }
      if (removed_SRDL.type === "link") {
        dispatch(updateLink({ ...removed_SRDL, adminId }));
      }
    }
  }

  /* people SDRL DnD */
  const SRDL_change = (dragDataId, dragId, dropId, dropIndex) => {
    /**********************************for fake update************************************* */
    let isPresentInDragPeopleData = false;
    let isPresentInDropPeopleData = false;
    const storeOriginalData = PeopleDataArray;
    let removed_SRDL_For_FakeUpdate = null;
    let FakeUpdate = PeopleDataArray.map((sp) => {
      removed_SRDL_For_FakeUpdate = sp.cardData.filter((scd) => scd._id == dragDataId);
      removed_SRDL_For_FakeUpdate = removed_SRDL_For_FakeUpdate[0];
      if (sp.peopleId == dragId) {
        /* remove */
        isPresentInDragPeopleData = sp.cardData.find((scd) => scd._id == dragDataId);
        return {
          ...sp,
          cardData: sp.cardData.filter((scd, index) => scd._id != dragDataId),
        };
      }
      if (sp.peopleId === dropId) {
        isPresentInDropPeopleData = sp.cardData.find((scd) => scd._id == dragDataId);
        /* add  */
        if (removed_SRDL_For_FakeUpdate && dropId) {
          if (removed_SRDL_For_FakeUpdate.type === "skill" || removed_SRDL_For_FakeUpdate.type === "role") {
            removed_SRDL_For_FakeUpdate = {
              ...removed_SRDL_For_FakeUpdate,
              owners: removed_SRDL_For_FakeUpdate.owners.filter((sowner) => sowner !== dragId),
            };
            if (!removed_SRDL_For_FakeUpdate.owners.includes(dropId)) {
              removed_SRDL_For_FakeUpdate.owners = [...removed_SRDL_For_FakeUpdate.owners, dropId];
            }
          }
        }
      }
      return sp;

    });

    setPeopleDataArray(FakeUpdate);

    if (isPresentInDragPeopleData && isPresentInDropPeopleData) {
      unfreezeWindow()
      const dropPeopleData = PeopleDataArray.find((sp) => sp.peopleData._id === dropId);
      Swal.fire({
        title: `Do you want to merge?`,
        showCancelButton: true,
        // text: `${dropPeopleData.peopleData.fname} ${dropPeopleData.peopleData.lname} already has this item.`,
        html: `
        <p>${dropPeopleData.peopleData.fname} ${dropPeopleData.peopleData.lname} already has this item.</p>
        <p>Tipp: To undo please open the tool and add owner again.</p>
      `,
        confirmButtonText: "Yes",
        cancelButtonText: `No`,
        cancelButtonColor: "#EE7364",
      }).then((result) => {
        if (result.isConfirmed) {
          setPeopleDataArray(storeOriginalData)
          // freezeWindow(false);
          df(dragDataId, dragId, dropId, dropIndex);
        } else {
          setPeopleDataArray(storeOriginalData)
          return;
        }
      });
    } else {
      df(dragDataId, dragId, dropId, dropIndex)
    }

    /******************************fake update ********************************** */

    // if (proceed) {
    // console.log(PeopleDataArray);
    // let removed_SRDL = null;
    // let updatedPeopleDataArray = PeopleDataArray.map((sp) => {
    //   if (sp.peopleId == dragId) {
    //     removed_SRDL = sp.cardData.filter((scd) => scd._id == dragDataId);
    //     removed_SRDL = removed_SRDL[0];
    //     return {
    //       ...sp,
    //       cardData: sp.cardData.filter((scd, index) => scd._id != dragDataId),
    //     };
    //   }
    //   return sp;
    // });
    // if (removed_SRDL && dropId) {
    //   if (removed_SRDL.type === "skill" || removed_SRDL.type === "role") {
    //     removed_SRDL = {
    //       ...removed_SRDL,
    //       owners: removed_SRDL.owners.filter((sowner) => sowner !== dragId),
    //     };
    //     if (!removed_SRDL.owners.includes(dropId)) {
    //       removed_SRDL.owners = [...removed_SRDL.owners, dropId];
    //     }
    //   }
    //   if (removed_SRDL.type === "domain") {
    //     if (removed_SRDL.owners.type === "63d3c91d40c4cf67373922e2") {
    //       removed_SRDL = {
    //         ...removed_SRDL,
    //         owners: {
    //           ...removed_SRDL.owners,
    //           owner: dropId,
    //         },
    //       };
    //     }
    //   }
    //   if (removed_SRDL.type === "link") {
    //     removed_SRDL = {
    //       ...removed_SRDL,
    //       owner: dropId,
    //     };
    //   }
    //   const updatedCardData_PeopleDataArray = updatedPeopleDataArray.map(
    //     (sp) => {
    //       const isAlraedy_present = sp.cardData.some(
    //         (spcd) => spcd._id == removed_SRDL._id
    //       );
    //       console.log(sp.peopleId,dragId)

    //       if (sp.peopleId === dropId && removed_SRDL && !isAlraedy_present) {
    //         return {
    //           ...sp,
    //           // cardData: [...sp.cardData, removed_SRDL], /* i have to drop it in particular "dropIndex"..do not add in last*/
    //           cardData: [
    //             ...sp.cardData.slice(0, dropIndex),
    //             removed_SRDL,
    //             ...sp.cardData.slice(dropIndex)
    //           ],
    //         };
    //       }
    //       return sp;
    //     }
    //   );
    //   updatedPeopleDataArray = updatedCardData_PeopleDataArray;
    // }
    // setPeopleDataArray(updatedPeopleDataArray);
    // /* api call wrt type ---> (owner is not change in state)*/
    // /* find type and update call */
    // if (removed_SRDL && removed_SRDL.type) {
    //   let adminId = myInfo.userId;
    //   if (removed_SRDL.type === "skill") {
    //     /* remove domain owner where owners.type === removed_SRDL._id */
    //     let allTargetedIds = domains
    //       .filter((sd) => sd.owners.type === removed_SRDL._id)
    //       .map((sd) => sd._id);
    //     dispatch(updateSkills({ ...removed_SRDL, adminId })).then(() => {
    //       dispatch(MakeDomainOwnerLess({ idArray: allTargetedIds }));
    //     });
    //   }
    //   if (removed_SRDL.type === "role") {
    //     /* remove domain owner where owner.type === removed_SRDL._id */
    //     let allTargetedIds = domains
    //       .filter((sd) => sd.owners.type === removed_SRDL._id)
    //       .map((sd) => sd._id);
    //     dispatch(updateRole({ ...removed_SRDL, adminId })).then(() => {
    //       dispatch(MakeDomainOwnerLess({ idArray: allTargetedIds }));
    //     });
    //   }
    //   if (removed_SRDL.type === "domain") {
    //     dispatch(updateDomain({ ...removed_SRDL, adminId }));
    //   }
    //   if (removed_SRDL.type === "link") {
    //     dispatch(updateLink({ ...removed_SRDL, adminId }));
    //   }
    // }

    // }
  };


  /* Function to remove a task based on object ID and tag index */
  const AddRemoveTask = (dragId, dropData, taskIndex, taskDropIndex, type) => {
    let adminId = myInfo.userId;
    if (type === "role") {
      // ROLE ---> SKILL/ ROLE
      let removedTask = "";
      // Update RoleDataArray: remove the task from the specified index
      let updatedRoleDataArray = RoleDataArray.map((item) => {
        if (item._id === dragId) {
          removedTask = item.tasks[taskIndex];
          return {
            ...item,
            tasks: item.tasks.filter((task, index) => {
              // console.log(
              //   "Filtering index:",
              //   index,
              //   "Task:",
              //   task,
              //   "removeTaskIndex",
              //   taskIndex
              // );
              return index != taskIndex;
            }),
          };
        }
        return item;
      });
      /*--------> update drag data in db <------------ */
      const changedDragRoleData = updatedRoleDataArray.find(
        (sr) => sr._id === dragId
      );
      dispatch(updateRole({ ...changedDragRoleData, adminId }));
      /*---------------------------------------------- */
      // Update the state with the modified arrays
      if (dropData.type === "skill") {
        // Update SkillDataArray: add the removed tag to the specified skill
        const updatedSkillDataArray = SkillDataArray.map((item) => {
          if (item._id === dropData._id && removedTask) {
            // Copy the current tasks array
            const updatedTasks = [...item.tasks];
            // Insert the removedTask at the specified index (taskDropIndex)
            updatedTasks.splice(taskDropIndex, 0, removedTask);

            return {
              ...item,
              tasks: updatedTasks,
            };
          }
          return item;
        });
        // console.log(taskDropIndex);
        // console.log(updatedSkillDataArray.tasks);

        // const updatedSkillDataArray = SkillDataArray.map((item) => {
        //   if (item._id === dropData._id && removedTask) {
        //     //taskDropIndex -- push in that index
        //     return {
        //       ...item,
        //       tasks: [...item.tasks, removedTask],
        //     };
        //   }
        //   return item;
        // });
        setSkillDataArray(updatedSkillDataArray);

        /*--------> update drop data in db <------------ */
        const changedDragSkillData = updatedSkillDataArray.find(
          (sr) => sr._id === dropData._id
        );
        dispatch(updateSkills({ ...changedDragSkillData, adminId }));
        /*---------------------------------------------- */
      } else if (dropData.type === "role") {
        // Update RoleDataArray: add the removed tag to the specified skill
        const updatedRoleDataArrayAgain = updatedRoleDataArray.map((item) => {
          if (item._id === dropData._id && removedTask) {
            // Copy the current tasks array
            const updatedTasks = [...item.tasks];
            // Insert the removedTask at the specified index (taskDropIndex)
            updatedTasks.splice(taskDropIndex, 0, removedTask);

            return {
              ...item,
              tasks: updatedTasks,
            };
          }
          return item;
        });

        // const updatedRoleDataArrayAgain = updatedRoleDataArray.map((item) => {
        //   if (item._id === dropData._id && removedTask) {
        //     return {
        //       ...item,
        //       tasks: [...item.tasks, removedTask],
        //     };
        //   }
        //   return item;
        // });
        updatedRoleDataArray = updatedRoleDataArrayAgain;

        /*--------> update drop data in db <------------ */
        const changedDropRoleData = updatedRoleDataArrayAgain.find(
          (sr) => sr._id === dropData._id
        );
        dispatch(updateRole({ ...changedDropRoleData, adminId }));
        /*---------------------------------------------- */
      }
      setRoleDataArray(updatedRoleDataArray);
    } else if (type === "skill") {
      /* SKILL ---> SKILL/ ROLE */
      let removedTask = "";
      // Update SkillDataArray: remove the tag from the specified index
      let updatedSkillDataArray = SkillDataArray.map((item) => {
        if (item._id === dragId) {
          removedTask = item.tasks[taskIndex];
          return {
            ...item,
            tasks: item.tasks.filter((_, index) => index != taskIndex),
          };
        }
        return item;
      });
      /*--------> update drag data in db <------------ */
      const changedDragSkillData = updatedSkillDataArray.find(
        (sr) => sr._id === dragId
      );
      dispatch(updateSkills({ ...changedDragSkillData, adminId }));
      /*---------------------------------------------- */

      if (dropData.type === "skill") {
        // Update SkillDataArray: add the removed tag to the specified skill
        const updatedSkillDataArrayAgain = updatedSkillDataArray.map((item) => {
          if (item._id === dropData._id && removedTask) {
            // Copy the current tasks array
            const updatedTasks = [...item.tasks];
            // console.log(updatedTasks, taskDropIndex);
            // Insert the removedTask at the specified index (taskDropIndex)
            updatedTasks.splice(taskDropIndex, 0, removedTask);
            return {
              ...item,
              tasks: updatedTasks,
            };
          }
          return item;
        });
        /* update variable */
        updatedSkillDataArray = updatedSkillDataArrayAgain;
        /*--------> update drop data in db <------------ */
        const changedDropSkillData = updatedSkillDataArray.find(
          (sr) => sr._id === dropData._id
        );
        dispatch(updateSkills({ ...changedDropSkillData, adminId }));
        /*---------------------------------------------- */
      } else if (dropData.type === "role") {
        // Update RoleDataArray: add the removed tag to the specified role
        const updatedRoleDataArray = RoleDataArray.map((item) => {
          if (item._id === dropData._id && removedTask) {
            // Copy the current tasks array
            const updatedTasks = [...item.tasks];
            // Insert the removedTask at the specified index (taskDropIndex)
            updatedTasks.splice(taskDropIndex, 0, removedTask);

            return {
              ...item,
              tasks: updatedTasks,
            };
          }
          return item;
        });
        // const updatedRoleDataArray = RoleDataArray.map((item) => {
        //   if (item._id === dropData._id && removedTask) {
        //     return {
        //       ...item,
        //       tasks: [...item.tasks, removedTask],
        //     };
        //   }
        //   return item;
        // });
        /*--------> update drop data in db <------------ */
        const changedDropRoleData = updatedRoleDataArray.find(
          (sr) => sr._id === dropData._id
        );
        dispatch(updateRole({ ...changedDropRoleData, adminId }));
        /*---------------------------------------------- */

        /* update state */
        setRoleDataArray(updatedRoleDataArray);
      }
      setSkillDataArray(updatedSkillDataArray);
    }
  };

  /* React DnD Controller function */
  const handleDragEnd = (result) => {
    const { destination, draggableId, source } = result;
    if (!draggableId || !source || !destination) {
      return false;
    }
    const draggableData = draggableId && draggableId.split("-");
    const destinationDataDetails = destination.droppableId.split("-");
    const sourceDataDetails = source.droppableId.split("-");

    let DetailsObj = {
      daragDataId: draggableData[4],
      dragDataType: draggableData[2],
      dragCard: sourceDataDetails[1],
      // dragFromType:draggableData,
      dragFromIndex: source.index,
      dropCard: destinationDataDetails[1],
      dragToIndex: destination.index,
    };

    if (DetailsObj.dragCard === DetailsObj.dropCard) {
      return;
    } else {
      freezeWindow(false);
      if (DetailsObj.daragDataId !== "null") {
        /* skill role domain change */
        SRDL_change(
          DetailsObj.daragDataId,
          DetailsObj.dragCard,
          DetailsObj.dropCard,
          destination.index
        );
      } else {
        /* skill role task change */
        let taskIndex = draggableData[6];
        let taskDropIndex = destination.index;

        let dragCartDetails =
          SkillDataArray.find((ss) => ss._id === DetailsObj.dragCard) ||
          RoleDataArray.find((ss) => ss._id === DetailsObj.dragCard);
        let dropCardDetails =
          SkillDataArray.find((ss) => ss._id === DetailsObj.dropCard) ||
          RoleDataArray.find((ss) => ss._id === DetailsObj.dropCard);

        if (dragCartDetails.type === "skill") {
          AddRemoveTask(
            dragCartDetails._id,
            dropCardDetails,
            taskIndex,
            taskDropIndex,
            "skill"
          );
        } else if (dragCartDetails.type === "role") {
          AddRemoveTask(
            dragCartDetails._id,
            dropCardDetails,
            taskIndex,
            taskDropIndex,
            "role"
          );
        }
      }
    }
  };

  /* for people outside update  */
  useEffect(() => {
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    if (peopleMessagee && peopleMessagee.length > 0) {
      dispatch(getState(obj)).then(() => {
        localStorage.setItem("latest-created", cuPeopleId);
        dispatch({ type: PEOPLE_ID_CLEAR });
        dispatch({ type: PEOPLE_SUCCESS_MESSAGE_CLEAR });
      });
      dispatch({ type: PEOPLE_SUCCESS_MESSAGE_CLEAR });
    }

    if (peopleError) {
      Swal.fire({
        title: "Error",
        showClass: {
          popup: "animate_animated animate_fadeInDown",
        },
        hideClass: {
          popup: "animate_animated animate_fadeOutUp",
        },
        text: peopleError,
      });
      dispatch({ type: PEOPLE_ERROR_CLEAR });
    }
  }, [peopleMessagee, peopleError, cuPeopleId]);

  /* for skill outside update */
  useEffect(() => {
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    if (skillsMessagee && skillsMessagee.length > 0) {
      dispatch(getState(obj));
      localStorage.setItem("latest-created", cuSkillId);
      dispatch({ type: SKILL_ID_CLEAR });
      dispatch({ type: SKILL_SUCCESS_MESSAGE_CLEAR });
    }

    if (skillError) {
      Swal.fire({
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: skillError,
      });
      dispatch({ type: SKILL_ERROR_CLEAR });
    }
  }, [skillsMessagee, skillError, cuSkillId]);

  /* for role outside update */
  useEffect(() => {
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    if (roleMessagee && roleMessagee.length > 0) {
      dispatch(getState(obj));
      localStorage.setItem("latest-created", cuRoleId);
      dispatch({ type: ROLE_ID_CLEAR });
      dispatch({ type: ROLE_SUCCESS_MESSAGE_CLEAR });
    }

    if (roleError) {
      Swal.fire({
        title: "Error",
        showClass: {
          popup: "animate_animated animate_fadeInDown",
        },
        hideClass: {
          popup: "animate_animated animate_fadeOutUp",
        },
        text: roleError,
      });
      // alert.error(peopleError)
      dispatch({ type: ROLE_ERROR_CLEAR });
    }
  }, [roleMessagee, roleError, cuRoleId]);

  /* for domain outside update */
  useEffect(() => {
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    if (domainMessagee) {
      dispatch(getState(obj));
      localStorage.setItem("latest-created", cuDomainId);
      dispatch({ type: DOMAIN_ID_CLEAR });
      dispatch({ type: DOMAIN_SUCCESS_MESSAGE_CLEAR });
      dispatch({ type: DOMAIN_SUCCESS_MESSAGE_CLEAR });
    }

    if (domainError) {
      Swal.fire({
        // icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: domainError,
      });
      dispatch({ type: DOMAIN_ERROR_CLEAR });
    }
  }, [domainMessagee, domainError, cuDomainId]);

  /* for link outside update */
  useEffect(() => {
    // console.log(linkMessagee, linkError, cuLinkId)
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;

    if (linkMessagee) {
      dispatch(getState(obj));
      localStorage.setItem("latest-created", cuLinkId);
      dispatch({ type: LINK_ID_CLEAR });
      dispatch({ type: LINK_SUCCESS_MESSAGE_CLEAR });
      dispatch({ type: LINK_SUCCESS_MESSAGE_CLEAR });
    }

    if (linkError) {
      Swal.fire({
        // icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: linkError,
      });
      // alert.error(peopleError)
      dispatch({ type: LINK_ERROR_CLEAR });
      // dispatch(getDomain(obj));
      // closeLink();
    }
  }, [linkMessagee, linkError, cuLinkId]);

  return (
    <div className="wrap-lists all-lists">
      <DragDropContext
        // onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        {PeopleDataArray.length > 0 &&
          PeopleDataArray.map((sp, i) => {
            return (
              <div
                className="board-col"
                data-board_id="people"
                key={sp.peopleId}
              >
                <div className="list list-bg-yellow" data-list_id="people">
                  <div className="dropdown" style={{ position: "static", cursor: "pointer" }}
                    onClick={() => handleModalOpen(sp.peopleData)}>
                    <h4
                      className="list-title">
                      {`${sp.peopleData.fname} ${sp.peopleData.lname}`}
                      <a
                        className="list-menu"
                        href="#"
                      >
                        <i className="fas fa-plus"></i>
                      </a>
                    </h4>
                    {
                      /* (e.availability > 0) && */
                      workloadToggle === true && (
                        <PillComponent
                          occupied={aggrigatedPersonFTE(
                            skills,
                            roles,
                            domains,
                            links,
                            sp.peopleData._id
                          )}
                          total={sp.peopleData.availability}
                        />
                      )
                    }
                  </div>
                  <div className="static-relations">
                    {sp.peopleData.tags && sp.peopleData.tags.length > 0
                      ? sp.peopleData.tags.map((tag, indexs) => (
                        <span key={indexs} className="custom-badge green tags">
                          {tag}
                        </span>
                      ))
                      : null}
                  </div>

                  <Droppable
                    droppableId={`people-${sp.peopleId}-index-${i}`}
                    direction="vertical"
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="card-list sortable-card-list scrollbar"
                      >
                        {sp.cardData && sp.cardData.length > 0
                          ? sp.cardData.map((card, index) => {
                            return (
                              <RenderItem
                                peopleData={sp.peopleData}
                                key={card._id}
                                item={card}
                                itemType={card.type}
                                peopleId={sp.peopleId}
                                index={index}
                                workloadToggle={workloadToggle}
                                skills={skills}
                                roles={roles}
                                domains={domains}
                                links={links}
                                handleModalOpen={handleModalOpen}
                              />
                            );
                          })
                          : null}

                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </div>
            );
          })}

        {SkillDataArray.length > 0 &&
          SkillDataArray.map((ss, index) => {
            const ownername = peoples && peoples.map((sp) => {
              if (ss.owners.includes(sp._id)) {
                return `${sp.fname} ${sp.lname}`;
              }
            }).filter(Boolean);

            return (
              <>
                <SkillRoleComapreMode
                  key={ss._id}
                  item={ss}
                  itemType="skill"
                  index={index}
                  handleModalOpen={handleModalOpen}
                  ownerNameArray={ownername}
                />
              </>

            );
          })}

        {RoleDataArray.length > 0 &&
          RoleDataArray.map((ss, index) => {
            const ownername = peoples && peoples.map((sp) => {
              if (ss.owners.includes(sp._id)) {
                return `${sp.fname} ${sp.lname}`;
              }
            }).filter(Boolean);
            return (
              <SkillRoleComapreMode
                key={ss._id}
                item={ss}
                itemType="role"
                index={index}
                handleModalOpen={handleModalOpen}
                ownerNameArray={ownername}
              />
            );
          })}

      </DragDropContext>
    </div>
  );
};

export default CompareModePage;
