import axios from 'axios'
import {
    SKILL_CREATE_SUCCESS, SKILL_CREATE_FAIL, SKILL_GET_SUCCESS, SKILL_DELETE_SUCCESS,
    SKILL_UPDATE_SUCCESS, SKILL_UPDATE_FAIL, SKILL_DELETE_FAIL, SKILL_GET_FAIL,
    SKILL_DROP_UPDATE_SUCCESS, SKILL_DROP_UPDATE_FAIL
} from '../types/skillType'

import { DOMAIN_GET_SUCCESS } from '../types/domainType'

import { CIRCLE_GET_SUCCESS } from '../types/circleType'

import { PROJECT_GET_SUCCESS } from '../types/projectType'

import { MEMBER_GET_SUCCESS } from '../types/memberType'

import { PFMEMBER_GET_SUCCESS } from '../types/pfmemberType'

// const token = localStorage.getItem("authToken");
import { SERVER_URI } from '../../config/keys'

import { isCardExist } from '../../HelperFunctions/CardExist'
import { DispatchBlocker } from '../DispatchBlocker/DispatchBlocker'

const loggedInAxios = axios.create({
    baseURL: SERVER_URI,
  });
  
  export const userAuth = (token) => {
    loggedInAxios.interceptors.request.use(
      config => {
        config.headers.authorization = `Bearer ${token}`;
        return config;
      },
      error => {
        return Promise.reject(error)
      }
    )
  }

// export const userAuth = (token) => {
//     axios.interceptors.request.use(
//         config => {
//             config.headers.authorization = `Bearer ${token}`;
//             return config;
//         },
//         error => {
//             return Promise.reject(error)
//         }
//     )
// }

export const createSkills = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        // Accessing the current state
        const currentState = getState();
        if(isCardExist(currentState.skills.skills, datas.skillName, 'Skill')){
            dispatch({
                type: SKILL_CREATE_FAIL,
                payload: {
                    skillError: `Skill with this name already exist!`
                }
            })
            return;
        }
        
        // const url = 'http://localhost:8080'
        let token = localStorage.getItem('authToken')

        try {
            userAuth(token)
            const response = await axios.post(`${SERVER_URI}/user/skill/create`, datas)
            // console.log(response.data)

            dispatch({
                type: SKILL_CREATE_SUCCESS,
                payload: {
                    skillSuccessMessage: response.data.message,
                    cuSkillId: response.data.data._id,
                    nDaoamins: response.data.nDaoamins,
                    skills: response.data.skills,
                }
            })

            dispatch({
                type: DOMAIN_GET_SUCCESS,
                payload: {
                    domains: response.data.domains,
                    dDomains: response.data.fDomains,
                }
            })

            dispatch({
                type: CIRCLE_GET_SUCCESS,
                payload: {
                    circles: response.data.circles,
                    dCircles: response.data.fCircles
                }
            })

            dispatch({
                type: PROJECT_GET_SUCCESS,
                payload: {
                    projects: response.data.projects,
                }
            })

        } catch (error) {
            if (error && error.response) {
                let data = error.response.data.message
                dispatch({
                    type: SKILL_CREATE_FAIL,
                    payload: {
                        skillError: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: SKILL_CREATE_FAIL,
                    payload: {
                        skillError: error.message
                    }
                })
            }
        }
    }
}

export const getSkills = (datas) => {
    return async (dispatch) => {
        // const url = 'http://localhost:8080'
        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'adminId': datas.userId,
                'teamId': datas.teamId,
                'curskill': datas.curskill,
                'skillsData': datas.skillsData,
                'url': `${SERVER_URI}/user/skill/get`
            }
        }
        // console.log(config)
        try {
            const token = localStorage.getItem("authToken");
            // console.log(token)
            userAuth(token)
            const response = await axios.get(`${SERVER_URI}/user/skill/get`, config)
            // console.log( response.data)
            // localStorage.setItem('authToken', response.data.data.token)

            dispatch({
                type: SKILL_GET_SUCCESS,
                payload: {
                    skillSuccessMessage: response.data.message,
                    skills: response.data.data
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: SKILL_GET_FAIL,
                    payload: {
                        skillError: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: SKILL_GET_FAIL,
                    payload: {
                        skillError: error.message
                    }
                })
            }
        }
    }
}

export const updateSkills = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        // Accessing the current state
        const currentState = getState();
        if(isCardExist(currentState.skills.skills, datas.skillName, 'Skill', datas._id)){
            dispatch({
                type: SKILL_UPDATE_FAIL,
                payload: {
                    skillError: `Skill with this name already exist!`
                }
            })
            return;
        }

        // const url = 'http://localhost:8080'
        let token = localStorage.getItem('authToken')
        try {
            userAuth(token)
            const response = await loggedInAxios.put(`${SERVER_URI}/user/skill/update`, datas)
            // console.log(response)
            dispatch({
                type: SKILL_UPDATE_SUCCESS,
                payload: {
                    skillSuccessMessage: response.data.message,
                    nDaoamins: response.data.nDaoamins,
                    skills: response.data.skills,
                    domains: response.data.domains,
                }
            })

            dispatch({
                type: DOMAIN_GET_SUCCESS,
                payload: {
                    domains: response.data.domains,
                    dDomains: response.data.fDomains,
                }
            })

            dispatch({
                type: CIRCLE_GET_SUCCESS,
                payload: {
                    circles: response.data.circles,
                    dCircles: response.data.fCircles
                }
            })

            dispatch({
                type: PROJECT_GET_SUCCESS,
                payload: {
                    projects: response.data.projects,
                }
            })

            dispatch({
                type: MEMBER_GET_SUCCESS,
                payload: {
                    membersuccessMessage: response.data.message,
                    members: response.data.members
                }
            })

            dispatch({
                type: PFMEMBER_GET_SUCCESS,
                payload: {
                    membersuccessMessage: response.data.message,
                    members: response.data.pfmembers
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: SKILL_UPDATE_FAIL,
                    payload: {
                        skillError: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: SKILL_UPDATE_FAIL,
                    payload: {
                        skillError: error.message
                    }
                })
            }
        }
    }
}

export const dropUpdateSkills = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        let token = localStorage.getItem('authToken')
        try {
            // console.log(datas)
            userAuth(token)
            const response = await axios.put(`${SERVER_URI}/user/skill/update`, datas)
            // console.log(response)
            dispatch({
                type: SKILL_DROP_UPDATE_SUCCESS,
                payload: {
                    skillSuccessMessage: response.data.message,
                    skills: response.data.skills,
                }
            })

            dispatch({
                type: DOMAIN_GET_SUCCESS,
                payload: {
                    domains: response.data.domains,
                    dDomains: response.data.fDomains,
                }
            })

            dispatch({
                type: CIRCLE_GET_SUCCESS,
                payload: {
                    circles: response.data.circles,
                    dCircles: response.data.fCircles
                }
            })

            dispatch({
                type: PROJECT_GET_SUCCESS,
                payload: {
                    projects: response.data.projects,
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: SKILL_DROP_UPDATE_FAIL,
                    payload: {
                        skillError: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: SKILL_DROP_UPDATE_FAIL,
                    payload: {
                        skillError: error.message
                    }
                })
            }
        }
    }
}

export const deleteSkills = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        let token = localStorage.getItem('authToken')
        let skillSort = localStorage.getItem('skillSort')
        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'adminId': datas.adminId,
                'teamId': datas.teamId,
                'url': `${SERVER_URI}/user/skill/delete`,
                'skillId': datas.skillId,
                'skillSort': skillSort,
                'softDelete': datas.softDelete
            }
        }
        try {
            // console.log(datas)
            userAuth(token)
            const response = await axios.delete(`${SERVER_URI}/user/skill/delete`, config)
            // console.log(response);

            dispatch({
                type: SKILL_DELETE_SUCCESS,
                payload: {
                    skillSuccessMessage: response.data.message,
                    skills: response.data.skills,
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: SKILL_DELETE_FAIL,
                    payload: {
                        skillError: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: SKILL_DELETE_FAIL,
                    payload: {
                        skillError: error.message
                    }
                })
            }
        }
    }
}