import {
    CIRCLE_CREATE_SUCCESS, CIRCLE_CREATE_FAIL, CIRCLE_GET_SUCCESS, CIRCLE_DELETE_SUCCESS,
    CIRCLE_UPDATE_SUCCESS, CIRCLE_UPDATE_FAIL, CIRCLE_DELETE_FAIL, CIRCLE_GET_FAIL,
    CIRCLE_SUCCESS_MESSAGE_CLEAR, CIRCLE_ERROR_CLEAR, SIGN_OUT, CIRCLE_DROP_UPDATE_SUCCESS, CIRCLE_DROP_UPDATE_FAIL,
    CIRCLE_ID_CLEAR
} from '../types/circleType'

import { areArraysEqual } from '../../HelperFunctions/ArraysAreEqual';

const userState = {
    circles: [],
    dCircles: [],
    cuCircleId: "",
    circleMessage: '',
    circleMessagee: '',
    circleError: ''
}


export const circleReducer = (state = userState, action) => {
    const { type, payload } = action
    // const {users, successMessage } = payload
    // console.log(payload.users)

    if (type === CIRCLE_CREATE_SUCCESS) {
        return {
            ...state,
            circles: payload.circles,
            circleMessagee: payload.circleSuccessMessage,
            cuCircleId: payload.cuCircleId,
            circleError: ""
        }
    }
    if (type === CIRCLE_GET_SUCCESS) {
        const d_result = areArraysEqual(state.circles, payload.circles);

        const dc_result = areArraysEqual(state.dCircles, payload.dCircles);
        return {
            ...state,
            circles: d_result ? state.circles : payload.circles,
            dCircles: dc_result ? state.dCircles : payload.dCircles,
            circleMessage: payload.circleSuccessMessage,
            circleError: ""
        }
    }
    if (type === CIRCLE_DELETE_SUCCESS) {
        return {
            ...state,
            circles: payload.circles,
            circleMessagee: payload.circleSuccessMessage,
            circleError: ""
        }
    }
    if (type === CIRCLE_UPDATE_SUCCESS) {
        // console.log(payload)
        return {
            ...state,
            circles: payload.circles,
            circleMessagee: payload.circleSuccessMessage, //payload.circleSuccessMessage,
            circleError: ""
        }
    }
    if (type === CIRCLE_DROP_UPDATE_SUCCESS) {
        return {
            ...state,
            circles: payload.circles,
            circleMessagee: "", //payload.circleSuccessMessage,
            circleError: "",
        }
    }
    if (type === CIRCLE_CREATE_FAIL) {
        return {
            ...state,
            circleError: payload.circleError
        }
    }
    if (type === CIRCLE_DELETE_FAIL) {
        return {
            ...state,
            circleError: payload.circleError
        }
    }
    if (type === CIRCLE_UPDATE_FAIL) {
        return {
            ...state,
            circleError: payload.circleError,// payload.error
        }
    }
    if (type === CIRCLE_DROP_UPDATE_FAIL) {
        return {
            ...state,
            circleError: "",// payload.error
        }
    }
    if (type === CIRCLE_GET_FAIL) {
        return {
            ...state,
            circleError: payload.circleError
        }
    }
    if (type === CIRCLE_SUCCESS_MESSAGE_CLEAR) {
        return {
            ...state,
            circleMessagee: ''
        }
    }
    if (type === CIRCLE_ERROR_CLEAR) {
        return {
            ...state,
            circleError: ''
        }
    }
    if (type === CIRCLE_ID_CLEAR) {
        return {
            ...state,
            cuCircleId: ''
        }
    }
    if (type === SIGN_OUT) {
        return {
            ...state,
            circles: [],
            circleMessage: '',
            circleMessagee: '',
            circleError: ''
        }
    }


    return state;
}