export const TAGS_GET_SUCCESS = "TAGS_GET_SUCCESS"
export const TAGS_GET_FAIL = "TAGS_GET_FAIL"
export const TAGS_CREATE_SUCCESS = "TAGS_GET_SUCCESS"
export const TAGS_CREATE_FAIL = "TAGS_GET_FAIL"
export const TAGS_DELETE_SUCCESS = 'TAGS_DELETE_SUCCESS'
export const TAGS_UPDATE_SUCCESS = 'TAGS_UPDATE_SUCCESS'
export const TAGS_UPDATE_FAIL = 'TAGS_UPDATE_FAIL'
export const TAGS_DELETE_FAIL = 'TAGS_DELETE_FAIL'

export const TAGS_SUCCESS_MESSAGE_CLEAR = 'TAGS_SUCCESS_MESSAGE_CLEAR'
export const TAGS_ERROR_CLEAR = 'TAGS_ERROR_CLEAR'


export const SIGN_OUT = "SIGN_OUT"