export const DOMAIN_CREATE_SUCCESS = 'DOMAIN_CREATE_SUCCESS'
export const DOMAIN_CREATE_FAIL = 'DOMAIN_CREATE_FAIL'
export const DOMAIN_GET_SUCCESS = 'DOMAIN_GET_SUCCESS'
export const DOMAIN_GET_FAIL = 'DOMAIN_GET_FAIL'
export const DOMAIN_DELETE_SUCCESS = 'DOMAIN_DELETE_SUCCESS'
export const DOMAIN_UPDATE_SUCCESS = 'DOMAIN_UPDATE_SUCCESS'
export const DOMAIN_UPDATE_FAIL = 'DOMAIN_UPDATE_FAIL'
export const DOMAIN_DELETE_FAIL = 'DOMAIN_DELETE_FAIL'
export const DOMAIN_DROP_UPDATE_SUCCESS = 'DOMAIN_DROP_UPDATE_SUCCESS'
export const DOMAIN_DROP_UPDATE_FAIL = 'DOMAIN_DROP_UPDATE_FAIL'

export const DOMAIN_ID_CLEAR = 'DOMAIN_ID_CLEAR'

export const DOMAIN_SUCCESS_MESSAGE_CLEAR = 'DOMAIN_SUCCESS_MESSAGE_CLEAR'
export const DOMAIN_ERROR_CLEAR = 'DOMAIN_ERROR_CLEAR'
export const SIGN_OUT = 'SIGN_OUT'

export const DOMAINS_MODIFICATIONS = 'DOMAINS_MODIFICATIONS'