import axios from 'axios'
import {
    PROJECT_CREATE_SUCCESS, PROJECT_CREATE_FAIL, PROJECT_GET_SUCCESS, PROJECT_DELETE_SUCCESS,
    PROJECT_UPDATE_SUCCESS, PROJECT_UPDATE_FAIL, PROJECT_DELETE_FAIL, PROJECT_GET_FAIL,
    PROJECT_DROP_UPDATE_SUCCESS, PROJECT_DROP_UPDATE_FAIL
} from '../types/projectType'

import {
    MEMBER_GET_SUCCESS
} from '../types/memberType'

// const token = localStorage.getItem("authToken");
import { SERVER_URI } from '../../config/keys'

import { isCardExist } from '../../HelperFunctions/CardExist'
import { DispatchBlocker } from '../DispatchBlocker/DispatchBlocker'

export const userAuth = (token) => {
    axios.interceptors.request.use(
        config => {
            config.headers.authorization = `Bearer ${token}`;
            return config;
        },
        error => {
            return Promise.reject(error)
        }
    )
}

export const createProject = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        // Accessing the current state
        const currentState = getState();
        if(isCardExist(currentState.projects.projects, datas.projectName, 'Project')){
            dispatch({
                type: PROJECT_CREATE_FAIL,
                payload: {
                    projectError: `Project with this name already exist!`
                }
            })
            return;
        }

        // const url = 'http://localhost:8080'
        let token = localStorage.getItem('authToken')
        try {
            userAuth(token)
            // console.log(datas)
            const response = await axios.post(`${SERVER_URI}/user/project/create`, datas)
            // console.log(response.data)

            dispatch({
                type: PROJECT_CREATE_SUCCESS,
                payload: {
                    projects: response.data.projects,
                    ProjectSuccessMessage: response.data.message,
                    cuProjectId: response.data.data._id
                }
            })

            dispatch({
                type: MEMBER_GET_SUCCESS,
                payload: {
                    members: response.data.members
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: PROJECT_CREATE_FAIL,
                    payload: {
                        projectError: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: PROJECT_CREATE_FAIL,
                    payload: {
                        projectError: error.message
                    }
                })
            }
        }
    }
}

export const getProject = (datas) => {
    return async (dispatch) => {
        // const url = 'http://localhost:8080'
        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'adminId': datas.userId,
                'teamId': datas.teamId,
                'curproject': datas.curproject,
                'projectsData': datas.projectsData,
                'url': `${SERVER_URI}/user/project/get`
            }
        }
        // console.log(config)
        try {
            const token = localStorage.getItem("authToken");
            // console.log(token)
            userAuth(token)
            const response = await axios.get(`${SERVER_URI}/user/project/get`, config)
            // console.log( response.data)
            // localStorage.setItem('authToken', response.data.data.token)

            dispatch({
                type: PROJECT_GET_SUCCESS,
                payload: {
                    projectSuccessMessage: response.data.message,
                    projects: response.data.data
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: PROJECT_GET_FAIL,
                    payload: {
                        projectError: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: PROJECT_GET_FAIL,
                    payload: {
                        projectError: error.message
                    }
                })
            }
        }
    }
}

export const updateProject = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        // Accessing the current state
        const currentState = getState();
        if(isCardExist(currentState.projects.projects, datas.projectName, 'Project', datas._id)){
            dispatch({
                type: PROJECT_CREATE_FAIL,
                payload: {
                    projectError: `Project with this name already exist!`
                }
            })
            return;
        }
        
        // const url = 'http://localhost:8080'
        let token = localStorage.getItem('authToken')
        try {
            // console.log(datas)
            userAuth(token)
            const response = await axios.put(`${SERVER_URI}/user/project/update`, datas)

            dispatch({
                type: PROJECT_UPDATE_SUCCESS,
                payload: {
                    projects: response.data.projects,
                    ProjectSuccessMessage: response.data.message,
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: PROJECT_UPDATE_FAIL,
                    payload: {
                        projectError: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: PROJECT_UPDATE_FAIL,
                    payload: {
                        projectError: error.message
                    }
                })
            }
        }
    }
}

export const dropUpdateProject = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        let token = localStorage.getItem('authToken')
        try {
            // console.log(datas)
            userAuth(token)
            const response = await axios.put(`${SERVER_URI}/user/project/update`, datas)

            dispatch({
                type: PROJECT_DROP_UPDATE_SUCCESS,
                payload: {
                    projects: response.data.projects,
                    ProjectSuccessMessage: response.data.message,
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: PROJECT_DROP_UPDATE_FAIL,
                    payload: {
                        projectError: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: PROJECT_DROP_UPDATE_FAIL,
                    payload: {
                        projectError: error.message
                    }
                })
            }
        }
    }
}

export const deleteProject = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        let token = localStorage.getItem('authToken')
        let projectSort = localStorage.getItem('projectSort')
        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'adminId': datas.adminId,
                'teamId': datas.teamId,
                'url': `${SERVER_URI}/user/project/delete`,
                'projectId': datas.projectId,
                'projectSort': projectSort,
                'softdelete': datas.softdelete
            }
        }
        try {
            // console.log(datas)
            userAuth(token)
            const response = await axios.delete(`${SERVER_URI}/user/project/delete`, config)

            dispatch({
                type: PROJECT_DELETE_SUCCESS,
                payload: {
                    projects: response.data.projects,
                    ProjectSuccessMessage: response.data.message,
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: PROJECT_DELETE_FAIL,
                    payload: {
                        projectError: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: PROJECT_DELETE_FAIL,
                    payload: {
                        projectError: error.message
                    }
                })
            }
        }
    }
}