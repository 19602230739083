export const PROJECT_CREATE_SUCCESS = 'PROJECT_CREATE_SUCCESS'
export const PROJECT_CREATE_FAIL = 'PROJECT_CREATE_FAIL'
export const PROJECT_GET_SUCCESS = 'PROJECT_GET_SUCCESS'
export const PROJECT_GET_FAIL = 'PROJECT_GET_FAIL'
export const PROJECT_DELETE_SUCCESS = 'PROJECT_DELETE_SUCCESS'
export const PROJECT_UPDATE_SUCCESS = 'PROJECT_UPDATE_SUCCESS'
export const PROJECT_UPDATE_FAIL = 'PROJECT_UPDATE_FAIL'
export const PROJECT_DELETE_FAIL = 'PROJECT_DELETE_FAIL'
export const PROJECT_DROP_UPDATE_SUCCESS = 'PROJECT_DROP_UPDATE_SUCCESS'
export const PROJECT_DROP_UPDATE_FAIL = 'PROJECT_DROP_UPDATE_FAIL'


export const PROJECT_ID_CLEAR = 'PROJECT_ID_CLEAR'


export const PROJECT_SUCCESS_MESSAGE_CLEAR = 'PROJECT_SUCCESS_MESSAGE_CLEAR'

export const PROJECT_ERROR_CLEAR = 'PROJECT_ERROR_CLEAR'
export const SIGN_OUT = 'SIGN_OUT'