import React from "react";
import Select from "react-select";

export default function DefaultRoleOwners({
  value,
  setValue,
  workloadState,
  isExtPerson,
  handleWorkloadChange,
  numberInputOnWheelPreventChange,
}) {
  return (
    <div className="form-group role-owners ms default-role-owners">
      <div className="row">
        <div className="col-xs-9">
          <h4>Owners</h4>
        </div>
        <div className="col-xs-3">
        {value && value.length > 0 && (<h4>FTE</h4>)}
        </div>
      </div>
      <div className="row">
        {value && value.length === 0 && (<div className="col-xs-12"><p>Owners will be automatically added</p></div>)}
        {value && value.length > 0 && (
          <div className="col-xs-9" style={{ paddingRight: 0 }}>
            <Select
              closeMenuOnSelect={false}
              classNamePrefix="select"
              className="multiple-select-container"
              // components={animatedComponents}
              isClearable
              isSearchable
              // onChange={(newValue) => handelSelect(newValue)}
              defaultValue={
                value && value.length > 0
                  ? value.map((cumentor, cmindex) => {
                    // console.log(cumentor)
                    return {
                      label: (
                        <div style={{ fontSize: "14px" }}>
                          {cumentor.value && isExtPerson(cumentor.value) ? (
                            <>
                              <span
                                className="custom-badge blue"
                                style={{
                                  display: "inline-block",
                                }}
                              >
                                EXT
                              </span>{" "}
                              {cumentor.label}
                            </>
                          ) : (
                            cumentor.label
                          )}
                        </div>
                      ),
                      value: cumentor.value,
                    };
                  })
                  : null
              }
              isMulti
              isDisabled
            // options={options}
            // components={{ Option: CustomOption }}
            // menuIsOpen={true}
            />
          </div>
        )}
        <div className="col-xs-3">
          {value &&
            value.length > 0 &&
            value.map((sv, index) => {
              // console.log(sv)
              // Find the corresponding plan value from workloadState based on ownerId (sv.value)
              const matchingWorkload = workloadState.find(
                (item) => item && item.ownerId === sv.value
              );
              // Extract the plan value or use an empty string if it doesn't exist
              const planValue = matchingWorkload ? matchingWorkload.plan : "";

              return (
                <input
                  key={index}
                  type="number"
                  id={`plan-${index}`}
                  className="form-control fte"
                  name={`plan-${index}`}
                  placeholder="Max: 1"
                  value={planValue !== 0 ? planValue : ""}
                  step="any"
                  disabled={index === value.length}
                  onFocus={(e) =>
                    index === value.length &&
                    e.target.removeAttribute("readOnly")
                  }
                  onChange={(e) => {
                    const newValue = e.target.value;
                    if (newValue && index === value.length) {
                      // Update the value state to include the new entry
                      const newValues = [...value, { value: newValue }];
                      setValue(newValues);
                      handleWorkloadChange({ value: newValue }, newValue);
                    } else {
                      handleWorkloadChange(sv, newValue);
                    }
                  }}
                  onWheel={numberInputOnWheelPreventChange}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
}
