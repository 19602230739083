import {
    ACCESS_MANAGEMENT_GET_SUCCESS, ACCESS_MANAGEMENT_GET_FAIL, ACCESS_MANAGEMENT_DELETE_SUCCESS, ACCESS_MANAGEMENT_DELETE_FAIL, ACCESS_MANAGEMENT_UPDATE_SUCCESS, ACCESS_MANAGEMENT_UPDATE_FAIL,
    ACCESS_MANAGEMENT_SUCCESS_MESSAGE_CLEAR, ACCESS_MANAGEMENT_ERROR_MESSAGE_CLEAR, ACCESS_MANAGEMENT_CREATE_SUCCESS
} from '../types/accessManagementTypes'


const userState = {
    accessManagementData: [],
    accessManagementSuccessMessage: null,
    // tagsMessagee: '',
    accessManagementErrorMessage: null
}


export const accessManagementReducer = (state = userState, action) => {
    const { type, payload } = action

    // if (type === ACCESS_MANAGEMENT_GET_SUCCESS) {
    //     return {
    //         ...state,
    //         accessManagementData: payload.tag,
    //         accessManagementSuccessMessage: payload.successMessage
    //     }
    // }

    // if (type === ACCESS_MANAGEMENT_CREATE_SUCCESS) {
    //     return {
    //         ...state,
    //         tagsMessagee: payload.successMessage
    //     }
    // }

    // if (type === ACCESS_MANAGEMENT_DELETE_SUCCESS) {
    //     return {
    //         ...state,
    //         tagsMessagee: payload.tagsSuccessMessage
    //     }
    // }

    if (type === ACCESS_MANAGEMENT_GET_SUCCESS) {
        return {
            ...state,
            accessManagementData: payload.data,
        }
    }
    if (type === ACCESS_MANAGEMENT_GET_FAIL) {
        return {
            ...state,
            accessManagementErrorMessage: payload.error
        }
    }

    if (type === ACCESS_MANAGEMENT_UPDATE_SUCCESS) {
        return {
            ...state,
            accessManagementSuccessMessage: payload.successMessage
        }
    }
    if (type === ACCESS_MANAGEMENT_UPDATE_FAIL) {
        return {
            ...state,
            accessManagementErrorMessage: payload.error
        }
    }



    // if (type === ACCESS_MANAGEMENT_DELETE_FAIL) {
    //     return {
    //         ...state,
    //         accessManagementErrorMessage: payload.error
    //     }
    // }

    if (type === ACCESS_MANAGEMENT_SUCCESS_MESSAGE_CLEAR) {
        return {
            ...state,
            accessManagementSuccessMessage: null
        }
    }
    if (type === ACCESS_MANAGEMENT_ERROR_MESSAGE_CLEAR) {
        return {
            ...state,
            accessManagementErrorMessage: null
        }
    }

    // if (type === SIGN_OUT) {
    //     return {
    //         ...state,
    //         accessManagementData: [],
    //         accessManagementSuccessMessage: '',
    //         accessManagementErrorMessage: ''
    //     }
    // }

    return state;
}