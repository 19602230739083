import React from "react";
import { useDispatch, useSelector } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import { historyGet } from "../../../../store/actions/historyAction";
import { HISTORY_MESSAGE_CLEAR, HISTORY_DATAS_CLEAR } from "../../../../store/types/historyType";
import Objectives from "../../Body/Model/Objectives";
import Documents from "../../Body/Model/Documents";
import { GetAliasesName } from "../../../../HelperFunctions/GetAliasesName";

export default function ProjectModal({ data, closeProject, isAllView,teams }) {
  const { error, successMessage, reportInfo, reportTeam, reportPeople, reportSkill, reportRole, reportDomain, reportLink, reportCircle, reportProject, reportMeeting } = useSelector((state) => state.authReport);
  const { historys, historyMessagee, historyError, historyMessage } = useSelector((state) => state.historys);
  const { pfmembers, pfmemberMessagee, pfmemberError, pfmemberMessage } = useSelector((state) => state.pfmembers);
  const { members } = useSelector((state) => state.members);
  const { circles, dCircles, cuCircleId, circleMessagee, circleError } = useSelector((state) => state.circles);
  const { projects, cuProjectId, projectMessagee, projectError } = useSelector((state) => state.projects);
  // console.log(circles);
  const dispatch = useDispatch();

  const pMmembes = (curCoresMembers, curExtendedsMembers) => {
    //People
    let CcorfoPeople = [];
    if (curCoresMembers && curCoresMembers.People.length > 0) {
      for (let i = 0; i < reportPeople.length; i++) {
        let inc = curCoresMembers.People.includes(reportPeople[i]._id);
        if (inc) {
          CcorfoPeople.push(reportPeople[i]);
        }
      }
    }

    // console.log(CcorfoPeople)
    let CextfoPeople = [];
    if (curExtendedsMembers && curExtendedsMembers.People.length > 0) {
      for (let i = 0; i < reportPeople.length; i++) {
        let inc = curExtendedsMembers.People.includes(reportPeople[i]._id);
        if (inc) {
          CextfoPeople.push(reportPeople[i]);
        }
      }
    }

    //Skills
    let CcorfoSkill = [];
    if (curCoresMembers && curCoresMembers.Skills.length > 0) {
      for (let i = 0; i < reportSkill.length; i++) {
        let inc = curCoresMembers.Skills.includes(reportSkill[i]._id);
        if (inc) {
          CcorfoSkill.push(reportSkill[i]);
        }
      }
    }

    // console.log(CcorfoPeople)

    let CextfoSkill = [];
    if (curExtendedsMembers && curExtendedsMembers.Skills.length > 0) {
      for (let i = 0; i < reportSkill.length; i++) {
        let inc = curExtendedsMembers.Skills.includes(reportSkill[i]._id);
        if (inc) {
          CextfoSkill.push(reportSkill[i]);
        }
      }
    }

    //Roles
    let CcorfoRoles = [];
    if (curCoresMembers && curCoresMembers.Roles.length > 0) {
      for (let i = 0; i < reportRole.length; i++) {
        let inc = curCoresMembers.Roles.includes(reportRole[i]._id);
        if (inc) {
          CcorfoRoles.push(reportRole[i]);
        }
      }
    }

    // console.log(CcorfoPeople)
    let CextfoRoles = [];
    if (curExtendedsMembers && curExtendedsMembers.Roles.length > 0) {
      for (let i = 0; i < reportRole.length; i++) {
        let inc = curExtendedsMembers.Roles.includes(reportRole[i]._id);
        if (inc) {
          CextfoRoles.push(reportRole[i]);
        }
      }
    }

    //Links
    let CcorfoLinks = [];
    if (curCoresMembers && curCoresMembers.Links.length > 0) {
      for (let i = 0; i < reportLink.length; i++) {
        let inc = curCoresMembers.Links.includes(reportLink[i]._id);
        if (inc) {
          CcorfoLinks.push(reportLink[i]);
        }
      }
    }

    // console.log(CcorfoPeople)
    let CextfoLinks = [];
    if (curExtendedsMembers && curExtendedsMembers.Links.length > 0) {
      for (let i = 0; i < reportLink.length; i++) {
        let inc = curExtendedsMembers.Links.includes(reportLink[i]._id);
        if (inc) {
          CextfoLinks.push(reportLink[i]);
        }
      }
    }

    // console.log(CextfoPeople)
    //Domains
    let CcorfoDomains = [];
    if (curCoresMembers && curCoresMembers.Domains.length > 0) {
      for (let i = 0; i < reportDomain.length; i++) {
        let inc = curCoresMembers.Domains.includes(reportDomain[i]._id);
        if (inc) {
          CcorfoDomains.push(reportDomain[i]);
        }
      }
    }

    // console.log(CcorfoPeople)
    let CextfoDomains = [];
    if (curExtendedsMembers && curExtendedsMembers.Domains.length > 0) {
      for (let i = 0; i < reportDomain.length; i++) {
        let inc = curExtendedsMembers.Domains.includes(reportDomain[i]._id);
        if (inc) {
          CextfoDomains.push(reportDomain[i]);
        }
      }
    }

    //Circles
    let PrcorfoCircles = [];
    if (curCoresMembers && curCoresMembers.Circles.length > 0) {
      for (let i = 0; i < circles.length; i++) {
        // console.log(261, curCoresMembers.Circles, circles[i]._id);
        let inc = curCoresMembers.Circles.includes(circles[i]._id);
        if (inc) {
          PrcorfoCircles.push(circles[i]);
        }
      }
    }

    // console.log(CextfoPeople)

    let curData = {};

    if (
      CcorfoPeople.length > 0 ||
      CextfoPeople.length > 0 ||
      CcorfoSkill.length > 0 ||
      CextfoSkill.length > 0 ||
      CcorfoRoles.length > 0 ||
      CextfoRoles.length > 0 ||
      CcorfoLinks.length > 0 ||
      CextfoLinks.length > 0 ||
      CcorfoDomains.length > 0 ||
      CextfoDomains.length > 0 ||
      PrcorfoCircles.length > 0
    ) {
      curData = {
        coreMembers: {
          People: CcorfoPeople,
          Skills: CcorfoSkill,
          Roles: CcorfoRoles,
          Links: CcorfoLinks,
          Domains: CcorfoDomains,
          Circles: PrcorfoCircles,
        },
        extendedMembers: {
          People: CextfoPeople,
          Skills: CextfoSkill,
          Roles: CextfoRoles,
          Links: CextfoLinks,
          Domains: CextfoDomains,
        },
      };
    }
    return curData;
  };


  let curStandIn = {};
  if (data && data.standIn && data.standIn.length > 0 && reportPeople && reportPeople.length > 0) {
    for (let i = 0; i < reportPeople.length; i++) {
      if (reportPeople[i]._id === data.standIn) {
        curStandIn = reportPeople[i];
      }
    }
  }

  // lead
  let curLead = null;
  if (data && data.lead && data.lead.length > 0 && reportPeople && reportPeople.length > 0) {
    const personLead = reportPeople.find(people => people._id === data.lead);
    if (personLead) {
      curLead = `${personLead.fname} ${personLead.lname}`;
    }

    const skillLead = reportSkill.find(skill => skill._id === data.lead)
    if (skillLead) {
      curLead = `${skillLead.skillName} (${GetAliasesName(teams, "Skills")})`;
      const person = reportPeople.find(people => people._id === skillLead.owners[0]);
      if (person) {
        curLead += `<span class="blue-text">${person.fname} ${person.lname}</span>`;
      }
    }

    const roleLead = reportRole.find(role => role._id === data.lead)
    if (roleLead) {
      curLead = `${roleLead.roleName} (${GetAliasesName(teams, "Roles")})`;
      const person = reportPeople.find(people => people._id === roleLead.owners[0]);
      if (person) {
        curLead += `<span class="custom-badge blue-text">${person.fname} ${person.lname}</span>`;
      }
    }
  }

  // console.log(curLead);

  // ADMINISTRATION
  let curAdministration = [];
  if (data && data.administration && data.administration.length > 0 && reportPeople.length > 0) {
    for (let i = 0; i < data.administration.length; i++) {
      for (let j = 0; j < reportPeople.length; j++) {
        if (data.administration[i].owner === reportPeople[j]._id) {
          curAdministration.push({
            statusReport: data.administration[i].statusReport,
            ownerId: reportPeople[j]._id,
            ownerName: `${reportPeople[j].fname} ${reportPeople[j].lname}`,
          });
        }
      }
    }
  }

  var curPModeData = {};
  if (data && data._id.length > 0) {
    if (!isAllView) {
      for (let i = 0; i < reportProject.length; i++) {
        let curCoresMembers = {};
        let curExtendedsMembers = {};
        if (reportProject[i].memberId === data._id) {
          curCoresMembers = reportProject[i].members.coreMembers;
          curExtendedsMembers = reportProject[i].members.extendedMembers;
          let res = pMmembes(curCoresMembers, curExtendedsMembers);
          if (res) {
            curPModeData = res;
            break;
          }
        }
      }
    } else {
      for (let i = 0; i < reportProject.length; i++) {
        let curCoresMembers = {};
        let curExtendedsMembers = {};
        if (reportProject[i].memberId === data._id) {
          curCoresMembers = reportProject[i].members.coreMembers;
          curExtendedsMembers = reportProject[i].members.extendedMembers;
          let res = pMmembes(curCoresMembers, curExtendedsMembers);
          if (res) {
            curPModeData = res;
            break;
          }
        }
      }
    }
  }

  let cardData = {}
  if (data && data.lead && data.lead.length > 0) {
    let res = { people: null, rAndS: null, curType: null }
    let cardDatas = {}
    if (res && res.rAndS === null && reportSkill && reportSkill.length > 0) {
      for (let i = 0; i < reportSkill.length; i++) {
        if (reportSkill[i]._id === data.lead) {
          res = { ...res, ["rAndS"]: reportSkill[i]._id, ["curType"]: "Skills" }
          cardDatas = reportSkill[i]
          break;
        }
      }
    }
    if (res && res.rAndS === null && reportRole && reportRole.length > 0) {
      for (let i = 0; i < reportRole.length; i++) {
        if (reportRole[i]._id === data.lead) {
          res = { ...res, ["rAndS"]: reportRole[i]._id, ["curType"]: "Roles" }
          cardDatas = reportRole[i]
          break;
        }
      }
    }
    if (res && res.rAndS === null) {
      if (reportPeople && reportPeople.length > 0) {
        for (let i = 0; i < reportPeople.length; i++) {
          if (reportPeople[i]._id === data.lead) {
            res = { ...res, ["people"]: reportPeople[i]._id, ["curType"]: "People" }
            break;
          }
        }
      }
    } else if (res && res.rAndS && res.rAndS.length > 0 && cardDatas && Object.keys(cardDatas).length > 0) {
      if ((cardDatas.ownerRole === "Single owner" || cardDatas.ownerType === "Single owner") && cardDatas.owners && cardDatas.owners.length > 0) {
        if (reportPeople && reportPeople.length > 0) {
          for (let i = 0; i < reportPeople.length; i++) {
            if (reportPeople[i]._id === cardDatas.owners[0]) {
              res = { ...res, ["people"]: reportPeople[i]._id }
              break;
            }
          }
        }
      }
    }
    cardData = res
  }

  /*
   * Get the owner names to show in blue text pills 
   @parameters 
   # data: skill/ role card data 
   # cpid: circle/ project id
   # type: core/ extended
   */
  const getAllOwnerFCM = (data, cpId = false, type = false) => {
    let allData = [];

    if (data && Object.keys(data).length > 0 && data.owners && data.owners.length > 0) {
      // console.log(data, cpId, type, pfmembers);
      let currentPfmembers = pfmembers.find(pfm => pfm.memberType === cpId);
      if (currentPfmembers) {
        // If the skill/ role is in the allOwners flag array
        if (currentPfmembers.allOwnersFlag[type].includes(data._id)) {
          // console.log('all owners');
          /* if (reportPeople && reportPeople.length > 0) {
            for (let i = 0; i < data.owners.length; i++) {
              for (let j = 0; j < reportPeople.length; j++) {
                if (data.owners[i] === reportPeople[j]._id) {
                  allData.push(reportPeople[j])
                  break;
                }
              }
            }
          } */
        }
        // If the skill/ role is not in the allOwners flag array
        else {
          // console.log('not all owners');
          for (let i = 0; i < data.owners.length; i++) {
            for (let j = 0; j < reportPeople.length; j++) {
              if (type === 'core') {
                let item = currentPfmembers.coreMembers.Skills.filter(sk => (sk.CardId === data._id && sk.peopleIds === reportPeople[j]._id));
                if (item && item.length > 0 && !allData.includes(reportPeople[j])) {
                  allData.push(reportPeople[j])
                  break;
                }

                item = item.length === 0 ? currentPfmembers.coreMembers.Roles.filter(rk => (rk.CardId === data._id && rk.peopleIds === reportPeople[j]._id)) : [];
                if (item && item.length > 0 && !allData.includes(reportPeople[j])) {
                  allData.push(reportPeople[j])
                  break;
                }
              }

              if (type === 'extended') {
                let item = currentPfmembers.extendedMembers.Skills.filter(sk => (sk.CardId === data._id && sk.peopleIds === reportPeople[j]._id));
                if (item && item.length > 0 && !allData.includes(reportPeople[j])) {
                  allData.push(reportPeople[j])
                  break;
                }

                item = item.length === 0 ? currentPfmembers.extendedMembers.Roles.filter(rk => (rk.CardId === data._id && rk.peopleIds === reportPeople[j]._id)) : [];
                if (item && item.length > 0 && !allData.includes(reportPeople[j])) {
                  allData.push(reportPeople[j])
                  break;
                }
              }

            }
          }

        }
      }
    }

    return allData;
  }

  const sSIsExtrnal = (e) => {
    if (e.ownerType === "Single owner") {
      for (let i = 0; i < reportPeople.length; i++) {
        if (e.owners[0] === reportPeople[i]._id) {
          if (reportPeople[i].workRole === "External") {
            return true;
          }
        }
      }
    } else if (e.ownerType === "Multiple owners") {
      let count = 0;
      for (let i = 0; i < reportPeople.length; i++) {
        let inc = e.owners.includes(reportPeople[i]._id);
        if (inc) {
          if (reportPeople[i].workRole === "External") {
            count++;
          }
        }
      }
      if (count === e.owners.length) {
        return true;
      }
    }
    return false;
  };

  const sRIsExtrnal = (e) => {
    if (e.owners && e.owners.length > 0) {
      if (e.ownerRole === "Single owner") {
        for (let i = 0; i < reportPeople.length; i++) {
          if (e.owners[0] === reportPeople[i]._id) {
            if (reportPeople[i].workRole === "External") {
              return true;
            }
          }
        }
      } else if (e.ownerRole === "Multiple owners") {
        let count = 0;
        for (let i = 0; i < reportPeople.length; i++) {
          let inc = e.owners.includes(reportPeople[i]._id);
          if (inc) {
            if (reportPeople[i].workRole === "External") {
              count++;
            }
          }
        }
        if (count === e.owners.length) {
          return true;
        }
      }
    }
    return false;
  };

  const sDIsExtrnal = (e) => {
    if (e.owners && e.owners.owner && e.owners.owner.length > 0) {
      for (let i = 0; i < reportPeople.length; i++) {
        if (e.owners.owner === reportPeople[i]._id) {
          if (reportPeople[i].workRole === "External") {
            return true;
          }
        }
      }
    }

    return false;
  };

  const getOwnerTypeFCM = (data) => {
    if (data && Object.keys(data).length > 0 && data.owners && data.owners.type) {
      let curdata = { type: null, cardId: null, name: null }
      if (reportSkill && reportSkill.length > 0) {
        for (let i = 0; i < reportSkill.length; i++) {
          if (reportSkill[i]._id === data.owners.type) {
            curdata = { ...curdata, ["type"]: "Skill", ["cardId"]: data.owners.type, ["name"]: `${reportSkill[i].skillName}` }
          }
        }
      }
      if (curdata.type === null && reportRole && reportRole.length > 0) {
        for (let i = 0; i < reportRole.length; i++) {
          if (reportRole[i]._id === data.owners.type) {
            curdata = { ...curdata, ["type"]: "Role", ["cardId"]: data.owners.type, ["name"]: `${reportRole[i].roleName}` }
          }
        }
      }
      return curdata
    }
  }

  const sLIsExtrnal = (e) => {
    if (e.owner && e.owner.length > 0) {
      for (let i = 0; i < reportPeople.length; i++) {
        if (e.owner === reportPeople[i]._id) {
          if (reportPeople[i].workRole === "External") {
            return true;
          }
        }
      }
    }

    return false;
  };

  let meetingsData = [];
  if (data && data.meetings && data.meetings.length > 0 && reportMeeting.length > 0) {
    for (let i = 0; i < data.meetings.length; i++) {
      for (let j = 0; j < reportMeeting.length; j++) {
        if (data.meetings[i] === reportMeeting[j]._id) {
          meetingsData.push(reportMeeting[j]);
        }
      }
    }
  }

  const historysGet = (data) => {
    if (historys && historys.length === 0) {
      dispatch(historyGet({ cardId: data }));
    } else {
      dispatch({ type: HISTORY_DATAS_CLEAR });
    }
  };

  let historysMessage = [];

  if (historys && historys.length > 0) {
    for (let i = 0; i < historys.length; i++) {
      if (historys[i].cardStatus === "created") {
        let str = `<i>${historys[i].hcTime}</i> : Created`;
        historysMessage.push(str);
      } else if (historys[i].cardStatus === "added") {
        let str =
          `<i>${historys[i].hcTime}</i> :  ${historys[i].field} added ` +
          " (" +
          `<b>${historys[i].next}</b>` +
          ")";
        historysMessage.push(str);
      } else if (historys[i].cardStatus === "changed") {
        let str =
          `<i>${historys[i].hcTime}</i> :  ${historys[i].field} changed from ` +
          `<b>${historys[i].prev}</b>` +
          " to " +
          `<b>${historys[i].next}</b>`;
        historysMessage.push(str);
      } else if (historys[i].cardStatus === "removed") {
        let str =
          `<i>${historys[i].hcTime}</i> :  ${historys[i].field} removed ` +
          " (previously: " +
          `<b>${historys[i].next}</b>` +
          ")";
        historysMessage.push(str);
      }
    }
  }

  const CloseProjectModal = () => {
    dispatch({ type: HISTORY_DATAS_CLEAR });
    closeProject();
  };

  // console.log(curPModeData);

  return (
    <div id="projectModal" role="dialog" data-backdrop="static" data-keyboard="false">
      <div className="modal-dialog modal-lg modal-dialog-scrollable">
        <form className="form">
          <div className="modal-content">

            <div className="modal-header header-green">
              <h4 className="modal-title">
                <img alt="" src="../images/icon-project.png" style={{ width: "17px", marginTop: "-3px", marginRight: "5px" }} />
                {/* PROJECT */GetAliasesName(teams, "Projects").toUpperCase()}
              </h4>
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">

                  <div className="form-group">
                    <div className="input-group mb-3">
                      <div
                        name="project_name"
                        className="form-control tool-name"
                        placeholder="Project name"
                      >
                        {data.projectName}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <h4>Purpose</h4>
                    {data.purpose && (
                      <TextareaAutosize
                        cacheMeasurements
                        type="text"
                        value={data.purpose}
                        className="form-control purpose logit"
                        name="project_purpose"
                        placeholder="+ Add purpose"
                        disabled
                        readOnly
                      />
                    )}
                  </div>

                  <div className="form-group" data-id="task-list">
                    <h4>Tasks</h4>
                    {data.tasks && data.tasks.length > 0 && (
                      <div className="row task-list">
                        {data.tasks && data.tasks.length > 0
                          ? data.tasks.map((r, tindex) => {
                            return (
                              <div
                                className="col-xs-12 task"
                                key={tindex}
                                style={{ position: "relative" }}
                              >
                                <TextareaAutosize
                                  cacheMeasurements
                                  id={`task${tindex}`}
                                  type="text"
                                  placeholder="+ Add task"
                                  name="tasks"
                                  className="form-control logit"
                                  value={r}
                                  disabled
                                  readOnly
                                >
                                  <span className="fa fa-circle"> </span>
                                </TextareaAutosize>
                              </div>
                            );
                          })
                          : null}
                      </div>
                    )}
                  </div>

                  <div className="form-group objectives">
                    <Objectives data={data} project={data} type="REPORT_DATA"/>
                  </div>

                  <div className="form-group meetings" data-id="meetings">
                    <h4>Meetings</h4>
                    <div className="meeting-list meeting-container">
                      {meetingsData && meetingsData.length > 0
                        ? meetingsData.map((e, index) => {
                          return (
                            <div key={index} className="meeting" >
                              <div className="meeting-content">
                                <p>
                                  <b>{e.meetingsName}</b>
                                </p>
                                <p>{e.meetingsPurpose}</p>
                                {e.recurrenceType === "As needed" || e.durationType === "As needed" ? (
                                  <>
                                    <p>
                                      {e.recurrenceType} {e.durationType}
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    {" "}
                                    {e.recurrence && (
                                      <p>
                                        Every {e.recurrence} {e.recurrenceType}{" "}
                                        {e.duration && (
                                          <span>
                                            for {e.duration} {e.durationType}
                                          </span>
                                        )}
                                      </p>
                                    )}
                                  </>
                                )}
                                <p>{e.XTD ? "incl. XTD" : null}</p>
                              </div>
                            </div>
                          );
                        })
                        : null}
                    </div>
                  </div>

                </div>
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group" data-id="lead">
                        <h4>Lead</h4>
                        <ul className="list-of-member">
                          {curLead && curLead !== null ? (
                            <li dangerouslySetInnerHTML={{ __html: curLead }}></li>
                          ) : null}

                          {curStandIn && Object.keys(curStandIn).length > 0 ? (
                            <li>
                              Stand In:{" "}
                              {`${curStandIn.fname} ${curStandIn.lname}`}{" "}
                            </li>
                          ) : null}

                          {data.leadToDos && data.leadToDos.length > 0 ? (
                            <li>
                              Lead Tasks:
                              <ul className="list-of-member">
                                {data.leadToDos && data.leadToDos.length > 0
                                  ? data.leadToDos.map((e, index) => {
                                    return <li key={index}>{e}</li>;
                                  })
                                  : null}
                              </ul>
                            </li>
                          ) : null}
                        </ul>
                      </div>

                      <div className="form-group circles" data-id="circles">
                        <h4>Circles</h4>
                        <ul className="list-of-member">
                          {curPModeData.coreMembers && curPModeData.coreMembers.Circles && curPModeData.coreMembers.Circles.length > 0
                            ? curPModeData.coreMembers.Circles.map(
                              (cr, indexs) => {
                                return (
                                  <li key={indexs}>{cr.circleName}</li>
                                );
                              }
                            )
                            : null}
                        </ul>
                      </div>
                      <Documents data={data} type="MY-REPORT" />

                      <div className="form-group" data-id="admins">
                        <h4>Administration</h4>
                        <ul className="list-of-member">
                          {curAdministration && curAdministration.length > 0
                            ? curAdministration.map((e, index) => {
                              return (
                                <li key={index}>
                                  <strong>{e.statusReport}</strong>,{" "}
                                  {e.ownerName}
                                </li>
                              );
                            })
                            : null}
                        </ul>
                      </div>

                      {data.tags && data.tags.length > 0 && (
                        <div className="form-group">
                          <h4>Tags</h4>
                          <div
                            id="tagator_"
                            className="tagator_element options-hidden"
                            style={{
                              padding: "1px 2px",
                              "flex-grow": "0",
                              position: "relative",
                            }}
                          >
                            <span
                              className="tagator_textlength"
                              style={{
                                position: "absolute",
                                visibility: "hidden",
                              }}
                            ></span>
                            <div className="tagator_tags">
                              {data.tags && data.tags.length > 0
                                ? data.tags.map((e, index) => {
                                  return (
                                    <div key={index} className="tagator_tag">
                                      {e}
                                      <div style={{ clear: "both" }}></div>
                                    </div>
                                  );
                                })
                                : null}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-md-6 members">
                      <div className="form-group">
                        <h4>Members</h4>
                      </div>
                      <div className="member-in">
                        <div
                          className="list list-bg-red"
                          data-list_id="projects"
                          style={{ padding: "5px", borderRadius: "5px" }}
                        >
                          <div className="card-list scrollbar">
                            <div className="project-tags">
                              {data.tags && data.tags.length > 0
                                ? data.tags.map((e, index) => {
                                  return (
                                    <span
                                      key={index}
                                      className="custom-badge green tags"
                                    >
                                      {e}
                                    </span>
                                  );
                                })
                                : null}
                            </div>

                            <h6 style={{ borderBottom: "1px solid #000" }} >
                              Core members
                            </h6>

                            <div className="panel panel-default">
                              <div id="core" className="panel-body single box-container"  >
                                {curPModeData.coreMembers && curPModeData.coreMembers.People && curPModeData.coreMembers.People.length > 0
                                  ? curPModeData.coreMembers.People.map((p, indexs) => {
                                    let dLead = false;
                                    let udLead = false;
                                    if (cardData && Object.keys(cardData).length > 0) {
                                      if (cardData.people && cardData.people.length > 0 && cardData.people === p._id) {
                                        if (cardData.curType === "People" && cardData.rAndS === null) {
                                          dLead = true
                                        } else if ((cardData.curType === "Roles" || cardData.curType === "Skills") && cardData.rAndS) {
                                          udLead = true
                                        }
                                      }
                                    }
                                    return (
                                      <div key={indexs} className="card">
                                        <span className="static-relations">
                                          <span className="custom-badge lightgray">
                                            Person
                                          </span>

                                          {p.workRole === "External" ? (
                                            <span className="custom-badge blue ext-owner-pill">
                                              EXT
                                            </span>
                                          ) : null}
                                          {dLead ? (
                                            <span className="custom-badge red-o direct-lead">
                                              LEAD
                                            </span>
                                          ) : null}
                                          {udLead ? (
                                            <span className="custom-badge gray-o">
                                              LEAD
                                            </span>
                                          ) : null}
                                          {p.tags && p.tags.length > 0
                                            ? p.tags.map((r, indext) => (
                                              <span key={indext} className="custom-badge green tags">
                                                {r}
                                              </span>
                                            ))
                                            : null}
                                        </span>
                                        <a className="card-edit" >{`${p.fname} ${p.lname}`}</a>
                                      </div>
                                    );
                                  })
                                  : null}

                                {curPModeData.coreMembers && curPModeData.coreMembers.Skills && curPModeData.coreMembers.Skills.length > 0
                                  ? curPModeData.coreMembers.Skills.map((s, indexs) => {
                                    let isExternal = sSIsExtrnal(s);
                                    let dLead = false;
                                    if (cardData && Object.keys(cardData).length > 0) {
                                      if (cardData.rAndS && cardData.rAndS.length > 0 && cardData.rAndS === s._id) {
                                        if (cardData.curType === "Skills") {
                                          dLead = true
                                        }
                                      }
                                    }
                                    let allOwner = getAllOwnerFCM(s, data._id, 'core')
                                    return (
                                      <div key={indexs} className="card"
                                      >
                                        <span className="static-relations">
                                          <span className="custom-badge lightgray">
                                            {/* Skill */ GetAliasesName(teams, "Skills").toLowerCase()}
                                          </span>
                                          {isExternal ? (
                                            <span className="custom-badge blue ext-owner-pill">
                                              EXT
                                            </span>
                                          ) : null}
                                          {s.owners && s.owners.length === 0 ? (
                                            <span className="custom-badge no-owner-pill red">
                                              NO
                                            </span>
                                          ) : null}
                                          {s.ownerType && s.ownerType.length > 0 &&
                                            s.ownerType === "Single owner" ? (
                                            <span className="custom-badge blue-o">1</span>
                                          ) : null}
                                          {dLead ? (
                                            <span className="custom-badge red-o direct-lead">
                                              LEAD
                                            </span>
                                          ) : null}
                                          {s.tags && s.tags.length > 0
                                            ? s.tags.map((r, index) => (
                                              <span key={index} className="custom-badge green tags">
                                                {r}
                                              </span>
                                            ))
                                            : null}
                                        </span>
                                        <a className="card-edit"  >
                                          {s.skillName}
                                        </a>
                                        <br />
                                        {allOwner && allOwner.length > 0
                                          ? allOwner.map((value, pdindex) => {
                                            return (
                                              <span
                                                key={pdindex}
                                                style={{ color: "#8CC1F3", paddingLeft: '0px' }}
                                                className="custom-badge tags"
                                              >{`${value.fname} ${value.lname}`}</span>
                                            );
                                          })
                                          : null}
                                      </div>
                                    );
                                  })
                                  : null}

                                {curPModeData.coreMembers && curPModeData.coreMembers.Roles && curPModeData.coreMembers.Roles.length > 0
                                  ? curPModeData.coreMembers.Roles.map((r, indexs) => {
                                    let isExternal = sRIsExtrnal(r);
                                    let dLead = false;
                                    if (cardData && Object.keys(cardData).length > 0) {
                                      if (cardData.rAndS && cardData.rAndS.length > 0 && cardData.rAndS === r._id) {
                                        if (cardData.curType === "Roles") {
                                          dLead = true
                                        }
                                      }
                                    }

                                    let allOwner = getAllOwnerFCM(r, data._id, 'core')
                                    return (
                                      <div key={indexs} className="card">
                                        <span className="static-relations">
                                          <span className="custom-badge lightgray">
                                            {/* Role */ GetAliasesName(teams, "Roles").toLowerCase()}
                                          </span>
                                          {isExternal ? (
                                            <span className="custom-badge blue ext-owner-pill">
                                              EXT
                                            </span>
                                          ) : null}
                                          {r.owners && r.owners.length === 0 ? (
                                            <span className="custom-badge no-owner-pill red">
                                              NO
                                            </span>
                                          ) : null}
                                          {r.ownerRole.length > 0 && r.ownerRole === "Single owner" ? (
                                            <span className="custom-badge blue-o">1</span>
                                          ) : null}
                                          {dLead ? (
                                            <span className="custom-badge red-o direct-lead">
                                              LEAD
                                            </span>
                                          ) : null}
                                          {r.tags && r.tags.length > 0
                                            ? r.tags.map((t, indext) => (
                                              <span key={indext} className="custom-badge green tags">
                                                {t}
                                              </span>
                                            ))
                                            : null}
                                        </span>
                                        <a className="card-edit"  >
                                          {r.roleName}
                                        </a>
                                        <br />
                                        {allOwner && allOwner.length > 0
                                          ? allOwner.map((value, pdindex) => {
                                            return (
                                              <span
                                                key={pdindex}
                                                style={{ color: "#8CC1F3", paddingLeft: '0px' }}
                                                className="custom-badge tags"
                                              >{`${value.fname} ${value.lname}`}</span>
                                            );
                                          })
                                          : null}
                                      </div>
                                    );
                                  })
                                  : null}

                                {curPModeData.coreMembers && curPModeData.coreMembers.Domains && curPModeData.coreMembers.Domains.length > 0
                                  ? curPModeData.coreMembers.Domains.map((d, indexs) => {
                                    let curOwner = {}
                                    if (d && Object.keys(d).length > 0 && d.owners && d.owners.owner && d.owners.owner.length > 0) {
                                      if (reportPeople && reportPeople.length > 0) {
                                        for (let i = 0; i < reportPeople.length; i++) {
                                          if (reportPeople[i]._id === d.owners.owner) {
                                            curOwner = reportPeople[i]
                                            break;
                                          }
                                        }
                                      }
                                    }
                                    let isExternal = sDIsExtrnal(d);
                                    let ownerType = getOwnerTypeFCM(d)
                                    return (
                                      <div key={indexs} className="card" >
                                        <span className="static-relations">
                                          <span className="custom-badge lightgray">
                                            {/* Domain */ GetAliasesName(teams, "Domains").toLowerCase()}
                                          </span>
                                          {isExternal ? (
                                            <span className="custom-badge blue ext-owner-pill">
                                              EXT
                                            </span>
                                          ) : null}
                                          {d.owners && d.owners.owner && d.owners.owner.length > 0 ? null : (
                                            <span className="custom-badge no-owner-pill red">
                                              NO
                                            </span>
                                          )}
                                          {d.tags && d.tags.length > 0
                                            ? d.tags.map((t, indext) => (
                                              <span key={indext} className="custom-badge green tags">
                                                {t}
                                              </span>
                                            ))
                                            : null}
                                        </span>
                                        <a className="card-edit"  >
                                          {d.domainName}
                                        </a>
                                        {ownerType && ownerType.name ? <p className="owner-type">{`${ownerType.name} ( ${GetAliasesName(teams, ownerType.type)})`}</p> : null}
                                        {curOwner && Object.keys(curOwner).length > 0 ?
                                          <span
                                            style={{ color: "#8CC1F3", paddingLeft: '0px' }}
                                            className="custom-badge tags"
                                          >{`${curOwner.fname} ${curOwner.lname}`}</span>
                                          : null}
                                      </div>
                                    );
                                  })
                                  : null}

                                {curPModeData.coreMembers && curPModeData.coreMembers.Links && curPModeData.coreMembers.Links.length > 0
                                  ? curPModeData.coreMembers.Links.map((l, indexs) => {
                                    let isExternal = sLIsExtrnal(l);
                                    let curOwner = {}
                                    if (l && Object.keys(l).length > 0 && l.owner && l.owner.length > 0) {
                                      if (reportPeople && reportPeople.length > 0) {
                                        for (let i = 0; i < reportPeople.length; i++) {
                                          if (reportPeople[i]._id === l.owner) {
                                            curOwner = reportPeople[i]
                                            break;
                                          }
                                        }
                                      }
                                    }

                                    return (
                                      <div key={indexs} className="card">
                                        <span className="static-relations">
                                          <span className="custom-badge lightgray">
                                            {/* Link */ GetAliasesName(teams, "Links").toLowerCase()}
                                          </span>
                                          {isExternal ? (
                                            <span className="custom-badge blue ext-owner-pill">
                                              EXT
                                            </span>
                                          ) : null}
                                          {l.owner && l.owner.length > 0 ? null : (
                                            <span className="custom-badge no-owner-pill red">
                                              NO
                                            </span>
                                          )}
                                          {l.tags && l.tags.length > 0
                                            ? l.tags.map((t, indext) => (
                                              <span key={indext} className="custom-badge green tags">
                                                {t}
                                              </span>
                                            ))
                                            : null}
                                        </span>
                                        <a className="card-edit"  >
                                          {l.linkName}
                                        </a>
                                        {curOwner && Object.keys(curOwner).length > 0 ?
                                          <span
                                            style={{ color: "#8CC1F3", paddingLeft: '0px' }}
                                            className="custom-badge tags"
                                          >{`${curOwner.fname} ${curOwner.lname}`}</span>
                                          : null}
                                      </div>
                                    );
                                  })
                                  : null}

                                {/* {curPModeData.coreMembers &&
                                  curPModeData.coreMembers.Circles &&
                                  curPModeData.coreMembers.Circles.length >
                                  0
                                  ? curPModeData.coreMembers.Circles.map(
                                    (pr, indexs) => {
                                      return (
                                        <div
                                          key={indexs}
                                          className="card"
                                          data-card_date="2022-03-06 21:48:02"
                                          data-card_name="Devanshi"
                                        >
                                          <span className="custom-badge lightgray">
                                            Projects
                                          </span>
                                          <a
                                            className="card-edit"
                                            data-card_id="971"
                                          >
                                            {pr.projectName}
                                          </a>
                                        </div>
                                      );
                                    }
                                  )
                                  : null} */}
                              </div>
                            </div>

                            <h6 style={{ borderBottom: "1px solid #000" }}  >
                              Extended members
                            </h6>

                            <div className="panel panel-default">
                              <div id="extended" className="panel-body single box-container"  >
                                {curPModeData.extendedMembers && curPModeData.extendedMembers.People && curPModeData.extendedMembers.People.length > 0
                                  ? curPModeData.extendedMembers.People.map((p, indexs) => {
                                    let dLead = false;
                                    let udLead = false;
                                    if (cardData && Object.keys(cardData).length > 0) {
                                      if (cardData.people && cardData.people.length > 0 && cardData.people === p._id) {
                                        if (cardData.curType === "People" && cardData.rAndS === null) {
                                          dLead = true
                                        } else if ((cardData.curType === "Roles" || cardData.curType === "Skills") && cardData.rAndS) {
                                          udLead = true
                                        }
                                      }
                                    }
                                    return (
                                      <div key={indexs} className="card">
                                        <span className="static-relations">
                                          <span className="custom-badge lightgray">
                                            Person
                                          </span>
                                          {p.workRole === "External" ? (
                                            <span className="custom-badge blue ext-owner-pill">
                                              EXT
                                            </span>
                                          ) : null}
                                          {dLead ? (
                                            <span className="custom-badge red-o direct-lead">
                                              LEAD
                                            </span>
                                          ) : null}
                                          {udLead ? (
                                            <span className="custom-badge gray-o">
                                              LEAD
                                            </span>
                                          ) : null}
                                          {p.tags && p.tags.length > 0
                                            ? p.tags.map((r, indext) => (
                                              <span key={indext} className="custom-badge green tags">
                                                {r}
                                              </span>
                                            ))
                                            : null}
                                        </span>
                                        <a className="card-edit" >{`${p.fname} ${p.lname}`}</a>
                                      </div>
                                    );
                                  })
                                  : null}

                                {curPModeData.extendedMembers && curPModeData.extendedMembers.Skills && curPModeData.extendedMembers.Skills.length > 0
                                  ? curPModeData.extendedMembers.Skills.map((s, indexs) => {
                                    let isExternal = sSIsExtrnal(s);
                                    let dLead = false;
                                    if (cardData && Object.keys(cardData).length > 0) {
                                      if (cardData.rAndS && cardData.rAndS.length > 0 && cardData.rAndS === s._id) {
                                        if (cardData.curType === "Skills") {
                                          dLead = true
                                        }
                                      }
                                    }
                                    let allOwner = getAllOwnerFCM(s, data._id, 'extended')
                                    return (
                                      <div key={indexs} className="card"
                                      >
                                        <span className="static-relations">
                                          <span className="custom-badge lightgray">
                                            {/* Skill */ GetAliasesName(teams, "Skills").toLowerCase()}
                                          </span>
                                          {isExternal ? (
                                            <span className="custom-badge blue ext-owner-pill">
                                              EXT
                                            </span>
                                          ) : null}
                                          {s.owners && s.owners.length === 0 ? (
                                            <span className="custom-badge no-owner-pill red">
                                              NO
                                            </span>
                                          ) : null}
                                          {s.ownerType && s.ownerType.length > 0 &&
                                            s.ownerType === "Single owner" ? (
                                            <span className="custom-badge blue-o">1</span>
                                          ) : null}
                                          {dLead ? (
                                            <span className="custom-badge red-o direct-lead">
                                              LEAD
                                            </span>
                                          ) : null}
                                          {s.tags && s.tags.length > 0
                                            ? s.tags.map((r, index) => (
                                              <span key={index} className="custom-badge green tags">
                                                {r}
                                              </span>
                                            ))
                                            : null}
                                        </span>
                                        <a className="card-edit"  >
                                          {s.skillName}
                                        </a>
                                        {allOwner && allOwner.length > 0
                                          ? allOwner.map((value, pdindex) => {
                                            return (
                                              <span
                                                key={pdindex}
                                                style={{ color: "#8CC1F3", paddingLeft: '0px' }}
                                                className="custom-badge tags"
                                              >{`${value.fname} ${value.lname}`}</span>
                                            );
                                          })
                                          : null}
                                      </div>
                                    );
                                  })
                                  : null}

                                {curPModeData.extendedMembers && curPModeData.extendedMembers.Roles && curPModeData.extendedMembers.Roles.length > 0
                                  ? curPModeData.extendedMembers.Roles.map((r, indexs) => {
                                    let isExternal = sRIsExtrnal(r);
                                    let dLead = false;
                                    if (cardData && Object.keys(cardData).length > 0) {
                                      if (cardData.rAndS && cardData.rAndS.length > 0 && cardData.rAndS === r._id) {
                                        if (cardData.curType === "Roles") {
                                          dLead = true
                                        }
                                      }
                                    }

                                    let allOwner = getAllOwnerFCM(r, data._id, 'extended')
                                    return (
                                      <div key={indexs} className="card">
                                        <span className="static-relations">
                                          <span className="custom-badge lightgray">
                                            {/* Role */ GetAliasesName(teams, "Roles").toLowerCase()}
                                          </span>
                                          {isExternal ? (
                                            <span className="custom-badge blue ext-owner-pill">
                                              EXT
                                            </span>
                                          ) : null}
                                          {r.owners && r.owners.length === 0 ? (
                                            <span className="custom-badge no-owner-pill red">
                                              NO
                                            </span>
                                          ) : null}
                                          {r.ownerRole.length > 0 && r.ownerRole === "Single owner" ? (
                                            <span className="custom-badge blue-o">1</span>
                                          ) : null}
                                          {dLead ? (
                                            <span className="custom-badge red-o direct-lead">
                                              LEAD
                                            </span>
                                          ) : null}
                                          {r.tags && r.tags.length > 0
                                            ? r.tags.map((t, indext) => (
                                              <span key={indext} className="custom-badge green tags">
                                                {t}
                                              </span>
                                            ))
                                            : null}
                                        </span>
                                        <a className="card-edit"  >
                                          {r.roleName}
                                        </a>
                                        {allOwner && allOwner.length > 0
                                          ? allOwner.map((value, pdindex) => {
                                            return (
                                              <span
                                                key={pdindex}
                                                style={{ color: "#8CC1F3", paddingLeft: '0px' }}
                                                className="custom-badge tags"
                                              >{`${value.fname} ${value.lname}`}</span>
                                            );
                                          })
                                          : null}
                                      </div>
                                    );
                                  })
                                  : null}

                                {curPModeData.extendedMembers && curPModeData.extendedMembers.Domains && curPModeData.extendedMembers.Domains.length > 0
                                  ? curPModeData.extendedMembers.Domains.map((d, indexs) => {
                                    let curOwner = {}
                                    if (d && Object.keys(d).length > 0 && d.owners && d.owners.owner && d.owners.owner.length > 0) {
                                      if (reportPeople && reportPeople.length > 0) {
                                        for (let i = 0; i < reportPeople.length; i++) {
                                          if (reportPeople[i]._id === d.owners.owner) {
                                            curOwner = reportPeople[i]
                                            break;
                                          }
                                        }
                                      }
                                    }
                                    let isExternal = sDIsExtrnal(d);
                                    let ownerType = getOwnerTypeFCM(d)
                                    return (
                                      <div key={indexs} className="card" >
                                        <span className="static-relations">
                                          <span className="custom-badge lightgray">
                                            {/* Domain */ GetAliasesName(teams, "Domains").toLowerCase()}
                                          </span>
                                          {isExternal ? (
                                            <span className="custom-badge blue ext-owner-pill">
                                              EXT
                                            </span>
                                          ) : null}
                                          {d.owners && d.owners.owner && d.owners.owner.length > 0 ? null : (
                                            <span className="custom-badge no-owner-pill red">
                                              NO
                                            </span>
                                          )}
                                          {d.tags && d.tags.length > 0
                                            ? d.tags.map((t, indext) => (
                                              <span key={indext} className="custom-badge green tags">
                                                {t}
                                              </span>
                                            ))
                                            : null}
                                        </span>
                                        <a className="card-edit"  >
                                          {d.domainName}
                                        </a>
                                        {ownerType && ownerType.name ? <p className="owner-type">{`${ownerType.name} ( ${GetAliasesName(teams, ownerType.type)})`}</p> : null}
                                        {curOwner && Object.keys(curOwner).length > 0 ?
                                          <span
                                            style={{ color: "#8CC1F3", paddingLeft: '0px' }}
                                            className="custom-badge tags"
                                          >{`${curOwner.fname} ${curOwner.lname}`}</span>
                                          : null}
                                      </div>
                                    );
                                  })
                                  : null}

                                {curPModeData.extendedMembers && curPModeData.extendedMembers.Links && curPModeData.extendedMembers.Links.length > 0
                                  ? curPModeData.extendedMembers.Links.map((l, indexs) => {
                                    let isExternal = sLIsExtrnal(l);
                                    let curOwner = {}
                                    if (l && Object.keys(l).length > 0 && l.owner && l.owner.length > 0) {
                                      if (reportPeople && reportPeople.length > 0) {
                                        for (let i = 0; i < reportPeople.length; i++) {
                                          if (reportPeople[i]._id === l.owner) {
                                            curOwner = reportPeople[i]
                                            break;
                                          }
                                        }
                                      }
                                    }

                                    return (
                                      <div key={indexs} className="card">
                                        <span className="static-relations">
                                          <span className="custom-badge lightgray">
                                            {/* Link */ GetAliasesName(teams, "Links").toLowerCase()}
                                          </span>
                                          {isExternal ? (
                                            <span className="custom-badge blue ext-owner-pill">
                                              EXT
                                            </span>
                                          ) : null}
                                          {l.owner && l.owner.length > 0 ? null : (
                                            <span className="custom-badge no-owner-pill red">
                                              NO
                                            </span>
                                          )}
                                          {l.tags && l.tags.length > 0
                                            ? l.tags.map((t, indext) => (
                                              <span key={indext} className="custom-badge green tags">
                                                {t}
                                              </span>
                                            ))
                                            : null}
                                        </span>
                                        <a className="card-edit"  >
                                          {l.linkName}
                                        </a>
                                        {curOwner && Object.keys(curOwner).length > 0 ?
                                          <span
                                            style={{ color: "#8CC1F3", paddingLeft: '0px' }}
                                            className="custom-badge tags"
                                          >{`${curOwner.fname} ${curOwner.lname}`}</span>
                                          : null}
                                      </div>
                                    );
                                  })
                                  : null}

                              </div>
                            </div>

                            <h6 style={{ borderBottom: "1px solid #000" }} className="meetings-heading" >
                              Meetings
                            </h6>

                            <div className="panel panel-default">
                              <div id="meetings" className="panel-body single box-container" >
                                {meetingsData && meetingsData.length > 0
                                  ? meetingsData.map((m, indexm) => {
                                    return (
                                      <div
                                        key={indexm}
                                        className="card meeting-card "
                                      >
                                        <p>
                                          <span className="fas fa-users"></span>{" "}
                                          <b>{m.meetingsName}</b>
                                        </p>
                                        <p>{m.meetingsPurpose}</p>
                                        <p></p>
                                        <p></p>
                                      </div>
                                    );
                                  })
                                  : null}
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <div className="row">
                <div className="col-xs-4 text-left">

                  <button
                    type="button"
                    onClick={() => historysGet(data._id)}
                    style={
                      historysMessage.length > 0
                        ? { backgroundColor: "#F3D068" }
                        : null
                    }
                    className="btn btn-mytpt-gray history"
                  >
                    <img alt="" src="../images/newicons/history.svg" />
                  </button>
                </div>
                <div className="col-xs-8 text-right">
                  <button
                    type="button"
                    onClick={CloseProjectModal}
                    data-tippy-content="Close Tool"
                    className="btn btn-mytpt-red"
                    data-dismiss="modal"
                  >
                    <i className="fa fa-times"></i>
                  </button>

                  {/* <!-- <button type="button" data-tippy-content="Save And Close" className="btn btn-mytpt save-close" data-modal_id="projectsModal"><i className="fa fa-check"></i></button> --> */}
                </div>
              </div>
              {historysMessage.length > 0 ? (
                <div className="row activities">
                  <div className="col-xs-12">
                    <h4>History</h4>
                    {historysMessage.reverse().map((e, index) => {
                      return <p key={index} dangerouslySetInnerHTML={{ __html: e }}></p>;
                    })}
                  </div>
                </div>
              ) : null}
            </div>

          </div>
        </form>
      </div>
    </div>
  );
}
