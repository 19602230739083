import {
    STATE_CREATE_SUCCESS, STATE_CREATE_FAIL, STATE_GET_SUCCESS, STATE_DELETE_SUCCESS,
    STATE_UPDATE_SUCCESS, STATE_UPDATE_FAIL, STATE_DELETE_FAIL, STATE_GET_FAIL,
    STATE_SUCCESS_MESSAGE_CLEAR, STATE_ERROR_CLEAR, SIGN_OUT, STATELM_SET_SUCCESS,
    STATELM_SET_FAIL, STATES_MODIFICATIONS
}
    from '../types/stateType'
import { areArraysEqual } from '../../HelperFunctions/ArraysAreEqual';

const State = {
    states: {},
    statesLM: {},
    trashData:{},
    cuStateId: '',
    statesMessagee: '',
    statesError: '',
    statesMessage: ''
}

export const statesReducer = (state = State, action) => {
    const { type, payload } = action
    // const {users, successMessage } = payload
    // console.log(payload.users)

    if (type === STATE_CREATE_SUCCESS) {
        return {
            ...state,
            states: payload.states,
            statesMessagee: payload.statesuccessMessage,
            cuStateId: payload.cuStateId,
            statesError: ""
        }
    }
    if (type === STATE_GET_SUCCESS) {
        const result = areArraysEqual(state.states, payload.states);

        return {
            ...state,
            states: result ? state.states : payload.states,
            trashData: payload.trashData,
            statesMessage: payload.statesuccessMessage,
            statesError: ""
        }
    }
    if (type === STATE_DELETE_SUCCESS) {
        return {
            ...state,
            statesMessagee: payload.statesuccessMessage,
            statesError: "",
            states: payload.states,
        }
    }
    if (type === STATE_UPDATE_SUCCESS) {
        const result = areArraysEqual(state.states, payload.states);
        // console.log(state.states, payload.states, result)
        return {
            ...state,
            states: result ? payload.states : state.states,
            statesMessagee: payload.statesuccessMessage,
            statesError: ""
        }
    }
    if (type === STATES_MODIFICATIONS) {
        // console.log(payload)
        return {
            ...state,
            states: payload
        }
    }
    if (type === STATE_CREATE_FAIL) {
        return {
            ...state,
            statesError: payload.error
        }
    }
    if (type === STATE_DELETE_FAIL) {
        return {
            ...state,
            statesError: payload.error
        }
    }
    if (type === STATE_UPDATE_FAIL) {
        return {
            ...state,
            statesError: payload.error
        }
    }
    if (type === STATE_GET_FAIL) {
        return {
            ...state,
            statesError: payload.error
        }
    }
    if (type === STATE_SUCCESS_MESSAGE_CLEAR) {
        return {
            ...state,
            statesMessagee: ''
        }
    }
    if (type === STATE_ERROR_CLEAR) {
        return {
            ...state,
            statesError: ''
        }
    }
    if (type === STATELM_SET_SUCCESS) {
        return {
            ...state,
            statesLM: payload.statesLM
        }
    }
    if (type === STATELM_SET_SUCCESS) {
        return {
            ...state,
            statesError: payload.statesError
        }
    }
    if (type === SIGN_OUT) {
        return {
            ...state,
            states: [],
            statesMessagee: '',
            statesError: '',
            statesMessage: '',
            trashData:{},
        }
    }



    return state;
}