import {
    PFMEMBER_CREATE_SUCCESS, PFMEMBER_CREATE_FAIL, PFMEMBER_GET_SUCCESS, PFMEMBER_DELETE_SUCCESS,
    PFMEMBER_UPDATE_SUCCESS, PFMEMBER_UPDATE_FAIL, PFMEMBER_DELETE_FAIL, PFMEMBER_GET_FAIL,
    PFMEMBER_SUCCESS_MESSAGE_CLEAR, PFMEMBER_ERROR_CLEAR, SIGN_OUT
}
    from '../types/pfmemberType'



const pfmemberState = {
    pfmembers: [],
    pfmemberMessagee: '',
    pfmemberError: '',
    pfmemberMessage: ""
}

export const pfmemberReducer = (state = pfmemberState, action) => {
    const { type, payload } = action
    // const {users, successMessage } = payload
    // console.log(payload.users)

    if (type === PFMEMBER_CREATE_SUCCESS) {
        return {
            ...state,
            // members: payload.members,
            pfmemberMessagee: payload.membersuccessMessage,
            pfmemberError: ""
        }
    }
    if (type === PFMEMBER_GET_SUCCESS) {
        return {
            ...state,
            pfmembers: payload.members,
            pfmemberMessage: payload.membersuccessMessage,
            pfmemberError: ""
        }
    }
    if (type === PFMEMBER_DELETE_SUCCESS) {
        return {
            ...state,
            pfmemberMessagee: payload.membersuccessMessage,
            pfmemberError: ""
        }
    }
    if (type === PFMEMBER_UPDATE_SUCCESS) {
        return {
            ...state,
            pfmemberMessagee: payload.membersuccessMessage,
            pfmemberError: ""
        }
    }
    if (type === PFMEMBER_CREATE_FAIL) {
        return {
            ...state,
            pfmemberError: payload.errorMember
        }
    }
    if (type === PFMEMBER_DELETE_FAIL) {
        return {
            ...state,
            pfmemberError: payload.errorMember
        }
    }
    if (type === PFMEMBER_UPDATE_FAIL) {
        return {
            ...state,
            pfmemberError: payload.errorMember
        }
    }
    if (type === PFMEMBER_GET_FAIL) {
        return {
            ...state,
            pfmemberError: payload.errorMember
        }
    }
    if (type === PFMEMBER_SUCCESS_MESSAGE_CLEAR) {
        return {
            ...state,
            pfmemberMessagee: ''
        }
    }
    if (type ===  PFMEMBER_ERROR_CLEAR) {
        return {
            ...state,
            pfmemberError: ''
        }
    }

    if (type === SIGN_OUT) {
        return {
            ...state,
            pfmembers: [],
            pfmemberMessagee: '',
            pfmemberError: '',
            pfmemberMessage: ""
        }
    }

    return state;
}