import {
    HISTORY_CREATE_SUCCESS, HISTORY_CREATE_FAIL, HISTORY_GET_SUCCESS, HISTORY_GET_FAIL, HISTORY_DELETE_SUCCESS, HISTORY_UPDATE_SUCCESS,
    HISTORY_UPDATE_FAIL, HISTORY_DELETE_FAIL, HISTORY_SUCCESS_MESSAGE_CLEAR, HISTORY_ERROR_CLEAR, SIGN_OUT,
    HISTORY_MESSAGE_CLEAR, HISTORY_DATAS_CLEAR
} from '../../store/types/historyType'



const historyState = {
    historys: [],
    historyMessagee: '',//typo ??
    historyError: '',
    historyMessage: ''
}


export const historyReducer = (state = historyState, action) => {
    const { type, payload } = action
    // const {users, successMessage } = payload

    if (type === HISTORY_GET_SUCCESS) {
        return {
            ...state,
            historys: payload.historys,
            historyMessage: payload.historyMessage
        }
    }
    if (type === HISTORY_MESSAGE_CLEAR) {
        return {
            ...state,
            historyMessage: ""
        }
    }
    if (type === HISTORY_DATAS_CLEAR) {
        return {
            ...state,
            historys: []
        }
    }
    if (type === SIGN_OUT) {
        return {
            ...state,
            historys: [],
            historyMessagee: '',
            historyError: '',
            historyMessage: ''
        }
    }


    return state;
}
