import React from 'react'

export default function FCPeopleList({tagFilteredMegedData, GetAliasesName, teams, CcorfoPeoples, CExtfoPeoples, clickedOnList, toggleEXTnNOCards, getSortIcon, isInfos, pCardHideOnchange, note, pnoteRef, handleSave, noteSaveData, cFPonDrop, cFPonDragOver, isTag, isEXT, isNO, TagWiseSorting, tagFilteredObj, extNoFilteredObj, focused, circlesSorted, skillsSorted, rolesSorted, CFPeoplePills, isAddedAsPersonCF, cFPonDrag, ScrollIntoView, setPepole, cardOpensCf, toggleTagCards, PfToCAndP, cFpeopleSub, CFPeoplePillsE, cfIsDuplicatePerson, CextfoPeople, filteredCFPDatas, OverlayTrigger, popover, cFPeopleHandel, lFromState, peopleSubmit, pref, fName, handleNameChange, pfNameHandel, fcPref, cfPeopleSearch, setCfPeopleSearch, cuPeopleId, setFName, setPepoles, peopleFrom}) {
    return (
        <div
            className={
                !clickedOnList.listName.includes("people") && Object.keys(tagFilteredMegedData).length > 0 &&
                    tagFilteredMegedData.people &&
                    tagFilteredMegedData.people.length === 0
                    ? "board-col hide"
                    : "board-col"
            }
            data-board_id="people"
        >
            <div className="list list-bg-yellow" data-list_id="people">
                <div className="dropdown" style={{ position: "static" }}>
                    <h4 className="list-title">
                        <img alt="" src="images/icon-people.png" /> {GetAliasesName(teams, "People")} (
                        <span>{CcorfoPeoples.length + CExtfoPeoples.length}</span>){" "}
                        <a
                            className={
                                clickedOnList.isClicked &&
                                    clickedOnList.listName.includes("people")
                                    ? "list-menu refresh-list"
                                    : "list-menu refresh-list hide"
                            }
                            href="#"
                            onClick={() => {
                                toggleEXTnNOCards("people", "", true, "list");
                            }}
                        >
                            <i className="fas fa-sync-alt"></i>
                        </a>{" "}
                        <a
                            className={
                                clickedOnList.isClicked &&
                                    clickedOnList.listName.includes("people")
                                    ? "list-menu dropdown-toggle hide"
                                    : "list-menu dropdown-toggle"
                            }
                            data-toggle="dropdown"
                            href="#"
                        >
                            {getSortIcon("People")}
                            <i className="fas fa-ellipsis-v"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-right" role="menu">
                            <li role="presentation">
                                <a
                                    onClick={() => isInfos("People")}
                                    role="menuitem"
                                    className="mytpt-list-info"
                                >
                                    Info
                                </a>
                            </li>
                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    data-toggle="modal"
                                    data-target="#peopleModal"
                                >
                                    Add new person
                                </a>
                            </li>
                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    onClick={() => pCardHideOnchange("People")}
                                    className="hide_list"
                                >
                                    Hide list
                                </a>
                            </li>

                            <li role="presentation" className="divider"></li>
                            <li
                                role="presentation"
                                onClick={() => {
                                    toggleEXTnNOCards("people", "EXT", false);
                                }}
                            >
                                <a role="menuitem" className="show-external">
                                    Show external people
                                </a>
                            </li>
                        </ul>
                    </h4>
                </div>

                <div className="card-list scrollbar">
                    <div
                        className="form-group notes"
                        style={note.People ? { display: "block" } : { display: "none" }}
                    >
                        <textarea
                            className="notes"
                            ref={pnoteRef}
                            name="People"
                            onBlur={(e) => handleSave(e)}
                            placeholder="Notes"
                            maxLength="100"
                            autoFocus={null}
                            defaultValue={noteSaveData.People}
                        ></textarea>
                    </div>

                    <h6 style={{ borderBottom: "1px solid #000" }}>Core members</h6>
                    <div className="panel panel-default">
                        <div
                            onDrop={() => cFPonDrop("cFpCore")}
                            onDragOver={(event) => cFPonDragOver(event)}
                            id="core"
                            className="panel-body box-container"
                        >
                            {CcorfoPeoples && CcorfoPeoples.length > 0
                                ? CcorfoPeoples.map((e, index) => {
                                    if (isTag || isEXT || isNO) {
                                        let Proceed = TagWiseSorting(
                                            e,
                                            isTag,
                                            isEXT,
                                            isNO,
                                            tagFilteredObj,
                                            extNoFilteredObj,
                                            "people"
                                        );
                                        if (!Proceed) return;
                                    }
                                    let flagRed = false;
                                    let flagGray = false;
                                    let flagLGray = false;
                                    if (focused && focused.card === "Circles") {
                                        let focusedCircle = circlesSorted.find(
                                            (c) => c._id === focused.id
                                        );
                                        let leadOfCicleRedInHtml = focusedCircle.lead;
                                        let leadOfCicleGrayInHtml = focusedCircle.standIn;
                                        if (leadOfCicleRedInHtml) {
                                            if (leadOfCicleRedInHtml === e._id) {
                                                flagRed = true;
                                            } else {
                                                let curPLead = "";
                                                if (
                                                    skillsSorted &&
                                                    skillsSorted.length > 0 &&
                                                    leadOfCicleRedInHtml
                                                ) {
                                                    for (let j = 0; j < skillsSorted.length; j++) {
                                                        if (
                                                            skillsSorted[j].ownerType === "Single owner" &&
                                                            skillsSorted[j].owners &&
                                                            skillsSorted[j].owners.length > 0
                                                        ) {
                                                            if (
                                                                skillsSorted[j]._id === leadOfCicleRedInHtml
                                                            ) {
                                                                curPLead = skillsSorted[j].owners[0];
                                                                break;
                                                            }
                                                        }
                                                    }
                                                }
                                                if (!curPLead) {
                                                    if (
                                                        rolesSorted &&
                                                        rolesSorted.length > 0 &&
                                                        leadOfCicleRedInHtml
                                                    ) {
                                                        for (let j = 0; j < rolesSorted.length; j++) {
                                                            if (
                                                                rolesSorted[j].ownerRole === "Single owner" &&
                                                                rolesSorted[j].owners &&
                                                                rolesSorted[j].owners.length > 0
                                                            ) {
                                                                if (
                                                                    rolesSorted[j]._id === leadOfCicleRedInHtml
                                                                ) {
                                                                    curPLead = rolesSorted[j].owners[0];
                                                                    break;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                if (curPLead && curPLead.length > 0) {
                                                    if (e._id === curPLead) {
                                                        flagLGray = true;
                                                    }
                                                }
                                            }
                                        }
                                        if (leadOfCicleGrayInHtml === e._id) {
                                            flagGray = true;
                                        }
                                    }

                                    let curPills = [];
                                    if (CFPeoplePills && CFPeoplePills.length > 0) {
                                        // console.log("Initial CFPeoplePills: ",CFPeoplePills)
                                        for (let i = 0; i < CFPeoplePills.length; i++) {
                                            let duplicate = false;
                                            if (curPills.length > 0) {
                                                for (let j = 0; j < curPills.length; j++) {
                                                    if (
                                                        curPills[j].peopleId ===
                                                        CFPeoplePills[i].peopleId &&
                                                        curPills[j].Id === CFPeoplePills[i].Id
                                                    ) {
                                                        // console.log("Duplicate found: ",curPills[j], CFPeoplePills[i]);
                                                        duplicate = true;
                                                        break;
                                                    }
                                                }
                                            }

                                            if (CFPeoplePills[i].peopleId === e._id && !duplicate) {
                                                curPills.push(CFPeoplePills[i]);
                                            }
                                        }
                                    }
                                    // console.log("Current pills: ",curPills);

                                    let addAsPerson = isAddedAsPersonCF(e._id, "Core");
                                    return (
                                        <div
                                            onDrag={() => cFPonDrag(e, "cFpCore")}
                                            key={e._id}
                                            draggable
                                            // className="card "
                                            className={`card ${localStorage.getItem("latest-created") === e._id
                                                ? " latest-created "
                                                : ""
                                                }`}
                                            data-list_id="people"
                                            style={{ minHeight: "60px" }}
                                            onClick={(event) => {
                                                ScrollIntoView();
                                                if (
                                                    event.target.classList.contains("card") ||
                                                    event.target.classList.contains("card-edit")
                                                ) {
                                                    setPepole(e, CcorfoPeoples, index);
                                                }
                                            }}
                                        >
                                            <div className="static-relations">
                                                {e.workRole === "External" ? (
                                                    <span
                                                        onClick={() => {
                                                            toggleEXTnNOCards(e._id, "EXT", false, "pill");
                                                        }}
                                                        className="custom-badge blue ext-owner-pill"
                                                    >
                                                        EXT
                                                    </span>
                                                ) : null}
                                                {curPills && curPills.length > 0
                                                    ? curPills.map((d, index) => {
                                                        return (
                                                            <span onClick={() => cardOpensCf(d)}>
                                                                {!d.standIn ? (
                                                                    <span className="custom-badge lightgray">
                                                                        {d.name}
                                                                    </span>
                                                                ) : (
                                                                    <span className="custom-badge gray-o">
                                                                        {d.name}
                                                                    </span>
                                                                )}
                                                            </span>
                                                        );
                                                    })
                                                    : null}
                                                {e.tags && e.tags.length > 0
                                                    ? e.tags.map((r, index) => (
                                                        <span
                                                            onClick={() => toggleTagCards(r)}
                                                            key={index}
                                                            className="custom-badge green tags"
                                                        >
                                                            {r}
                                                        </span>
                                                    ))
                                                    : null}
                                            </div>
                                            <div className="relations">
                                                {addAsPerson ? (
                                                    <span className="custom-badge lightgray text-red person-pill">
                                                        Added as person
                                                    </span>
                                                ) : null}
                                                {flagRed ? (
                                                    <span className="custom-badge red-o direct-lead">
                                                        LEAD
                                                    </span>
                                                ) : null}
                                                {flagGray ? (
                                                    <span className="custom-badge gray-o">
                                                        LEAD (Stand-In)
                                                    </span>
                                                ) : null}
                                                {flagLGray ? (
                                                    <span className="custom-badge gray-o">LEAD</span>
                                                ) : null}
                                            </div>
                                            <a
                          /* onClick={() => setPepole(e, CcorfoPeoples, index)} */ className="card-edit"
                                            >{`${e.fname} ${e.lname}`}</a>
                                            <a className="card-focus">
                                                <img
                                                    onClick={() => PfToCAndP("People", e)}
                                                    src="./images/newicons/focus.svg"
                                                />
                                            </a>
                                            {addAsPerson ? (
                                                <a
                                                    className="card-unlink"
                                                    style={{ display: "block", color: "red" }}
                                                >
                                                    <i
                                                        onClick={() => cFpeopleSub(e, "core")}
                                                        className="icon far fa-times-circle"
                                                    ></i>
                                                </a>
                                            ) : null}
                                        </div>
                                    );
                                })
                                : null}
                        </div>
                    </div>

                    <h6 style={{ borderBottom: "1px solid #000" }}>Extended members</h6>
                    <div className="panel panel-default">
                        <div
                            onDrop={() => cFPonDrop("cFpExtend")}
                            onDragOver={(event) => cFPonDragOver(event)}
                            id="extended"
                            className="panel-body box-container"
                        >
                            {CExtfoPeoples && CExtfoPeoples.length > 0
                                ? CExtfoPeoples.map((e, index) => {
                                    if (isTag || isEXT || isNO) {
                                        let Proceed = TagWiseSorting(
                                            e,
                                            isTag,
                                            isEXT,
                                            isNO,
                                            tagFilteredObj,
                                            extNoFilteredObj,
                                            "people"
                                        );
                                        if (!Proceed) return;
                                    }
                                    let flagRed = false;
                                    let flagGray = false;
                                    if (focused && focused.card === "Circles") {
                                        let focusedCircle = circlesSorted.find(
                                            (c) => c._id === focused.id
                                        );
                                        let leadOfCicleRedInHtml = focusedCircle.lead;
                                        let leadOfCicleGrayInHtml = focusedCircle.standIn;
                                        if (leadOfCicleRedInHtml === e._id) {
                                            flagRed = true;
                                        }
                                        if (leadOfCicleGrayInHtml === e._id) {
                                            flagGray = true;
                                        }
                                    }
                                    let curPills = [];
                                    if (CFPeoplePillsE && CFPeoplePillsE.length > 0) {
                                        for (let i = 0; i < CFPeoplePillsE.length; i++) {
                                            if (CFPeoplePillsE[i].peopleId === e._id) {
                                                curPills.push(CFPeoplePillsE[i]);
                                            }
                                        }
                                    }
                                    let flag = true;
                                    if (e && Object.keys(e).length > 0) {
                                        flag = cfIsDuplicatePerson(e);
                                    }
                                    let addAsPerson = isAddedAsPersonCF(e._id, "Extended");
                                    return (
                                        <div
                                            // onDragStart={(e) => peopleDragStart(e, index)}
                                            // onDragEnter={(e) => peopleDragEnter(e, index)}
                                            // onDragEnd={peopleDrop}
                                            onDrag={() => cFPonDrag(e, "cFpExtend")}
                                            key={e._id}
                                            draggable
                                            // className="card "
                                            className={`card ${localStorage.getItem("latest-created") === e._id
                                                ? " latest-created "
                                                : ""
                                                }`}
                                            data-list_id="people"
                                            onClick={(event) => {
                                                if (
                                                    event.target.classList.contains("card") ||
                                                    event.target.classList.contains("card-edit")
                                                ) {
                                                    setPepole(e, CextfoPeople, index);
                                                }
                                            }}
                                        >
                                            <div className="static-relations">
                                                {e.workRole === "External" ? (
                                                    <span
                                                        onClick={() => {
                                                            toggleEXTnNOCards(e._id, "EXT", false, "pill");
                                                        }}
                                                        className="custom-badge blue ext-owner-pill"
                                                    >
                                                        EXT
                                                    </span>
                                                ) : null}
                                                {e.tags && e.tags.length > 0
                                                    ? e.tags.map((r, index) => (
                                                        <span
                                                            onClick={() => toggleTagCards(r)}
                                                            key={index}
                                                            className="custom-badge green tags"
                                                        >
                                                            {r}
                                                        </span>
                                                    ))
                                                    : null}
                                                {curPills && curPills.length > 0
                                                    ? curPills.map((d, index) => {
                                                        return (
                                                            <span onClick={() => cardOpensCf(d)}>
                                                                {!d.standIn ? (
                                                                    <span className="custom-badge lightgray">
                                                                        {d.name}
                                                                    </span>
                                                                ) : (
                                                                    <span className="custom-badge gray-o">
                                                                        {d.name}
                                                                    </span>
                                                                )}
                                                            </span>
                                                        );
                                                    })
                                                    : null}
                                            </div>
                                            <div className="relations">
                                                {addAsPerson ? (
                                                    <span className="custom-badge lightgray text-red person-pill">
                                                        Added as person
                                                    </span>
                                                ) : null}

                                                {flagGray ? (
                                                    <span className="custom-badge gray-o">
                                                        LEAD (Stand-In)
                                                    </span>
                                                ) : null}
                                            </div>

                                            <a
                          /* onClick={() => setPepole(e, CextfoPeople, index)} */ className="card-edit"
                                            >{`${e.fname} ${e.lname}`}</a>
                                            {flag ? (
                                                <a className="card-focus">
                                                    <img
                                                        onClick={() => PfToCAndP("People", e)}
                                                        src="./images/newicons/focus.svg"
                                                    />
                                                </a>
                                            ) : null}
                                            {curPills && curPills.length === 0 ? (
                                                <a
                                                    className="card-unlink"
                                                    style={{ display: "block", color: "red" }}
                                                >
                                                    <i
                                                        onClick={() => cFpeopleSub(e, "extend")}
                                                        className="icon far fa-times-circle"
                                                    ></i>
                                                </a>
                                            ) : null}
                                        </div>
                                    );
                                })
                                : null}
                        </div>
                    </div>

                    <span className="clearable2">
                        <input
                            type="text"
                            ref={fcPref}
                            style={{ color: "black" }}
                            value={cfPeopleSearch}
                            onChange={(e) => setCfPeopleSearch(e.target.value)}
                            className="form-control search_list2 transparent"
                            name="search_list"
                            placeholder="Search to select more"
                            autoComplete="off"
                        />
                        {cfPeopleSearch.length > 0 ? (
                            <i
                                className="clearable__clear"
                                onClick={() => setCfPeopleSearch("")}
                                style={{ display: "inline", color: "black" }}
                            >
                                ×
                            </i>
                        ) : null}
                    </span>

                    <div className="panel panel-default">
                        <div
                            onDrop={() => cFPonDrop("cFpDefult")}
                            onDragOver={(event) => cFPonDragOver(event)}
                            id="notmatched"
                            className="panel-body box-container"
                        >
                            {filteredCFPDatas && filteredCFPDatas.length > 0
                                ? filteredCFPDatas.map((e, index) => {
                                    if (isTag || isEXT || isNO) {
                                        let Proceed = TagWiseSorting(
                                            e,
                                            isTag,
                                            isEXT,
                                            isNO,
                                            tagFilteredObj,
                                            extNoFilteredObj,
                                            "people"
                                        );
                                        if (!Proceed) return;
                                    }
                                    return (
                                        <div
                                            // onDragStart={(e) => peopleDragStart(e, index)}
                                            // onDragEnter={(e) => peopleDragEnter(e, index)}
                                            // onDragEnd={peopleDrop}
                                            onDrag={() => cFPonDrag(e, "cFpDefult")}
                                            key={index}
                                            draggable
                                            className={`card notmatched ${localStorage.getItem("latest-created") !== "" &&
                                                cuPeopleId === e._id
                                                ? " latest-created "
                                                : ""
                                                }`}
                                            data-list_id="people"
                                            onClick={(event) => {
                                                if (
                                                    event.target.classList.contains("card") ||
                                                    event.target.classList.contains("card-edit")
                                                ) {
                                                    setPepole(e, filteredCFPDatas, index);
                                                }
                                            }}
                                        >
                                            <div className="static-relations">
                                                {e.workRole === "External" ? (
                                                    <span
                                                        onClick={() => {
                                                            toggleEXTnNOCards(e._id, "EXT", false, "pill");
                                                        }}
                                                        className="custom-badge blue ext-owner-pill"
                                                    >
                                                        EXT
                                                    </span>
                                                ) : null}
                                                {e.tags && e.tags.length > 0
                                                    ? e.tags.map((r, index) => (
                                                        <span
                                                            onClick={() => toggleTagCards(r)}
                                                            key={index}
                                                            className="custom-badge green tags"
                                                        >
                                                            {r}
                                                        </span>
                                                    ))
                                                    : null}
                                            </div>

                                            <a
                                                onClick={() => setPepole(e, filteredCFPDatas, index)}
                                                className="card-edit"
                                            >{`${e.fname} ${e.lname}`}</a>
                                            <OverlayTrigger
                                                trigger="click"
                                                placement="right"
                                                overlay={popover}
                                            >
                                                <a
                                                    className="card-link"
                                                    variant="success"
                                                    style={{ display: "block" }}
                                                >
                                                    <i
                                                        onClick={() => cFPeopleHandel(e, "People")}
                                                        className="icon fa fa-plus-circle"
                                                    ></i>
                                                </a>
                                            </OverlayTrigger>
                                        </div>
                                    );
                                })
                                : null}
                        </div>
                    </div>

                    <div
                        className="card2"
                        style={
                            lFromState.Peoples ? { display: "block" } : { display: "none" }
                        }
                    >
                        <form className="form-new-card" onSubmit={peopleSubmit} role="form">
                            <div className="form-group">
                                <input
                                    type="text"
                                    ref={pref}
                                    name="card_name"
                                    placeholder="Add first name"
                                    data-mode="Circle focus mode"
                                    className="form-control"
                                    value={fName}
                                    onChange={handleNameChange}
                                    onKeyPress={pfNameHandel}
                                    autoComplete="off"
                                    required
                                />
                            </div>

                            <div className="form-group" style={{ marginTop: "10px" }}>
                                <button type="submit" className="btn btn-mytpt add-quick">
                                    Save
                                </button>
                                <a
                                    href="#"
                                    type="button" // onClick={() => setPepoles({ fname: fName })}
                                    onClick={() => {
                                        // Added by Debashis on 13-12-2023 (updated 18-12-2023)
                                        let lastname = "";
                                        let firstname = "";
                                        if (fName.trim().indexOf(" ") >= 0) {
                                            let nameArray = fName.split(" ");
                                            lastname = nameArray.pop();
                                            firstname = nameArray.join(" ");
                                        } else {
                                            lastname = "";
                                            firstname = fName;
                                        }
                                        setFName("");
                                        setPepoles({ fname: firstname, lname: lastname });
                                        lFromState.Peoples = false;
                                    }}
                                    className="btn btn-link underline open-tool"
                                >
                                    Edit details
                                </a>
                                <a
                                    href="#"
                                    onClick={() => peopleFrom()}
                                    className="btn btn-link underline close-add-card"
                                >
                                    Close
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
                <a
                    className="btn-list add-card"
                    onClick={() => peopleFrom()}
                    data-list_id="people"
                    style={
                        !lFromState.Peoples ? { display: "block" } : { display: "none" }
                    }
                >
                    + Add new person
                </a>
            </div>
        </div>
    )
}
