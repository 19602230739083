export const USER_FORGOT_PASSWORD_SUCCESS = 'USER_FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORDT_FAIL = 'FORGOT_PASSWORDT_FAIL'
export const SUCCESS_MESSAGE_CLEAR = 'SUCCESS_MESSAGE_CLEAR'
export const ERROR_CLEAR = 'ERROR_CLEAR'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORDT_FAIL = 'RESET_PASSWORDT_FAIL'

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'

export const USER_VERIFIY_SUCCESS = 'USER_VERIFIY_SUCCESS'
export const USER_VERIFIY_FAIL = 'USER_VERIFIY_FAIL'

export const CUR_USER_DATA_GET_SUCCESS = 'CUR_USER_DATA_GET_SUCCESS'
export const CUR_USER_DATA_GET_FAIL = 'CUR_USER_DATA_GET_FAIL'

export const CUR_USER_LHDATA_GET_SUCCESS = 'CUR_USER_LHDATA_GET_SUCCESS'
export const CUR_USER_LHDATA_GET_FAIL = 'CUR_USER_LHDATA_GET_FAIL'


export const PAYMENT_VERIFIY_SUCCESS = 'PAYMENT_VERIFIY_SUCCESS'
export const PAYMENT_VERIFIY_MSG_CLEAR = 'PAYMENT_VERIFIY_MSG_CLEAR'
export const PAYMENT_VERIFIY_FAIL = 'PAYMENT_VERIFIY_FAIL'

export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const SIGN_OUT = 'SIGN_OUT'
export const SOCIAL_LOGIN_TOKEN_DELETE = 'SOCIAL_LOGIN_TOKEN_DELETE'

export const BULK_ADD_SUCCESS = 'BULK_ADD_SUCCESS'
export const BULK_ADD_FAIL = 'BULK_ADD_FAIL'

export const DIRECT_ADD_CO_ADMIN_VERIFIY_SUCCESS = "DIRECT_ADD_CO_ADMIN_VERIFIY_SUCCESS";
export const DIRECT_ADD_CO_ADMIN_VERIFIY_FAIL = "DIRECT_ADD_CO_ADMIN_VERIFIY_FAIL";


export const GET_USER_META_DATA_SUCCESS = 'GET_USER_META_DATA_SUCCESS'
export const GET_USER_META_DATA_ERROR = 'GET_USER_META_DATA_ERROR'