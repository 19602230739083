import React, { useEffect, useState } from "react";
import { Box, IconButton, Popover, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import LinkIcon from "@mui/icons-material/Link";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Swal from "sweetalert2";

export default function Documents({ data, type = "", setDocumentData }) {
  const [dataSets, setDataSets] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [formData, setFormData] = useState({ title: "", Link: "" });
  const [currentIndex, setCurrentIndex] = useState(null);
  const [isNewDocument, setIsNewDocument] = useState(false);

  const handleClick = (event, index) => {
    if (type === "MY-REPORT") {
      const a = document.createElement("a");
      a.href = dataSets[index].Link;
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      return;
    }

    setAnchorEl(event.currentTarget);
    if (index === -1) return;
    setCurrentIndex(index);
    const prefillData = dataSets[index];
    setFormData(prefillData);
    setFormData(dataSets[index]);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentIndex(null);
    setIsNewDocument(false);
    setFormData({ title: "", Link: "" });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/.*)?$/;

    if (formData.title.trim(" ").length === 0) {
      Swal.fire({
        title: "Error",
        text: "Please enter a document title!",
        showConfirmButton: true,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        // timer: 1500,
      });
      handleClose();
      return;
    }
    const isValidUrl = urlPattern.test(formData.Link);

    if (!isValidUrl) {
      Swal.fire({
        title: "Error",
        text: "Enter a valid document URL!",
        showConfirmButton: true,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        // timer: 1500,
      });
      handleClose();
      return;
    }

    let updatedDataSets = [];
    if (currentIndex !== null && currentIndex !== undefined) {
      updatedDataSets = dataSets.map((data, index) =>
        index === currentIndex ? { ...data, ...formData } : data
      );
    } else {
      updatedDataSets = [...dataSets, formData];
    }

    setDataSets(updatedDataSets);

    setDocumentData(updatedDataSets);
    handleClose();
  };

  const handleDeleteLink = (removeObject) => {
    dataSets.splice(removeObject, 1);
    setDataSets(dataSets);

    setDocumentData(dataSets);
    handleClose();
  };

  const handleCopyLink = () => {
    const link = dataSets[currentIndex].Link;
    navigator.clipboard.writeText(link);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    let urlTitleArray = [];
    if (data.documents && data.documents.length > 0) {
      data.documents.map((e) => {
        urlTitleArray.push({ title: e.title, Link: e.Link });
      });
    }
    setDataSets(urlTitleArray);
  }, [data]);

  return (
    <div className="form-group member-in">
      <h4>Documents</h4>
      <div
        className="row task-list member-in"
        style={{ marginRight: "0px", marginLeft: "0px" }}
      >
        {dataSets &&
          dataSets.map((data, index) => (
            <div
              className="col-xs-12"
              key={index}
              onClick={(event) => handleClick(event, index)}
            >
              <a>{data.title}</a>
            </div>
          ))}
        {type !== "MY-REPORT" && (
          <div
            className="col-xs-12 skill-document-add2"
            onClick={(event) => {
              handleClick(event, -1);
              setIsNewDocument(true);
            }}
            style={{opacity: 1}}
          >
            + Add document
          </div>
        )}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box sx={{ width: "400px", p: 2 }} className="popover-box">
            <Typography variant="h5" mb={1} style={{ fontWeight: "bold" }}>
              Add Document
              {!isNewDocument ? (
                <IconButton
                  size="small"
                  onClick={() =>
                    window.open(dataSets[currentIndex].Link, "_blank")
                  }
                >
                  <LinkIcon fontSize="medium" />
                </IconButton>
              ) : null}
              {!isNewDocument ? (
                <IconButton size="small" onClick={handleCopyLink}>
                  <ContentCopyIcon fontSize="medium" />
                </IconButton>
              ) : null}
              {!isNewDocument ? (
                <IconButton
                  size="small"
                  onClick={() => handleDeleteLink(currentIndex)}
                >
                  <DeleteIcon fontSize="medium" />
                </IconButton>
              ) : null}
            </Typography>

            <form>
              <div className="form-group">
                <input
                  className="form-control"
                  placeholder="Title *"
                  name="title"
                  required={true}
                  value={formData.title}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <input
                  className="form-control"
                  placeholder="Link *"
                  name="Link"
                  value={formData.Link}
                  onChange={handleChange}
                  required={true}
                />
              </div>
              <button
                type="button"
                className="btn btn-mytpt"
                onClick={handleSubmit}
              >
                Apply
              </button>
            </form>
          </Box>
        </Popover>
      </div>
    </div>
  );
}
