import React from 'react'

export default function CircleModeList({ curCModeDatas, cAndpADatas, cmLeadVerify, getAllPeopleFD, Get_ShowHideColumn_Project_Circle_Mode, tagFilteredMegedData, specialModeDataObj, toggleEXTnNOCards, getSortIcon, setCircles, GetAliasesName, teams, cmHandelFocused, isEXT, isNO, isTag, TagWiseSorting, tagFilteredObj, extNoFilteredObj, IsOwnerExternal, getAllOwnerFCM, setPepole, peopleSorted, getOwnerTypeFCM, setSkill, cardOpensCfDomain, setDomain, setLink, OpenCFPeople, toggleTagCards, setRole, setProject }) {
    return (
        <div className="wrap-lists" id="circle-mode">
            {curCModeDatas && curCModeDatas.length > 0
                ? curCModeDatas.map((e, index) => {
                    var meetingsData = [];
                    if (e.meetings && e.meetings.length > 0) {
                        meetingsData = cAndpADatas(e.meetings);
                    }

                    let cardData = {};
                    if (e.lead && e.lead.length > 0) {
                        cardData = cmLeadVerify(e);
                    }
                    let allPeopleFD = [];
                    if (e.defaultCircle && e.defaultCircle === true) {
                        allPeopleFD = getAllPeopleFD();
                    }

                    let isExtNoF = false;
                    let curCAndPrId = localStorage.getItem("circleOrProjectId");
                    if (curCAndPrId === e._id) {
                        isExtNoF = true;
                    }
                    let hideColumn = Get_ShowHideColumn_Project_Circle_Mode(
                        e,
                        tagFilteredMegedData
                    );
                    const specificData = specialModeDataObj.find(
                        (item) => item.id === e._id
                    );

                    if (
                        tagFilteredMegedData &&
                        Object.keys(tagFilteredMegedData).length > 0 &&
                        hideColumn
                    )
                        return null;
                    return (
                        <>
                            <div
                                key={index}
                                className="board-col board-col-circles"
                                data-board_id={e._id}
                            >
                                <div
                                    className={
                                        e.defaultCircle
                                            ? "list default-circle list-bg-red"
                                            : "list list-bg-red"
                                    }
                                    data-list_id={e._id}
                                >
                                    <div className="dropdown">
                                        <h4 className="list-title">
                                            <img alt="" src="images/icon-circle.png" />
                                            {/* {e.circleName} */}
                                            {e.defaultCircle && e.circleName === "Team Circle" ? "Entire team" : e.circleName}

                                            <a
                                                className={
                                                    specialModeDataObj.some((item) => item.id === e._id)
                                                        ? "list-menu refresh-list"
                                                        : "list-menu refresh-list hide"
                                                }
                                                // className="list-menu refresh-list hide"
                                                href="#"
                                                onClick={() => {
                                                    toggleEXTnNOCards(
                                                        "",
                                                        "EXT",
                                                        true,
                                                        "list",
                                                        "CircleMode",
                                                        e._id
                                                    );
                                                }}
                                            >
                                                <i className="fas fa-sync-alt"></i>
                                            </a>

                                            <a
                                                className={
                                                    specialModeDataObj.some((item) => item.id === e._id)
                                                        ? "list-menu dropdown-toggle hide"
                                                        : "list-menu dropdown-toggle"
                                                }
                                                // className="list-menu dropdown-toggle"
                                                data-toggle="dropdown"
                                                href="#"
                                            >
                                                {getSortIcon("Circles")}
                                                <i className="fas fa-ellipsis-v"></i>
                                            </a>

                                            <ul
                                                className="dropdown-menu dropdown-menu-right"
                                                role="menu"
                                            >
                                                <li onClick={() => setCircles(e)} role="presentation">
                                                    <a
                                                        role="menuitem"
                                                        className="tool-from-mode"
                                                        data-list_id="circles"
                                                    >
                                                        Edit this {/* circle */ GetAliasesName(teams, "Circles").toLowerCase()}
                                                    </a>
                                                </li>
                                                {e.defaultCircle &&
                                                    e.defaultCircle === true ? null : (
                                                    <li
                                                        onClick={() => cmHandelFocused(index)}
                                                        role="presentation"
                                                    >
                                                        <a
                                                            role="menuitem"
                                                            className="focus-from-mode"
                                                            data-list_id="circles"
                                                        >
                                                            Enter focus mode
                                                        </a>
                                                    </li>
                                                )}
                                                <li role="presentation" className="divider"></li>
                                                <li
                                                    role="presentation"
                                                    onClick={() => {
                                                        // toggleEXTnNOCards(e._id, "EXT", false);
                                                        toggleEXTnNOCards(
                                                            "",
                                                            "EXT",
                                                            false,
                                                            "list",
                                                            "CircleMode",
                                                            e._id
                                                        );
                                                    }}
                                                >
                                                    <a role="menuitem" className="show-external">
                                                        Show items owned by external
                                                    </a>
                                                </li>
                                                <li
                                                    role="presentation"
                                                    onClick={() => {
                                                        // toggleEXTnNOCards(e._id, "NO", false);
                                                        toggleEXTnNOCards(
                                                            "",
                                                            "NO",
                                                            false,
                                                            "list",
                                                            "CircleMode",
                                                            e._id
                                                        );
                                                    }}
                                                >
                                                    <a role="menuitem" className="show-noowners">
                                                        Show items without owners
                                                    </a>
                                                </li>
                                            </ul>
                                        </h4>
                                    </div>
                                    <div className="card-list only scrollbar">
                                        <div className="project-tags">
                                            {e.tags && e.tags.length > 0
                                                ? e.tags.map((tag) => {
                                                    return (
                                                        <span className="custom-badge green tags">
                                                            {tag}
                                                        </span>
                                                    );
                                                })
                                                : null}
                                        </div>
                                        <h6 style={{ borderBottom: "1px solid #000" }}>
                                            Core members
                                        </h6>
                                        <div className="panel panel-default">
                                            <div
                                                id="core"
                                                className="panel-body single box-container"
                                            >
                                                {e.coreMembers &&
                                                    e.coreMembers.People &&
                                                    e.coreMembers.People.length > 0
                                                    ? e.coreMembers.People.map((p, indexs) => {
                                                        if (specificData && (isEXT || isNO)) {
                                                            if (
                                                                specificData.data.people &&
                                                                !specificData.data.people.includes(p._id)
                                                            ) {
                                                                return;
                                                            }
                                                        } else {
                                                            if (isTag || isEXT || isNO) {
                                                                let Proceed = TagWiseSorting(
                                                                    p,
                                                                    isTag,
                                                                    isEXT,
                                                                    isNO,
                                                                    tagFilteredObj,
                                                                    extNoFilteredObj,
                                                                    "people"
                                                                );
                                                                if (!Proceed) return;
                                                            }
                                                        }

                                                        let dLead = false;
                                                        let udLead = false;
                                                        if (
                                                            cardData &&
                                                            Object.keys(cardData).length > 0
                                                        ) {
                                                            if (
                                                                cardData.people &&
                                                                cardData.people.length > 0 &&
                                                                cardData.people === p._id
                                                            ) {
                                                                if (
                                                                    cardData.curType === "People" &&
                                                                    cardData.rAndS === null
                                                                ) {
                                                                    dLead = true;
                                                                } else if (
                                                                    (cardData.curType === "Roles" ||
                                                                        cardData.curType === "Skills") &&
                                                                    cardData.rAndS
                                                                ) {
                                                                    udLead = true;
                                                                }
                                                            }
                                                        }
                                                        // console.log(21814, p)
                                                        return (
                                                            <div key={indexs} className="card">
                                                                <span className="custom-badge lightgray">
                                                                    Person
                                                                </span>
                                                                {p.workRole === "External" ? (
                                                                    <span
                                                                        onClick={() => {
                                                                            toggleEXTnNOCards(
                                                                                e._id,
                                                                                "EXT",
                                                                                false,
                                                                                "pill"
                                                                            );
                                                                        }}
                                                                        className="custom-badge blue ext-owner-pill"
                                                                    >
                                                                        EXT
                                                                    </span>
                                                                ) : null}
                                                                {dLead ? (
                                                                    <span className="custom-badge red-o direct-lead">
                                                                        LEAD
                                                                    </span>
                                                                ) : null}
                                                                {udLead ? (
                                                                    <span className="custom-badge gray-o">
                                                                        LEAD
                                                                    </span>
                                                                ) : null}
                                                                {p.tags && p.tags.length > 0
                                                                    ? p.tags.map((r, indext) => (
                                                                        <span
                                                                            key={indext}
                                                                            onClick={() => toggleTagCards(r)}
                                                                            className="custom-badge green tags"
                                                                        >
                                                                            {r}
                                                                        </span>
                                                                    ))
                                                                    : null}
                                                                <a
                                                                    onClick={() => setPepole(p)}
                                                                    className="card-edit"
                                                                >{`${p.fname} ${p.lname}`}</a>
                                                            </div>
                                                        );
                                                    })
                                                    : null}

                                                {allPeopleFD && allPeopleFD.length > 0
                                                    ? allPeopleFD.map((value, indexp) => {
                                                        if (isTag || isEXT || isNO) {
                                                            let Proceed = TagWiseSorting(
                                                                value,
                                                                isTag,
                                                                isEXT,
                                                                isNO,
                                                                tagFilteredObj,
                                                                extNoFilteredObj,
                                                                "people"
                                                            );
                                                            if (!Proceed) return;
                                                        }
                                                        return (
                                                            <div key={indexp} className="card">
                                                                {value.tags && value.tags.length > 0
                                                                    ? value.tags.map((r, indext) => (
                                                                        <span
                                                                            key={indext}
                                                                            onClick={() => toggleTagCards(r)}
                                                                            className="custom-badge green tags"
                                                                        >
                                                                            {r}
                                                                        </span>
                                                                    ))
                                                                    : null}
                                                                <a
                                                                    onClick={() => setPepole(value)}
                                                                    className="card-edit"
                                                                >{`${value.fname} ${value.lname}`}</a>
                                                            </div>
                                                        );
                                                    })
                                                    : null}

                                                {e.coreMembers &&
                                                    e.coreMembers.Skills &&
                                                    e.coreMembers.Skills.length > 0
                                                    ? e.coreMembers.Skills.map((s, indexs) => {
                                                        if (specificData && (isEXT || isNO)) {
                                                            if (
                                                                specificData.data.skill &&
                                                                !specificData.data.skill.includes(s._id)
                                                            ) {
                                                                return;
                                                            }
                                                        } else {
                                                            if (isTag || isEXT || isNO) {
                                                                let Proceed = TagWiseSorting(
                                                                    s,
                                                                    isTag,
                                                                    isEXT,
                                                                    isNO,
                                                                    tagFilteredObj,
                                                                    extNoFilteredObj,
                                                                    "skill"
                                                                );
                                                                if (!Proceed) return;
                                                            }
                                                        }
                                                        let isExternal = IsOwnerExternal(s, "Skill");
                                                        let dLead = false;
                                                        if (
                                                            cardData &&
                                                            Object.keys(cardData).length > 0
                                                        ) {
                                                            if (
                                                                cardData.rAndS &&
                                                                cardData.rAndS.length > 0 &&
                                                                cardData.rAndS === s._id
                                                            ) {
                                                                if (cardData.curType === "Skills") {
                                                                    dLead = true;
                                                                }
                                                            }
                                                        }
                                                        let allOwner = getAllOwnerFCM(s, e._id, "core");
                                                        return (
                                                            <div key={indexs} className="card">
                                                                <span className="custom-badge lightgray">
                                                                    {/* Skill */ GetAliasesName(teams, "Skills")}
                                                                </span>
                                                                {isExternal ? (
                                                                    <span
                                                                        onClick={() => {
                                                                            toggleEXTnNOCards(
                                                                                e._id,
                                                                                "EXT",
                                                                                false,
                                                                                "pill"
                                                                            );
                                                                        }}
                                                                        className="custom-badge blue ext-owner-pill"
                                                                    >
                                                                        EXT
                                                                    </span>
                                                                ) : null}
                                                                {s.owners && s.owners.length > 0 ? null : (
                                                                    <span
                                                                        onClick={() => {
                                                                            toggleEXTnNOCards(
                                                                                e._id,
                                                                                "NO",
                                                                                false,
                                                                                "pill"
                                                                            );
                                                                        }}
                                                                        className="custom-badge no-owner-pill red"
                                                                    >
                                                                        NO
                                                                    </span>
                                                                )}
                                                                {s.ownerType &&
                                                                    s.ownerType.length > 0 &&
                                                                    s.ownerType === "Single owner" ? (
                                                                    <span className="custom-badge blue-o single-owner-pill">
                                                                        1
                                                                    </span>
                                                                ) : null}
                                                                {dLead ? (
                                                                    <span className="custom-badge red-o direct-lead">
                                                                        LEAD
                                                                    </span>
                                                                ) : null}
                                                                {s.tags && s.tags.length > 0
                                                                    ? s.tags.map((r, index) => (
                                                                        <span
                                                                            key={index}
                                                                            onClick={() => toggleTagCards(r)}
                                                                            className="custom-badge green tags"
                                                                        >
                                                                            {r}
                                                                        </span>
                                                                    ))
                                                                    : null}
                                                                <a
                                                                    className="card-edit"
                                                                    onClick={() => setSkill(s)}
                                                                >
                                                                    {s.skillName}
                                                                </a>
                                                                <br />
                                                                {allOwner && allOwner.length > 0
                                                                    ? allOwner.map((value, pdindex) => {
                                                                        return (
                                                                            <span
                                                                                key={pdindex}
                                                                                onClick={() =>
                                                                                    OpenCFPeople(value._id)
                                                                                }
                                                                                className="custom-badge tags blue-text"
                                                                            >{`${value.fname} ${value.lname}`}</span>
                                                                        );
                                                                    })
                                                                    : null}
                                                            </div>
                                                        );
                                                    })
                                                    : null}
                                                {e.coreMembers &&
                                                    e.coreMembers.Roles &&
                                                    e.coreMembers.Roles.length > 0
                                                    ? e.coreMembers.Roles.map((r, indexs) => {
                                                        if (specificData && (isEXT || isNO)) {
                                                            if (
                                                                specificData.data.role &&
                                                                !specificData.data.role.includes(r._id)
                                                            ) {
                                                                return;
                                                            }
                                                        } else {
                                                            if (isTag || isEXT || isNO) {
                                                                let Proceed = TagWiseSorting(
                                                                    r,
                                                                    isTag,
                                                                    isEXT,
                                                                    isNO,
                                                                    tagFilteredObj,
                                                                    extNoFilteredObj,
                                                                    "role"
                                                                );
                                                                if (!Proceed) return;
                                                            }
                                                        }

                                                        let isExternal = IsOwnerExternal(r, "Role");
                                                        let dLead = false;
                                                        if (
                                                            cardData &&
                                                            Object.keys(cardData).length > 0
                                                        ) {
                                                            if (
                                                                cardData.rAndS &&
                                                                cardData.rAndS.length > 0 &&
                                                                cardData.rAndS === r._id
                                                            ) {
                                                                if (cardData.curType === "Roles") {
                                                                    dLead = true;
                                                                }
                                                            }
                                                        }

                                                        let allOwner = getAllOwnerFCM(r, e._id, "core");
                                                        return (
                                                            <div key={indexs} className="card">
                                                                <span className="custom-badge lightgray">
                                                                    {/* Role */GetAliasesName(teams, "Roles")}
                                                                </span>
                                                                {isExternal ? (
                                                                    <span
                                                                        onClick={() => {
                                                                            toggleEXTnNOCards(
                                                                                e._id,
                                                                                "EXT",
                                                                                false,
                                                                                "pill"
                                                                            );
                                                                        }}
                                                                        className="custom-badge blue ext-owner-pill"
                                                                    >
                                                                        EXT
                                                                    </span>
                                                                ) : null}
                                                                {r.owners && r.owners.length === 0 ? (
                                                                    <span
                                                                        onClick={() => {
                                                                            toggleEXTnNOCards(
                                                                                e._id,
                                                                                "NO",
                                                                                false,
                                                                                "pill"
                                                                            );
                                                                        }}
                                                                        className="custom-badge no-owner-pill red"
                                                                    >
                                                                        NO
                                                                    </span>
                                                                ) : null}
                                                                {r.ownerRole &&
                                                                    r.ownerRole.length > 0 &&
                                                                    r.ownerRole === "Single owner" ? (
                                                                    <span className="custom-badge blue-o single-owner-pill">
                                                                        1
                                                                    </span>
                                                                ) : null}
                                                                {dLead ? (
                                                                    <span className="custom-badge red-o direct-lead">
                                                                        LEAD
                                                                    </span>
                                                                ) : null}
                                                                {r.tags && r.tags.length > 0
                                                                    ? r.tags.map((t, indext) => (
                                                                        <span
                                                                            key={indext}
                                                                            onClick={() => toggleTagCards(t)}
                                                                            className="custom-badge green tags"
                                                                        >
                                                                            {t}
                                                                        </span>
                                                                    ))
                                                                    : null}
                                                                <a
                                                                    className="card-edit"
                                                                    onClick={() => setRole(r)}
                                                                >
                                                                    {r.roleName}
                                                                </a>
                                                                <br />
                                                                {allOwner && allOwner.length > 0
                                                                    ? allOwner.map((value, pdindex) => {
                                                                        return (
                                                                            <span
                                                                                key={pdindex}
                                                                                onClick={() =>
                                                                                    OpenCFPeople(value._id)
                                                                                }
                                                                                className="custom-badge tags blue-text"
                                                                            >{`${value.fname} ${value.lname}`}</span>
                                                                        );
                                                                    })
                                                                    : null}
                                                            </div>
                                                        );
                                                    })
                                                    : null}
                                                {e.coreMembers &&
                                                    e.coreMembers.Domains &&
                                                    e.coreMembers.Domains.length > 0
                                                    ? e.coreMembers.Domains.map((d, indexs) => {
                                                        if (specificData && (isEXT || isNO)) {
                                                            if (
                                                                specificData.data.domain &&
                                                                !specificData.data.domain.includes(d._id)
                                                            ) {
                                                                return;
                                                            }
                                                        } else {
                                                            if (isTag || isEXT || isNO) {
                                                                let Proceed = TagWiseSorting(
                                                                    d,
                                                                    isTag,
                                                                    isEXT,
                                                                    isNO,
                                                                    tagFilteredObj,
                                                                    extNoFilteredObj,
                                                                    "domain"
                                                                );
                                                                if (!Proceed) return;
                                                            }
                                                        }

                                                        let curOwner = {};
                                                        if (
                                                            d &&
                                                            Object.keys(d).length > 0 &&
                                                            d.owners &&
                                                            d.owners.owner &&
                                                            d.owners.owner.length > 0
                                                        ) {
                                                            if (peopleSorted && peopleSorted.length > 0) {
                                                                for (
                                                                    let i = 0;
                                                                    i < peopleSorted.length;
                                                                    i++
                                                                ) {
                                                                    if (
                                                                        peopleSorted[i]._id === d.owners.owner
                                                                    ) {
                                                                        curOwner = peopleSorted[i];
                                                                        break;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        let isExternal = IsOwnerExternal(d, "Domain");
                                                        let ownerType = getOwnerTypeFCM(d);
                                                        return (
                                                            <div key={indexs} className="card">
                                                                <span className="custom-badge lightgray">
                                                                    {/* Domain */ GetAliasesName(teams, "Domains")}
                                                                </span>
                                                                {isExternal ? (
                                                                    <span
                                                                        onClick={() => {
                                                                            toggleEXTnNOCards(
                                                                                e._id,
                                                                                "EXT",
                                                                                false,
                                                                                "pill"
                                                                            );
                                                                        }}
                                                                        className="custom-badge blue ext-owner-pill"
                                                                    >
                                                                        EXT
                                                                    </span>
                                                                ) : null}
                                                                {d.owners &&
                                                                    d.owners.owner &&
                                                                    d.owners.owner.length > 0 ? null : (
                                                                    <span
                                                                        onClick={() => {
                                                                            toggleEXTnNOCards(
                                                                                e._id,
                                                                                "NO",
                                                                                false,
                                                                                "pill"
                                                                            );
                                                                        }}
                                                                        className="custom-badge no-owner-pill red"
                                                                    >
                                                                        NO
                                                                    </span>
                                                                )}
                                                                {d.tags && d.tags.length > 0
                                                                    ? d.tags.map((t, indext) => (
                                                                        <span
                                                                            key={indext}
                                                                            onClick={() => toggleTagCards(t)}
                                                                            className="custom-badge green tags"
                                                                        >
                                                                            {t}
                                                                        </span>
                                                                    ))
                                                                    : null}
                                                                <a
                                                                    className="card-edit"
                                                                    onClick={() => setDomain(d)}
                                                                >
                                                                    {d.domainName}
                                                                </a>
                                                                {ownerType && ownerType.name ? (
                                                                    <p
                                                                        onClick={() =>
                                                                            cardOpensCfDomain(ownerType)
                                                                        }
                                                                        className="owner-type"
                                                                    >{`${ownerType.name} (${ownerType.type})`}</p>
                                                                ) : null}
                                                                {curOwner &&
                                                                    Object.keys(curOwner).length > 0 ? (
                                                                    <span
                                                                        onClick={() =>
                                                                            OpenCFPeople(curOwner._id)
                                                                        }
                                                                        className="custom-badge tags blue-text"
                                                                    >{`${curOwner.fname} ${curOwner.lname}`}</span>
                                                                ) : null}
                                                            </div>
                                                        );
                                                    })
                                                    : null}

                                                {e.coreMembers &&
                                                    e.coreMembers.Links &&
                                                    e.coreMembers.Links.length > 0
                                                    ? e.coreMembers.Links.map((l, indexs) => {
                                                        if (specificData && (isEXT || isNO)) {
                                                            if (
                                                                specificData.data.link &&
                                                                !specificData.data.link.includes(l._id)
                                                            ) {
                                                                return;
                                                            }
                                                        } else {
                                                            if (isTag || isEXT || isNO) {
                                                                let Proceed = TagWiseSorting(
                                                                    l,
                                                                    isTag,
                                                                    isEXT,
                                                                    isNO,
                                                                    tagFilteredObj,
                                                                    extNoFilteredObj,
                                                                    "link"
                                                                );
                                                                if (!Proceed) return;
                                                            }
                                                        }
                                                        let isExternal = IsOwnerExternal(l, "Link");
                                                        let curOwner = {};
                                                        if (
                                                            l &&
                                                            Object.keys(l).length > 0 &&
                                                            l.owner &&
                                                            l.owner.length > 0
                                                        ) {
                                                            if (peopleSorted && peopleSorted.length > 0) {
                                                                for (
                                                                    let i = 0;
                                                                    i < peopleSorted.length;
                                                                    i++
                                                                ) {
                                                                    if (peopleSorted[i]._id === l.owner) {
                                                                        curOwner = peopleSorted[i];
                                                                        break;
                                                                    }
                                                                }
                                                            }
                                                        }

                                                        return (
                                                            <div key={indexs} className="card">
                                                                <span className="custom-badge lightgray">
                                                                    {/* Link */GetAliasesName(teams, "Links")}
                                                                </span>
                                                                {isExternal ? (
                                                                    <span
                                                                        onClick={() => {
                                                                            toggleEXTnNOCards(
                                                                                e._id,
                                                                                "EXT",
                                                                                false,
                                                                                "pill"
                                                                            );
                                                                        }}
                                                                        className="custom-badge blue ext-owner-pill"
                                                                    >
                                                                        EXT
                                                                    </span>
                                                                ) : null}
                                                                {l.owner && l.owner.length > 0 ? null : (
                                                                    <span
                                                                        onClick={() => {
                                                                            toggleEXTnNOCards(
                                                                                e._id,
                                                                                "NO",
                                                                                false,
                                                                                "pill"
                                                                            );
                                                                        }}
                                                                        className="custom-badge no-owner-pill red"
                                                                    >
                                                                        NO
                                                                    </span>
                                                                )}
                                                                {l.tags && l.tags.length > 0
                                                                    ? l.tags.map((t, indext) => (
                                                                        <span
                                                                            key={indext}
                                                                            onClick={() => toggleTagCards(t)}
                                                                            className="custom-badge green tags"
                                                                        >
                                                                            {t}
                                                                        </span>
                                                                    ))
                                                                    : null}
                                                                <a
                                                                    className="card-edit"
                                                                    onClick={() => setLink(l)}
                                                                >
                                                                    {l.linkName}
                                                                </a>
                                                                {curOwner &&
                                                                    Object.keys(curOwner).length > 0 ? (
                                                                    <span
                                                                        onClick={() =>
                                                                            OpenCFPeople(curOwner._id)
                                                                        }
                                                                        className="custom-badge tags blue-text"
                                                                    >{`${curOwner.fname} ${curOwner.lname}`}</span>
                                                                ) : null}
                                                            </div>
                                                        );
                                                    })
                                                    : null}
                                            </div>
                                        </div>
                                        <h6 style={{ borderBottom: "1px solid #000" }}>
                                            Extended members
                                        </h6>
                                        <div className="panel panel-default">
                                            <div
                                                id="extended"
                                                className="panel-body single box-container"
                                            >
                                                {e.extendedMembers &&
                                                    e.extendedMembers.People &&
                                                    e.extendedMembers.People.length > 0
                                                    ? e.extendedMembers.People.map((p, indexs) => {
                                                        if (specificData && (isEXT || isNO)) {
                                                            if (
                                                                specificData.data.people &&
                                                                !specificData.data.people.includes(p._id)
                                                            ) {
                                                                return;
                                                            }
                                                        } else {
                                                            if (isTag || isEXT || isNO) {
                                                                let Proceed = TagWiseSorting(
                                                                    e,
                                                                    isTag,
                                                                    isEXT,
                                                                    isNO,
                                                                    tagFilteredObj,
                                                                    extNoFilteredObj,
                                                                    "people"
                                                                );
                                                                if (!Proceed) return;
                                                            }
                                                        }

                                                        let dLead = false;
                                                        let udLead = false;
                                                        if (
                                                            cardData &&
                                                            Object.keys(cardData).length > 0
                                                        ) {
                                                            if (
                                                                cardData.people &&
                                                                cardData.people.length > 0 &&
                                                                cardData.people === p._id
                                                            ) {
                                                                if (
                                                                    cardData.curType === "People" &&
                                                                    cardData.rAndS === null
                                                                ) {
                                                                    dLead = true;
                                                                } else if (
                                                                    (cardData.curType === "Roles" ||
                                                                        cardData.curType === "Skills") &&
                                                                    cardData.rAndS
                                                                ) {
                                                                    udLead = true;
                                                                }
                                                            }
                                                        }
                                                        // console.log(21814, p)
                                                        return (
                                                            <div key={indexs} className="card">
                                                                <span className="custom-badge lightgray">
                                                                    Person
                                                                </span>
                                                                {p.workRole === "External" ? (
                                                                    <span
                                                                        onClick={() => {
                                                                            toggleEXTnNOCards(
                                                                                e._id,
                                                                                "EXT",
                                                                                false,
                                                                                "pill"
                                                                            );
                                                                        }}
                                                                        className="custom-badge blue ext-owner-pill"
                                                                    >
                                                                        EXT
                                                                    </span>
                                                                ) : null}
                                                                {dLead ? (
                                                                    <span className="custom-badge red-o direct-lead">
                                                                        LEAD
                                                                    </span>
                                                                ) : null}
                                                                {udLead ? (
                                                                    <span className="custom-badge gray-o">
                                                                        LEAD
                                                                    </span>
                                                                ) : null}
                                                                {p.tags && p.tags.length > 0
                                                                    ? p.tags.map((r, indext) => (
                                                                        <span
                                                                            key={indext}
                                                                            onClick={() => toggleTagCards(r)}
                                                                            className="custom-badge green tags"
                                                                        >
                                                                            {r}
                                                                        </span>
                                                                    ))
                                                                    : null}
                                                                <a
                                                                    onClick={() => setPepole(p)}
                                                                    className="card-edit"
                                                                >{`${p.fname} ${p.lname}`}</a>
                                                            </div>
                                                        );
                                                    })
                                                    : null}
                                                {e.extendedMembers &&
                                                    e.extendedMembers.Skills &&
                                                    e.extendedMembers.Skills.length > 0
                                                    ? e.extendedMembers.Skills.map((s, indexs) => {
                                                        if (specificData && (isEXT || isNO)) {
                                                            if (
                                                                specificData.data.skill &&
                                                                !specificData.data.skill.includes(s._id)
                                                            ) {
                                                                return;
                                                            }
                                                        } else {
                                                            if (isTag || isEXT || isNO) {
                                                                let Proceed = TagWiseSorting(
                                                                    s,
                                                                    isTag,
                                                                    isEXT,
                                                                    isNO,
                                                                    tagFilteredObj,
                                                                    extNoFilteredObj,
                                                                    "skill"
                                                                );
                                                                if (!Proceed) return;
                                                            }
                                                        }

                                                        let isExternal = IsOwnerExternal(s, "Skill");
                                                        let dLead = false;
                                                        if (
                                                            cardData &&
                                                            Object.keys(cardData).length > 0
                                                        ) {
                                                            if (
                                                                cardData.rAndS &&
                                                                cardData.rAndS.length > 0 &&
                                                                cardData.rAndS === s._id
                                                            ) {
                                                                if (cardData.curType === "Skills") {
                                                                    dLead = true;
                                                                }
                                                            }
                                                        }
                                                        let allOwner = getAllOwnerFCM(
                                                            s,
                                                            e._id,
                                                            "extended"
                                                        );
                                                        return (
                                                            <div key={indexs} className="card">
                                                                <span className="custom-badge lightgray">
                                                                    {/* Skill */ GetAliasesName(teams, "Skills")}
                                                                </span>
                                                                {isExternal ? (
                                                                    <span
                                                                        onClick={() => {
                                                                            toggleEXTnNOCards(
                                                                                e._id,
                                                                                "EXT",
                                                                                false,
                                                                                "pill"
                                                                            );
                                                                        }}
                                                                        className="custom-badge blue ext-owner-pill"
                                                                    >
                                                                        EXT
                                                                    </span>
                                                                ) : null}
                                                                {s.owners && s.owners.length === 0 ? (
                                                                    <span
                                                                        onClick={() => {
                                                                            toggleEXTnNOCards(
                                                                                e._id,
                                                                                "NO",
                                                                                false,
                                                                                "pill"
                                                                            );
                                                                        }}
                                                                        className="custom-badge no-owner-pill red"
                                                                    >
                                                                        NO
                                                                    </span>
                                                                ) : null}
                                                                {s.ownerType &&
                                                                    s.ownerType.length > 0 &&
                                                                    e.ownerType === "Single owner" ? (
                                                                    <span className="custom-badge blue-o single-owner-pill">
                                                                        1
                                                                    </span>
                                                                ) : null}
                                                                {dLead ? (
                                                                    <span className="custom-badge red-o direct-lead">
                                                                        LEAD
                                                                    </span>
                                                                ) : null}
                                                                {s.tags && s.tags.length > 0
                                                                    ? s.tags.map((r, index) => (
                                                                        <span
                                                                            key={index}
                                                                            onClick={() => toggleTagCards(r)}
                                                                            className="custom-badge green tags"
                                                                        >
                                                                            {r}
                                                                        </span>
                                                                    ))
                                                                    : null}
                                                                <a
                                                                    className="card-edit"
                                                                    onClick={() => setSkill(s)}
                                                                >
                                                                    {s.skillName}
                                                                </a>
                                                                <br />
                                                                {allOwner && allOwner.length > 0
                                                                    ? allOwner.map((value, pdindex) => {
                                                                        return (
                                                                            <span
                                                                                key={pdindex}
                                                                                onClick={() =>
                                                                                    OpenCFPeople(value._id)
                                                                                }
                                                                                className="custom-badge tags blue-text"
                                                                            >{`${value.fname} ${value.lname}`}</span>
                                                                        );
                                                                    })
                                                                    : null}
                                                            </div>
                                                        );
                                                    })
                                                    : null}
                                                {e.extendedMembers &&
                                                    e.extendedMembers.Roles &&
                                                    e.extendedMembers.Roles.length > 0
                                                    ? e.extendedMembers.Roles.map((r, indexs) => {
                                                        if (specificData && (isEXT || isNO)) {
                                                            if (
                                                                specificData.data.role &&
                                                                !specificData.data.role.includes(r._id)
                                                            ) {
                                                                return;
                                                            }
                                                        } else {
                                                            if (isTag || isEXT || isNO) {
                                                                let Proceed = TagWiseSorting(
                                                                    r,
                                                                    isTag,
                                                                    isEXT,
                                                                    isNO,
                                                                    tagFilteredObj,
                                                                    extNoFilteredObj,
                                                                    "role"
                                                                );
                                                                if (!Proceed) return;
                                                            }
                                                        }

                                                        let isExternal = IsOwnerExternal(r, "Role");
                                                        let dLead = false;
                                                        if (
                                                            cardData &&
                                                            Object.keys(cardData).length > 0
                                                        ) {
                                                            if (
                                                                cardData.rAndS &&
                                                                cardData.rAndS.length > 0 &&
                                                                cardData.rAndS === r._id
                                                            ) {
                                                                if (cardData.curType === "Roles") {
                                                                    dLead = true;
                                                                }
                                                            }
                                                        }

                                                        let allOwner = getAllOwnerFCM(
                                                            r,
                                                            e._id,
                                                            "extended"
                                                        );
                                                        return (
                                                            <div key={indexs} className="card">
                                                                <span className="custom-badge lightgray">
                                                                    {/* Role */GetAliasesName(teams, "Roles")}
                                                                </span>
                                                                {isExternal ? (
                                                                    <span
                                                                        onClick={() => {
                                                                            toggleEXTnNOCards(
                                                                                e._id,
                                                                                "EXT",
                                                                                false,
                                                                                "pill"
                                                                            );
                                                                        }}
                                                                        className="custom-badge blue ext-owner-pill"
                                                                    >
                                                                        EXT
                                                                    </span>
                                                                ) : null}
                                                                {r.owners && r.owners.length === 0 ? (
                                                                    <span
                                                                        onClick={() => {
                                                                            toggleEXTnNOCards(
                                                                                e._id,
                                                                                "NO",
                                                                                false,
                                                                                "pill"
                                                                            );
                                                                        }}
                                                                        className="custom-badge no-owner-pill red"
                                                                    >
                                                                        NO
                                                                    </span>
                                                                ) : null}
                                                                {r.ownerRole.length > 0 &&
                                                                    e.ownerRole === "Single owner" ? (
                                                                    <span className="custom-badge blue-o single-owner-pill">
                                                                        1
                                                                    </span>
                                                                ) : null}
                                                                {dLead ? (
                                                                    <span className="custom-badge red-o direct-lead">
                                                                        LEAD
                                                                    </span>
                                                                ) : null}
                                                                {r.tags && r.tags.length > 0
                                                                    ? r.tags.map((t, indext) => (
                                                                        <span
                                                                            key={indext}
                                                                            onClick={() => toggleTagCards(t)}
                                                                            className="custom-badge green tags"
                                                                        >
                                                                            {t}
                                                                        </span>
                                                                    ))
                                                                    : null}
                                                                <a
                                                                    className="card-edit"
                                                                    onClick={() => setRole(r)}
                                                                >
                                                                    {r.roleName}
                                                                </a>
                                                                <br />
                                                                {allOwner && allOwner.length > 0
                                                                    ? allOwner.map((value, pdindex) => {
                                                                        return (
                                                                            <span
                                                                                key={pdindex}
                                                                                onClick={() =>
                                                                                    OpenCFPeople(value._id)
                                                                                }
                                                                                className="custom-badge tags blue-text"
                                                                            >{`${value.fname} ${value.lname}`}</span>
                                                                        );
                                                                    })
                                                                    : null}
                                                            </div>
                                                        );
                                                    })
                                                    : null}
                                                {e.extendedMembers &&
                                                    e.extendedMembers.Domains &&
                                                    e.extendedMembers.Domains.length > 0
                                                    ? e.extendedMembers.Domains.map((d, indexs) => {
                                                        if (specificData && (isEXT || isNO)) {
                                                            if (
                                                                specificData.data.domain &&
                                                                !specificData.data.domain.includes(d._id)
                                                            ) {
                                                                return;
                                                            }
                                                        } else {
                                                            if (isTag || isEXT || isNO) {
                                                                let Proceed = TagWiseSorting(
                                                                    d,
                                                                    isTag,
                                                                    isEXT,
                                                                    isNO,
                                                                    tagFilteredObj,
                                                                    extNoFilteredObj,
                                                                    "domain"
                                                                );
                                                                if (!Proceed) return;
                                                            }
                                                        }

                                                        let curOwner = {};
                                                        if (
                                                            d &&
                                                            Object.keys(d).length > 0 &&
                                                            d.owners &&
                                                            d.owners.owner &&
                                                            d.owners.owner.length > 0
                                                        ) {
                                                            if (peopleSorted && peopleSorted.length > 0) {
                                                                for (
                                                                    let i = 0;
                                                                    i < peopleSorted.length;
                                                                    i++
                                                                ) {
                                                                    if (
                                                                        peopleSorted[i]._id === d.owners.owner
                                                                    ) {
                                                                        curOwner = peopleSorted[i];
                                                                        break;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        let isExternal = IsOwnerExternal(d, "Domain");
                                                        let ownerType = getOwnerTypeFCM(d);
                                                        return (
                                                            <div key={indexs} className="card">
                                                                <span className="custom-badge lightgray">
                                                                    {/* Domain */ GetAliasesName(teams, "Domains")}
                                                                </span>
                                                                {isExternal ? (
                                                                    <span
                                                                        onClick={() => {
                                                                            toggleEXTnNOCards(
                                                                                e._id,
                                                                                "EXT",
                                                                                false,
                                                                                "pill"
                                                                            );
                                                                        }}
                                                                        className="custom-badge blue ext-owner-pill"
                                                                    >
                                                                        EXT
                                                                    </span>
                                                                ) : null}
                                                                {d.owners &&
                                                                    d.owners.owner &&
                                                                    d.owners.owner.length > 0 ? null : (
                                                                    <span
                                                                        onClick={() => {
                                                                            toggleEXTnNOCards(
                                                                                e._id,
                                                                                "NO",
                                                                                false,
                                                                                "pill"
                                                                            );
                                                                        }}
                                                                        className="custom-badge no-owner-pill red"
                                                                    >
                                                                        NO
                                                                    </span>
                                                                )}
                                                                {d.tags && d.tags.length > 0
                                                                    ? d.tags.map((t, indext) => (
                                                                        <span
                                                                            key={indext}
                                                                            onClick={() => toggleTagCards(t)}
                                                                            className="custom-badge green tags"
                                                                        >
                                                                            {t}
                                                                        </span>
                                                                    ))
                                                                    : null}
                                                                <a
                                                                    className="card-edit"
                                                                    onClick={() => setDomain(d)}
                                                                >
                                                                    {d.domainName}
                                                                </a>
                                                                {ownerType && ownerType.name ? (
                                                                    <p
                                                                        onClick={() =>
                                                                            cardOpensCfDomain(ownerType)
                                                                        }
                                                                        className="owner-type"
                                                                    >{`${ownerType.name} (${ownerType.type})`}</p>
                                                                ) : null}
                                                                {curOwner &&
                                                                    Object.keys(curOwner).length > 0 ? (
                                                                    <span
                                                                        onClick={() =>
                                                                            OpenCFPeople(curOwner._id)
                                                                        }
                                                                        className="custom-badge tags blue-text"
                                                                    >{`${curOwner.fname} ${curOwner.lname}`}</span>
                                                                ) : null}
                                                            </div>
                                                        );
                                                    })
                                                    : null}
                                                {e.extendedMembers &&
                                                    e.extendedMembers.Links &&
                                                    e.extendedMembers.Links.length > 0
                                                    ? e.extendedMembers.Links.map((l, indexs) => {
                                                        if (specificData && (isEXT || isNO)) {
                                                            if (
                                                                specificData.data.link &&
                                                                !specificData.data.link.includes(l._id)
                                                            ) {
                                                                return;
                                                            }
                                                        } else {
                                                            if (isTag || isEXT || isNO) {
                                                                let Proceed = TagWiseSorting(
                                                                    l,
                                                                    isTag,
                                                                    isEXT,
                                                                    isNO,
                                                                    tagFilteredObj,
                                                                    extNoFilteredObj,
                                                                    "link"
                                                                );
                                                                if (!Proceed) return;
                                                            }
                                                        }

                                                        let isExternal = IsOwnerExternal(l, "Link");
                                                        let curOwner = {};
                                                        if (
                                                            l &&
                                                            Object.keys(l).length > 0 &&
                                                            l.owner &&
                                                            l.owner.length > 0
                                                        ) {
                                                            if (peopleSorted && peopleSorted.length > 0) {
                                                                for (
                                                                    let i = 0;
                                                                    i < peopleSorted.length;
                                                                    i++
                                                                ) {
                                                                    if (peopleSorted[i]._id === l.owner) {
                                                                        curOwner = peopleSorted[i];
                                                                        break;
                                                                    }
                                                                }
                                                            }
                                                        }

                                                        return (
                                                            <div key={indexs} className="card">
                                                                <span className="custom-badge lightgray">
                                                                    {/* Link */GetAliasesName(teams, "Links")}
                                                                </span>
                                                                {isExternal ? (
                                                                    <span
                                                                        onClick={() => {
                                                                            toggleEXTnNOCards(
                                                                                e._id,
                                                                                "EXT",
                                                                                false,
                                                                                "pill"
                                                                            );
                                                                        }}
                                                                        className="custom-badge blue ext-owner-pill"
                                                                    >
                                                                        EXT
                                                                    </span>
                                                                ) : null}
                                                                {l.owner && l.owner.length > 0 ? null : (
                                                                    <span
                                                                        onClick={() => {
                                                                            toggleEXTnNOCards(
                                                                                e._id,
                                                                                "NO",
                                                                                false,
                                                                                "pill"
                                                                            );
                                                                        }}
                                                                        className="custom-badge no-owner-pill red"
                                                                    >
                                                                        NO
                                                                    </span>
                                                                )}
                                                                {l.tags && l.tags.length > 0
                                                                    ? l.tags.map((t, indext) => (
                                                                        <span
                                                                            key={indext}
                                                                            onClick={() => toggleTagCards(t)}
                                                                            className="custom-badge green tags"
                                                                        >
                                                                            {t}
                                                                        </span>
                                                                    ))
                                                                    : null}
                                                                <a
                                                                    className="card-edit"
                                                                    onClick={() => setLink(l)}
                                                                >
                                                                    {l.linkName}
                                                                </a>
                                                                {curOwner &&
                                                                    Object.keys(curOwner).length > 0 ? (
                                                                    <span
                                                                        onClick={() =>
                                                                            OpenCFPeople(curOwner._id)
                                                                        }
                                                                        className="custom-badge tags blue-text"
                                                                    >{`${curOwner.fname} ${curOwner.lname}`}</span>
                                                                ) : null}
                                                            </div>
                                                        );
                                                    })
                                                    : null}
                                            </div>
                                        </div>
                                        <h6 style={{ borderBottom: "1px solid #000" }}>
                                            {/* Projects */ GetAliasesName(teams, "Projects")}
                                        </h6>
                                        <div className="panel panel-default">
                                            <div
                                                id="notmatched"
                                                className="panel-body single box-container"
                                            >
                                                {e.coreMembers &&
                                                    e.coreMembers.Projects &&
                                                    e.coreMembers.Projects.length > 0
                                                    ? e.coreMembers.Projects.map((p, indexs) => {
                                                        if (isTag || isEXT || isNO) {
                                                            let Proceed = TagWiseSorting(
                                                                p,
                                                                isTag,
                                                                isEXT,
                                                                isNO,
                                                                tagFilteredObj,
                                                                extNoFilteredObj,
                                                                "project"
                                                            );
                                                            if (!Proceed) return;
                                                        }
                                                        return (
                                                            <div key={indexs} className="card">
                                                                {/* <span className="custom-badge lightgray">
                                Project
                              </span> */}
                                                                {p.tags && p.tags.length > 0
                                                                    ? p.tags.map((t, indext) => (
                                                                        <span
                                                                            key={indext}
                                                                            onClick={() => toggleTagCards(t)}
                                                                            className="custom-badge green tags"
                                                                        >
                                                                            {t}
                                                                        </span>
                                                                    ))
                                                                    : null}
                                                                <a
                                                                    className="card-edit"
                                                                    onClick={() => setProject(p)}
                                                                >
                                                                    {p.projectName}
                                                                </a>
                                                            </div>
                                                        );
                                                    })
                                                    : null}
                                            </div>
                                        </div>
                                        <h6
                                            style={{ borderBottom: "1px solid #000" }}
                                            className="meetings-heading"
                                        >
                                            Meetings
                                        </h6>
                                        <div className="panel panel-default">
                                            <div
                                                id="meetings"
                                                className="panel-body single box-container"
                                            >
                                                {meetingsData && meetingsData.length > 0
                                                    ? meetingsData.map((m, indexm) => {
                                                        return (
                                                            <div className="card meeting-card ">
                                                                <div className="meeting-content">
                                                                    <p>
                                                                        <span className="fas fa-users"></span>
                                                                        <b style={{ marginLeft: "5px" }}>
                                                                            {m.meetingsName}
                                                                        </b>
                                                                    </p>
                                                                    <p>{m.meetingsPurpose}</p>
                                                                    {m.recurrenceType === "As needed" ||
                                                                        m.durationType === "As needed" ? (
                                                                        <>
                                                                            <p>
                                                                                {m.recurrenceType} {m.durationType}
                                                                            </p>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {" "}
                                                                            {m.recurrence && (
                                                                                <p>
                                                                                    Every {m.recurrence}{" "}
                                                                                    {m.recurrenceType}{" "}
                                                                                    {m.duration && (
                                                                                        <span>
                                                                                            for {m.duration}{" "}
                                                                                            {m.durationType}
                                                                                        </span>
                                                                                    )}
                                                                                </p>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                    <p>{m.XTD ? "incl. XTD" : null}</p>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                    : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    );
                })
                : null}
        </div>
    )
}
