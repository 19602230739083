export const DispatchBlocker = (getState) => {
    const state = getState();
    let { curUserData: { curTeam }, myInfo: { userId } } = state.auth;
    let { teams } = state.teams;
    const targetTeam = teams.find((st) => st._id === curTeam);
    // console.log(targetTeam.controlledBy,userId)
    if (targetTeam && Object.keys(targetTeam).length > 0 && targetTeam.controlledBy && targetTeam.controlledBy !== userId) {
        // console.log("----block----");
        return false;
    }
    // console.log(true)
    return true;
};
