import React from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PillComponent from '../PillComponent';

export default function CircleList({tagFilteredMegedData, handleAliesChange, GetAliasesName, filteredCData, getSortIcon, isInfos, setCircles, pCardHideOnchange, setFocusedMode, noteOnchange, note, handeSorts, setCombinedRefs, handleSave, noteSaveData, circleSearch, setCircleSearch, tagFilteredObj, statesLM, ScrollIntoView, setCircle, parentCircleRef, setCardRef, toggleTagCards, handelFocused, circleSubmit, dCircles, cref, circle, handleCircleChange, setCircleName, circlesFrom, compareMode, teams, sCref, isTag, lFromState
}) {
    if (!compareMode.active) {
        return (
            <div
                className={
                    Object.keys(tagFilteredMegedData).length > 0 &&
                        tagFilteredMegedData.circle &&
                        tagFilteredMegedData.circle.length === 0
                        ? "board-col hide"
                        : "board-col"
                }
                data-board_id="circles"
            >
                <div className="list list-bg-red" data-list_id="circles">
                    <div className="dropdown">
                        <h4 className="list-title">
                            <img alt="" src="images/icon-circle.png" />
                            <span contentEditable={true}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault(); // Prevent new line
                                    }
                                }} data-name="circles" onBlur={(e) => handleAliesChange(e)}>{GetAliasesName(teams, "Circles")}</span>
                            <span>{` (${filteredCData.length})`}</span>{" "}
                            <a className="list-menu refresh-list hide" href="#">
                                <i className="fas fa-sync-alt"></i>
                            </a>{" "}
                            <a
                                className="list-menu dropdown-toggle"
                                data-toggle="dropdown"
                                href="#"
                            >
                                {getSortIcon("Circles")}
                                <i className="fas fa-ellipsis-v"></i>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-right" role="menu">
                                <li role="presentation">
                                    <a
                                        onClick={() => isInfos("Circles")}
                                        role="menuitem"
                                        className="mytpt-list-info"
                                    >
                                        Info
                                    </a>
                                </li>
                                <li role="presentation">
                                    <a role="menuitem" onClick={() => setCircles()}>
                                        Add new {GetAliasesName(teams, "Circles").toLowerCase()}
                                    </a>
                                </li>
                                <li role="presentation">
                                    <a
                                        role="menuitem"
                                        onClick={() => pCardHideOnchange("Circles")}
                                        className="hide_list"
                                    >
                                        Hide list
                                    </a>
                                </li>
                                <li
                                    onClick={() => setFocusedMode("Circle")}
                                    role="presentation"
                                >
                                    <a role="menuitem" className="circle-mode">
                                        Enter circle mode
                                    </a>
                                </li>
                                <li role="presentation">
                                    <a role="menuitem" style={{ paddingRight: "5px" }}>
                                        Show Notes
                                        <label className="switch">
                                            <input
                                                name="Circles"
                                                onChange={(e) => noteOnchange(e)}
                                                checked={note.Circles}
                                                className="dd-switch"
                                                type="checkbox"
                                            />
                                            <span className="slider"></span>
                                        </label>
                                    </a>
                                </li>

                                <li role="presentation" className="divider"></li>
                                <li className="dropdown-header">Sort by:</li>
                                <li role="presentation">
                                    <a
                                        role="menuitem"
                                        className="sortcards"
                                        onClick={() => handeSorts("NewToOld", "Circles")}
                                    >
                                        New to Old
                                    </a>
                                </li>
                                <li role="presentation">
                                    <a
                                        role="menuitem"
                                        className="sortcards"
                                        onClick={() => handeSorts("OldToNew", "Circles")}
                                    >
                                        Old to New
                                    </a>
                                </li>
                                <li role="presentation">
                                    <a
                                        role="menuitem"
                                        className="sortcards"
                                        data-sort_order="asc"
                                        onClick={() => handeSorts("AtoZ", "Circles")}
                                    >
                                        A-Z
                                    </a>
                                </li>
                                <li role="presentation">
                                    <a
                                        role="menuitem"
                                        className="sortcards"
                                        data-sort_order="desc"
                                        onClick={() => handeSorts("ZtoA", "Circles")}
                                    >
                                        Z-A
                                    </a>
                                </li>
                            </ul>
                        </h4>
                    </div>
                    <Droppable droppableId="circles" direction="vertical">
                        {(circlesProvided) => (
                            <div
                                // ref={circlesProvided.innerRef}
                                ref={setCombinedRefs(
                                    circlesProvided.innerRef,
                                    parentCircleRef
                                )}
                                {...circlesProvided.droppableProps}
                                className="card-list sortable-card-list scrollbar"
                            >
                                <div
                                    className="form-group notes"
                                    style={
                                        note.Circles ? { display: "block" } : { display: "none" }
                                    }
                                >
                                    <textarea
                                        className="notes"
                                        name="Circles"
                                        // defaultValue=''
                                        // value={noteSaveData.Circles}
                                        //   onChange={(e) => handleSave(e)}
                                        onBlur={(e) => handleSave(e)}
                                        placeholder="Notes"
                                        maxLength="100"
                                        defaultValue={noteSaveData.Circles}
                                    ></textarea>
                                </div>
                                <span className="clearable">
                                    <input
                                        type="text"
                                        ref={sCref}
                                        style={{ color: "black" }}
                                        value={circleSearch}
                                        onChange={(e) => setCircleSearch(e.target.value)}
                                        className="form-control search_list transparent"
                                        name="search_list"
                                        placeholder="Start typing to search..."
                                        autoComplete="off"
                                    />
                                    {circleSearch.length > 0 ? (
                                        <i
                                            className="clearable__clear"
                                            onClick={() => setCircleSearch("")}
                                            style={{ display: "inline", color: "black" }}
                                        >
                                            ×
                                        </i>
                                    ) : null}
                                </span>

                                {filteredCData && filteredCData.length > 0
                                    ? filteredCData.map((e, index) => {
                                        if (
                                            isTag &&
                                            Object.keys(tagFilteredObj).length > 0 &&
                                            tagFilteredObj.circle &&
                                            tagFilteredObj.circle.length > 0 &&
                                            !tagFilteredObj.circle.includes(e._id)
                                        ) {
                                            return null;
                                        }
                                        return (
                                            <Draggable
                                                key={index}
                                                draggableId={`${e._id}`}
                                                index={index}
                                                isDragDisabled={
                                                    statesLM.Circles !== "Parsonal" ? true : false
                                                }
                                            >
                                                {(circlesProvided) => (
                                                    <div
                                                        ref={circlesProvided.innerRef}
                                                        {...circlesProvided.draggableProps}
                                                        {...circlesProvided.dragHandleProps}
                                                        key={index}
                                                        // onDragStart={(e) => circlesDragStart(e, index)}
                                                        // onDragEnter={(e) => circlesDragEnter(e, index)}
                                                        // onDragEnd={circlesDrop}
                                                        className={`card ${localStorage.getItem("latest-created") ===
                                                            e._id
                                                            ? "latest-created"
                                                            : ""
                                                            }`}
                                                        data-list_id="circles"
                                                        onClick={(event) => {
                                                            ScrollIntoView();
                                                            if (
                                                                event.target.classList.contains("card") ||
                                                                event.target.classList.contains("card-edit")
                                                            ) {
                                                                setCircle(e, filteredCData, index);
                                                            }
                                                            const scrollTop =
                                                                parentCircleRef.current.scrollTop;
                                                            setCardRef(prevCardRef =>
                                                                prevCardRef.map(item =>
                                                                    item.type === "circle" ? { ...item, val: scrollTop } : item
                                                                )
                                                            );
                                                        }}
                                                    >
                                                        <div className="static-relations">
                                                            {e.tags && e.tags.length > 0
                                                                ? e.tags.map((r, indexs) => (
                                                                    <span
                                                                        onClick={() => toggleTagCards(r)}
                                                                        key={indexs}
                                                                        className="custom-badge green tags"
                                                                    >
                                                                        {r}
                                                                    </span>
                                                                ))
                                                                : null}
                                                        </div>
                                                        <a
                                                            // onClick={() => setCircle(e, filteredCData, index)}
                                                            className="card-edit"
                                                        >
                                                            {e.circleName}
                                                        </a>
                                                        <a className="card-focus">
                                                            <img
                                                                onClick={() =>
                                                                    handelFocused({
                                                                        card: "Circles",
                                                                        index: index,
                                                                        id: e._id,
                                                                    })
                                                                }
                                                                src="./images/newicons/focus.svg"
                                                            />
                                                        </a>
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    })
                                    : null}

                                {dCircles && dCircles.length > 0
                                    ? dCircles.map((d, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="card default-circle"
                                                data-card_name="Circle Lead"
                                                data-default="true"
                                                data-html2canvas-ignore="true"
                                            >
                                                <span className="static-relations">
                                                    {d.tags && d.tags.length > 0
                                                        ? d.tags.map((r, indexs) => (
                                                            <span
                                                                onClick={() => toggleTagCards(r)}
                                                                key={indexs}
                                                                className="custom-badge green tags"
                                                            >
                                                                {r}
                                                            </span>
                                                        ))
                                                        : null}
                                                </span>
                                                <a
                                                    onClick={() => {
                                                        setCircle(d, dCircles, index);
                                                        const scrollTop =
                                                            parentCircleRef.current.scrollTop;
                                                        setCardRef(prevCardRef =>
                                                            prevCardRef.map(item =>
                                                                item.type === "circle" ? { ...item, val: scrollTop } : item
                                                            )
                                                        );
                                                    }}
                                                    className="card-edit"
                                                >
                                                    {d.circleName === "Team Circle" ? "Entire Team" : d.circleName}
                                                </a>
                                            </div>
                                        );
                                    })
                                    : null}

                                {circlesProvided.placeholder}

                                <div
                                    className="card2"
                                    style={
                                        lFromState.Circles
                                            ? { display: "block" }
                                            : { display: "none" }
                                    }
                                >
                                    <form
                                        className="form-new-card"
                                        role="form"
                                        onSubmit={circleSubmit}
                                    >
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                ref={cref}
                                                name="card_name"
                                                value={circle}
                                                onChange={handleCircleChange}
                                                required
                                                placeholder={`+ Add ${GetAliasesName(teams, "Circles").toLowerCase()} name`}
                                                className="form-control"
                                                autoComplete="off"
                                            />
                                        </div>
                                        <div className="form-group" style={{ marginTop: "10px" }}>
                                            <button
                                                type="submit"
                                                className="btn btn-mytpt add-quick close-add-card"
                                            >
                                                Save
                                            </button>
                                            <a
                                                href="#"
                                                type="button"
                                                onClick={() => {
                                                    setCircles({ circleName: circle });
                                                    setCircleName("");
                                                    lFromState.Circles = false;
                                                }}
                                                className="btn btn-link underline open-tool"
                                            >
                                                Edit details
                                            </a>
                                            <a
                                                href="#"
                                                onClick={() => circlesFrom()}
                                                className="btn btn-link underline close-add-card"
                                            >
                                                Close
                                            </a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        )}
                    </Droppable>

                    <a
                        className="btn-list add-card"
                        onClick={() => circlesFrom()}
                        data-list_id="circles"
                        style={
                            !lFromState.Circles ? { display: "block" } : { display: "none" }
                        }
                    >
                        + Add new {/* circle */}{GetAliasesName(teams, "Circles").toLowerCase()}
                    </a>
                </div>
            </div>
        );
    } else {
        return <></>;
    }
}
