export const ROLE_CREATE_SUCCESS = 'ROLE_CREATE_SUCCESS'
export const ROLE_CREATE_FAIL = 'ROLE_CREATE_FAIL'
export const ROLE_GET_SUCCESS = 'ROLE_GET_SUCCESS'
export const ROLE_GET_FAIL = 'ROLE_GET_FAIL'
export const ROLE_DELETE_SUCCESS = 'ROLE_DELETE_SUCCESS'
export const ROLE_UPDATE_SUCCESS = 'ROLE_UPDATE_SUCCESS'
export const ROLE_UPDATE_FAIL = 'ROLE_UPDATE_FAIL'
export const ROLE_DELETE_FAIL = 'ROLE_DELETE_FAIL'
export const ROLE_DROP_UPDATE_SUCCESS = 'ROLE_DROP_UPDATE_SUCCESS'
export const ROLE_DROP_UPDATE_FAIL = 'ROLE_DROP_UPDATE_FAIL'

export const ROLE_ID_CLEAR = 'ROLE_ID_CLEAR'


export const ROLE_SUCCESS_MESSAGE_CLEAR = 'ROLE_SUCCESS_MESSAGE_CLEAR'
export const ROLE_ERROR_CLEAR = 'ROLE_ERROR_CLEAR'
export const SIGN_OUT = 'SIGN_OUT'

export const ROLES_MODIFICATIONS = 'ROLES_MODIFICATIONS'