export const PFMEMBER_CREATE_SUCCESS = 'PFMEMBER_CREATE_SUCCEPFMEMBERSS'
export const PFMEMBER_CREATE_FAIL = 'PFMEMBER_CREATE_FAIL'
export const PFMEMBER_GET_SUCCESS = 'PFMEMBER_GET_SUCCESS'
export const PFMEMBER_GET_FAIL = 'PFMEMBER_GET_FAIL'
export const PFMEMBER_DELETE_SUCCESS = 'PFMEMBER_DELETE_SUCCESS'
export const PFMEMBER_UPDATE_SUCCESS = 'PFMEMBER_UPDATE_SUCCESS'
export const PFMEMBER_UPDATE_FAIL = 'PFMEMBER_UPDATE_FAIL'
export const PFMEMBER_DELETE_FAIL = 'PFMEMBER_DELETE_FAIL'


export const PFMEMBER_SUCCESS_MESSAGE_CLEAR = 'PFMEMBER_SUCCESS_MESSAGE_CLEAR'
export const PFMEMBER_ERROR_CLEAR = 'PFMEMBER_ERROR_CLEAR'
export const SIGN_OUT = 'SIGN_OUT'