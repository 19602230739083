import React from 'react'
// import TextareaAutosize from "react-textarea-autosize";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';

export default function Tasks({formValues, onDragStart, onDragOver, onDrop, handleForm, handleKeyPress, removeFormFields}) {
    return (
        <>
            <h4>Tasks</h4>
            <div className="row task-list">
                {formValues && formValues.length > 0 ?
                    formValues.map((element, index) => {
                        return (
                            <div
                                draggable
                                onDragStart={(e) => onDragStart(e, index)}
                                onDragOver={(e) => onDragOver(e, index)}
                                onDrop={(e) => onDrop(e, index)}
                                className={formValues.length - 1 === index && element.tasks.length === 0 ? "col-xs-12 tasks new-task" : "col-xs-12 task"}
                                key={index}
                                style={{ position: "relative", cursor: 'move' }}
                            >
                                <TextareaAutosize
                                    cacheMeasurements
                                    id={`task${index}`}
                                    type="text"
                                    placeholder="+ Add task"
                                    name="tasks"
                                    className="form-control logit"
                                    value={element.tasks || ""}
                                    onChange={(e) => handleForm(index, e)}
                                    onKeyPress={handleKeyPress}
                                >
                                    <span className="fa fa-circle"> </span>
                                </TextareaAutosize>
                                {formValues.length - 1 !== index &&
                                    element.tasks.length !== 0 ? (
                                    <button
                                        type="button"
                                        className="button remove btn btn-remove"
                                        onClick={() => removeFormFields(index)}
                                    >
                                        <span
                                            style={{ paddingLeft: "15%" }}
                                            className="fa fa-times"
                                            aria-hidden="true"
                                        ></span>
                                    </button>
                                ) : null}
                            </div>
                        );
                    }) : null}
            </div>
        </>
    )
}
