import React, { useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { AddOnVerify } from '../../../store/actions/authAction';
import { PAYMENT_VERIFIY_MSG_CLEAR } from '../../../store/types/authType';
import { CLIENT_URL } from '../../../config/keys';
import { SyncLoader } from 'react-spinners';

const AddOn = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    let sessionId = searchParams.get("session_id");
    let plan = searchParams.get("plan");

    const { loading, successMessage, paySuccessMessage, payErrorMessage, error, authenticate, myInfo, curUserData } =
        useSelector((state) => state.auth);

    useEffect(() => {
        dispatch(AddOnVerify({
            userId: myInfo.userId,
            session_id: sessionId,
            plan
        }))
    }, []);

    useEffect(() => {
        if (paySuccessMessage && paySuccessMessage !== "") {
            dispatch({ type: PAYMENT_VERIFIY_MSG_CLEAR });
            // console.log("success==========>",paySuccessMessage)
            window.location.href = `${CLIENT_URL}/?am=true`;
        } else if(payErrorMessage && payErrorMessage !== "") {
            dispatch({ type: PAYMENT_VERIFIY_MSG_CLEAR });
            // console.log("fail==========>",paySuccessMessage)
            window.location.href = `${CLIENT_URL}`;
        }
    }, [paySuccessMessage,payErrorMessage])


    return (
        <div className="page-loader">
            <SyncLoader color="#36d7b7" />
        </div>
    )
}

export default AddOn