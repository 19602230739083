export function CardArrayForm(value){
    const circles = [];
    const domains = [];
    const links = [];
    const projects = [];
    const persons = [];
    const roles = [];
    const skills = [];

    for (let i = 0; i < value.length; i++) {
      if (value[i].type === "Circle") {
        circles.push(value[i]._id);
      }
      if (value[i].type === "Skill") {
        skills.push(value[i]._id);
      }
      if (value[i].type === "Domain") {
        domains.push(value[i]._id);
      }
      if (value[i].type === "People") {
        persons.push(value[i]._id);
      }
      if (value[i].type === "Role") {
        roles.push(value[i]._id);
      }
      if (value[i].type === "Link") {
        links.push(value[i]._id);
      }
      if (value[i].type === "Project") {
        projects.push(value[i]._id);
      }
    }
    const finalArray = [
      {
        type: "Project",
        projects,
      },
      {
        type: "Link",
        links,
      },
      {
        type: "Role",
        roles,
      },
      {
        type: "Person",
        persons,
      },
      {
        type: "Domain",
        domains,
      },
      {
        type: "Skill",
        skills,
      },
      {
        type: "Circle",
        circles,
      },
    ];
    return finalArray;
}