import {
    ROLE_CREATE_SUCCESS, ROLE_CREATE_FAIL, ROLE_GET_SUCCESS, ROLE_DELETE_SUCCESS,
    ROLE_UPDATE_SUCCESS, ROLE_UPDATE_FAIL, ROLE_DELETE_FAIL, ROLE_GET_FAIL,
    ROLE_SUCCESS_MESSAGE_CLEAR, ROLE_ERROR_CLEAR, SIGN_OUT, ROLE_DROP_UPDATE_SUCCESS, ROLE_DROP_UPDATE_FAIL,
    ROLE_ID_CLEAR, ROLES_MODIFICATIONS
} from '../types/roleType'

import { areArraysEqual } from '../../HelperFunctions/ArraysAreEqual';

const roleState = {
    roles: [],
    dRoles: [],
    nDaoamins: [],
    cuRoleId: "",
    roleMessagee: null,
    roleError: '',
    roleMessage: ""
}


export const roleReducer = (state = roleState, action) => {
    const { type, payload } = action
    // const {users, successMessage } = payload
    // console.log(payload.users)

    if (type === ROLE_CREATE_SUCCESS) {
        return {
            ...state,
            roles: payload.roles,
            dRoles: payload.dRoles,
            roleMessagee: payload.roleSuccessMessage,
            cuRoleId: payload.cuRoleId,
            nDaoamins: payload.nDaoamins,
            roleError: ""
        }
    }
    if (type === ROLE_GET_SUCCESS) {
        const r_result = areArraysEqual(state.roles, payload.roles);
        
        const dr_result = areArraysEqual(state.dRoles, payload.dRoles);

        return {
            ...state,
            roles: r_result ? state.roles : payload.roles,
            dRoles: dr_result ? state.dRoles : payload.dRoles,
            roleMessage: payload.roleSuccessMessage,
            roleError: ""
        }
    }
    if (type === ROLE_DELETE_SUCCESS) {
        return {
            ...state,
            roles: payload.roles,
            roleMessagee: payload.roleSuccessMessage,
            roleError: ""
        }
    }
    if (type === ROLE_UPDATE_SUCCESS) {
        // console.log(payload.roleSuccessMessage,payload.roleSuccessMessage);
        return {
            ...state,
            roles: payload.roles,
            roleMessagee: payload.roleSuccessMessage,// payload.roleSuccessMessage,
            nDaoamins: payload.nDaoamins,
            roleError: ""
        }
    }
    if (type === ROLE_DROP_UPDATE_SUCCESS) {
        // console.log("001");

        const result = areArraysEqual(state.roles, payload.roles);

        return {
            ...state,
            roles: result ? state.roles : payload.roles,
            roleMessagee: "",// payload.roleSuccessMessage,
            roleError: ""
        }
    }
    if (type === ROLE_CREATE_FAIL) {
        return {
            ...state,
            roleError: payload.roleError
        }
    }
    if (type === ROLE_DELETE_FAIL) {
        return {
            ...state,
            roleError: payload.roleError
        }
    }
    if (type === ROLE_UPDATE_FAIL) {
        return {
            ...state,
            roleError: payload.roleError,// payload.error
        }
    }
    if (type === ROLE_DROP_UPDATE_FAIL) {
        return {
            ...state,
            roleError: "",// payload.error
        }
    }
    if (type === ROLE_GET_FAIL) {
        return {
            ...state,
            roleError: payload.roleError
        }
    }
    if (type === ROLE_SUCCESS_MESSAGE_CLEAR) {
        // console.log("002");
        return {
            ...state,
            roleMessagee: null,
            nDaoamins: []
        }
    }
    if (type === ROLE_ERROR_CLEAR) {
        return {
            ...state,
            roleError: ''
        }
    }
    if (type === ROLE_ID_CLEAR) {
        return {
            ...state,
            cuRoleId: ''
        }
    }

    if (type === SIGN_OUT) {
        // console.log("003");
        return {
            ...state,
            roles: [],
            roleMessagee: '',
            roleError: '',
            roleMessage: ""
        }
    }
    
    if (type === ROLES_MODIFICATIONS) {
        // console.log(payload)
        return {
            ...state,
            roles: payload,
        }
    }
    return state;
}