export const PRICING_CREATE_SUCCESS = 'PRICING_CREATE_SUCCESS'
export const PRICING_CREATE_FAIL = 'PRICING_CREATE_FAIL'
export const PRICING_GET_SUCCESS = 'PRICING_GET_SUCCESS'
export const PRICING_GET_FAIL = 'PRICING_GET_FAIL'
export const PRICING_DELETE_SUCCESS = 'PRICING_DELETE_SUCCESS'
export const PRICING_UPDATE_SUCCESS = 'PRICING_UPDATE_SUCCESS'
export const PRICING_UPDATE_FAIL = 'PRICING_UPDATE_FAIL'
export const PRICING_DELETE_FAIL = 'PRICING_DELETE_FAIL'
export const PRICING_DROP_UPDATE_SUCCESS = 'PRICING_DROP_UPDATE_SUCCESS'
export const PRICING_DROP_UPDATE_FAIL = 'PRICING_DROP_UPDATE_FAIL'
export const INVOIC_GET_SUCCESS = 'INVOIC_GET_SUCCESS'
export const INVOIC_GET_FAIL = 'INVOIC_GET_FAIL'
export const SUB_GET_SUCCESS = 'SUB_GET_SUCCESS'
export const SUB_GET_FAIL = 'SUB_GET_FAIL'
export const SUB_DELETE_SUCCESS = 'SUB_DELETE_SUCCESS'
export const SUB_DELETE_SUCCESS_CLEAR = 'SUB_DELETE_SUCCESS_CLEAR'
export const SUB_DELETE_FAIL = 'SUB_DELETE_FAIL'
export const SUB_DELETE_FAIL_CLEAR = 'SUB_DELETE_FAIL_CLEAR'
export const PRICING_ID_CLEAR = 'PRICING_ID_CLEAR'
export const PRICING_SUCCESS_MESSAGE_CLEAR = 'PRICING_SUCCESS_MESSAGE_CLEAR'
export const PRICING_ERROR_CLEAR = 'PRICING_ERROR_CLEAR'
export const SIGN_OUT = 'SIGN_OUT'
export const UPGRADE_URL_GET_SUCCESS = 'UPGRADE_URL_GET_SUCCESS'
export const UPGRADE_URL_GET_FAIL = 'UPGRADE_URL_GET_FAIL'