import axios from 'axios'
import { SERVER_URI,CLIENT_MODE,CLIENT_URL } from '../../config/keys'
import {
    REVIEW_ADD_SUCCESS,
    REVIEW_ADD_FAIL,
    ALL_REVIEW_GET_SUCCESS,
    ALL_REVIEW_GET_ERROR,
    REVIEW_SUCCESS_MESSAGE_CLEAR,
    REVIEW_ERROR_MESSAGE_CLEAR
} from '../types/reviewType';

export const userAuth = (token) => {
    axios.interceptors.request.use(
        config => {
            config.headers.authorization = `Bearer ${token}`;
            return config;
        },
        error => {
            return Promise.reject(error)
        }
    )
}

export const ReviewSubmission = (datas) => {
    return async (dispatch, getState) => {
        // let proceed = DispatchBlocker(getState);
        // if(!proceed) return;
        try {
            const response = await axios.post(`${SERVER_URI}/review/collect-review`, datas);

            dispatch({
                type: REVIEW_ADD_SUCCESS,
                payload: {
                    message: response.data.message
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: REVIEW_ADD_FAIL,
                    payload: {
                        message: data
                    }
                })
            }
        }
    }
}

export const GetReviewSubmissionAnalytics = (datas) => {
    return async (dispatch, getState) => {
        // let proceed = DispatchBlocker(getState);
        // if(!proceed) return;
        try {
            // console.log(datas);
            let {userId, teamId } = datas;
            let config = {
                headers: {
                    userId: userId,
                    teamId: teamId
                }
            };
            const response = await axios.get(`${SERVER_URI}/review/scores`, config);

            dispatch({
                type: ALL_REVIEW_GET_SUCCESS,
                payload: {
                    // message: response.data.message,
                    data: response.data.data,
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: ALL_REVIEW_GET_ERROR,
                    payload: {
                        message: data
                    }
                })
            }
        }
    }
}
