export const SKILL_CREATE_SUCCESS = 'SKILL_CREATE_SUCCESS'
export const SKILL_CREATE_FAIL = 'SKILL_CREATE_FAIL'
export const SKILL_GET_SUCCESS = 'SKILL_GET_SUCCESS'
export const SKILL_GET_FAIL = 'SKILL_GET_FAIL'
export const SKILL_DELETE_SUCCESS = 'SKILL_DELETE_SUCCESS'
export const SKILL_UPDATE_SUCCESS = 'SKILL_UPDATE_SUCCESS'
export const SKILL_UPDATE_FAIL = 'SKILL_UPDATE_FAIL'
export const SKILL_DELETE_FAIL = 'SKILL_DELETE_FAIL'
export const SKILL_DROP_UPDATE_SUCCESS = 'SKILL_DROP_UPDATE_SUCCESS'
export const SKILL_DROP_UPDATE_FAIL = 'SKILL_DROP_UPDATE_FAIL'

export const SKILL_ID_CLEAR = 'SKILL_ID_CLEAR'

export const SKILL_SUCCESS_MESSAGE_CLEAR = 'SKILL_SUCCESS_MESSAGE_CLEAR'
export const SKILL_ERROR_CLEAR = 'SKILL_ERROR_CLEAR'
export const SIGN_OUT = 'SIGN_OUT'

export const SKILLS_MODIFICATIONS = "SKILLS_MODIFICATIONS"