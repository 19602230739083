export const NOTE_GET_SUCCESS = "NOTE_GET_SUCCESS"
export const NOTE_GET_FAIL = "NOTE_GET_FAIL"
export const NOTE_CREATE_SUCCESS = "NOTE_GET_SUCCESS"
export const NOTE_CREATE_FAIL = "NOTE_GET_FAIL"
export const NOTE_DELETE_SUCCESS = 'NOTE_DELETE_SUCCESS'
export const NOTE_UPDATE_SUCCESS = 'NOTE_UPDATE_SUCCESS'
export const NOTE_UPDATE_FAIL = 'NOTE_UPDATE_FAIL'
export const NOTE_DELETE_FAIL = 'NOTE_DELETE_FAIL'

export const NOTE_SUCCESS_MESSAGE_CLEAR = 'NOTE_SUCCESS_MESSAGE_CLEAR'
export const NOTE_ERROR_CLEAR = 'NOTE_ERROR_CLEAR'