export const MEETINGS_CREATE_SUCCESS = 'MEETINGS_CREATE_SUCCESS'
export const MEETINGS_CREATE_FAIL = 'MEETINGS_CREATE_FAIL'
export const MEETINGS_GET_SUCCESS = 'MEETINGS_GET_SUCCESS'
export const MEETINGS_DELETE_SUCCESS = 'MEETINGS_DELETE_SUCCESS'
export const MEETINGS_UPDATE_SUCCESS = 'MEETINGS_UPDATE_SUCCESS'
export const MEETINGS_UPDATE_FAIL = 'MEETINGS_UPDATE_FAIL'
export const MEETINGS_DELETE_FAIL = 'MEETINGS_DELETE_FAIL'
export const MEETINGS_GET_FAIL = 'MEETINGS_GET_FAIL'



export const MEETINGS_SUCCESS_MESSAGE_CLEAR = 'MEETINGS_SUCCESS_MESSAGE_CLEAR'
export const MEETINGS_ERROR_CLEAR = 'MEETINGS_ERROR_CLEAR'
export const SIGN_OUT = 'SIGN_OUT'