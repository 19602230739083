import {
    SETTINGS_ERROR_CLEAR, SETTINGS_SUCCESS_MESSAGE_CLEAR, SETTINGS_CREATE_FAIL, SETTINGS_CREATE_SUCCESS,
    SETTINGS_GET_FAIL, SETTINGS_GET_SUCCESS, ADMIN_SETTINGS_GET_SUCCESS
} from "../types/settingsType"

const userSettings = {
    adminSettings: {},
    Setting: {},
    SettingError: '',
    SettingSuccessMessage: '',
    SettingSuccessMessagee: ''
}

export const settingReducer = (state = userSettings, action) => {
    const { payload, type } = action;

    if (type === SETTINGS_CREATE_SUCCESS) {
        return {
            ...state,
            SettingSuccessMessage: payload.SettingSuccessMessage
        }
    }
    if (type === SETTINGS_CREATE_FAIL) {
        return {
            ...state,
            SettingError: payload.SettingError
        }
    }
    if (type === SETTINGS_GET_SUCCESS) {
        return {
            ...state,
            adminSettings: payload.Setting,
            // SettingSuccessMessagee: payload.SettingSuccessMessagee
        }
    }
    if (type === ADMIN_SETTINGS_GET_SUCCESS) {
        return {
            ...state,
            Setting: payload.Setting,
            SettingSuccessMessagee: payload.SettingSuccessMessagee
        }
    }
    if (type === SETTINGS_GET_FAIL) {
        return {
            ...state,
            SettingError: payload.SettingError
        }
    }
    if (type === SETTINGS_SUCCESS_MESSAGE_CLEAR) {
        return {
            ...state,
            SettingSuccessMessage: ""
        }
    }
    if (type === SETTINGS_ERROR_CLEAR) {
        return {
            ...state,
            SettingError: ""
        }
    }
    return state;
}