import React, { useEffect, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import Stack from "@mui/material/Stack";
import Rating from "@mui/material/Rating";

export default function Objectives({
  data,
  people,
  skill,
  domain,
  link,
  project,
  circle,
  role,
  type,
}) {
  let objectiveData = [];

  if (data.objectives && data.objectives.length > 0) {
    data.objectives.map((e) => {
      objectiveData.push({ objective: e.objective, rating: e.rating });
    });
    // objectiveData.push({ objective: '', rating: 0 });
  }

  if (objectiveData.length === 0) {
    objectiveData.push({ objective: "", rating: 0 });
  }

  const [objectives, setObjectives] = useState(objectiveData);

  let handleForm = (i, e) => {
    let newobjectives = [...objectives];
    newobjectives[i] = {
      ...newobjectives[i],
      [e.target.name]:
        e.target.name === "objective"
          ? e.target.value
          : parseFloat(e.target.value),
    };
    setObjectives(newobjectives);
  };

  let addFormFields = (e) => {
    let len = objectives.length;
    if (len > 0) {
      if (objectives[len - 1].objective.length > 0) {
        setObjectives([...objectives, { objective: "", rating: 0 }]);
      }
    }
  };

  let removeFormFields = (i) => {
    let newobjectives = [...objectives];
    newobjectives.splice(i, 1);
    setObjectives(newobjectives);
  };

  const [isClicking, setIsCliking] = useState(false);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addFormFields(e);
      setIsCliking(true);
    }
  };

  useEffect(() => {
    const textarea = document.getElementById(
      `objective${objectives.length - 1}`
    );
    if (textarea && isClicking) {
      textarea.focus();
      setIsCliking(false);
    }
  }, [objectives, setObjectives]);

  const onDragStart = (e, index) => {
    if (type === "REPORT_DATA") {
      return;
    }
    e.dataTransfer.setData("index", index);
  };

  const onDragOver = (e, index) => {
    if (type === "REPORT_DATA") {
      return;
    }
    e.preventDefault();
  };

  const onDrop = (e, index) => {
    if (type === "REPORT_DATA") {
      return;
    }
    const draggedIndex = e.dataTransfer.getData("index");
    const items = [...objectives];
    const draggedItem = items[draggedIndex];
    items.splice(draggedIndex, 1);
    items.splice(index, 0, draggedItem);
    setObjectives(items);
  };

  if (objectives && type !== "REPORT_DATA") {
    if (skill) {
      skill.objectives = objectives;
    } else if (role) {
      role.objectives = objectives;
    } else if (domain) {
      domain.objectives = objectives;
    } else if (link) {
      link.objectives = objectives;
    } else if (project) {
      project.objectives = objectives;
    } else if (circle) {
      circle.objectives = objectives;
    }
  }
  return (
    <>
      <h4>Objectives</h4>
      <div className="row task-list">
        {objectives && objectives.length > 0
          ? objectives.map((element, index) => {
              if (
                type === "REPORT_DATA" &&
                index === objectives.length - 1 &&
                element.objective === ""
              ) {
                return;
              }

              return (
                <div
                  draggable={
                    objectives.length - 1 === index && element.length === 0
                      ? false
                      : true
                  }
                  onDragStart={(e) => onDragStart(e, index)}
                  onDragOver={(e) => onDragOver(e, index)}
                  onDrop={(e) => onDrop(e, index)}
                  className={
                    objectives.length - 1 === index &&
                    element.objective.length === 0
                      ? "col-xs-12 tasks new-task"
                      : "col-xs-12 task"
                  }
                  key={index}
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <TextareaAutosize
                    // cacheMeasurements
                    id={`objective${index}`}
                    type="text"
                    placeholder="+ Add objective"
                    name="objective"
                    className="form-control logit"
                    value={element.objective || ""}
                    style={{
                      width:
                        objectives &&
                        objectives.length - 1 !== index &&
                        objectives.length !== 0
                          ? "70%"
                          : "100%",
                    }}
                    onChange={(e) => handleForm(index, e)}
                    onKeyPress={handleKeyPress}
                    disabled={type === "REPORT_DATA"}
                  >
                    <span className="fa fa-circle"> </span>
                  </TextareaAutosize>

                  {objectives.length - 1 !== index &&
                  element.objective.length !== 0 ? (
                    <>
                      <Stack style={{ width: "150px" }}>
                        <Rating
                          className="rating"
                          name="rating"
                          defaultValue={element.rating}
                          onChange={(e) => handleForm(index, e)}
                          showEmpty={false}
                          disabled={type === "REPORT_DATA"}
                        />
                      </Stack>

                      {type != "REPORT_DATA" && (
                        <button
                          type="button"
                          className="button remove btn btn-remove"
                          onClick={() => removeFormFields(index)}
                        >
                          <span
                            style={{ paddingLeft: "15%" }}
                            className="fa fa-times"
                            aria-hidden="true"
                          ></span>
                        </button>
                      )}
                    </>
                  ) : null}
                </div>
              );
            })
          : null}
      </div>
    </>
  );
}
