import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "./Navbar";
import Footer from '../../Component/Footer/Footer';
import ReCAPTCHA from "react-google-recaptcha";
import { userRegister } from "../../../store/actions/authAction";
import {
  SUCCESS_MESSAGE_CLEAR,
  ERROR_CLEAR,
  REGISTER_FAIL,
} from "../../../store/types/authType";
import Swal from "sweetalert2";
import { getpricing } from "../../../store/actions/pricingAction";
import { GoogleLogin } from "react-google-login";
import deCodeToken from "jwt-decode";
import { googleSignIn } from "../../../store/actions/authAction";
import { RC_SITE_KEY, GOOGLE_CLIENT_ID } from "../../../config/keys"

document.body.style = "";
document.body.classList = "no-app-pages body2 menu2";

export default function FreeTrial() {
  const {
    loading,
    stripeUrl,
    successMessage,
    paySuccessMessage,
    error,
    authenticate,
    myInfo,
  } = useSelector((state) => state.auth);
  const {
    pricings,
    Prices,
    cuPricingId,
    pricingMessagee,
    pricingMessage,
    pricingError,
  } = useSelector((state) => state.pricings);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    email: "",
    password: "",
    name: "",
    toc_pp: false,
  });

  const handleChange = (e) => {
    if (e.target.name !== "toc_pp") {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "toc_pp") {
      setState({
        ...state,
        [e.target.name]: e.target.checked,
      });
    }
  };

  useEffect(() => {
    dispatch(getpricing());
  }, []);

  const [pricesDatas, setPricesDatas] = useState({});

  useEffect(() => {
    if (pricings && pricings.length > 0) {
      for (let i = 0; i < pricings.length; i++) {
        // console.log(pricings[i].name === "FREE");
        if (pricings[i].name === "FREE") {
          setPricesDatas(pricings[i].metadata);
          break;
        }
      }
    }
  }, [pricings]);

  //   console.log(pricesDatas);

  const [checked, setChecked] = useState(false);
  const [loadings, setLoadings] = useState(false);

  const handleChanges = () => {
    setChecked(!checked);
  };

  const recaptchaRef = React.useRef();
  const [shows, setShows] = useState(false);

  const useCreate = async (e) => {
    if (state.toc_pp) {
      setLoadings(true);
      let obj = {
        fname: state.name.trim().split(" ")[0] || "",
        lname: state.name.trim().split(" ")[1] || "",
        companyName: "Company Name",
        role: "User",
        plan: "FTrial",
        teamName: "Test Team",
        userUrl: "http://example.com",
        prices: 0,
        email: state.email,
        password: state.password,
      };

      if (obj.fname == "" || obj.lname == "") {
        setLoadings(false);
        Swal.fire({
          // icon: "error",
          title: "Error",
          text: `Please enter both first name & last name (i.e. - Jhon Doe)!`,
          showConfirmButton: true,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
        return false;
      }

      const token = await recaptchaRef.current.executeAsync();
      obj.rc_token = token;
      //   console.log('109',obj)
      dispatch(userRegister(obj));
    } else if (!state.toc_pp) {
      dispatch({
        type: REGISTER_FAIL,
        payload: { error: "checked terms of service." },
      });
    }
  };

  useEffect(() => {
    if (authenticate) {
      setTimeout(() => {
        navigate("/");
        window.location.reload()
      }, 5000);
      navigate("/");
      window.location.reload()
      // console.log('ok')
    }
    if (successMessage) {
      dispatch({ type: SUCCESS_MESSAGE_CLEAR });
      setShows(true);
    }
    // alert.success(successMessage)

    if (error) {
      // alert.error(error)
      setLoadings(false);
      Swal.fire({
        // icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: error,
      });
      dispatch({ type: ERROR_CLEAR });
    }
  }, [successMessage, error]);

  // console.log(state)

  const tokenDecode = (token) => {
    const tokenDecoded = deCodeToken(token);
    const expTime = new Date(tokenDecoded.exp * 1000);
    if (new Date() > expTime) {
      return null;
    }
    return tokenDecoded;
  };

  const responseSuccess = async (res) => {
    let userData = tokenDecode(res.tokenObj.id_token);
    // console.log(tokenDecode(res.tokenObj.id_token));
    let obj = {
      fname: userData.given_name,
      lname: userData.family_name,
      companyName: userData.name,
      role: "User",
      plan: "FTrial",
      teamName: "Test Team",
      userUrl: "http://",
      prices: 0,
      email: userData.email,
      issSignIn: true,
      password: "",
    };
    const token = await recaptchaRef.current.executeAsync();
    obj.rc_token = token;
    dispatch(googleSignIn(obj));
  };

  const responseFailure = (res) => {
    // console.log("Login fail, response: ", res);
  };


  // console.log("201", pricesDatas);
  return (
    <div>
      <Navbar />
      <div className="container page-free-trial">
        {!shows ? (
          <>
            <div className="row">
              <div className="col-lg-12 col-sm-12 col-12">
                <div className="onboarding-h">
                  <h2>Test teamdecoder now for free!</h2>
                  <p>
                    The best way to discover <strong>teamdecoder</strong> and
                    see how it fits your needs.
                    <br />
                    The trial is free:{" "}
                    <strong>no credit card details are required.</strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-sm-12 col-12">
                <img alt="" className="top-img" src="./images/free-trial-2.png" />
              </div>
              <div className="col-lg-6 col-sm-12 col-12">
                <div id="video">
                  <img
                    style={{ width: "70%", float: "right" }}
                    src="./images/free-trial-3.png"
                  ></img>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 col-sm-12 col-12">
                <div
                  className="infomation-section"
                  style={{ backgroundColor: "transparent" }}
                >
                  <div className="row">
                    <div
                      className="col-lg-4 col-sm-12 col-12"
                      style={{
                        backgroundColor: "#ffffff",
                        padding: "30px",
                        borderRadius: "10px",
                        WebkitBoxShadow: "0px 0px 22px -8px rgba(0,0,0,0.75)",
                        MozBoxShadow: "0px 0px 22px -8px rgba(0,0,0,0.75)",
                        boxShadow: "0px 0px 22px -8px rgba(0,0,0,0.75)",
                      }}
                    >
                      <div className="tab-content">
                        <div
                          className={
                            shows
                              ? "tab-pane fade"
                              : "tab-pane fade show active"
                          }
                          id="step1"
                        >
                          <form id="registration">
                            <p>* indicates required fields</p>
                            <div className="from-st">
                              <div className="mb-3">
                                <input
                                  type="hidden"
                                  id="session_id"
                                  name="session_id"
                                  className="form-control"
                                  value="free_trial"
                                  required=""
                                />
                                <label for="email">
                                  Email address <span>*</span>
                                </label>
                                <input
                                  type="email"
                                  id="email"
                                  name="email"
                                  onChange={(e) => handleChange(e)}
                                  className="form-control"
                                  value={state.email}
                                  placeholder="Your Email"
                                  required=""
                                />
                              </div>

                              <div
                                className="mb-3"
                                style={{
                                  display: "block",
                                  position: "relative",
                                  width: "100%",
                                }}
                              >
                                <label for="password">
                                  Password <span>*</span>
                                </label>
                                <input
                                  type={checked ? "text" : "password"}
                                  id="password"
                                  name="password"
                                  value={state.password}
                                  onChange={(e) => handleChange(e)}
                                  className="form-control"
                                  placeholder="Your password"
                                  required=""
                                />
                                <div id="togglePassword">
                                  <svg
                                    style={
                                      checked
                                        ? { display: "none" }
                                        : { display: "block" }
                                    }
                                    onClick={() => handleChanges()}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-eye-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"></path>
                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"></path>
                                  </svg>

                                  <svg
                                    style={
                                      checked
                                        ? { display: "block" }
                                        : { display: "none" }
                                    }
                                    onClick={() => handleChanges()}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-eye-slash-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"></path>
                                    <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"></path>
                                  </svg>
                                </div>
                              </div>

                              <div className="mb-3">
                                <label for="first_name">
                                  Name <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  id="first_name"
                                  name="name"
                                  value={state.name}
                                  onChange={(e) => handleChange(e)}
                                  className="form-control"
                                  placeholder="Your full name"
                                  required=""
                                />
                              </div>

                              <div
                                className="mb-3 form-check"
                                style={{ paddingTop: "20px" }}
                              >
                                <input
                                  type="checkbox"
                                  id="toc_pp"
                                  name="toc_pp"
                                  className="form-check-input"
                                  onChange={(e) => handleChange(e)}
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  for="first_team"
                                  style={{ marginLeft: "8px" }}
                                >
                                  I agree to the{" "}
                                  <a
                                    href="https://legal.teamdecoder.com/terms-of-service"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    terms of service
                                  </a>
                                  . <span>*</span>
                                </label>
                              </div>

                              <button
                                type="button"
                                onClick={() => useCreate()}
                                className="btn btn-primary submit"
                                id="step1-continue"
                                data-quick_register="yes"
                              >
                                {" "}
                                {loadings ? (
                                  <i className="fa fa-spinner fa-spin"></i>
                                ) : null}
                                Start FREE
                              </button>

                              <p style={{ textAlign: "center" }}>OR</p>

                              <GoogleLogin
                                clientId={GOOGLE_CLIENT_ID}
                                render={(renderProps) => (
                                  <p
                                    onClick={renderProps.onClick}
                                    disabled={renderProps.disabled}
                                    style={{ textAlign: "center" }}
                                  >
                                    <a href="">
                                      <img alt="" src="./images/btn_google_signin_dark_normal_web.png" />
                                    </a>
                                  </p>
                                )}
                                buttonText="Login"
                                onSuccess={responseSuccess}
                                onFailure={responseFailure}
                                cookiePolicy={"single_host_origin"}
                                isSignedIn={false}
                              />

                              {/* <p style={{ "textAlign": "center" }}><a href=''><img alt="" src="./images/btn_google_signin_dark_normal_web.png" /></a></p> */}

                              <p style={{ marginTop: "15px" }}>
                                In order to be able to fulfill your teamdecoder
                                license, we will process your data. You can find
                                more information about how we process your data{" "}
                                <a
                                  href="https://legal.teamdecoder.com/privacy-policy"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  here
                                </a>
                                .
                              </p>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-sm-12 col-12"></div>
                    <div className="col-lg-7 col-sm-12 col-12">
                      <img
                        className="bottom-img"
                        src="./images/free-trial-2.png"
                      />
                      <ul className="accordingly-p-one">
                        {pricesDatas && Object.keys(pricesDatas).length > 0
                          ? Object.keys(pricesDatas).map((key, indexs) => {
                            return (
                              <li
                                key={indexs}
                                dangerouslySetInnerHTML={{
                                  __html: pricesDatas[key],
                                }}
                              ></li>
                            );
                          })
                          : null}
                      </ul>
                      <a
                        target="_blank"
                        href="http://info.mytpt.work/"
                        className="btn btn-primary submit lm"
                        rel="noopener noreferrer"
                      >
                        Learn More
                      </a>{" "}
                      <a
                        href="/register"
                        className="btn btn-link rn"
                        rel="noopener noreferrer"
                      >
                        Start FREE
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="container vertical-center">
              <div className="row">
                <div className="col-lg-1 col-sm-12 col-12"></div>
                <div className="col-lg-10 col-sm-12 col-12">
                  <form action="" method="post" className="infomation-section">
                    <nav>
                      <div
                        className="nav nav-pills nav-fill"
                        id="nav-tab"
                        role="tablist"
                      >
                        <a className="nav-link" id="step1-tab">
                          <span>1</span> Info&nbsp;&nbsp;
                          <img alt="" src="http://staged.mytpt.work/wp-content/themes/mytpt/images/arrow2a.png" />
                        </a>
                        <a className="nav-link active" id="step2-tab">
                          <span>2</span> Confirm&nbsp;&nbsp;
                          <img alt="" src="http://staged.mytpt.work/wp-content/themes/mytpt/images/arrow2a.png" />
                        </a>
                        <a className="nav-link" id="step2-tab">
                          <span>3</span> Success
                        </a>
                      </div>
                    </nav>
                    <div className="tab-content">
                      <div className="tab-pane fade show active" id="step1">
                        <div
                          //   className={
                          //     shows ? "tab-pane fade show active" : "tab-pane fade "
                          //   }
                          className="tab-pane fade show active"
                          id="step2"
                        >
                          <h4>Confirm</h4>
                          <div className="onboarding-y">
                            <h2
                              id="title"
                              style={{
                                marginBottom: "15px",
                                color: "#7FC2BF",
                              }}
                            >
                              Yeah :) Your account has been created!
                            </h2>
                            <p id="message">
                              We have sent an email to{" "}
                              <span className="email">{state.email}</span>.{" "}
                              <strong>
                                Please click the link in that email
                              </strong>{" "}
                              to verify your email address and you will be taken
                              back here to login in no time.
                              <br />
                              <small
                                style={{
                                  fontSize: "18px",
                                  display: "block",
                                  marginTop: "15px",
                                  lineHeight: "1.5",
                                }}
                              >
                                Don't forget to check your spam folder and add
                                us to your safe sender list to ensure you don't
                                miss any future communications.
                              </small>
                            </p>
                          </div>

                          <div
                            className="buttan-858"
                            style={{ display: "none" }}
                          >
                            <ul>
                              <li>
                                <a
                                  target="_blank"
                                  href="http://help.mytpt.work/"
                                  rel="noopener noreferrer"
                                >
                                  <img alt="" src="" />
                                </a>
                              </li>
                              <li>
                                <a
                                  target="_blank"
                                  href="http://webinar1.mytpt.work/"
                                  rel="noopener noreferrer"
                                >
                                  <img alt="" src="" />
                                </a>
                              </li>
                            </ul>
                            <a
                              href="https://staged.mytpt.work/app/"
                              className="btn btn-primary submit"
                              style={{ width: "40%", marginTop: "15px" }}
                              rel="noopener noreferrer"
                            >
                              Login
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    
      <Footer />

      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={RC_SITE_KEY}
      />
    </div>
  );
}