import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { userLogin, userForgot } from '../../../store/actions/authAction';
import { useAlert } from 'react-alert'
import { SUCCESS_MESSAGE_CLEAR, ERROR_CLEAR } from '../../../store/types/authType'
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import ReCAPTCHA from "react-google-recaptcha";
import Navbar from './Navbar';
import Footer from '../../Component/Footer/Footer';
import deCodeToken from 'jwt-decode'
import { GoogleLogin } from 'react-google-login';
import { googleSignIn } from '../../../store/actions/authAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { SyncLoader } from "react-spinners";
import { userSessionResetEmail } from "../../../store/actions/authAction"
import { RC_SITE_KEY, GOOGLE_CLIENT_ID } from "../../../config/keys"

// document.body.style = '';
// document.body.classList = 'no-app-pages full-height';

export default function Login(data) {
    const imageArr = data.bgImg.data;
    // console.log(data)
    const navigate = useNavigate();

    const [page, setPage] = React.useState({
        login: 'block',
        forgot: 'none'
    })

    const pageHandel = (data) => {
        dispatch({ type: ERROR_CLEAR });
        setTempError("")
        if (data === 'Login') {
            setPage({
                login: 'block',
                forgot: 'none'
            })
        } else if (data === 'Forgot') {
            setPage({
                login: 'none',
                forgot: 'block'
            })
        } else if (data === 'Create') {
            navigate("/pricing")
        }
    }

    const dispatch = useDispatch();
    const recaptchaRef = React.useRef();
    const alert = useAlert();
    const { loading, successMessage, error, authenticate, myInfo } = useSelector(state => state.auth)

    let Email = localStorage.getItem('email')
    let Password = localStorage.getItem('password')

    let obj = {
        email: Email ? Email : '',
        password: Password ? Password : ''
    }

    const [state, setState] = useState(obj)

    const inputHendle = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const [checked, setChecked] = useState(false);

    const handleChange = () => {
        setChecked(!checked);
    };

    const [loadings, setLoadings] = useState(false)
    const [Ploader, setPloader] = useState(false)

    const login = async (e) => {
        e.preventDefault()
        setLoadings(true)
        const token = await recaptchaRef.current.executeAsync();
        state.rc_token = token
        state.email = state.email.toLowerCase();
        // console.log(state);
        dispatch(userLogin(state))
    }

    const [fstate, setFState] = useState({
        email: ''
    })

    const inputHendles = (e) => {
        setFState({
            ...fstate,
            [e.target.name]: e.target.value
        })
    }

    const forgotPassword = async (e) => {
        e.preventDefault()
        const token = await recaptchaRef.current.executeAsync();
        fstate.rc_token = token
        setLoadings(true);
        dispatch(userForgot(fstate)).then(() => {
            setFState({ email: '' })
            setLoadings(false);
        })
    }

    const [tempError, setTempError] = useState("")
    useEffect(() => {
        if (successMessage) {
            // dispatch({ type: SUCCESS_MESSAGE_CLEAR })
            setTempError("")
        }

        if (error) {
            let curError = error.split(" ")
            // console.log(error.trim() !== "Access Restricted");
            // console.log(error.trim() == "Access Restricted");
            // console.log(curError[curError.length - 1] !== "email?");
            if (curError[curError.length - 1] !== "email?" && !error.trim().split("-").includes("Access Restricted")) {
                setTempError(error)
                setTimeout(() => {
                    dispatch({ type: ERROR_CLEAR })
                    setLoadings(false)
                    // setTempError("")
                }, 2000)
            }

            if (curError[curError.length - 1] === "email?") {
                Swal.fire({
                    // icon: 'info',
                    title: 'Account in use',
                    showClass: {
                        popup: 'animate__animated animate__fadeInDown'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOutUp'
                    },
                    text: error,
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.isConfirmed) {
                        dispatch(userSessionResetEmail({ ...state, editRequest: false, teamId: null }))
                        Swal.fire({
                            // position: "top-end",
                            // icon: "success",
                            title: "Success",
                            text: "We have sent a session reset email!",
                            showConfirmButton: true,
                            // timer: 1500,
                            customClass: {
                                popup: 'email-sent'
                            }
                        }).then((result) => {
                            // if (result.isConfirmed) {
                            setState({ ...state, email: "" })
                            // }
                        });

                    }
                })
                dispatch({ type: ERROR_CLEAR })
                setLoadings(false)
            }

            if (error.trim().split("-").includes("Access Restricted")) {
                Swal.fire({
                    title: `${error.trim().split("-")[0]}`,
                    showClass: {
                        popup: 'animate__animated animate__fadeInDown'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOutUp'
                    },
                    text: `${error.trim().split("-")[1]}`,
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.isConfirmed) {
                        setState({ ...state, email: "" })
                        dispatch({ type: ERROR_CLEAR })
                        setLoadings(false)
                    }
                })
                dispatch({ type: ERROR_CLEAR })
                setLoadings(false)
            }
        }

        if (authenticate) {
            setPloader(false);
            // setTimeout(() => {
            dispatch({ type: SUCCESS_MESSAGE_CLEAR })
            navigate("/");
            document.body.style = "";
            document.body.classList = "";
            // window.location.reload()
            // }, 1000);
        }
    }, [successMessage, error])

    const tokenDecode = (token) => {
        const tokenDecoded = deCodeToken(token);
        const expTime = new Date(tokenDecoded.exp * 1000);
        if (new Date() > expTime) {
            return null
        }
        return tokenDecoded;
    }

    const responseSuccess = async (res) => {
        let userData = tokenDecode(res.tokenId)
        let obj = {
            email: userData.email,
            password: '',
            googleSign: true
        }
        // console.log(userData, userData.email)
        setLoadings(true)
        setState({ ...state, email: userData.email })
        const token = await recaptchaRef.current.executeAsync();
        obj.rc_token = token
        dispatch(userLogin(obj))
    }

    const responseFailure = (res) => {
        // console.log('Login fail, response: ', res)
    }

    useEffect(() => {
        if (!authenticate) {
            setTimeout(() => {
                setPloader(true);
            }, 1000);
        }
    }, [!authenticate]);

    const [offset, setOffset] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const handleMouseMove = (e) => {
            // Calculate the offset in the opposite direction
            const xOffset = (window.innerWidth / 2 - e.clientX) / 20;
            const yOffset = (window.innerHeight / 2 - e.clientY) / 20;

            setOffset({ x: xOffset, y: yOffset });
        };

        window.addEventListener("mousemove", handleMouseMove);

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, []);

    return (
        <>
            {Ploader ?
                <>
                    <div className="container vertical-center">
                        <div className="row">
                            <div className="col-md-7 col-sm-12">
                                <div className={`flex-caption2 ${page.login === 'block' ? 'caption-padding' : null}`}>
                                    <div className="structuring-lo">
                                        {/* For wallpaper 0 */}
                                        <img alt="" className="vector-0-el el-a" src='../images/new-wallpapers/2531a.png' style={{
                                            transform: `translate(${offset.x}px, ${offset.y}px)`,
                                        }} />
                                        <img alt="" className="vector-0-el el-b" src='../images/new-wallpapers/2531b.png' style={{
                                            transform: `translate(${offset.x}px, ${offset.y}px)`,
                                        }} />
                                        <img alt="" className="vector-0-el el-c" src='../images/new-wallpapers/2531c.png' style={{
                                            transform: `translate(${offset.x}px, ${offset.y}px)`,
                                        }} />

                                        {/* For wallpaper 4 */}
                                        <img alt="" className="vector-4-el el-a" src='../images/new-wallpapers/2554a.png' style={{
                                            transform: `translate(${offset.x}px, ${offset.y}px)`,
                                        }} />

                                        <h4 style={{ "color": imageArr.color }} dangerouslySetInnerHTML={{ __html: imageArr.text }}></h4>
                                        <a href="/pricing/" className="button button-darkgrey-v46">Create account</a>
                                        <a target="_blank" rel="noopener nofollow noreferrer" href="http://teamdecoder.com/" className="button button-white-v46">More info</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-1 col-sm-12 mb-5"></div>
                            <div className="col-md-4 col-sm-12">
                                <div className="login-from">
                                    {page.login === 'block'
                                        // && localStorage.getItem('passwordResetDone') !== 'yes'
                                        ?
                                        <form className="v4notice">

                                            <h4>New Features:</h4>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div>
                                                    <h2 onClick={() => window.open('https://teamdecoder.tawk.help/article/workloadplanning', '_blank')}>Workload Planning</h2>
                                                    <h2 onClick={() => window.open('https://teamdecoder.tawk.help/article/compare-mode', '_blank')}>Compare Mode</h2>
                                                    <h2 onClick={() => window.open('https://teamdecoder.tawk.help/article/admins-co-admins', '_blank')}>Co-Admins</h2>
                                                </div>
                                                <div>
                                                    <h2 onClick={() => window.open('https://teamdecoder.tawk.help/article/surveys', '_blank')}>Surveys</h2>
                                                    <h2 onClick={() => window.open('https://teamdecoder.tawk.help/article/referral-program', '_blank')}>Referral Programm</h2>
                                                    <h2 onClick={() => window.open('https://teamdecoder.tawk.help/article/purpose-tree', '_blank')}>Purpose Tree</h2>
                                                </div>
                                            </div>
                                        </form>
                                        : null}

                                    <form id="mytpt-login" onSubmit={(e) => login(e)} style={{ "display": page.login }} data-gtm-form-interact-id="0">
                                        <h3>Start your session now</h3>
                                        {successMessage ?
                                            <div className="alert alert-success" style={{ "width": "100%" }}>{successMessage}</div>
                                            : null}
                                        {tempError ?
                                            <div className="alert alert-danger" style={{ "width": "100%" }}>{tempError}</div>
                                            : null}
                                        <div className="mb-3">
                                            <input type="email" placeholder="Email" id="email" name='email' onChange={inputHendle} value={state.email} autoComplete="off" wfd-id="id4"
                                                data-gtm-form-interact-field-id="0" />
                                        </div>

                                        <div className="mb-3" style={{ "display": "block", "position": "relative", "width": "100%" }}>
                                            <input type={checked ? "text" : "password"} placeholder="Password" name='password' onChange={inputHendle} value={state.password} id="password" wfd-id="id5"
                                                data-gtm-form-interact-field-id="1" />
                                            <div id="togglePassword">

                                                <svg style={checked ? { "display": "block" } : { "display": "none" }} onClick={() => handleChange()} xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16" fill="currentColor"
                                                    className="bi bi-eye-fill" viewBox="0 0 16 16">
                                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"></path>
                                                    <path
                                                        d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z">
                                                    </path>
                                                </svg>

                                                <svg style={checked ? { "display": "none" } : { "display": "block" }} onClick={() => handleChange()} xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16" fill="currentColor"
                                                    className="bi bi-eye-slash-fill" viewBox="0 0 16 16">
                                                    <path
                                                        d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z">
                                                    </path>
                                                    <path
                                                        d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z">
                                                    </path>
                                                </svg>

                                            </div>
                                        </div>

                                        <button type="submit" className="g-recaptch button" disabled={loadings ? 'disabled' : ''}>
                                            {loadings ?
                                                <FontAwesomeIcon icon={faSpinner} spin style={{ color: "#000000" }} />
                                                : 'Sign in'}
                                        </button>

                                        <div style={{ "textAlign": "center", marginTop: "10px" }}>OR</div>
                                        <GoogleLogin
                                            clientId={GOOGLE_CLIENT_ID}
                                            render={renderProps => (
                                                <p onClick={renderProps.onClick} disabled={renderProps.disabled} style={{ "textAlign": "center", marginTop: "10px" }}>
                                                    <a href=''><img alt="" src="./images/btn_google_signin_dark_normal_web.png" /></a>
                                                </p>
                                            )}
                                            buttonText="Login"
                                            onSuccess={(res) => { responseSuccess(res) }}
                                            onFailure={(res) => responseFailure(res)}
                                            cookiePolicy={'single_host_origin'}
                                            isSignedIn={false}

                                        />
                                        <a className="forget-pass" onClick={() => pageHandel('Forgot')} id="forget-pass">I forgot my
                                            password</a>
                                        <a onClick={() => pageHandel('Create')} className="forget-pass-two">Create account</a>
                                    </form>

                                    <form id="mytpt-lost-pass" onSubmit={forgotPassword} style={{ "display": page.forgot }}>
                                        <h3>Forgot your password?</h3>
                                        {successMessage ?
                                            <div className="alert alert-success" style={{ "width": "100%" }}>{successMessage}</div>
                                            : null}
                                        {tempError ?
                                            <div className="alert alert-danger" style={{ "width": "100%" }}>{tempError}</div>
                                            : null}
                                        <p>Enter your registered email address to receive password link.</p>
                                        <input type="email" id="email2" name='email' onChange={(e) => inputHendles(e)} value={fstate.email} placeholder="Email" autoComplete="off" wfd-id="id6" />
                                        <button type="submit" className="btn btn-primary g-recaptch">{loadings ?
                                            <FontAwesomeIcon icon={faSpinner} spin style={{ color: "#000000" }} /> : 'Send'}</button>
                                        <a onClick={() => pageHandel('Login')} className="forget-pass" id="back2login">
                                            Login
                                        </a>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />

                    <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={RC_SITE_KEY}
                    />
                </>
                :
                <div className="page-loaders">
                    <SyncLoader color="#36d7b7" />
                </div>
            }
        </>
    )
}
