import {
    TAGS_GET_SUCCESS, TAGS_GET_FAIL, TAGS_DELETE_SUCCESS, TAGS_DELETE_FAIL, TAGS_UPDATE_SUCCESS, TAGS_UPDATE_FAIL,
    TAGS_SUCCESS_MESSAGE_CLEAR, TAGS_ERROR_CLEAR, TAGS_CREATE_SUCCESS, SIGN_OUT
} from '../types/tagsType'


const userState = {
    tags: [],
    tagsMessage: '',
    tagsMessagee: '',
    tagsError: ''
}


export const tagsReducer = (state = userState, action) => {
    const { type, payload } = action

    if (type === TAGS_GET_SUCCESS) {
        return {
            ...state,
            tags: payload.tag,
            tagsMessage: payload.successMessage
        }
    }
    if (type === TAGS_CREATE_SUCCESS) {
        return {
            ...state,
            tagsMessagee: payload.successMessage
        }
    }
    if (type === TAGS_UPDATE_SUCCESS) {
        return {
            ...state,
            tagsMessagee: payload.successMessage
        }
    }
    if (type === TAGS_DELETE_SUCCESS) {
        return {
            ...state,
            tagsMessagee: payload.tagsSuccessMessage
        }
    }
    if (type === TAGS_SUCCESS_MESSAGE_CLEAR) {
        return {
            ...state,
            tagsMessagee: ""
        }
    }
    if (type === TAGS_GET_FAIL) {
        return {
            ...state,
            tagsError: payload.error
        }
    }
    if (type === TAGS_UPDATE_FAIL) {
        return {
            ...state,
            tagsError: payload.error
        }
    }
    if (type === TAGS_DELETE_FAIL) {
        return {
            ...state,
            tagsError: payload.error
        }
    }
    if (type === TAGS_ERROR_CLEAR) {
        return {
            ...state,
            tagsError: payload.error
        }
    }
    if (type === SIGN_OUT) {
        return {
            ...state,
            tags: [],
            tagsMessage: '',
            tagsError: ''
        }
    }



    return state;
}