export const PCTOOL_CREATE_SUCCESS = 'PCTOOL_CREATE_SUCCESS'
export const PCTOOL_CREATE_FAIL = 'PCTOOL_CREATE_FAIL'
export const PCTOOL_GET_SUCCESS = 'PCTOOL_GET_SUCCESS'
export const PCTOOL_GET_FAIL = 'PCTOOL_GET_FAIL'
export const PCTOOL_DELETE_SUCCESS = 'PCTOOL_DELETE_SUCCESS'
export const PCTOOL_UPDATE_SUCCESS = 'PCTOOL_UPDATE_SUCCESS'
export const PCTOOL_UPDATE_FAIL = 'PCTOOL_UPDATE_FAIL'
export const PCTOOL_DELETE_FAIL = 'PCTOOL_DELETE_FAIL'


export const PCTOOL_STATE_CLAER = 'PCTOOL_STATE_CLAER'


export const SIGN_OUT = 'SIGN_OUT'