/* export const GetAliasesName = (teamsData, key) => {
    let teamData = teamsData ? teamsData.find((team) => team._id === localStorage.getItem("teamId")): {};
    let aliasesNames = teamData && Object.keys(teamData) && teamData.aliases ? teamData.aliases : {};
    return aliasesNames[key.toLowerCase()] ? aliasesNames[key.toLowerCase()]  : key
} */

export const GetAliasesName = (teamsData, key) => {
    // Find the team with the matching ID, checking if teamsData exists
    const teamData = teamsData && teamsData.find(team => team._id === localStorage.getItem("teamId")) || {};
    // Get the aliases object or default to an empty object
    const aliasesNames = teamData.aliases || {};
    // Convert key to lowercase
    const lowerKey = key.toLowerCase();

    // Try to return the exact match, plural form, or singular form in aliases
    return aliasesNames[lowerKey]
        || aliasesNames[lowerKey + 's']  // Check for plural version (e.g., "role" -> "roles")
        || aliasesNames[lowerKey.slice(0, -1)]  // Check for singular version if plural (e.g., "roles" -> "role")
        || key;  // Return the original key if no match is found
};

