

import React from 'react'
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
// import { colourOptions } from './data';
import { useDispatch, useSelector } from 'react-redux';

// const options = [
//   { value: 'chocolate', label: 'Chocolate' },
//   { value: 'strawberry', label: 'Strawberry' },
//   { value: 'vanilla', label: 'Vanilla' }
// ]


const animatedComponents = makeAnimated();


export default function SingleSelet({ data }) {

  // console.log(data)

  const { peoples, peopleMessagee, peopleError } = useSelector(state => state.peoples)

  var arr = []

  var cumentor = []

  // current option
  if (data && data.mentor) {
    for (let i = 0; i < peoples.length; i++) {
      if (peoples[i]._id === data.mentor) {
        cumentor.push({ value: `${peoples[i]._id}`, label: `${peoples[i].fname} ${peoples[i].lname}` })
        break;
      }
    }
  }


  // console.log(cumentor)


  //options
  peoples.map(e => {
    arr.push({ value: `${e._id}`, label: `${e.fname} ${e.lname}` })
  })


  const options = arr

  const [inputValue, setInputValue] = React.useState('');
  const [value, setValue] = React.useState(cumentor);
  
  let mentorData = []
  if(value){
    mentorData.push(value.value)
    data.mentor = mentorData
  }

  // console.log(mentorData)
  // console.log(cumentor)
  // console.log(value)

  return (
    <div>
      <Select
        closeMenuOnSelect={false}
        components={animatedComponents}
        defaultValue={[...cumentor]}
        onChange={(newValue) => setValue(newValue)}
        onInputChange={(newValue) => setInputValue(newValue)}
        isClearable
        options={options}
      />
    </div>
  )
}

