import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import store from '../src/store/index';
import { positions, transitions, Provider as AlertProvider } from 'react-alert';
import alertTemplate from 'react-alert-template-basic';

const options = {
  timeout: 5000,
  offset: '250px',
  positions: positions.BOTTOM_CENTER,
  transitions: transitions.SCALE
}

function MyFallbackComponent() {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <div style={{ textAlign: "center", width: "500px" }}>
        <img src="./images/new-logo-nobg-sms.png" width="200" alt="Logo" style={{ marginBottom: "15px" }} />
        <h1 style={{ marginBottom: "15px" }}>We'll be back soon!</h1>
        <p>Sorry for the inconvenience. We're performing some maintenance at the moment. Please check back after some time!</p>
      </div>
    </div>

  )
}

// console.log(process.env.REACT_APP_NODE_ENV, process.env.REACT_APP_NODE_ENV === 'maintenance');
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    {
      process.env.REACT_APP_NODE_ENV === 'maintenance' ?
        <MyFallbackComponent /> :
        <Provider store={store}>
          <AlertProvider template={alertTemplate} {...options}>
            <App />
          </AlertProvider>
        </Provider>
    }
  </>
);

