import {
    DOMAIN_CREATE_SUCCESS, DOMAIN_CREATE_FAIL, DOMAIN_GET_SUCCESS, DOMAIN_DELETE_SUCCESS,
    DOMAIN_UPDATE_SUCCESS, DOMAIN_UPDATE_FAIL, DOMAIN_DELETE_FAIL, DOMAIN_GET_FAIL,
    DOMAIN_SUCCESS_MESSAGE_CLEAR, DOMAIN_ERROR_CLEAR, SIGN_OUT, DOMAIN_DROP_UPDATE_SUCCESS, DOMAIN_DROP_UPDATE_FAIL,
    DOMAIN_ID_CLEAR, DOMAINS_MODIFICATIONS
} from '../types/domainType'

import { areArraysEqual } from '../../HelperFunctions/ArraysAreEqual';

const userState = {
    domains: [],
    dDomains: [],
    cuDomainId: "",
    domainMessagee: '',
    domainError: '',
    domainMessage: ''
}

/* function areArraysEqual(array1, array2) {
    // console.log(array1, array2);
    // console.log(JSON.stringify(array1), JSON.stringify(array2));
    if(JSON.stringify(array1) !== JSON.stringify(array2)){
        return false;
    }

    return true;
} */

export const domainReducer = (state = userState, action) => {
    const { type, payload } = action
    // const {users, successMessage } = payload
    // console.log(payload.users)

    if (type === DOMAIN_CREATE_SUCCESS) {
        return {
            ...state,
            domains: payload.domains,
            dDomains: payload.dDomains,
            domainMessagee: payload.domainSuccessMessage,
            cuDomainId: payload.cuDomainId,
            domainError: ""
        }
    }
    if (type === DOMAIN_GET_SUCCESS) {
        // console.log(payload)
        const d_result = areArraysEqual(state.domains, payload.domains);
        
        const dd_result = areArraysEqual(state.dDomains, payload.dDomains);

        return {
            ...state,
            domains: d_result ? state.domains : payload.domains,
            dDomains: dd_result ? state.dDomains : payload.dDomains,
            domainMessage: payload.domainSuccessMessage,
            domainError: ""
        }
    }
    if (type === DOMAIN_DELETE_SUCCESS) {
        return {
            ...state,
            domains: payload.domains,
            dDomains: payload.dDomains,
            domainMessagee: payload.domainSuccessMessage,
            domainError: ""
        }
    }
    if (type === DOMAIN_UPDATE_SUCCESS) {
        return {
            ...state,
            domains: payload.domains,
            dDomains: payload.dDomains,
            domainMessagee: payload.domainSuccessMessage, // payload.domainSuccessMessage,
            domainError: ""
        }
    }
    if (type === DOMAIN_DROP_UPDATE_SUCCESS) {
        const result = areArraysEqual(state.domains, payload.domains);

        return {
            ...state,
            domains: result ? state.domains : payload.domains,
            domainMessagee: "", // payload.domainSuccessMessage,
            domainError: ""
        }
    }
    if (type === DOMAIN_CREATE_FAIL) {
        return {
            ...state,
            domainError: payload.domainError
        }
    }
    if (type === DOMAIN_DELETE_FAIL) {
        return {
            ...state,
            domainError: payload.domainError
        }
    }
    if (type === DOMAIN_UPDATE_FAIL) {
        return {
            ...state,
            domainError: payload.domainError, // payload.error
        }
    }
    if (type === DOMAIN_DROP_UPDATE_FAIL) {
        return {
            ...state,
            domainError: "", // payload.error
        }
    }
    if (type === DOMAIN_GET_FAIL) {
        return {
            ...state,
            domainError: payload.domainError
        }
    }
    if (type === DOMAIN_SUCCESS_MESSAGE_CLEAR) {
        return {
            ...state,
            domainMessagee: ''
        }
    }
    if (type === DOMAIN_ERROR_CLEAR) {
        // console.log("here domain msg clr");
        return {
            ...state,
            domainError: ''
        }
    }
    if (type === DOMAIN_ID_CLEAR) {
        return {
            ...state,
            cuDomainId: ''
        }
    }
    if (type === SIGN_OUT) {
        return {
            ...state,
            domains: [],
            domainMessagee: '',
            domainError: '',
            domainMessage: ''
        }
    }

    if (type === DOMAINS_MODIFICATIONS) {
        // console.log(payload)
        return {
            ...state,
            domains: payload,
        }
    }

    return state;
}