import {
    INFO_GET_SUCCESS, INFO_GET_FAIL, INFO_DELETE_SUCCESS, INFO_DELETE_FAIL, INFO_UPDATE_SUCCESS, INFO_UPDATE_FAIL,
    INFO_SUCCESS_MESSAGE_CLEAR, INFO_ERROR_CLEAR, INFO_CREATE_SUCCESS
} from '../types/infoType'


const infoState = {
    info: [],
    infoMessage: '',
    infoMessagee: '',
    infoError: ''
}


export const infoReducer = (state = infoState, action) => {
    const { type, payload } = action

    if (type === INFO_GET_SUCCESS) {
        return {
            ...state,
            info: payload.info,
            infoMessage: payload.successMessage
        }
    }
    if (type === INFO_CREATE_SUCCESS) {
        return {
            ...state,
            infoMessagee: payload.successMessage
        }
    }
    if (type === INFO_UPDATE_SUCCESS) {
        return {
            ...state,
            infoMessagee: payload.successMessage
        }
    }
    if (type === INFO_DELETE_SUCCESS) {
        return {
            ...state,
            infoMessagee: payload.infoSuccessMessage
        }
    }
    if (type === INFO_SUCCESS_MESSAGE_CLEAR) {
        return {
            ...state,
            infoMessagee: ""
        }
    }
    if (type === INFO_GET_FAIL) {
        return {
            ...state,
            infoError: payload.error
        }
    }
    if (type === INFO_UPDATE_FAIL) {
        return {
            ...state,
            infoError: payload.error
        }
    }
    if (type === INFO_DELETE_FAIL) {
        return {
            ...state,
            infoError: payload.error
        }
    }
    if (type === INFO_ERROR_CLEAR) {
        return {
            ...state,
            infoError: payload.error
        }
    }


    return state;
}