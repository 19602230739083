import axios from 'axios'
import {
    DOMAIN_CREATE_SUCCESS, DOMAIN_CREATE_FAIL, DOMAIN_GET_SUCCESS, DOMAIN_DELETE_SUCCESS,
    DOMAIN_UPDATE_SUCCESS, DOMAIN_UPDATE_FAIL, DOMAIN_DELETE_FAIL, DOMAIN_GET_FAIL,
    DOMAIN_DROP_UPDATE_SUCCESS, DOMAIN_DROP_UPDATE_FAIL
} from '../types/domainType'

import { SKILL_GET_SUCCESS } from '../types/skillType'

import { ROLE_GET_SUCCESS } from '../types/roleType'

// const token = localStorage.getItem("authToken");
import { SERVER_URI } from '../../config/keys'

import { isCardExist } from '../../HelperFunctions/CardExist'
import { DispatchBlocker } from '../DispatchBlocker/DispatchBlocker'

export const userAuth = (token) => {
    axios.interceptors.request.use(
        config => {
            config.headers.authorization = `Bearer ${token}`;
            return config;
        },
        error => {
            return Promise.reject(error)
        }
    )
}

export const createDomain = (datas) => {
    return async (dispatch,  getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        // Accessing the current state
        const currentState = getState();
        if(isCardExist(currentState.domains.domains, datas.domainName, 'Domain')){
            dispatch({
                type: DOMAIN_CREATE_FAIL,
                payload: {
                    domainError: `Domain with this name already exist!`
                }
            })
            return;
        }

        // const url = 'http://localhost:8080'
        let token = localStorage.getItem('authToken')
        try {
            userAuth(token)
            // console.log(datas)
            const response = await axios.post(`${SERVER_URI}/user/domain/create`, datas)

            dispatch({
                type: DOMAIN_CREATE_SUCCESS,
                payload: {
                    domains: response.data.domains,
                    dDomains: response.data.fData,
                    domainSuccessMessage: response.data.message,
                    cuDomainId: response.data.data._id,
                }
            })

            dispatch({
                type: SKILL_GET_SUCCESS,
                payload: {
                    skills: response.data.skills
                }
            })

            dispatch({
                type: ROLE_GET_SUCCESS,
                payload: {
                    roles: response.data.roles,
                    dRoles: response.data.fRoles
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: DOMAIN_CREATE_FAIL,
                    payload: {
                        domainError: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: DOMAIN_CREATE_FAIL,
                    payload: {
                        domainError: error.message
                    }
                })
            }
        }
    }
}

export const getDomain = (datas) => {
    return async (dispatch) => {
        // const url = 'http://localhost:8080'
        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'adminId': datas.userId,
                'teamId': datas.teamId,
                'curdomain': datas.curdomain,
                'domainsData': datas.domainsData,
                'url': `${SERVER_URI}/user/domain/get`
            }
        }
        // console.log(config)
        try {
            const token = localStorage.getItem("authToken");
            // console.log(token)
            userAuth(token)
            const response = await axios.get(`${SERVER_URI}/user/domain/get`, config)
            // console.log(response.data)
            // localStorage.setItem('authToken', response.data.data.token)

            dispatch({
                type: DOMAIN_GET_SUCCESS,
                payload: {
                    domainSuccessMessage: response.data.message,
                    domains: response.data.data,
                    dDomains: response.data.fData
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: DOMAIN_GET_FAIL,
                    payload: {
                        domainError: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: DOMAIN_GET_FAIL,
                    payload: {
                        domainError: error.message
                    }
                })
            }
        }
    }
}

export const updateDomain = (datas) => {
    return async (dispatch,  getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        // Accessing the current state
        const currentState = getState();
        if(isCardExist(currentState.domains.domains, datas.domainName, 'Domain', datas._id)){
            dispatch({
                type: DOMAIN_UPDATE_FAIL,
                payload: {
                    domainError: `Domain with this name already exist!`
                }
            })
            return;
        }

        // const url = 'http://localhost:8080'
        let token = localStorage.getItem('authToken')
        try {
            // console.log(datas)
            userAuth(token)
            const response = await axios.put(`${SERVER_URI}/user/domain/update`, datas)

            dispatch({
                type: DOMAIN_UPDATE_SUCCESS,
                payload: {
                    domains: response.data.domains,
                    dDomains: response.data.fData,
                    domainSuccessMessage: response.data.message,
                    cuDomainId: response.data.data._id
                }
            })

            dispatch({
                type: SKILL_GET_SUCCESS,
                payload: {
                    skills: response.data.skills
                }
            })

            dispatch({
                type: ROLE_GET_SUCCESS,
                payload: {
                    roles: response.data.roles,
                    dRoles: response.data.fRoles
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: DOMAIN_UPDATE_FAIL,
                    payload: {
                        domainError: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: DOMAIN_UPDATE_FAIL,
                    payload: {
                        domainError: error.message
                    }
                })
            }
        }
    }
}

export const dropUpdateDomain = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        let token = localStorage.getItem('authToken')
        // console.log(datas)
        try {
            // console.log(datas)
            userAuth(token)
            const response = await axios.put(`${SERVER_URI}/user/domain/update`, datas)

            dispatch({
                type: DOMAIN_DROP_UPDATE_SUCCESS,
                payload: {
                    domains: response.data.domains,
                    domainSuccessMessage: response.data.message
                }
            })

            dispatch({
                type: SKILL_GET_SUCCESS,
                payload: {
                    skills: response.data.skills
                }
            })

            dispatch({
                type: ROLE_GET_SUCCESS,
                payload: {
                    roles: response.data.roles,
                    dRoles: response.data.fRoles
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: DOMAIN_DROP_UPDATE_FAIL,
                    payload: {
                        domainError: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: DOMAIN_DROP_UPDATE_FAIL,
                    payload: {
                        domainError: error.message
                    }
                })
            }
        }
    }
}

export const deleteDomain = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        // const url = 'http://localhost:8080'
        let token = localStorage.getItem('authToken')
        let domainSort = localStorage.getItem('domainSort')
        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'adminId': datas.adminId,
                'teamId': datas.teamId,
                'url': `${SERVER_URI}/user/domain/delete`,
                'domainId': datas.domainId,
                'domainSort': domainSort,
                'softdelete': datas.softdelete
            }
        }
        try {
            // console.log(datas)
            userAuth(token)
            const response = await axios.delete(`${SERVER_URI}/user/domain/delete`, config)
            // console.log(response)
            dispatch({
                type: DOMAIN_DELETE_SUCCESS,
                payload: {
                    domains: response.data.domains,
                    dDomains: response.data.fData,
                    domainSuccessMessage: response.data.message,
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: DOMAIN_DELETE_FAIL,
                    payload: {
                        domainError: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: DOMAIN_DELETE_FAIL,
                    payload: {
                        domainError: error.message
                    }
                })
            }
        }
    }
}

export const MakeDomainOwnerLess = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        // const url = 'http://localhost:8080'
        let token = localStorage.getItem('authToken');
        try {
            // console.log(datas)
            userAuth(token)
            const response = await axios.put(`${SERVER_URI}/user/domain/make-domain-owner-null`, datas);

            // dispatch({
            //     type: DOMAIN_UPDATE_SUCCESS,
                // payload: {
                //     domains: response.data.domains,
                //     dDomains: response.data.fData,
                //     domainSuccessMessage: response.data.message,
                //     cuDomainId: response.data.data._id
                // }
            // })
            
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: DOMAIN_UPDATE_FAIL,
                    payload: {
                        domainError: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: DOMAIN_UPDATE_FAIL,
                    payload: {
                        domainError: error.message
                    }
                })
            }
        }
    }
}