import {
    PCTOOL_CREATE_SUCCESS, PCTOOL_CREATE_FAIL, PCTOOL_GET_SUCCESS, PCTOOL_GET_FAIL, PCTOOL_STATE_CLAER, SIGN_OUT
} from '../types/toolsType'



const toolState = {
    circleTools: [],
    toolSuccessMessage: '',
    toolErrors: ''
}


export const toolsReducer = (state = toolState, action) => {
    const { type, payload } = action

    if (type === PCTOOL_CREATE_SUCCESS) {
        return {
            ...state,
            circleTools: payload.circleTools
        }
    }

    if(type === PCTOOL_STATE_CLAER ){
        return {
            ...state,
            circleTools: payload.circleTools
        }
    }

    if (type === PCTOOL_CREATE_FAIL) {
        return {
            ...state,
            toolErrors: payload.toolErrors
        }
    }
    if (type === PCTOOL_GET_SUCCESS) {
        return {
            ...state,
            message: payload.message,
        }
    }
    if (type === PCTOOL_GET_FAIL) {
        return {
            ...state,
            message: payload.message
        }
    }

    if (type === SIGN_OUT) {
        return {
            ...state,
            circleTools: [],
            toolSuccessMessage: '',
            toolErrors: ''
        }
    }


    return state;
}