import axios from "axios";
import {
  TEAM_CREATE_SUCCESS,
  TEAM_CREATE_FAIL,
  TEAM_GET_SUCCESS,
  TEAM_DELETE_SUCCESS,
  TEAM_UPDATE_SUCCESS,
  TEAM_UPDATE_FAIL,
  TEAM_DELETE_FAIL,
  TEAM_GET_FAIL,
  TEAM_UPDATE_MANY_SUCCESS,
  TEAM_UPDATE_MANY_FAIL,
  GET_TEMPLATE_ERR,
  GET_TEMPLATE_SUCC,
} from "../types/teamType";

// const token = localStorage.getItem("authToken");
import { SERVER_URI } from "../../config/keys";
import { DispatchBlocker } from "../DispatchBlocker/DispatchBlocker";

const loggedInAxios = axios.create({
  baseURL: SERVER_URI,
});

export const userAuth = (token) => {
  loggedInAxios.interceptors.request.use(
    config => {
      config.headers.authorization = `Bearer ${token}`;
      return config;
    },
    error => {
      return Promise.reject(error)
    }
  )
}

export const createTeam = (datas) => {
  return async (dispatch, getState) => {
    let proceed = DispatchBlocker(getState);
    if (!proceed) return;
    let token = localStorage.getItem("authToken");
    try {
      userAuth(token);
      const response = await axios.post(`${SERVER_URI}/team/create`, datas);
      // console.log(response.data)
      // localStorage.setItem('authToken', response.data.data.token)

      dispatch({
        type: TEAM_CREATE_SUCCESS,
        payload: {
          // successMessage : response.data.message
        },
      });
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        let data = error.response.data.message;
        dispatch({
          type: TEAM_CREATE_FAIL,
          payload: {
            errors: data,
          },
        });
      } else if (error.message) {
        dispatch({
          type: TEAM_CREATE_FAIL,
          payload: {
            errors: error.message,
          },
        });
      }
    }
  };
};

// export const getTeam = (datas) => {
//   return async (dispatch) => {
//     const config = {
//       headers: {
//         "Content-Type": "application/json",
//         adminId: datas.userId,
//         Authorization: `Bearer ${datas.token}`,
//       },
//     };
//     try {
//       const token = localStorage.getItem("authToken");
//       // userAuth(token);
//       console.log(config);
//       const response = await axios.get(`${SERVER_URI}/team/get`, config);

//       console.log(response.data);
//       localStorage.setItem("modeSwitchPopUp", response.data.modeSwitchPopUp);
//       console.log("ls:", localStorage.getItem("modeSwitchPopUp"));

//       dispatch({
//         type: TEAM_GET_SUCCESS,
//         payload: {
//           teams: response.data.data,
//         },
//       });
//     } catch (error) {
//       if (error && error.response && error.response.data && error.response.data.message) {
//         let data = error.response.data.message;
//         dispatch({
//           type: TEAM_GET_FAIL,
//           payload: {
//             errors: data,
//           },
//         });
//       } else if (error.message) {
//         dispatch({
//           type: TEAM_GET_FAIL,
//           payload: {
//             errors: error.message,
//           },
//         });
//       }
//     }
//   };
// };

export const getTeam = (datas) => {
  return async (dispatch) => {
    // const url = 'http://localhost:8080'
    const config = {
      headers: {
        "Content-Type": "application/josn",
        adminId: datas.userId,
        url: `${SERVER_URI}/team/get`,
      },
    };
    try {
      const token = localStorage.getItem("authToken");
      userAuth(token);
      const response = await loggedInAxios.get(`${SERVER_URI}/team/get`, config);
      localStorage.setItem("modeSwitchPopUp", response.data.modeSwitchPopUp)
      // console.log("ls:", localStorage.getItem("modeSwitchPopUp", response.data.modeSwitchPopUp));

      dispatch({
        type: TEAM_GET_SUCCESS,
        payload: {
          // successMessage: response.data.message,
          teams: response.data.data,
        },
      });
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        let data = error.response.data.message;
        dispatch({
          type: TEAM_GET_FAIL,
          payload: {
            errors: data,
          },
        });
      } else if (error.message) {
        dispatch({
          type: TEAM_GET_FAIL,
          payload: {
            errors: error.message,
          },
        });
      }
    }
  };
};

export const getTemplate = ({ userId }) => {
  return async (dispatch) => {
    const response = await axios.get(
      `${SERVER_URI}/team/template?userId=${userId}`
    );
    // console.log(response.data.data);

    dispatch({
      type: GET_TEMPLATE_SUCC,
      payload: {
        templetesData: response.data.data,
      },
    });

    try {
    } catch (error) {
      dispatch({
        type: GET_TEMPLATE_ERR,
        payload: {
          message: error.response.data.message || error.message,
        },
      });
    }
  };
};

export const updateTeam = (datas) => {
  return async (dispatch, getState) => {
    let proceed = DispatchBlocker(getState);
    if (!proceed) return;
    // let token = localStorage.getItem("authToken");
    try {
      // userAuth(token);
      const response = await axios.post(`${SERVER_URI}/team/update`, datas);

      dispatch({
        type: TEAM_UPDATE_SUCCESS,
        payload: {
          successMessage: response.data.message,
          // token: response.data.data.token,
        },
      });
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        let data = error.response.data.message;
        dispatch({
          type: TEAM_UPDATE_FAIL,
          payload: {
            errors: data,
          },
        });
      } else if (error.message) {
        dispatch({
          type: TEAM_UPDATE_FAIL,
          payload: {
            errors: error.message,
          },
        });
      }
    }
  };
};

// update many teams

export const updateManyTeams = (datas) => {
  return async (dispatch, getState) => {
    let proceed = DispatchBlocker(getState);
    if (!proceed) return;
    try {
      const token = localStorage.getItem("authToken");
      userAuth(token);
      const response = await axios.put(
        `${SERVER_URI}/team/update-many-teams`,
        datas
      );
      // localStorage.setItem('authToken', response.data.data.token)

      dispatch({
        type: TEAM_UPDATE_MANY_SUCCESS,
        payload: {
          sMessage: response.data.message,
        },
      });
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        let data = error.response.data.message;
        dispatch({
          type: TEAM_UPDATE_MANY_FAIL,
          payload: {
            errors: data,
          },
        });
      } else if (error.message) {
        dispatch({
          type: TEAM_UPDATE_MANY_FAIL,
          payload: {
            errors: error.message,
          },
        });
      }
    }
  };
};

export const copyAction = (datas) => {
  return async (dispatch, getState) => {
    let proceed = DispatchBlocker(getState);
    if (!proceed) return;
    try {
      const token = localStorage.getItem("authToken");
      // console.log(datas)
      userAuth(token);
      const response = await axios.post(
        `${SERVER_URI}/team/copy/create`,
        datas
      );

      // console.log(response.data)
      dispatch({
        type: TEAM_UPDATE_MANY_SUCCESS,
        payload: {
          sMessage: response.data.message
        }
      })
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        let data = error.response.data.message;
        dispatch({
          type: TEAM_UPDATE_MANY_FAIL,
          payload: {
            errors: data,
          },
        });
      } else if (error.message) {
        dispatch({
          type: TEAM_UPDATE_MANY_FAIL,
          payload: {
            errors: error.message,
          },
        });
      }
    }
  };
};

export const teamAction = (datas) => {
  return async (dispatch, getState) => {
    let proceed = DispatchBlocker(getState);
    if (!proceed) return;
    try {
      const token = localStorage.getItem("authToken");
      // console.log(token)
      userAuth(token);
      const response = await axios.post(
        `${SERVER_URI}/team/transfer/create`,
        datas
      );
      // console.log(response)
      dispatch({
        type: TEAM_UPDATE_MANY_SUCCESS,
        payload: {
          sMessage: response.data.message
        }
      })
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        let data = error.response.data.message;
        dispatch({
          type: TEAM_UPDATE_MANY_FAIL,
          payload: {
            errors: data,
          },
        });
      } else if (error.message) {
        dispatch({
          type: TEAM_UPDATE_MANY_FAIL,
          payload: {
            errors: error.message,
          },
        });
      }
    }
  };
};

export const getAllTeamData = (datas) => {
  // console.log(datas)
  return async (dispatch) => {
    // const url = 'http://localhost:8080'
    const config = {
      headers: {
        "Content-Type": "application/josn",
        adminId: datas.userId
      },
    };
    try {
      const token = localStorage.getItem("authToken");
      userAuth(token);
      const response = await loggedInAxios.get(`${SERVER_URI}/team/get-all`, config);

      dispatch({
        type: TEAM_GET_SUCCESS,
        payload: {
          // successMessage: response.data.message,
          teams: response.data.data,
        },
      });
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        let data = error.response.data.message;
        dispatch({
          type: TEAM_GET_FAIL,
          payload: {
            errors: data,
          },
        });
      } else if (error.message) {
        dispatch({
          type: TEAM_GET_FAIL,
          payload: {
            errors: error.message,
          },
        });
      }
    }
  };
};

export const deleteTeam = (datas) => {
  return async (dispatch, getState) => {
    let proceed = DispatchBlocker(getState);
    if (!proceed) return;
    try {
      const token = localStorage.getItem("authToken");
      userAuth(token);

      const config = {
        headers: {
          'Content-Type': 'application/josn',
          'adminId': datas.adminId,
          'teamId': datas.teamId,
          'url': `${SERVER_URI}/team/delete`
        }
      }

      const response = await axios.delete(`${SERVER_URI}/team/delete`, config);
      // console.log(response.data)

      dispatch({
        type: TEAM_DELETE_SUCCESS,
        payload: {
          successMessage: response.data.message,
          // added by Naeem
          teams: response.data.teams
        },
      });

      dispatch({
        type: TEAM_GET_SUCCESS,
        payload: {
          teams: response.data.teams
        },
      });
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        let data = error.response.data.message;
        dispatch({
          type: TEAM_DELETE_FAIL,
          payload: {
            errors: data,
          },
        });
      } else if (error.message) {
        dispatch({
          type: TEAM_DELETE_FAIL,
          payload: {
            errors: error.message,
          },
        });
      }
    }
  };
};