export const TEAM_CREATE_SUCCESS = 'TEAM_CREATE_SUCCESS'
export const TEAM_CREATE_FAIL = 'TEAM_CREATE_FAIL'
export const TEAM_GET_SUCCESS = 'TEAM_GET_SUCCESS'
export const TEAM_GET_FAIL = 'TEAM_GET_FAIL'
export const TEAM_DELETE_SUCCESS = 'TEAM_DELETE_SUCCESS'
export const TEAM_UPDATE_SUCCESS = 'TEAM_UPDATE_SUCCESS'
export const TEAM_UPDATE_FAIL = 'TEAM_UPDATE_FAIL'
export const TEAM_DELETE_FAIL = 'TEAM_DELETE_FAIL'

export const TEAM_SUCCESS_CLEAR = 'TEAM_SUCCESS_CLEAR'
export const TEAM_ERROR_CLEAR = 'TEAM_ERROR_CLEAR'

export const TEAM_UPDATE_MANY_SUCCESS = 'TEAM_UPDATE_MANY_SUCCESS'
export const TEAM_UPDATE_MANY_FAIL = 'TEAM_UPDATE_MANY_FAIL'

export const GET_TEMPLATE_SUCC = 'GET_TEMPLATE_SUCC'
export const GET_TEMPLATE_ERR = 'GET_TEMPLATE_ERR'

export const SIGN_OUT = 'SIGN_OUT'