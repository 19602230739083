import {
    PRICING_CREATE_SUCCESS, PRICING_CREATE_FAIL, PRICING_GET_SUCCESS, PRICING_DELETE_SUCCESS,
    PRICING_UPDATE_SUCCESS, PRICING_UPDATE_FAIL, PRICING_DELETE_FAIL, PRICING_GET_FAIL,
    PRICING_SUCCESS_MESSAGE_CLEAR, PRICING_ERROR_CLEAR, SIGN_OUT, PRICING_DROP_UPDATE_SUCCESS, PRICING_DROP_UPDATE_FAIL,
    PRICING_ID_CLEAR, INVOIC_GET_SUCCESS,  INVOIC_GET_FAIL, SUB_GET_SUCCESS,SUB_DELETE_SUCCESS,SUB_DELETE_FAIL_CLEAR,SUB_DELETE_FAIL,SUB_DELETE_SUCCESS_CLEAR, UPGRADE_URL_GET_SUCCESS, UPGRADE_URL_GET_FAIL
} from '../types/pricingType'

const userState = {
    pricings: [],
    Onboarding: [],
    Prices: [],
    invoicData : [],
    subDatas: [],
    invoicMessage : '',
    invoicError : '',
    cuPricingId: "",
    pricingMessagee: '',
    pricingMessage: '',
    pricingError: '',
    subSuccessMessage:'',
    stripeUrl: ''
}

export const pricingReducer = (state = userState, action) => {
    const { type, payload } = action

    if (type === PRICING_CREATE_SUCCESS) {
        return {
            ...state,
            //    pricings: payload.pricings,
            pricingMessagee: payload.pricingSuccessMessage,
            cupricingId: payload.cupricingId,
            pricingError: ""
        }
    }
    if (type === PRICING_GET_SUCCESS) {
        return {
            ...state,
            pricings: payload.pricings,
            Onboarding: payload.Onboarding,
            Prices: payload.Prices,
            pricingMessage: payload.pricingSuccessMessage,
            pricingError: ""
        }
    }
    if (type === PRICING_DELETE_SUCCESS) {
        return {
            ...state,
            pricingMessagee: payload.pricingSuccessMessage,
            pricingError: ""
        }
    }
    if (type === PRICING_UPDATE_SUCCESS) {
        return {
            ...state,
            pricingMessagee:  payload.pricingSuccessMessage,// payload.pricingSuccessMessage,
            pricingError: ""
        }
    }
    if (type === PRICING_DROP_UPDATE_SUCCESS) {
        return {
            ...state,
            pricingMessagee: "",// payload.pricingSuccessMessage,
            pricingError: ""
        }
    }
    if(type === INVOIC_GET_SUCCESS){
        return {
            ...state,
            invoicMessage: payload.invoicSuccessMessage,// payload.invoicSuccessMessage
            invoicData: payload.invoicData
        }
    }
    if(type === SUB_GET_SUCCESS ){
        return {
            ...state,
            subDatas: payload.subDatas
        }
    }
    if (type === PRICING_CREATE_FAIL) {
        return {
            ...state,
            pricingError: payload.error
        }
    }
    if (type === PRICING_DELETE_FAIL) {
        return {
            ...state,
            pricingError: payload.error
        }
    }
    if (type === PRICING_UPDATE_FAIL) {
        return {
            ...state,
            pricingError: payload.error,// payload.error
        }
    }
    if (type === PRICING_DROP_UPDATE_FAIL) {
        return {
            ...state,
            pricingError: "",// payload.error
        }
    }
    if (type === PRICING_GET_FAIL) {
        return {
            ...state,
            pricingError: payload.error
        }
    }
    if (type === PRICING_SUCCESS_MESSAGE_CLEAR) {
        return {
            ...state,
            pricingMessagee: ""
        }
    }
    if (type === PRICING_ERROR_CLEAR) {
        return {
            ...state,
            pricingMessagee: ""
        }
    }
    if (type === PRICING_ID_CLEAR) {
        return {
            ...state,
            cupricingId: ""
        }
    }

    if (type === SIGN_OUT) {
        return {
            ...state,
            pricings: [],
            pricingMessagee: '',
            pricingMessage: '',
            pricingError: ''
        }
    }
    if(type === SUB_DELETE_SUCCESS){
        return{
            ...state,
            subSuccessMessage:payload.subSuccessMessage
        }
    }

    if(type === SUB_DELETE_SUCCESS_CLEAR){
        return{
            ...state,
            subSuccessMessage:''
        }
    }
    if(type === SUB_DELETE_FAIL){
        return{
            ...state,
            pricingError:payload.pricingError
        }
    }
    if(type === SUB_DELETE_FAIL_CLEAR){
        return{
            ...state,
            pricingError:''
        }
    }
    if (type === UPGRADE_URL_GET_SUCCESS) {
        return {
            ...state,
            stripeUrl: payload.stripeUrl
        }
    }
    if (type === UPGRADE_URL_GET_FAIL) {
        return {
            ...state,
            pricingError: payload.pricingError
        }
    }
    return state;
}