import axios from "axios";
import {
  STATE_CREATE_SUCCESS,
  STATE_CREATE_FAIL,
  STATE_GET_SUCCESS,
  STATE_DELETE_SUCCESS,
  STATE_UPDATE_SUCCESS,
  STATE_UPDATE_FAIL,
  STATE_DELETE_FAIL,
  STATE_GET_FAIL,
} from "../types/stateType";

import { PEOPLE_GET_SUCCESS } from "../types/peopleType";

import { SKILL_GET_SUCCESS } from "../types/skillType";

import { ROLE_GET_SUCCESS } from "../types/roleType";

import { DOMAIN_GET_SUCCESS } from "../types/domainType";

import { LINK_GET_SUCCESS } from "../types/linkType";

import { CIRCLE_GET_SUCCESS } from "../types/circleType";

import { PROJECT_GET_SUCCESS } from "../types/projectType";

import { MEMBER_GET_SUCCESS } from "../types/memberType";

import { PFMEMBER_GET_SUCCESS } from "../types/pfmemberType";

import { MEETINGS_GET_SUCCESS } from "../types/meetingType";

// const token = localStorage.getItem("authToken");
import { SERVER_URI } from '../../config/keys';
import { sortingOrderCreate_for_stateAction } from '../../HelperFunctions/Sorting';
import { DispatchBlocker } from '../DispatchBlocker/DispatchBlocker'

export const userAuth = (token) => {
  axios.interceptors.request.use(
    (config) => {
      config.headers.authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const createState = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        let token = localStorage.getItem('authToken')
        try {
            userAuth(token)
            // console.log(datas)
            const response = await axios.post(`${SERVER_URI}/user/states/create`, datas)
            // console.log(response.data)

      // sortingOrderCreate_for_stateAction(response.data.states);

      dispatch({
        type: STATE_CREATE_SUCCESS,
        payload: {
          statesuccessMessage: response.data.message,
          cuStateId: response.data.data,
          states: response.data.states,
        },
      });
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        let data = error.response.data.message;
        dispatch({
          type: STATE_CREATE_FAIL,
          payload: {
            statesError: data,
          },
        });
      } else if (error.message) {
        dispatch({
          type: STATE_CREATE_FAIL,
          payload: {
            statesError: error.message,
          },
        });
      }
    }
  };
};

export const getState = (datas) => {
  return async (dispatch) => {
    // const url = 'http://localhost:8080'
    const config = {
      headers: {
        "Content-Type": "application/josn",
        adminId: datas.userId,
        team: datas.teamId,
        url: `${SERVER_URI}/user/states/get`,
      },
    };
    try {
      const token = localStorage.getItem("authToken");
      // console.log(token)
      userAuth(token);
      const response = await axios.get(`${SERVER_URI}/user/states/get`, config);
      
      const {
        deletedCircles,
        deletedDomains,
        deletedLinks,
        deletedPeoples,
        deletedProjects,
        deletedRoles,
        deletedSkills,
      } = response.data;

      dispatch({
        type: STATE_GET_SUCCESS,
        payload: {
          statesuccessMessage: response.data.message,
          states: response.data.data,
          trashData: {
            deletedCircles,
            deletedDomains,
            deletedLinks,
            deletedPeoples,
            deletedProjects,
            deletedRoles,
            deletedSkills,
          },
        },
      });

      dispatch({
        type: PEOPLE_GET_SUCCESS,
        payload: {
          peopleSuccessMessage: response.data.message,
          people: response.data.peoples,
        },
      });

      dispatch({
        type: SKILL_GET_SUCCESS,
        payload: {
          skillSuccessMessage: response.data.message,
          skills: response.data.skills,
        },
      });

      dispatch({
        type: ROLE_GET_SUCCESS,
        payload: {
          roleSuccessMessage: response.data.message,
          roles: response.data.roles,
          dRoles: response.data.fRoles,
        },
      });

      dispatch({
        type: DOMAIN_GET_SUCCESS,
        payload: {
          domainSuccessMessage: response.data.message,
          domains: response.data.domains,
          dDomains: response.data.fDomains,
        },
      });

      dispatch({
        type: LINK_GET_SUCCESS,
        payload: {
          linkSuccessMessage: response.data.message,
          links: response.data.links,
        },
      });

      dispatch({
        type: CIRCLE_GET_SUCCESS,
        payload: {
          circleSuccessMessage: response.data.message,
          circles: response.data.circles,
          dCircles: response.data.fCircles,
        },
      });

      dispatch({
        type: PROJECT_GET_SUCCESS,
        payload: {
          projectSuccessMessage: response.data.message,
          projects: response.data.projects,
        },
      });

      dispatch({
        type: MEMBER_GET_SUCCESS,
        payload: {
          membersuccessMessage: response.data.message,
          members: response.data.cmembers.concat(response.data.pmembers),
        },
      });

      dispatch({
        type: PFMEMBER_GET_SUCCESS,
        payload: {
          membersuccessMessage: response.data.message,
          members: response.data.cpfmembers.concat(response.data.ppfmembers),
        },
      });

      dispatch({
        type: MEETINGS_GET_SUCCESS,
        payload: {
          meetingsSuccessMessage: response.data.message,
          meetings: response.data.cmeetings.concat(response.data.pmeetings),
        },
      });
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        let data = error.response.data.message;
        dispatch({
          type: STATE_GET_FAIL,
          payload: {
            statesError: data,
          },
        });
      } else if (error.message) {
        dispatch({
          type: STATE_GET_FAIL,
          payload: {
            statesError: error.message,
          },
        });
      }
    }
  };
};

export const updateState = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        // const url = 'http://localhost:8080'
        let token = localStorage.getItem('authToken')
        try {
            // console.log(datas)
            userAuth(token)
            const response = await axios.put(`${SERVER_URI}/user/states/update`, datas)
            // console.log(response.data)

      // sortingOrderCreate_for_stateAction(response.data.states);

      dispatch({
        type: STATE_UPDATE_SUCCESS,
        payload: {
          states: response.data.states,
          statesuccessMessage: response.data.message,
        },
      });

      /* dispatch({
                type: PEOPLE_GET_SUCCESS,
                payload: {
                    peopleSuccessMessage: response.data.message,
                    people: response.data.peoples
                }
            })

            dispatch({
                type: SKILL_GET_SUCCESS,
                payload: {
                    skillSuccessMessage: response.data.message,
                    skills: response.data.skills
                }
            })

            dispatch({
                type: ROLE_GET_SUCCESS,
                payload: {
                    roleSuccessMessage: response.data.message,
                    roles: response.data.roles,
                    dRoles: response.data.fRoles
                }
            })

            dispatch({
                type: DOMAIN_GET_SUCCESS,
                payload: {
                    domainSuccessMessage: response.data.message,
                    domains: response.data.domains,
                    dDomains: response.data.fDomains
                }
            })

            dispatch({
                type: LINK_GET_SUCCESS,
                payload: {
                    linkSuccessMessage: response.data.message,
                    links: response.data.links
                }
            })

            dispatch({
                type: CIRCLE_GET_SUCCESS,
                payload: {
                    circleSuccessMessage: response.data.message,
                    circles: response.data.circles,
                    dCircles: response.data.fCircles
                }
            })
            
            dispatch({
                type: PROJECT_GET_SUCCESS,
                payload: {
                    projectSuccessMessage: response.data.message,
                    projects: response.data.projects
                }
            }) */

      dispatch({
        type: MEMBER_GET_SUCCESS,
        payload: {
          membersuccessMessage: response.data.message,
          members: response.data.cmembers.concat(response.data.pmembers),
        },
      });

      dispatch({
        type: PFMEMBER_GET_SUCCESS,
        payload: {
          membersuccessMessage: response.data.message,
          members: response.data.cpfmembers.concat(response.data.ppfmembers),
        },
      });

      /* dispatch({
                type : MEETINGS_GET_SUCCESS,
                payload : {
                    meetingsSuccessMessage : response.data.message,
                    meetings : response.data.cmeetings.concat(response.data.pmeetings)
                }
            }) */
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        let data = error.response.data.message;
        dispatch({
          type: STATE_UPDATE_FAIL,
          payload: {
            statesError: data,
          },
        });
      } else if (error.message) {
        dispatch({
          type: STATE_UPDATE_FAIL,
          payload: {
            statesError: error.message,
          },
        });
      }
    }
  };
};

export const deleteState = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        // const url = 'http://localhost:8080'
        let token = localStorage.getItem('authToken')
        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'adminId': datas.adminId,
                'teamId': datas.teamId,
                'url': `${SERVER_URI}/user/skill/delete`,
                'skillId': datas.skillId
            }
        }
        try {
            // console.log(datas)
            userAuth(token)
            const response = await axios.delete(`${SERVER_URI}/user/skill/delete`, config);

      // sortingOrderCreate_for_stateAction(response.data.states);

      dispatch({
        type: STATE_DELETE_SUCCESS,
        payload: {
          states: response.data.states,
          statesuccessMessage: response.data.message,
        },
      });
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        let data = error.response.data.message;
        dispatch({
          type: STATE_DELETE_FAIL,
          payload: {
            statesError: data,
          },
        });
      } else if (error.message) {
        dispatch({
          type: STATE_DELETE_FAIL,
          payload: {
            statesError: error.message,
          },
        });
      }
    }
  };
};
