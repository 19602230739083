import axios from "axios";
import {
  TAGS_GET_SUCCESS,
  TAGS_GET_FAIL,
  TAGS_CREATE_SUCCESS,
  TAGS_CREATE_FAIL,
  TAGS_DELETE_SUCCESS,
  TAGS_DELETE_FAIL,
  TAGS_UPDATE_SUCCESS,
  TAGS_UPDATE_FAIL,
  TAGS_SUCCESS_MESSAGE_CLEAR,
  TAGS_ERROR_CLEAR,
} from "../types/tagsType";

// const token = localStorage.getItem("authToken");
import { SERVER_URI } from '../../config/keys';
import { DispatchBlocker } from "../DispatchBlocker/DispatchBlocker.js"

export const userAuth = (token) => {
  axios.interceptors.request.use(
    (config) => {
      config.headers.authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const tagCreate = (datas) => {
  return async (dispatch, getState) => {
    let proceed = DispatchBlocker(getState);
    if (!proceed) return;
    try {
      // console.log(datas)
      const token = localStorage.getItem("authToken");
      userAuth(token)
      const response = await axios.post(`${SERVER_URI}/user/tags/create`, datas)

      dispatch({
        type: TAGS_CREATE_SUCCESS,
        payload: {
          successMessage: response.data.message,
          // tags : response.data.data
        }
      })

    } catch (error) {
      if (error && error.response && error.response.data && error.response.data.message) {
        let data = error.response.data.message
        dispatch({
          type: TAGS_CREATE_FAIL,
          payload: {
            tagsError: data
          }
        })
      } else if (error.message) {
        dispatch({
          type: TAGS_CREATE_FAIL,
          payload: {
            tagsError: error.message
          }
        })
      }
    }
  }
};

export const getTags = (datas) => {
  return async (dispatch) => {
    // const url = 'http://localhost:8080'
    const config = {
      headers: {
        "Content-Type": "application/josn",
        adminId: datas.userId,
        teamId: datas.teamId,
        url: `${SERVER_URI}/user/tags/get`,
      },
    };
    try {
      // const token = localStorage.getItem("authToken");
      // userAuth(token)
      const response = await axios.get(`${SERVER_URI}/user/tags/get`, config);
      // console.log(response.data.data)

      dispatch({
        type: TAGS_GET_SUCCESS,
        payload: {
          successMessage: response.data.message,
          tag: response.data.data,
        },
      });
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        let data = error.response.data.message;
        dispatch({
          type: TAGS_GET_FAIL,
          payload: {
            tagsError: data,
          },
        });
      } else if (error.message) {
        dispatch({
          type: TAGS_GET_FAIL,
          payload: {
            tagsError: error.message,
          },
        });
      }
    }
  };
};

export const updateTags = (datas) => {
  return async (dispatch, getState) => {
    let proceed = DispatchBlocker(getState);
    if (!proceed) return;
    try {
      const token = localStorage.getItem("authToken");
      // console.log(token)
      userAuth(token)
      const response = await axios.post(`${SERVER_URI}/tags/update`, datas)
      // console.log(response.data.message)
      // localStorage.setItem('authToken', response.data.data.token)

      dispatch({
        type: TAGS_UPDATE_SUCCESS,
        payload: {
          successMessage: response.data.message
        }
      })

    } catch (error) {
      if (error && error.response && error.response.data && error.response.data.message) {
        let data = error.response.data.message
        dispatch({
          type: TAGS_UPDATE_FAIL,
          payload: {
            tagsError: data
          }
        })
      } else if (error.message) {
        dispatch({
          type: TAGS_UPDATE_FAIL,
          payload: {
            tagsError: error.message
          }
        })
      }
    }
  }
};

export const deleteTags = (datas) => {
  return async (dispatch, getState) => {
    let proceed = DispatchBlocker(getState);
    if (!proceed) return;
    let token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/josn',
        'url': `${SERVER_URI}/user/tags/delete`,
        'adminId': datas.adminId,
        'teamId': datas.teamId,
        'tagsIds': JSON.stringify(datas.tagsIds)
        // 'tagsIds': datas.tagsIds
      }
    }
    // console.log(datas.adminId);
    try {
      // console.log(datas)
      userAuth(token)
      const response = await axios.delete(`${SERVER_URI}/user/tags/delete`, config)

      dispatch({
        type: TAGS_DELETE_SUCCESS,
        payload: {
          tagsSuccessMessage: response.data.message,
        },
      });
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        let data = error.response.data.message;
        dispatch({
          type: TAGS_DELETE_FAIL,
          payload: {
            tagsError: data,
          },
        });
      } else if (error.message) {
        dispatch({
          type: TAGS_DELETE_FAIL,
          payload: {
            tagsError: error.message,
          },
        });
      }
    }
  };
};

export const updateDeleteFromTrash = (datas) => {
  const { cardArray, actionType, teamId, circleSort } = datas;
  return async (dispatch) => {
    let token = localStorage.getItem("authToken");
    try {
      userAuth(token);
      const response = await axios.post(
        `${SERVER_URI}/user/stash/update-delete`,
        { cardArray, actionType, teamId, circleSort }
      );

      dispatch({
        type: TAGS_DELETE_SUCCESS,
        payload: {
          tagsSuccessMessage: response.data.message,
        },
      });
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        let data = error.response.data.message;
        dispatch({
          type: TAGS_DELETE_FAIL,
          payload: {
            tagsError: data,
          },
        });
      } else if (error.message) {
        dispatch({
          type: TAGS_DELETE_FAIL,
          payload: {
            tagsError: error.message,
          },
        });
      }
    }
  };
};
