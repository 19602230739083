export const GetPeopleDataDetails = (
    comparedData,
    peoples,
    skills,
    roles,
    domains,
    links
) => {
    let data = [];
    const AllPeopleId = comparedData
        .filter((data) => data.type === "people")
        .map((p) => p.id);
    if (AllPeopleId.length > 0) {
        AllPeopleId.map((sp) => {
            let Obj = {
                peopleId: sp,
                peopleData: {},
                cardData: [],
            };
            let peopleDetails = peoples.filter((p) => p._id === sp);
            Obj.peopleData = { ...peopleDetails[0], type: "people" };
            skills.map((ss) => {
                if (ss.owners.includes(sp)) {
                    Obj.cardData.push({ ...ss, type: "skill" });
                }
            });
            roles.map((sr) => {
                if (sr.owners.includes(sp)) {
                    Obj.cardData.push({ ...sr, type: "role" });
                }
            });
            domains.map((sd) => {
                if (
                    sd.owners && sd.owners.owner && 
                    sd.owners.type && sd.owners.type === "63d3c91d40c4cf67373922e2" &&
                    sd.owners.owner === sp
                ) {
                    Obj.cardData.push({ ...sd, type: "domain" });
                }
            });
            links.map((sl) => {
                if (sl.owner === sp) {
                    Obj.cardData.push({ ...sl, type: "link" });
                }
            });
            data.push(Obj);
        });
    }
    return data;
};

export const GetSkillDataArray = (comparedData, skills) => {
    let data = [];
    const AllSkillId = comparedData
        .filter((data) => data.type === "skill")
        .map((s) => s.id);
    // console.log(AllSkillId, skills);
    if (AllSkillId.length > 0) {
        AllSkillId.map((si) => {
            skills.map((ss) => {
                if (ss._id === si) {
                    data.push({ ...ss, type: "skill" });
                }
            });
        });
    }
    // console.log(data);
    return data;
};
export const GetRoleDataArray = (comparedData, roles) => {
    let data = [];
    const AllRoleId = comparedData
        .filter((data) => data.type === "role")
        .map((r) => r.id);
    // console.log(AllRoleId,roles);
    if (AllRoleId.length > 0) {
        AllRoleId.map((si) => {
            roles.map((sr) => {
                if (sr._id === si) {
                    data.push({ ...sr, type: "role" });
                }
            });
        });
    }
    return data;
};

export const CompareAndModifyStateData = (stateData, dbData) => {
    // Create a map for quick lookup of dbData by _id
    const dbDataMap = new Map(dbData.map(obj => [obj._id, obj]));

    // Merge stateData with dbData
    const mergedData = stateData.map(stateObj => {
        const dbObj = dbDataMap.get(stateObj._id);
        if (dbObj) {
            // Merge stateObj with dbObj
            return { ...stateObj, ...dbObj };
        } else {
            // If no corresponding dbObj found, just return stateObj
            return stateObj;
        }
    });
    return mergedData;
}
