import {
    SKILL_CREATE_SUCCESS, SKILL_CREATE_FAIL, SKILL_GET_SUCCESS, SKILL_DELETE_SUCCESS,
    SKILL_UPDATE_SUCCESS, SKILL_UPDATE_FAIL, SKILL_DELETE_FAIL, SKILL_GET_FAIL,
    SKILL_SUCCESS_MESSAGE_CLEAR, SKILL_ERROR_CLEAR, SIGN_OUT, SKILL_DROP_UPDATE_SUCCESS, SKILL_DROP_UPDATE_FAIL,
    SKILL_ID_CLEAR, SKILLS_MODIFICATIONS
}
    from '../types/skillType'

import { areArraysEqual } from '../../HelperFunctions/ArraysAreEqual';

const skillState = {
    skills: [],
    nDaoamins: [],
    cuSkillId: "",
    skillsMessagee: '',
    skillError: '',
    skillsMessage: ''
}

export const skillsReducer = (state = skillState, action) => {
    const { type, payload } = action
    // const {users, successMessage } = payload
    // console.log(payload.users)

    if (type === SKILL_CREATE_SUCCESS) {
        return {
            ...state,
            skills: payload.skills,
            skillsMessagee: payload.skillSuccessMessage,
            cuSkillId: payload.cuSkillId,
            nDaoamins: payload.nDaoamins,
            skillError: ""
        }
    }

    if (type === SKILL_GET_SUCCESS) {
        // console.log(payload)
        const result = areArraysEqual(state.skills, payload.skills);

        return {
            ...state,
            skills: result ? state.skills : payload.skills,
            skillsMessage: payload.skillSuccessMessage,
            skillError: ""
        }
    }
    if (type === SKILL_DELETE_SUCCESS) {
        return {
            ...state,
            skills: payload.skills,
            skillsMessagee: payload.skillSuccessMessage,
            skillError: ""
        }
    }
    if (type === SKILL_UPDATE_SUCCESS) {
        return {
            ...state,
            skills: payload.skills,
            skillsMessagee: payload.skillSuccessMessage,// payload.skillSuccessMessage,
            nDaoamins: payload.nDaoamins,
            skillError: ""
        }
    }
    if (type === SKILL_DROP_UPDATE_SUCCESS) {
        // console.log(payload.skills)
        /* checker */

        const result = areArraysEqual(state.skills, payload.skills);
        // console.log(97, result);

        return {
            ...state,
            skills: result ? state.skills : payload.skills,
            // skills: payload.skills,
            skillsMessagee: "",// payload.skillSuccessMessage,
            skillError: ""
        }
    }
    if (type === SKILL_CREATE_FAIL) {
        return {
            ...state,
            skillError: payload.skillError
        }
    }
    if (type === SKILL_DELETE_FAIL) {
        return {
            ...state,
            skillError: payload.skillError
        }
    }
    if (type === SKILL_UPDATE_FAIL) {
        return {
            ...state,
            skillError: payload.skillError,// payload.error
        }
    }
    if (type === SKILL_DROP_UPDATE_FAIL) {
        return {
            ...state,
            skillError: "",// payload.error
        }
    }
    if (type === SKILL_GET_FAIL) {
        return {
            ...state,
            skillError: payload.error
        }
    }
    if (type === SKILL_SUCCESS_MESSAGE_CLEAR) {
        return {
            ...state,
            skillsMessagee: '',
            nDaoamins: []
        }
    }
    if (type === SKILL_ERROR_CLEAR) {
        return {
            ...state,
            skillError: ''
        }
    }
    if (type === SKILL_ID_CLEAR) {
        return {
            ...state,
            cuSkillId: ''
        }
    }
    if (type === SIGN_OUT) {
        return {
            ...state,
            skills: [],
            skillsMessagee: '',
            skillError: '',
            skillsMessage: ''
        }
    }
    if (type === SKILLS_MODIFICATIONS) {
        // console.log(payload)
        return {
            ...state,
            skills: payload,
        }
    }
    return state;
}