export const blockDispatchMiddleware = store => next => action => {
    const state = store.getState();
    let { type, payLoad } = action;

    let { curUserData: { curTeam }, myInfo: { userId } } = state.auth;
    // console.log("team data in blockDispatchMiddleware",state.teams.teams)
    // let { teams = [] } = state.teams;
    let { teams } = state.teams;

    const targetTeam = teams.find((st) => st._id === curTeam);
    if (!type.includes("_GET_")) {
        // console.log(targetTeam && Object.keys(targetTeam).length > 0 && targetTeam.controlledBy !== userId)
        if (targetTeam && Object.keys(targetTeam).length > 0 && targetTeam.controlledBy && targetTeam.controlledBy !== userId) {
            // console.log("---BLOCK---MIDDLEWIRE----")
            return;
        }
    }
    // console.log("<<<--- not block in middlewire --->>>");
    return next(action);
};

export default blockDispatchMiddleware;