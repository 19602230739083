import React from "react";


export default function InfoModel({ closeInfo,infoData}) {
  return (
    <div
      id="infoModal"
      role="dialog"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div className="modal-dialog modal-md modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">List Info</h4>
          </div>
          <div className="modal-body">
            <p>{infoData.infoMassage}</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              data-tippy-content="Close Tool"
              className="btn btn-mytpt-red"
              data-dismiss="modal"
              onClick={closeInfo}
            >
              <i className="fa fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
