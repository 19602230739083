import React from "react";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
export default function InfoModel({ closeInfo, openInfo, data }) {

  return (
    <div
      id="infoModal"
      role="dialog"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div className="modal-dialog modal-md modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">List Info</h4>
          </div>
          <div className="modal-body">
            <p>{data.infoMassage}</p>
          </div>
          <div className="modal-footer">
            <Tippy content={<span>Close Tool</span>}>
              <button
                type="button"
                className="btn btn-mytpt-red"
                data-dismiss="modal"
                onClick={closeInfo}
              >
                <i className="fa fa-times"></i>
              </button>
            </Tippy>
          </div>
        </div>
      </div>
    </div>
  );
}
