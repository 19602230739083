import {
  PRICING_CREATE_SUCCESS,
  PRICING_CREATE_FAIL,
  PRICING_GET_SUCCESS,
  PRICING_DELETE_SUCCESS,
  PRICING_UPDATE_SUCCESS,
  PRICING_UPDATE_FAIL,
  PRICING_DELETE_FAIL,
  PRICING_GET_FAIL,
  PRICING_SUCCESS_MESSAGE_CLEAR,
  PRICING_ERROR_CLEAR,
  SIGN_OUT,
  PRICING_DROP_UPDATE_SUCCESS,
  PRICING_DROP_UPDATE_FAIL,
  PRICING_ID_CLEAR,
  INVOIC_GET_SUCCESS,
  INVOIC_GET_FAIL,
  SUB_GET_SUCCESS,
  SUB_GET_FAIL,
  SUB_DELETE_SUCCESS,
  SUB_DELETE_FAIL,
  SUB_DELETE_SUCCESS_CLEAR,
  SUB_DELETE_FAIL_CLEAR,
  UPGRADE_URL_GET_SUCCESS,
  UPGRADE_URL_GET_FAIL,
} from "../types/pricingType";

import axios from "axios";

import { SERVER_URI } from "../../config/keys";
import { CLIENT_URL } from "../../config/keys";

export const generateStripeUpgradeUrl = (data) => {
  localStorage.setItem("Rnavigate", "GO");
  return async (dispatch) => {
    // console.log(data)
    const config = {
      headers: {
        "Content-Type": "application/josn",
        userId: data.userId,
        plan: data.plan,
        interval: data.interval,
        email: data.email,
        refBy: data.refBy,
        quantity: data.quantity || 1,
        url: CLIENT_URL,
      },
    };
    // console.log(48,config);
    try {
      const response = await axios.get(
        `${SERVER_URI}/user/generate/upgrade-url`,
        config
      );
      // console.log(response.data);
      // localStorage.setItem('authToken', response.data.data.token)

      dispatch({
        type: UPGRADE_URL_GET_SUCCESS,
        payload: {
          stripeUrl: response.data.data,
        },
      });
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        let data = error.response.data.message;
        dispatch({
          type: UPGRADE_URL_GET_FAIL,
          payload: {
            pricingError: data,
          },
        });
      } else if (error.message) {
        dispatch({
          type: UPGRADE_URL_GET_FAIL,
          payload: {
            pricingError: error.message,
          },
        });
      }
    }
  };
};

export const getpricing = (datas) => {
  return async (dispatch) => {
    // const url = 'http://localhost:8080'
    const config = {
      headers: {
        "Content-Type": "application/josn",
      },
    };
    try {
      const response = await axios.get(
        `${SERVER_URI}/user/all/pricing`,
        config
      );
      // console.log(response.data.Onboarding);
      // localStorage.setItem('authToken', response.data.data.token)

      dispatch({
        type: PRICING_GET_SUCCESS,
        payload: {
          pricingSuccessMessage: response.data.message,
          pricings: response.data.data,
          Prices: response.data.prices,
          Onboarding: response.data.Onboarding,
        },
      });
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        let data = error.response.data.message;
        dispatch({
          type: PRICING_GET_FAIL,
          payload: {
            pricingError: data,
          },
        });
      } else if (error.message) {
        dispatch({
          type: PRICING_GET_FAIL,
          payload: {
            pricingError: error.message,
          },
        });
      }
    }
  };
};

export const getOnboarding = (datas) => {
  return async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/josn",
        adminId: datas.adminId,
      },
    };

    try {
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        let data = error.response.data.message;
        dispatch({
          type: PRICING_GET_FAIL,
          payload: {
            pricingError: data,
          },
        });
      } else if (error.message) {
        dispatch({
          type: PRICING_GET_FAIL,
          payload: {
            pricingError: error.message,
          },
        });
      }
    }
  };
};

export const getInvoic = (datas) => {
  return async (dispatch) => {
    // const url = 'http://localhost:8080'
    const config = {
      headers: {
        "Content-Type": "application/josn",
        adminId: datas.adminId,
      },
    };
    // console.log(config)
    try {
      const response = await axios.get(
        `${SERVER_URI}/user/productInvoic`,
        config
      );
      // console.log( response.data)
      // localStorage.setItem('authToken', response.data.data.token)

      dispatch({
        type: INVOIC_GET_SUCCESS,
        payload: {
          invoicSuccessMessage: response.data.message,
          invoicData: response.data.data.data,
        },
      });
    } catch (error) {
      let data = error.response.data.message;
      // console.log(data)
      dispatch({
        type: INVOIC_GET_FAIL,
        payload: {
          error: data,
        },
      });
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        let data = error.response.data.message;
        dispatch({
          type: INVOIC_GET_FAIL,
          payload: {
            invoicError: data,
          },
        });
      } else if (error.message) {
        dispatch({
          type: INVOIC_GET_FAIL,
          payload: {
            invoicError: error.message,
          },
        });
      }
    }
  };
};

// getsub
export const getSubs = (datas) => {
  return async (dispatch) => {
    // const url = 'http://localhost:8080'
    const config = {
      headers: {
        "Content-Type": "application/josn",
        adminId: datas.adminId,
      },
    };
    // console.log(config)
    try {
      const response = await axios.get(`${SERVER_URI}/user/getsub`, config);
      // console.log( response.data)
      // localStorage.setItem('authToken', response.data.data.token)

      dispatch({
        type: SUB_GET_SUCCESS,
        payload: {
          subSuccessMessage: response.data.message,
          subDatas: response.data.data.data,
        },
      });
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        let data = error.response.data.message;
        dispatch({
          type: SUB_GET_SUCCESS,
          payload: {
            pricingError: data,
          },
        });
      } else if (error.message) {
        dispatch({
          type: SUB_GET_SUCCESS,
          payload: {
            pricingError: error.message,
          },
        });
      }
    }
  };
};

export const deleteSub = (datas) => {
  return async (dispatch) => {
    // const url = 'http://localhost:8080'
    const config = {
      headers: {
        "Content-Type": "application/josn",
        subId: datas.subId,
      },
    };
    // console.log(config)
    try {
      const response = await axios.delete(
        `${SERVER_URI}/user/deletesub`,
        config
      );
      // console.log( response.data)
      // localStorage.setItem('authToken', response.data.data.token)

      dispatch({
        type: SUB_DELETE_SUCCESS,
        payload: {
          subSuccessMessage: response.data.message,
        },
      });
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        let data = error.response.data.message;
        dispatch({
          type: SUB_DELETE_FAIL,
          payload: {
            pricingError: data,
          },
        });
      } else if (error.message) {
        dispatch({
          type: SUB_DELETE_FAIL,
          payload: {
            pricingError: error.message,
          },
        });
      }
    }
  };
};
