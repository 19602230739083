import axios from 'axios'
import {
    MEETINGS_CREATE_SUCCESS, MEETINGS_CREATE_FAIL, MEETINGS_GET_SUCCESS, MEETINGS_DELETE_SUCCESS,
    MEETINGS_UPDATE_SUCCESS, MEETINGS_UPDATE_FAIL, MEETINGS_DELETE_FAIL, MEETINGS_GET_FAIL
} from '../types/meetingType'


// const token = localStorage.getItem("authToken");
import { SERVER_URI } from '../../config/keys'
import { DispatchBlocker } from '../DispatchBlocker/DispatchBlocker'

export const userAuth = (token) => {
    axios.interceptors.request.use(
        config => {
            config.headers.authorization = `Bearer ${token}`;
            return config;
        },
        error => {
            return Promise.reject(error)
        }
    )
}

export const createMeetings = (datas) => {
    return async (dispatch, getState) => {
            let proceed = DispatchBlocker(getState);
            if (!proceed) return;
            // const url = 'http://localhost:8080'
            let token = localStorage.getItem('authToken')
            try {
                userAuth(token)
                // console.log(datas)
                const response = await axios.post(`${SERVER_URI}/user/meetings/create`, datas)
                // console.log(response.data.data)

                dispatch({
                    type: MEETINGS_CREATE_SUCCESS,
                    payload: {
                        meetingsSuccessMessage: response.data.message,
                        meetingId: response.data.data._id,
                        meetings: response.data.meetings
                    }
                })

            } catch (error) {
                if (error && error.response && error.response.data && error.response.data.message) {
                    let data = error.response.data.message
                    dispatch({
                        type: MEETINGS_CREATE_FAIL,
                        payload: {
                            meetingsError: data
                        }
                    })
                } else if (error.message) {
                    dispatch({
                        type: MEETINGS_CREATE_FAIL,
                        payload: {
                            meetingsError: error.message
                        }
                    })
                }
            }
        }
    }

    export const getMeetings = (datas) => {
        return async (dispatch) => {
            // const url = 'http://localhost:8080'
            const config = {
                headers: {
                    'Content-Type': 'application/josn',
                    'adminId': datas.userId,
                    'team': datas.teamId,
                    'url': `${SERVER_URI}/user/meetings/get`
                }
            }
            // console.log(config)
            try {
                const token = localStorage.getItem("authToken");
                userAuth(token)
                const response = await axios.get(`${SERVER_URI}/user/meetings/get`, config)

                dispatch({
                    type: MEETINGS_GET_SUCCESS,
                    payload: {
                        meetingsSuccessMessage: response.data.message,
                        meetings: response.data.data
                    }
                })

            } catch (error) {
                if (error && error.response && error.response.data && error.response.data.message) {
                    let data = error.response.data.message
                    dispatch({
                        type: MEETINGS_GET_FAIL,
                        payload: {
                            meetingsError: data
                        }
                    })
                } else if (error.message) {
                    dispatch({
                        type: MEETINGS_GET_FAIL,
                        payload: {
                            meetingsError: error.message
                        }
                    })
                }
            }
        }
    }

    export const updateMeetings = (datas) => {
        return async (dispatch, getState) => {
            let proceed = DispatchBlocker(getState);
            if (!proceed) return;
            let token = localStorage.getItem('authToken')
            try {
                // console.log(datas)
                userAuth(token)
                const response = await axios.put(`${SERVER_URI}/user/meetings/update`, datas)

                dispatch({
                    type: MEETINGS_UPDATE_SUCCESS,
                    payload: {
                        meetingSuccessMessage: response.data.message,
                        meetings: response.data.meetings
                    }
                })

            } catch (error) {
                if (error && error.response && error.response.data && error.response.data.message) {
                    let data = error.response.data.message
                    dispatch({
                        type: MEETINGS_UPDATE_FAIL,
                        payload: {
                            meetingsError: data
                        }
                    })
                } else if (error.message) {
                    dispatch({
                        type: MEETINGS_UPDATE_FAIL,
                        payload: {
                            meetingsError: error.message
                        }
                    })
                }
            }
        }
    }

    export const deleteMeetings = (datas) => {
        return async (dispatch, getState) => {
            let proceed = DispatchBlocker(getState);
            if (!proceed) return;
            let token = localStorage.getItem('authToken')
            const config = {
                headers: {
                    'Content-Type': 'application/josn',
                    'adminId': datas.adminId,
                    'teamId': datas.teamId,
                    'url': `${SERVER_URI}/user/meetings/delete`,
                    'meetingId': datas.meetingId
                }
            }
            try {
                // console.log(datas)
                userAuth(token)
                const response = await axios.delete(`${SERVER_URI}/user/meetings/delete`, config)

                dispatch({
                    type: MEETINGS_DELETE_SUCCESS,
                    payload: {
                        meetingsSuccessMessage: response.data.message,
                        meetings: response.data.meetings
                    }
                })

            } catch (error) {
                if (error && error.response && error.response.data && error.response.data.message) {
                    let data = error.response.data.message
                    dispatch({
                        type: MEETINGS_DELETE_FAIL,
                        payload: {
                            meetingsError: data
                        }
                    })
                } else if (error.message) {
                    dispatch({
                        type: MEETINGS_DELETE_FAIL,
                        payload: {
                            meetingsError: error.message
                        }
                    })
                }
            }
        }
    }