import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getpricing } from '../../../store/actions/pricingAction';
import Navbar from './Navbar';
import Footer from '../../Component/Footer/Footer';

document.body.style = '';
document.body.classList = 'no-app-pages body2 menu2';

export default function Onboarding() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { pricings, Onboarding, Prices, cuPricingId, pricingMessagee, pricingMessage, pricingError } = useSelector(state => state.pricings)

    useEffect(() => {
        dispatch(getpricing())
    }, [])


    let getPrice = (data) => {
        if (data) {
            for (let i = 0; i < Prices.length; i++) {
                if (Prices[i].product === data) {
                    return Prices[i].unit_amount
                }
            }
        }
    }

    var priceData 

    if (Onboarding && Onboarding.length > 0) {
        priceData = getPrice(Onboarding[0].id)
    }

    // console.log(priceData)


    // useEffect(() => {
    //     if (Onboarding && Onboarding.length > 0) {
    //         priceData = getPrice(Onboarding[0].id)
    //     }
    // }, [Onboarding])


    document.body.style = "background-image: url('./images/inner-bg-2-1440w.png')"
    
    return (
        <div>
            {/* <!-- NAVBAR --> */}
            <Navbar />
            {/* <!-- END NAVBAR -->  */}
            <div className="container vertical-center page-addon">
                <div className="row">
                    <div className="col-lg-4 col-sm-12 col-12">
                        <div className="onboarding-section">
                            {Onboarding && Onboarding.length > 0 ? <h2>{Onboarding[0].name}</h2> : null}
                            <ul className="accordingly-p">
                                {Onboarding && Onboarding.length > 0 ? <li>{Onboarding[0].description}</li> : null}
                            </ul>
                            <ul className="accordingly-p">
                                {Onboarding && Onboarding.length > 0 && Onboarding[0].metadata && Object.keys(Onboarding[0].metadata).length > 0 ?
                                    Object.keys(Onboarding[0].metadata).map((key, indexs) => {
                                        return (
                                            <li key={indexs} dangerouslySetInnerHTML={{ __html: Onboarding[0].metadata[key] }}></li>
                                        )
                                    })
                                    : null}
                            </ul>
                            <div className="pricing-head-price-two7">
                                {priceData ? <h4>{priceData / 100} <span style={{ "font-weight": "normal" }}>EUR</span></h4> : null}
                                <p>(one time payment)</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-sm-12 col-12">
                        <div className="alert alert-error" style={{ "display": "none" }}></div>
                        <form action="https://staged.mytpt.work/onboarding/" method="post" id="registration"
                            className="infomation-section">
                            <div className="tab-content">
                                <div className="tab-pane fade in active" id="step2">
                                    <div className="onboarding-h">
                                        <h2>You need more than just a tool?</h2>
                                        <p>The <strong>mytpt</strong> <span>onboarding package</span> helps you set everything
                                            up, and gets
                                            you going.</p>

                                        <a href="https://staged.mytpt.work/?redirect=https://staged.mytpt.work/onboarding"
                                            className="btn btn-primary submit" style={{ "width": "330px", "margin-top": "30px" }}>Login to Buy
                                            Now</a>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
