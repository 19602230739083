import React from 'react'
import PNFForm from '../../Component/PNFForm/PNFForm'
import { useNavigate } from "react-router-dom";
// import './Style.css'
import Navbar from './Navbar';

function PNF() {
    const navigate = useNavigate();
    
    document.body.style = '';
    document.body.classList = 'no-app-pages body1';

    return (
        <div>
            <Navbar/>
            <PNFForm />
        </div>

    )
}

export default PNF