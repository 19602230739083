export const HISTORY_CREATE_SUCCESS = 'HISTORY_CREATE_SUCCESS'
export const HISTORY_CREATE_FAIL = 'HISTORY_CREATE_FAIL'
export const HISTORY_GET_SUCCESS = 'HISTORY_GET_SUCCESS'
export const HISTORY_GET_FAIL = 'HISTORY_GET_FAIL'
export const HISTORY_DELETE_SUCCESS = 'HISTORY_DELETE_SUCCESS'
export const HISTORY_UPDATE_SUCCESS = 'HISTORY_UPDATE_SUCCESS'
export const HISTORY_UPDATE_FAIL = 'HISTORY_UPDATE_FAIL'
export const HISTORY_DELETE_FAIL = 'HISTORY_DELETE_FAIL'

export const HISTORY_MESSAGE_CLEAR = 'HISTORY_MESSAGE_CLEAR'
export const HISTORY_DATAS_CLEAR = 'HISTORY_DATAS_CLEAR'


export const HISTORY_SUCCESS_MESSAGE_CLEAR = 'HISTORY_SUCCESS_MESSAGE_CLEAR'
export const HISTORY_ERROR_CLEAR = 'HISTORY_ERROR_CLEAR'
export const SIGN_OUT = 'SIGN_OUT'

