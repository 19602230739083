import React from 'react'
import PillComponent from '../PillComponent';

export default function FPPeopleList({ GetAliasesName, teams, peopleSorted, clickedOnList, toggleEXTnNOCards, getSortIcon, isInfos, pCardHideOnchange, noteOnchange, note, pnoteRef, handleSave, noteSaveData, PFSotedData, isTag, isEXT, isNO, TagWiseSorting, ScrollIntoView, setPepole, workloadToggle, aggrigatedPersonFTE, skills, roles, dRoles, domains, links, toggleTagCards, focused, handelFocused, lFromState, peopleSubmit, pref, fName, handleNameChange, pfNameHandel, setFName, setPepoles, peopleFrom, tagFilteredObj, extNoFilteredObj }) {
    return (
        <div className="board-col" data-board_id="people">
            <div className="list list-bg-yellow" data-list_id="people">
                <div className="dropdown" style={{ position: "static" }}>
                    <h4 className="list-title">
                        <img alt="" src="images/icon-people.png" /> {GetAliasesName(teams, "People")} ({peopleSorted.length}
                        ){" "}
                        <a
                            className={
                                clickedOnList.isClicked &&
                                    clickedOnList.listName.includes("people")
                                    ? "list-menu refresh-list"
                                    : "list-menu refresh-list hide"
                            }
                            href="#"
                            onClick={() => {
                                toggleEXTnNOCards("people", "", true, "list");
                            }}
                        >
                            <i className="fas fa-sync-alt"></i>
                        </a>
                        <a
                            className={
                                clickedOnList.isClicked &&
                                    clickedOnList.listName.includes("people")
                                    ? "list-menu dropdown-toggle hide"
                                    : "list-menu dropdown-toggle"
                            }
                            data-toggle="dropdown"
                            href="#"
                        >
                            {getSortIcon("People")}
                            <i className="fas fa-ellipsis-v"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-right" role="menu">
                            <li role="presentation">
                                <a
                                    onClick={() => isInfos("People")}
                                    role="menuitem"
                                    className="mytpt-list-info"
                                >
                                    Info
                                </a>
                            </li>
                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    data-toggle="modal"
                                    data-target="#peopleModal"
                                >
                                    Add new person
                                </a>
                            </li>

                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    onClick={() => pCardHideOnchange("People")}
                                    className="hide_list"
                                >
                                    Hide list
                                </a>
                            </li>
                            <li role="presentation">
                                <a role="menuitem" style={{ paddingRight: "5px" }}>
                                    Show Notes
                                    <label className="switch">
                                        <input
                                            name="People"
                                            onChange={(e) => noteOnchange(e)}
                                            checked={note.People}
                                            className="dd-switch"
                                            type="checkbox"
                                        />
                                        <span className="slider"></span>
                                    </label>
                                </a>
                            </li>
                            <li role="presentation" className="divider"></li>
                            <li
                                role="presentation"
                                onClick={() => {
                                    toggleEXTnNOCards("people", "EXT", false);
                                }}
                            >
                                <a role="menuitem" className="show-external">
                                    Show external people
                                </a>
                            </li>
                        </ul>
                    </h4>
                </div>

                <div className="card-list scrollbar" /* ref={focusListRef} */>
                    <div
                        className="form-group notes"
                        style={note.People ? { display: "block" } : { display: "none" }}
                    >
                        <textarea
                            className="notes"
                            ref={pnoteRef}
                            name="People"
                            onBlur={(e) => handleSave(e)}
                            placeholder="Notes"
                            maxLength="100"
                            autoFocus={null}
                            defaultValue={noteSaveData.People}
                        ></textarea>
                    </div>

                    {PFSotedData && PFSotedData.length > 0
                        ? PFSotedData.map((e, index) => {
                            if ((isTag || isEXT || isNO) && e._id !== focused.id) {
                                let Proceed = TagWiseSorting(
                                    e,
                                    isTag,
                                    isEXT,
                                    isNO,
                                    tagFilteredObj,
                                    extNoFilteredObj,
                                    "people"
                                );
                                if (!Proceed) return;
                            }

                            return (
                                <div
                                    // onDragStart={(e) => peopleDragStart(e, index)}
                                    // onDragEnter={(e) => peopleDragEnter(e, index)}
                                    // onDragEnd={peopleDrop}
                                    key={e._id}
                                    // draggable={focused && focused.card && focused.card !== "People" ? true : false}
                                    // className={`card ${focused.card === "People" && focused.index === index ? 'focused-card' : localStorage.getItem('latest-created') === e._id ? ' latest-created ' : ''}`}
                                    className={`card ${focused.card === "People" && focused.id === e._id
                                        ? "focused-card"
                                        : localStorage.getItem("latest-created") === e._id
                                            ? " latest-created "
                                            : ""
                                        }`}
                                    data-list_id="people"
                                    onClick={(event) => {
                                        ScrollIntoView();
                                        if (
                                            event.target.classList.contains("card") ||
                                            event.target.classList.contains("card-edit")
                                        ) {
                                            setPepole(e, PFSotedData, index);
                                        }

                                        // if (parentPeopleRef.current) {
                                        //   const scrollTop = parentPeopleRef.current.scrollTop;
                                        //   // console.log("Scroll position:", scrollTop);
                                        //   setFocusedRef(scrollTop);
                                        // }
                                        /* if (focusListRef.current) {
                                          const scrollTop = focusListRef.current.scrollTop;
                                          console.log("Scroll position:", scrollTop);
                                          setFocusedRef(scrollTop);
                                        } */
                                    }}
                                >
                                    <div className="static-relations">
                                        {
                            /* (e.availability > 0) && */ workloadToggle ===
                                            true && (
                                                <PillComponent
                                                    occupied={aggrigatedPersonFTE(
                                                        skills,
                                                        [...roles, ...dRoles],
                                                        domains,
                                                        links,
                                                        e._id
                                                    )}
                                                    total={e.availability}
                                                />
                                            )
                                        }
                                        {e.workRole === "External" ? (
                                            <span
                                                onClick={() => {
                                                    toggleEXTnNOCards(e._id, "EXT", false, "pill");
                                                }}
                                                className="custom-badge blue ext-owner-pill"
                                            >
                                                EXT
                                            </span>
                                        ) : null}
                                        {e.tags && e.tags.length > 0
                                            ? e.tags.map((r, indexs) => (
                                                <span
                                                    onClick={() => toggleTagCards(r)}
                                                    key={indexs}
                                                    className="custom-badge green tags"
                                                >
                                                    {r}
                                                </span>
                                            ))
                                            : null}
                                    </div>

                                    <a
                          /* onClick={() => setPepole(e, PFSotedData, index)} */ className="card-edit"
                                    >{`${e.fname} ${e.lname}`}</a>
                                    {focused.card === "People" && focused.id === e._id ? (
                                        <a className="card-unlink" style={{ display: "block" }}>
                                            <i
                                                onClick={() =>
                                                    handelFocused({
                                                        card: "CPeople",
                                                        index: null,
                                                        id: null,
                                                    })
                                                }
                                                className="icon far fa-times-circle fas"
                                            ></i>
                                        </a>
                                    ) : focused.card === "People" && focused.id !== e._id ? (
                                        <a className="card-focus">
                                            <img
                                                onClick={() =>
                                                    handelFocused({
                                                        card: "People",
                                                        index: index,
                                                        id: e._id,
                                                    })
                                                }
                                                src="./images/newicons/focus.svg"
                                            />
                                        </a>
                                    ) : null}
                                </div>
                            );
                        })
                        : null}

                    <div
                        className="card2"
                        style={
                            lFromState.Peoples ? { display: "block" } : { display: "none" }
                        }
                    >
                        <form
                            className="form-new-card"
                            role="form"
                            onSubmit={peopleSubmit}
                        >
                            <div className="form-group">
                                <input
                                    type="text"
                                    ref={pref}
                                    name="card_name"
                                    placeholder="Add first name"
                                    data-mode="People focus mode"
                                    className="form-control"
                                    value={fName}
                                    onChange={handleNameChange}
                                    onKeyPress={pfNameHandel}
                                    autoComplete="off"
                                    required
                                />
                            </div>
                            <div className="form-group" style={{ marginTop: "10px" }}>
                                <button type="submit" className="btn btn-mytpt add-quick">
                                    Save
                                </button>
                                <a
                                    href="#"
                                    type="button"
                                    // onClick={() => setPepoles({ fname: fName })}
                                    onClick={() => {
                                        // Added by Debashis on 13-12-2023 (updated 18-12-2023)
                                        let lastname = "";
                                        let firstname = "";
                                        if (fName.trim().indexOf(" ") >= 0) {
                                            let nameArray = fName.split(" ");
                                            lastname = nameArray.pop();
                                            firstname = nameArray.join(" ");
                                        } else {
                                            lastname = "";
                                            firstname = fName;
                                        }
                                        setFName("");
                                        setPepoles({ fname: firstname, lname: lastname });
                                        lFromState.Peoples = false;
                                    }}
                                    className="btn btn-link underline open-tool"
                                >
                                    Edit details
                                </a>
                                <a
                                    href="#"
                                    onClick={() => peopleFrom()}
                                    className="btn btn-link underline close-add-card"
                                >
                                    Close
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
                <a
                    className="btn-list add-card"
                    onClick={() => peopleFrom()}
                    data-list_id="people"
                    style={
                        !lFromState.Peoples ? { display: "block" } : { display: "none" }
                    }
                >
                    + Add new person
                </a>
            </div>
        </div>
    );
}
