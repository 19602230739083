import React, { useEffect, useState } from 'react'
import Select from 'react-select';
// import JSON from 'json'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios'

import { SERVER_URI } from '../../../../config/keys'
import { createAndUpdateMember, getPFMember } from '../../../../store/actions/pfmemberAction'
import { updateMember, getMember } from '../../../../store/actions/memberAction'
import { PCTOOL_CREATE_SUCCESS, PCTOOL_CREATE_FAIL, PCTOOL_GET_SUCCESS, PCTOOL_GET_FAIL, SIGN_OUT } from '../../../../store/types/toolsType'

import { MEMBER_MODIFICATIONS } from '../../../../store/types/memberType';
import { GetAliasesName } from '../../../../HelperFunctions/GetAliasesName';

export default function PeopleModeTool({ closeCircleModeTool, focused, setFocused, temporarilyMovedItem, setTemporarilyMovedItem,teams }) {
    let dispatch = useDispatch()

    const { loading, successMessage, error, authenticate, myInfo } = useSelector(state => state.auth)
    const { peoples, peopleMessagee, errors } = useSelector(state => state.peoples)
    const { circleTools, toolSuccessMessage, toolErrors } = useSelector(state => state.tools)
    const { members, memberMessagee, memberError, memberMessage } = useSelector((state) => state.members);

    let adminId = myInfo.userId
    let teamId = localStorage.getItem('teamId')
    let pfcData = JSON.parse(localStorage.getItem("pfcAddData"))
    let pfCAndPrCData = JSON.parse(localStorage.getItem("pfCAndPrCData"))
    var memberTypes = localStorage.getItem("memberType")

    const [tools, setTools] = useState({})
    const [cmtype, SetCmType] = useState('Core')
    const [focusUser, setFocusUser] = useState({})
    const [headName, setHeadName] = useState({
        adding: "",
        to: ""
    })

    /* const setPFsCirclePluss = (data) => {

    } */

    useEffect(() => {
        if (headName && headName.adding.length === 0 && focusUser && Object.keys(focusUser).length > 0) {
            if (memberTypes === "Circles") {
                if (pfCAndPrCData && Object.keys(pfCAndPrCData).length > 0) {
                    setHeadName({
                        ...headName,
                        ["adding"]: `Adding: ${focusUser.fname} ${focusUser.lname} (Person)`,
                        ["to"]: `To: ${pfCAndPrCData.circleName} (Circle)`
                    })
                }
            } else if (memberTypes === "Projects") {
                if (pfCAndPrCData && Object.keys(pfCAndPrCData).length > 0) {
                    setHeadName({
                        ...headName,
                        ["adding"]: `Adding: ${focusUser.fname} ${focusUser.lname} (Person)`,
                        ["to"]: `To: ${pfCAndPrCData.projectName} (Project)`
                    })
                }
            }
        }
    }, [focusUser, pfCAndPrCData])

    useEffect(() => {
        if (focused && focused.card.length > 0 && focused.card === "People") {
            let fcData = localStorage.getItem("fpData")
            let userData = {}
            if (fcData && fcData.length > 0) {
                for (let i = 0; i < peoples.length; i++) {
                    if (fcData === peoples[i]._id) {
                        userData = peoples[i]
                        break;
                    }
                }
            }
            setFocusUser(userData)
        }
    }, [focused])

    useEffect(() => {
        if (Object.keys(tools).length === 0) {
            var userRdata = {
                Skill: [],
                Role: [],
                Domain: [],
                Link: []
            }
            if (pfcData.data && pfcData.data.length > 0) {
                let userData = [...pfcData.data]
                for (let i = 0; i < userData.length; i++) {
                    if (userData[i].type === 'Skill') {
                        userRdata.Skill = [...userRdata.Skill, userData[i]]
                    } else if (userData[i].type === 'Role') {
                        userRdata.Role = [...userRdata.Role, userData[i]]
                    } else if (userData[i].type === 'Domain') {
                        userRdata.Domain = [...userRdata.Domain, userData[i]]
                    } else if (userData[i].type === 'Link') {
                        userRdata.Link = [...userRdata.Link, userData[i]]
                    }
                }
            }
            if (userRdata && Object.keys(userRdata).length > 0 && (userRdata.Skill.length > 0 || userRdata.Role.length > 0 || userRdata.Domain.length > 0 || userRdata.Link.length > 0)) {
                setTools(userRdata)
            }
        }

    }, [pfcData.data && pfcData.data.length > 0])

    const [pfcState, setPfcState] = useState([])

    const handelChange = (isChecked, value) => {
        if (value.type === "People") {
            if (isChecked === true) {
                setPfcState([value])
            } else if (isChecked === false) {
                setPfcState([])
            }
        } else {
            if (isChecked === true) {
                if (pfcState && pfcState.length > 0 && pfcState[0].Id === focusUser._id) {
                    setPfcState([value])
                } else {
                    setPfcState([...pfcState, value])
                }
            } else if (isChecked === false) {
                let filterData = pfcState.filter((x) => {
                    return x.Id !== value.Id
                })
                setPfcState(filterData)
            }
        }

    }

    const cFActions = (popoverId) => {
        if (popoverId) {
            // console.log(members, popoverId);
            let updated_members = [...members];
            let updated_popoverId = {...popoverId};

            // Find the index of the object to replace in the array
            let indexToReplace = updated_members.findIndex(member => (
                member.teamId === updated_popoverId.teamId &&
                member.memberType === updated_popoverId.memberType
            ));

            // Replace the object in the array
            if (indexToReplace !== -1) {
                updated_members[indexToReplace] = updated_popoverId;
            }

            dispatch({ type: MEMBER_MODIFICATIONS, payload: updated_members })
            setTemporarilyMovedItem(null);

            popoverId.adminId = myInfo.userId
            popoverId.url = `${SERVER_URI}/user/member/create`
            let obj = {}
            obj.userId = myInfo.userId
            obj.teamId = popoverId.teamId
            dispatch(updateMember(popoverId)).then(() => {
                // dispatch(getMember(obj))
                localStorage.removeItem("actionType");
                localStorage.removeItem("membercData");
                localStorage.removeItem("memberType");
            })
        }
    }

    const pFActions = (popoverIds) => {
        if (popoverIds) {
            popoverIds.adminId = myInfo.userId
            popoverIds.url = `${SERVER_URI}/user/member/update`
            let obj = {}
            obj.userId = myInfo.userId
            obj.teamId = popoverIds.teamId
            dispatch(updateMember(popoverIds)).then(() => {
                // dispatch(getMember(obj))
                localStorage.removeItem("actionType");
                localStorage.removeItem("memberPData");
                localStorage.removeItem("memberType");
            })
        }
    }

    const cFActionspf = (pfpopoverId) => {
        if (pfpopoverId) {
            pfpopoverId.adminId = myInfo.userId
            pfpopoverId.url = `${SERVER_URI}/user/pfmember/create`
            let obj = {}
            obj.userId = myInfo.userId
            obj.teamId = pfpopoverId.teamId
            dispatch(createAndUpdateMember(pfpopoverId)).then(() => {
                dispatch(getPFMember(obj))
                localStorage.removeItem("pfmembercData");
            })
        }
    }

    const pFActionspf = (pfpopoverIds) => {
        if (pfpopoverIds) {
            pfpopoverIds.adminId = myInfo.userId
            pfpopoverIds.url = `${SERVER_URI}/user/pfmember/create`
            let obj = {}
            obj.userId = myInfo.userId
            obj.teamId = pfpopoverIds.teamId
            dispatch(createAndUpdateMember(pfpopoverIds)).then(() => {
                dispatch(getPFMember(obj))
                localStorage.removeItem("pfmemberPData");
            })
        }
    }

    // membercData
    const pFCirclePopover = (data) => {
        var popoverIds = JSON.parse(localStorage.getItem("memberPData"))
        var popoverId = JSON.parse(localStorage.getItem("membercData"))

        var pfpopoverIds = JSON.parse(localStorage.getItem("pfmemberPData"))
        var pfpopoverId = JSON.parse(localStorage.getItem("pfmembercData"))

        var memberType = localStorage.getItem("memberType")

        let type = cmtype || "Core"

        if (memberType === "Circles" && data && data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                let popoverValue = data[i].type
                let popoverMember = { _id: data[i].Id }
                if (popoverValue === "People" && popoverMember && popoverId) {
                    if (type === "Core") {
                        popoverId.coreMembers.People = [...new Set([...popoverId.coreMembers.People, popoverMember._id])]
                    }
                    else if (type === "Extended") {
                        popoverId.extendedMembers.People = [...new Set([...popoverId.extendedMembers.People, popoverMember._id])]
                    }
                }
                else if (popoverValue === "Skill" && popoverMember && pfpopoverId) {
                    if (type === "Core") {
                        pfpopoverId.coreMembers.Skills = [...new Set([...pfpopoverId.coreMembers.Skills, { CardId: popoverMember._id.toString(), peopleIds: focusUser._id.toString() }])]
                        popoverId.coreMembers.Skills = [...new Set([...popoverId.coreMembers.Skills, popoverMember._id])]
                    }
                    else if (type === "Extended") {
                        pfpopoverId.extendedMembers.Skills = [...new Set([...pfpopoverId.extendedMembers.Skills, { CardId: popoverMember._id.toString(), peopleIds: focusUser._id.toString() }])]
                        popoverId.extendedMembers.Skills = [...new Set([...popoverId.extendedMembers.Skills, popoverMember._id])]
                    }
                }
                else if (popoverValue === "Domain" && popoverMember && pfpopoverId) {
                    if (type === "Core") {
                        pfpopoverId.coreMembers.Domains = [...new Set([...pfpopoverId.coreMembers.Domains, { CardId: popoverMember._id.toString(), peopleIds: focusUser._id.toString() }])]
                        popoverId.coreMembers.Domains = [...new Set([...popoverId.coreMembers.Domains, popoverMember._id])]
                    }
                    else if (type === "Extended") {
                        pfpopoverId.extendedMembers.Domains = [...new Set([...pfpopoverId.extendedMembers.Domains, { CardId: popoverMember._id.toString(), peopleIds: focusUser._id.toString() }])]
                        popoverId.extendedMembers.Domains = [...new Set([...popoverId.extendedMembers.Domains, popoverMember._id])]
                    }
                }
                else if (popoverValue === "Role" && popoverMember && pfpopoverId) {
                    if (type === "Core") {
                        pfpopoverId.coreMembers.Roles = [...new Set([...pfpopoverId.coreMembers.Roles, { CardId: popoverMember._id.toString(), peopleIds: focusUser._id.toString() }])]
                        popoverId.coreMembers.Roles = [...new Set([...popoverId.coreMembers.Roles, popoverMember._id])]
                    }
                    else if (type === "Extended") {
                        pfpopoverId.extendedMembers.Roles = [...new Set([...pfpopoverId.extendedMembers.Roles, { CardId: popoverMember._id.toString(), peopleIds: focusUser._id.toString() }])]
                        popoverId.extendedMembers.Roles = [...new Set([...popoverId.extendedMembers.Roles, popoverMember._id])]
                    }
                }
                else if (popoverValue === "Link" && popoverMember && pfpopoverId) {
                    if (type === "Core") {
                        pfpopoverId.coreMembers.Links = [...new Set([...pfpopoverId.coreMembers.Links, { CardId: popoverMember._id.toString(), peopleIds: focusUser._id.toString() }])]
                        popoverId.coreMembers.Links = [...new Set([...popoverId.coreMembers.Links, popoverMember._id])]
                    }
                    else if (type === "Extended") {
                        pfpopoverId.extendedMembers.Links = [...new Set([...pfpopoverId.extendedMembers.Links, { CardId: popoverMember._id.toString(), peopleIds: focusUser._id.toString() }])]
                        popoverId.extendedMembers.Links = [...new Set([...popoverId.extendedMembers.Links, popoverMember._id])]
                    }
                } else if (popoverValue === "Project" && popoverMember && popoverId) {
                    if (type === "Core") {
                        popoverId.coreMembers.Projects = [...new Set([...popoverId.coreMembers.Projects, popoverMember._id])]
                        // cFActions(popoverId)
                    }
                }
            }
            if (pfpopoverId && Object.keys(pfpopoverId).length > 0 && popoverId && Object.keys(popoverId).length > 0) {
                cFActionspf(pfpopoverId)
                cFActions(popoverId)
            }

        } else if (memberType === "Projects" && data && data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                let popoverValue = data[i].type
                let popoverMember = { _id: data[i].Id }
                if (popoverValue === "People" && popoverMember) {
                    if (type === "Core") {
                        popoverIds.coreMembers.People = [...new Set([...popoverIds.coreMembers.People, popoverMember._id])]
                    } else if (type === "Extended") {
                        popoverIds.extendedMembers.People = [...new Set([...popoverIds.extendedMembers.People, popoverMember._id])]
                    }
                }
                else if (popoverValue === "Skill" && popoverMember) {
                    if (type === "Core") {
                        pfpopoverIds.coreMembers.Skills = [...new Set([...pfpopoverIds.coreMembers.Skills, { CardId: popoverMember._id.toString(), peopleIds: focusUser._id.toString() }])]
                        popoverIds.coreMembers.Skills = [...new Set([...popoverIds.coreMembers.Skills, popoverMember._id])]
                    } else if (type === "Extended") {
                        pfpopoverIds.extendedMembers.Skills = [...new Set([...pfpopoverIds.extendedMembers.Skills, { CardId: popoverMember._id.toString(), peopleIds: focusUser._id.toString() }])]
                        popoverIds.extendedMembers.Skills = [...new Set([...popoverIds.extendedMembers.Skills, popoverMember._id])]
                    }
                }
                else if (popoverValue === "Domain" && popoverMember) {
                    if (type === "Core") {
                        pfpopoverIds.coreMembers.Domains = [...new Set([...pfpopoverIds.coreMembers.Domains, { CardId: popoverMember._id.toString(), peopleIds: focusUser._id.toString() }])]
                        popoverIds.coreMembers.Domains = [...new Set([...popoverIds.coreMembers.Domains, popoverMember._id])]
                    } else if (type === "Extended") {
                        pfpopoverIds.extendedMembers.Domains = [...new Set([...pfpopoverIds.extendedMembers.Domains, { CardId: popoverMember._id.toString(), peopleIds: focusUser._id.toString() }])]
                        popoverIds.extendedMembers.Domains = [...new Set([...popoverIds.extendedMembers.Domains, popoverMember._id])]
                    }
                }
                else if (popoverValue === "Role" && popoverMember) {
                    if (type === "Core") {
                        pfpopoverIds.coreMembers.Roles = [...new Set([...pfpopoverIds.coreMembers.Roles, { CardId: popoverMember._id.toString(), peopleIds: focusUser._id.toString() }])]
                        popoverIds.coreMembers.Roles = [...new Set([...popoverIds.coreMembers.Roles, popoverMember._id])]
                    } else if (type === "Extended") {
                        pfpopoverIds.extendedMembers.Roles = [...new Set([...pfpopoverIds.extendedMembers.Roles, { CardId: popoverMember._id.toString(), peopleIds: focusUser._id.toString() }])]
                        popoverIds.extendedMembers.Roles = [...new Set([...popoverIds.extendedMembers.Roles, popoverMember._id])]
                    }
                }
                else if (popoverValue === "Link" && popoverMember) {
                    if (type === "Core") {
                        pfpopoverIds.coreMembers.Links = [...new Set([...pfpopoverIds.coreMembers.Links, { CardId: popoverMember._id.toString(), peopleIds: focusUser._id.toString() }])]
                        popoverIds.coreMembers.Links = [...new Set([...popoverIds.coreMembers.Links, popoverMember._id])]
                    } else if (type === "Extended") {
                        pfpopoverIds.extendedMembers.Links = [...new Set([...pfpopoverIds.extendedMembers.Links, { CardId: popoverMember._id.toString(), peopleIds: focusUser._id.toString() }])]
                        popoverIds.extendedMembers.Links = [...new Set([...popoverIds.extendedMembers.Links, popoverMember._id])]
                    }
                } else if (popoverValue === "Circle" && popoverMember) {
                    if (type === "Core") {
                        popoverIds.coreMembers.Circles = [...new Set([...popoverIds.coreMembers.Circles, popoverMember._id])]
                    }
                }
            }
            if (pfpopoverIds && Object.keys(pfpopoverIds).length > 0 && popoverIds && Object.keys(popoverIds).length > 0) {
                pFActionspf(pfpopoverIds)
                pFActions(popoverIds)
            }

        }
    }

    const saveTools = () => {
        pFCirclePopover(pfcState)
        closeCircleModeTool()
    }

    // verify that is checked or not
    const isChecked = (Id) => {
        if (pfcState && pfcState.length > 0) {
            for (let i = 0; i < pfcState.length; i++) {
                if (pfcState[i].Id === Id) {
                    return true
                }
            }
        }
        return false
    }

    return (
        <div id="" role="dialog" data-backdrop="static" data-keyboard="false" >
            <div className="modal-dialog modal-sm modal-dialog-scrollable " >
                {/* Modal content*/}
                <div className="modal-content ">
                    <div className="modal-header">
                        {headName && Object.keys(headName).length > 0 && headName.adding ?
                            <h5 className="modal-title">{headName.adding}</h5>
                            : null}
                        {headName && Object.keys(headName).length > 0 && headName.to ?
                            <h5 className="modal-title">{headName.to}</h5>
                            : null}
                    </div>

                    <div className="modal-body">
                        <form className="form">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="form-group mtype" style={{ marginBottom: '0px' }}>
                                        <input type="radio" id="mtype_core" className="logit" onChange={(e) => SetCmType("Core")} name="mtype" checked={cmtype === "Core"} />
                                        <label htmlFor="mtype_core" className="inline-label">Core</label>

                                        <input type="radio" id="mtype_xtd" className="logit" onChange={(e) => SetCmType("Extended")} name="mtype" checked={cmtype === "Extended"} />
                                        <label htmlFor="mtype_xtd" className="inline-label">Extended</label>
                                    </div>

                                    <p style={{ marginBottom: '5px' }}>How do you want to add?</p>

                                    <div>
                                        <div className="card-on-popup">
                                            {focusUser ?
                                                <label><input style={{ marginRight: '2%', position: 'unset' }} checked={isChecked(focusUser._id)} onChange={(e) => handelChange(e.target.checked, { Id: focusUser._id, name: `${focusUser.fname} ${focusUser.lname} (Person)`, type: 'People' })} type="checkbox" />{`${focusUser.fname} ${focusUser.lname} (Person)`}</label>
                                                : null}
                                        </div>


                                        {tools && tools.Skill && tools.Skill.length > 0 ?
                                            <Accordion sx={{ borderRadius: '10px', marginBottom: '10px' }} >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography sx={{ fontSize: 14 }} >Skills</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <FormGroup>
                                                        {tools && tools.Skill && tools.Skill.length > 0 ?
                                                            tools.Skill.map((value, index) => {
                                                                return (
                                                                    <div key={index}>
                                                                        <FormControlLabel control={<Checkbox checked={isChecked(value.Id)} onChange={(e) => handelChange(e.target.checked, value)} />} label={
                                                                            <Typography component="div" fontSize={14}>
                                                                                {value.name}
                                                                            </Typography>
                                                                        } />
                                                                    </div>
                                                                )
                                                            })
                                                            : null}

                                                    </FormGroup>
                                                </AccordionDetails>
                                            </Accordion>
                                            : null}

                                        {tools && tools.Role && tools.Role.length > 0 ?
                                            <Accordion sx={{ borderRadius: '10px', marginBottom: '10px' }} >
                                                <AccordionSummary
                                                    sx={{ backgroundColor: '#EEEEEE', borderRadius: '10px' }}
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel3a-content"
                                                    id="panel3a-header"
                                                >
                                                    <Typography sx={{ fontSize: 14 }} >{/* Roles */ GetAliasesName(teams, "Roles")}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <FormGroup>
                                                        {tools && tools.Role && tools.Role.length > 0 ?
                                                            tools.Role.map((value, index) => {
                                                                return (
                                                                    <div key={index}>
                                                                        <FormControlLabel control={<Checkbox checked={isChecked(value.Id)} onChange={(e) => handelChange(e.target.checked, value)} />} label={
                                                                            <Typography component="div" fontSize={14}>
                                                                                {value.name}
                                                                            </Typography>
                                                                        } />

                                                                    </div>

                                                                )
                                                            })
                                                            : null}
                                                    </FormGroup>
                                                </AccordionDetails>
                                            </Accordion>
                                            : null}

                                        {tools && tools.Domain && tools.Domain.length > 0 ?
                                            <Accordion sx={{ borderRadius: '10px', marginBottom: '10px' }} >
                                                <AccordionSummary
                                                    sx={{ backgroundColor: '#EEEEEE', borderRadius: '10px' }}
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel3a-content"
                                                    id="panel3a-header"
                                                >
                                                    <Typography sx={{ fontSize: 14 }} >{GetAliasesName(teams, "Domains")}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <FormGroup>
                                                        {tools && tools.Domain && tools.Domain.length > 0 ?
                                                            tools.Domain.map((value, index) => {
                                                                return (
                                                                    <div key={index}>
                                                                        <FormControlLabel control={<Checkbox checked={isChecked(value.Id)} onChange={(e) => handelChange(e.target.checked, value)} />} label={
                                                                            <Typography component="div" fontSize={14}>
                                                                                {value.name}
                                                                            </Typography>
                                                                        } />
                                                                    </div>

                                                                )
                                                            })
                                                            : null}
                                                    </FormGroup>
                                                </AccordionDetails>
                                            </Accordion>
                                            : null}

                                        {tools && tools.Link && tools.Link.length > 0 ?
                                            <Accordion sx={{ borderRadius: '10px', marginBottom: '10px' }} >
                                                <AccordionSummary
                                                    sx={{ backgroundColor: '#EEEEEE', borderRadius: '10px' }}
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel3a-content"
                                                    id="panel3a-header"
                                                >
                                                    <Typography sx={{ fontSize: 14 }} >Links</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <FormGroup>
                                                        {tools && tools.Link && tools.Link.length > 0 ?
                                                            tools.Link.map((value, index) => {
                                                                return (
                                                                    <div key={index}>
                                                                        <FormControlLabel control={<Checkbox checked={isChecked(value.Id)} onChange={(e) => handelChange(e.target.checked, value)} />} label={
                                                                            <Typography component="div" fontSize={14}>
                                                                                {value.name}
                                                                            </Typography>
                                                                        } />
                                                                    </div>

                                                                )
                                                            })
                                                            : null}
                                                    </FormGroup>
                                                </AccordionDetails>
                                            </Accordion>
                                            : null}

                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="modal-footer" style={{ 'borderRadius': ' 6px' }}>
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <Tippy content={<span>Close Tool</span>}>
                                    <button type="button" onClick={() => { closeCircleModeTool(); setTemporarilyMovedItem(null); }} data-tippy-content="Close Tool" className="btn btn-mytpt btn-mytpt-red" data-dismiss="modal">
                                        <i className="fa fa-times"></i>
                                    </button>
                                </Tippy>
                                <input type="button" id="mytpt-delete-tags" onClick={() => { saveTools(); setTemporarilyMovedItem(null); }} className="btn btn-mytpt" value="Save" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}