export const isCardExist = (data, cardName, cardType, cardId = false) => {
    let attribute = '';
    switch (cardType) {
        case 'Skill':
            attribute = 'skillName'; break;

        case 'Role':
            attribute = 'roleName'; break;

        case 'Domain':
            attribute = 'domainName'; break;

        case 'Link':
            attribute = 'linkName'; break;

        case 'Circle':
            attribute = 'cricleName'; break;

        case 'Project':
            attribute = 'projectName'; break;

        default: attribute = '';
    }

    if (attribute === '') return true;

    let teamId = localStorage.getItem('teamId');

    if (cardId) {
        if (data && data.some(item => item._id !== cardId && item[attribute] === cardName && item.teamId === teamId)) {
            return true;
        }
    } else {
        if (data && data.some(item => item[attribute] === cardName && item.teamId === teamId)) {
            return true;
        }
    }

    return false;
}