import React from 'react'

export default function PrProjectsList({ handleAliesChange, GetAliasesName, teams, projectsSorted, getSortIcon, isInfos, setProjects, pCardHideOnchange, note, handleSave, noteSaveData, PrFCircleSorted, isTag, isEXT, isNO, focused, TagWiseSorting, ScrollIntoView, setProject, focusListRef, setFocusedRef, toggleTagCards, handelFocused, lFromState, projectSubmit, prref, project, handleProjectChange, setProjectName, projectsFrom, tagFilteredObj, extNoFilteredObj }) {
    return (
        <div className="board-col" data-board_id="projects">
            <div className="list list-bg-red" data-list_id="projects">
                <div className="dropdown">
                    <h4 className="list-title">
                        <img alt="" src="images/icon-project.png" /> {/* {GetAliasesName(teams, "Projects")} (
            {}){" "} */}
                        <span contentEditable={true}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault(); // Prevent new line
                                }
                            }} data-name="projects" onBlur={(e) => handleAliesChange(e)}>{GetAliasesName(teams, "Projects")}</span>
                        <span>{` (${projectsSorted.length})`}</span>{" "}
                        <a className="list-menu refresh-list hide" href="#">
                            <i className="fas fa-sync-alt"></i>
                        </a>
                        <a
                            className="list-menu dropdown-toggle"
                            data-toggle="dropdown"
                            href="#"
                        >
                            {getSortIcon("Projects")}
                            <i className="fas fa-ellipsis-v"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-right" role="menu">
                            <li role="presentation">
                                <a
                                    onClick={() => isInfos("Projects")}
                                    role="menuitem"
                                    className="mytpt-list-info"
                                >
                                    Info
                                </a>
                            </li>
                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    data-toggle="modal"
                                    data-target="#projectsModal"
                                    onClick={() => setProjects()}
                                >
                                    Add new {GetAliasesName(teams, "Projects").toLowerCase()}
                                </a>
                            </li>
                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    onClick={() => pCardHideOnchange("Projects")}
                                    className="hide_list"
                                >
                                    Hide list
                                </a>
                            </li>
                            <li role="presentation" className="hide">
                                <a role="menuitem" className="show-external">
                                    Show external people
                                </a>
                            </li>
                            <li role="presentation" className="hide">
                                <a role="menuitem" className="show-noowners">
                                    Show items without owners
                                </a>
                            </li>
                        </ul>
                    </h4>
                </div>

                <div className="card-list scrollbar" /* ref={focusListRef} */>
                    <div
                        className="form-group notes"
                        style={note.Projects ? { display: "block" } : { display: "none" }}
                    >
                        <textarea
                            className="notes"
                            name="Projects"
                            // defaultValue=''
                            // value={noteSaveData.Projects}
                            //   onChange={(e) => handleSave(e)}
                            onBlur={(e) => handleSave(e)}
                            placeholder="Notes"
                            maxLength="100"
                            defaultValue={noteSaveData.Projects}
                        ></textarea>
                    </div>

                    {PrFCircleSorted && PrFCircleSorted.length > 0
                        ? PrFCircleSorted.map((e, index) => {
                            /* you can use existing function or tagFilterdata necause it is only affected by tagFilter Day */
                            if ((isTag || isEXT || isNO) && e._id !== focused.id) {
                                let Proceed = TagWiseSorting(
                                    e,
                                    isTag,
                                    isEXT,
                                    isNO,
                                    tagFilteredObj,
                                    extNoFilteredObj,
                                    "project"
                                );
                                if (!Proceed) return;
                            }
                            return (
                                <div
                                    // onDragStart={(e) => projectsDragStart(e, index)}
                                    // onDragEnter={(e) => projectsDragEnter(e, index)}
                                    // onDragEnd={projectsDrop}
                                    draggable={
                                        focused && focused.card && focused.card !== "Projects"
                                            ? true
                                            : false
                                    }
                                    key={index}
                                    className={`card ${focused.card === "Projects" && focused.id === e._id
                                        ? "focused-card"
                                        : localStorage.getItem("latest-created") === e._id
                                            ? " latest-created "
                                            : ""
                                        }`}
                                    data-list_id="projects"
                                    onClick={(event) => {
                                        ScrollIntoView();
                                        if (
                                            event.target.classList.contains("card") ||
                                            event.target.classList.contains("card-edit")
                                        ) {
                                            setProject(e, PrFCircleSorted, index);
                                        }
                                        if (focusListRef.current) {
                                            const scrollTop = focusListRef.current.scrollTop;
                                            // console.log("Scroll position:", scrollTop);
                                            setFocusedRef(scrollTop);
                                        }
                                    }}
                                >
                                    <div className="static-relations">
                                        {e.tags && e.tags.length > 0
                                            ? e.tags.map((r, index) => (
                                                <span
                                                    onClick={() => toggleTagCards(r)}
                                                    key={index}
                                                    className="custom-badge green tags"
                                                >
                                                    {r}
                                                </span>
                                            ))
                                            : null}
                                    </div>
                                    <a
                      /* onClick={() => setProject(e, PrFCircleSorted, index)} */ className="card-edit"
                                    >
                                        {e.projectName}
                                    </a>
                                    {focused.card === "Projects" && focused.id === e._id ? (
                                        <a className="card-unlink" style={{ display: "block" }}>
                                            <i
                                                onClick={() =>
                                                    handelFocused({
                                                        card: "CProjects",
                                                        index: null,
                                                        id: null,
                                                    })
                                                }
                                                className="icon far fa-times-circle fas"
                                            ></i>
                                        </a>
                                    ) : focused.card === "Projects" && focused.id !== e._id ? (
                                        <a className="card-focus">
                                            <img
                                                onClick={() =>
                                                    handelFocused({
                                                        card: "Projects",
                                                        index: index,
                                                        id: e._id,
                                                    })
                                                }
                                                src="./images/newicons/focus.svg"
                                            />
                                        </a>
                                    ) : null}
                                </div>
                            );
                        })
                        : null}
                    <div
                        className="card2"
                        style={
                            lFromState.Projects ? { display: "block" } : { display: "none" }
                        }
                    >
                        <form
                            className="form-new-card"
                            role="form"
                            onSubmit={projectSubmit}
                        >
                            <div className="form-group">
                                <input
                                    type="text"
                                    ref={prref}
                                    name="card_name"
                                    required
                                    placeholder={`+ Add ${GetAliasesName(teams, "Projects").toLowerCase()} name`}
                                    className="form-control"
                                    value={project}
                                    onChange={handleProjectChange}
                                    autoComplete="off"
                                />
                            </div>
                            <div className="form-group" style={{ marginTop: "10px" }}>
                                <button type="submit" className="btn btn-mytpt add-quick">
                                    Save
                                </button>
                                <a
                                    href="#"
                                    type="button"
                                    onClick={() => {
                                        setProjects({ projectName: project });
                                        setProjectName("");
                                        lFromState.Projects = false;
                                    }}
                                    className="btn btn-link underline open-tool"
                                >
                                    Edit details
                                </a>
                                <a
                                    href="#"
                                    onClick={() => projectsFrom()}
                                    className="btn btn-link underline close-add-card"
                                >
                                    Close
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
                <a
                    className="btn-list add-card"
                    onClick={() => projectsFrom()}
                    data-list_id="projects"
                    style={
                        !lFromState.Projects ? { display: "block" } : { display: "none" }
                    }
                >
                    + Add new {/* project */} {GetAliasesName(teams, "Projects").toLowerCase()}
                </a>
            </div>
        </div>
    )
}
