import {
    NOTE_GET_SUCCESS, NOTE_GET_FAIL, NOTE_DELETE_SUCCESS, NOTE_DELETE_FAIL, NOTE_UPDATE_SUCCESS, NOTE_UPDATE_FAIL,
    NOTE_SUCCESS_MESSAGE_CLEAR, NOTE_ERROR_CLEAR, NOTE_CREATE_SUCCESS
} from '../types/noteType'


const noteState = {
    noteData: [],
    noteMessage: '',
    noteMessagee: '',
    noteError: ''
}


export const noteReducer = (state = noteState, action) => {
    const { type, payload } = action

    if (type === NOTE_GET_SUCCESS) {
        return {
            ...state,
            noteData: payload.noteData,
            noteMessage: payload.successMessage
        }
    }
    if (type === NOTE_CREATE_SUCCESS) {
        return {
            ...state,
            noteData: payload.noteData,
            noteMessagee: payload.successMessage
        }
    }
    if (type === NOTE_UPDATE_SUCCESS) {
        return {
            ...state,
            noteData: payload.noteData,
            noteMessagee: payload.successMessage
        }
    }
    if (type === NOTE_DELETE_SUCCESS) {
        return {
            ...state,
            noteMessagee: payload.noteSuccessMessage
        }
    }
    if (type === NOTE_SUCCESS_MESSAGE_CLEAR) {
        return {
            ...state,
            noteMessagee: ""
        }
    }
    if (type === NOTE_GET_FAIL) {
        return {
            ...state,
            noteError: payload.error
        }
    }
    if (type === NOTE_UPDATE_FAIL) {
        return {
            ...state,
            noteError: payload.error
        }
    }
    if (type === NOTE_DELETE_FAIL) {
        return {
            ...state,
            noteError: payload.error
        }
    }
    if (type === NOTE_ERROR_CLEAR) {
        return {
            ...state,
            noteError: payload.error
        }
    }


    return state;
}