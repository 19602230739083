import {
    REVIEW_ADD_SUCCESS,
    REVIEW_ADD_FAIL,
    ALL_REVIEW_GET_SUCCESS,
    ALL_REVIEW_GET_ERROR,
    REVIEW_SUCCESS_MESSAGE_CLEAR,
    REVIEW_ERROR_MESSAGE_CLEAR
} from '../types/reviewType';

const reviewState = {
    reviewAnalyticsData: [],
    review_successMessage: '',
    review_errorMessage: ""
}


export const reviewReducer = (state = reviewState, action) => {
    const { type, payload } = action

    if (type === REVIEW_ADD_SUCCESS) {
        return {
            ...state,
            review_successMessage: payload.message
        }
    }
    if (type === REVIEW_ADD_FAIL) {
        return {
            ...state,
            review_errorMessage: payload.message
        }
    }
    if (type === ALL_REVIEW_GET_SUCCESS) {
        return {
            ...state,
            // review_successMessage: payload.message,
            reviewAnalyticsData: payload.data,
            review_errorMessage: "",
        }
    }
    if (type === ALL_REVIEW_GET_ERROR) {
        return {
            ...state,
            review_successMessage: "",
            review_errorMessage: payload.message,
        }
    }

    if (type === REVIEW_SUCCESS_MESSAGE_CLEAR) {
        return {
            ...state,
            review_successMessage: null
        }
    }

    if (type === REVIEW_ERROR_MESSAGE_CLEAR) {
        return {
            ...state,
            review_errorMessage: null
        }
    }

    return state;
}