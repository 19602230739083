import axios from 'axios'
import {
    MEMBER_CREATE_SUCCESS, MEMBER_CREATE_FAIL, MEMBER_GET_SUCCESS, MEMBER_DELETE_SUCCESS,
    MEMBER_UPDATE_SUCCESS, MEMBER_UPDATE_FAIL, MEMBER_DELETE_FAIL, MEMBER_GET_FAIL, MEMBER_MODIFICATIONS
} from '../types/memberType'

// const token = localStorage.getItem("authToken");
import {SERVER_URI} from '../../config/keys'
import { DispatchBlocker } from '../DispatchBlocker/DispatchBlocker'

export const userAuth = (token) => {
    axios.interceptors.request.use(
        config => {
            config.headers.authorization = `Bearer ${token}`;
            return config;
        },
        error => {
            return Promise.reject(error)
        }
    )
}

export const createMember = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        let token = localStorage.getItem('authToken')
        try {
            userAuth(token)
            // console.log(datas)
            const response = await axios.post(`${SERVER_URI}/user/member/create`, datas)
            // console.log(response.data)

            dispatch({
                type: MEMBER_CREATE_SUCCESS,
                payload: {
                    membersuccessMessage: response.data.message,
                    members: response.data.members,
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: MEMBER_CREATE_FAIL,
                    payload: {
                        errorMember: data
                    }
                })
            }else if(error.message){
                dispatch({
                    type: MEMBER_CREATE_FAIL,
                    payload: {
                        errorMember: error.message
                    }
                })
            }
        }
    }
}

export const getMember = (datas) => {
    return async (dispatch) => {
        // const url = 'http://localhost:8080'
        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'adminId': datas.userId,
                'teamId': datas.teamId,
                'url': `${SERVER_URI}/user/member/get`
            }
        }
        try {
            const token = localStorage.getItem("authToken");
            userAuth(token)
            const response = await axios.get(`${SERVER_URI}/user/member/get`, config)
            // console.log(response.data)
            dispatch({
                type: MEMBER_GET_SUCCESS,
                payload: {
                    membersuccessMessage: response.data.message,
                    members: response.data.data
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: MEMBER_GET_FAIL,
                    payload: {
                        errorMember: data,
                    }
                })
            }else if(error.message){
                dispatch({
                    type: MEMBER_GET_FAIL,
                    payload: {
                        errorMember: error.message
                    }
                })
            }
        }
    }
}

export const updateMember = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        let token = localStorage.getItem('authToken')
        try {
            // console.log(datas)
            userAuth(token)
            const response = await axios.put(`${SERVER_URI}/user/member/update`, datas)
            // console.log(response.data.members);
            dispatch({
                type: MEMBER_UPDATE_SUCCESS,
                payload: {
                    membersuccessMessage: response.data.message,
                    members: response.data.members,
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: MEMBER_UPDATE_FAIL,
                    payload: {
                        errorMember: data
                    }
                })
            }else if(error.message){
                dispatch({
                    type: MEMBER_UPDATE_FAIL,
                    payload: {
                        errorMember: error.message
                    }
                })
            }
        }
    }
}

export const deleteMember = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        let token = localStorage.getItem('authToken')
        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'adminId': datas.adminId,
                'teamId': datas.teamId,
                'url': `${SERVER_URI}/user/person/delete`,
                'personId': datas.personId,
            }
        }
        try {
            // console.log(datas)
            userAuth(token)
            const response = await axios.delete(`${SERVER_URI}/user/person/delete`, config)

            dispatch({
                type: MEMBER_DELETE_SUCCESS,
                payload: {
                    membersuccessMessage: response.data.message,
                    members: response.data.members,
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: MEMBER_DELETE_FAIL,
                    payload: {
                        errorMember: data
                    }
                })
            }else if(error.message){
                dispatch({
                    type: MEMBER_DELETE_FAIL,
                    payload: {
                        errorMember: error.message
                    }
                })
            }
        }
    }
}