import React, { useState } from "react";
import { useSelector } from "react-redux";

const styles = {
  textPill: {
    color: "#000",
    // fontWeight: "bold",
    border: "1px solid black",
    margin: "0.05 rem",
    padding: "2px 7px",
  },
}; 

const PillComponent = ({ occupied, total, type = "PILL" }) => {
  const colorCode = (occupied, total) => {
    occupied = parseFloat(occupied.toFixed(2));
    total = parseFloat(total.toFixed(2));
    
    if (occupied < total) {
      return { backgroundColor: "#f3de9f", color: "black" };
    } else if (occupied === total) {
      return { backgroundColor: "#9ce7d3", color: "black" };
    } else if (occupied > total) {
      return { backgroundColor: "#f0a69d", color: "black" };
    }
  };
  // console.log(total, isNaN(total),typeof total , total === undefined, total  ? total  : "-");
  if(isNaN(total)){
    total = 0;
  }
  return (
    <>
      {total && type === "PILL" ? (
        <span
          className={`custom-badge ext-owner-pill`}
          style={colorCode(occupied, total)}
        >{`${occupied.toFixed(2)}/${total ? total.toFixed(2) : "-"}`}</span>
      ) : (
        <span
          className={`custom-badge ext-owner-pill`}
          style={styles.textPill}
        >{`${occupied.toFixed(2)}/${total ? total.toFixed(2) : "-"}`}</span>
      )}
    </>
  );
};

export default PillComponent;
