import axios from 'axios'
import {
    NOTE_GET_SUCCESS, NOTE_GET_FAIL, NOTE_CREATE_SUCCESS, NOTE_CREATE_FAIL,
    NOTE_UPDATE_SUCCESS, NOTE_UPDATE_FAIL, NOTE_DELETE_FAIL,
    NOTE_SUCCESS_MESSAGE_CLEAR, NOTE_ERROR_CLEAR, NOTE_DELETE_SUCCESS
} from '../types/noteType'

import { SERVER_URI } from '../../config/keys'
import { DispatchBlocker } from '../DispatchBlocker/DispatchBlocker'

export const userAuth = (token) => {
    axios.interceptors.request.use(
        config => {
            config.headers.authorization = `Bearer ${token}`;
            return config;
        },
        error => {
            return Promise.reject(error)
        }
    )
}

export const noteCreate = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        try {
            const token = localStorage.getItem("authToken");
            userAuth(token)
            const response = await axios.post(`${SERVER_URI}/user/note/create`, datas)
            // console.log(response)
            dispatch({
                type: NOTE_CREATE_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                    noteData: response.data.data
                }
            })

        } catch (error) {
            let data = error.response.data.message
            // console.log(data)
            dispatch({
                type: NOTE_CREATE_FAIL,
                payload: {
                    error: data
                }
            })
        }
    }
}

export const getNote = (datas) => {
    return async (dispatch) => {
        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'teamId': datas.teamId,
                'url': `${SERVER_URI}/user/note/get`
            }
        }
        try {

            const response = await axios.get(`${SERVER_URI}/user/note/get`, config)
            //  console.log(67,response)
            dispatch({
                type: NOTE_GET_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                    noteData: response.data.data
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: NOTE_GET_FAIL,
                    payload: {
                        noteError: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: NOTE_GET_FAIL,
                    payload: {
                        noteError: error.message
                    }
                })
            }
        }
    }
}

export const updateNote = (datas) => {
    // console.log(datas)
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        try {
            const token = localStorage.getItem("authToken");
            // console.log(token)
            userAuth(token)
            const response = await axios.post(`${SERVER_URI}/user/note/create`, datas)

            dispatch({
                type: NOTE_UPDATE_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                    noteData: response.data.data
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: NOTE_UPDATE_FAIL,
                    payload: {
                        noteError: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: NOTE_UPDATE_FAIL,
                    payload: {
                        noteError: error.message
                    }
                })
            }
        }
    }
}

export const deleteNote = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        let token = localStorage.getItem('authToken')
        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'url': `${SERVER_URI}/user/note/delete`, // API not written yet
                'teamId': datas.teamId,
                'noteId': datas.noteId
            }
        }
        // console.log(datas.adminId);
        try {
            // console.log(datas)
            userAuth(token)
            const response = await axios.delete(`${SERVER_URI}/user/note/delete`, config)

            dispatch({
                type: NOTE_DELETE_SUCCESS,
                payload: {
                    noteSuccessMessage: response.data.message,
                    noteData: response.data.data
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: NOTE_DELETE_FAIL,
                    payload: {
                        noteError: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: NOTE_DELETE_FAIL,
                    payload: {
                        noteError: error.message
                    }
                })
            }
        }
    }
}