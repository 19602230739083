import React, { useEffect } from 'react'

const ConditionalOveralayModal = () => {
    useEffect(() => {
        const mode = localStorage.getItem("mode");
        if (mode === "View") {
            // Add overlay to all modal bodies
            document.querySelectorAll('.modal-body').forEach(modalBody => {
                modalBody.classList.add('modal-overlay');
            });
        } else {
            // Remove overlay from all modal bodies
            document.querySelectorAll('.modal-body').forEach(modalBody => {
                modalBody.classList.remove('modal-overlay');
            });
        }
    }, [localStorage.getItem("mode")]);
    return null;
}

export default ConditionalOveralayModal