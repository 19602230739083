export const clearLocalStorage = async () => {
    const selectedItems = ['uc_ui_version','uc_user_interaction','uc_settings','uc_user_country','ShowComapareMsg'];
    let allLocalStorageKeysArray = Object.keys(localStorage);
    for (let i = 0; i < allLocalStorageKeysArray.length; i++) {
        const key = allLocalStorageKeysArray[i];
        if (!selectedItems.includes(key)) {
            localStorage.removeItem(key);
        }
    }

    for (let i = 0; i < sessionStorage.length; i++) {
        const key = sessionStorage.key(i);
        if (!selectedItems.includes(key) && key !== "ShowComapareMsg") {
            sessionStorage.setItem(key, null);
        }
    }
}
