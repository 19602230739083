import axios from 'axios'
import {
    PEOPLE_CREATE_SUCCESS, PEOPLE_CREATE_FAIL, PEOPLE_GET_SUCCESS, PEOPLE_DELETE_SUCCESS,
    PEOPLE_UPDATE_SUCCESS, PEOPLE_UPDATE_FAIL, PEOPLE_DELETE_FAIL, PEOPLE_GET_FAIL,
    PEOPLE_DROP_UPDATE_SUCCESS, PEOPLE_DROP_UPDATE_FAIL
} from '../types/peopleType'


// const token = localStorage.getItem("authToken");
import {SERVER_URI} from '../../config/keys'
import { DispatchBlocker } from '../DispatchBlocker/DispatchBlocker'
// import { sortingOrderCreate } from '../../HelperFunctions/Sorting';

export const userAuth = (token) => {
    axios.interceptors.request.use(
        config => {
            config.headers.authorization = `Bearer ${token}`;
            return config;
        },
        error => {
            return Promise.reject(error)
        }
    )
}

export const createPeople = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        let token = localStorage.getItem('authToken')
        try {
            userAuth(token)
            // console.log(datas);
            const response = await axios.post(`${SERVER_URI}/user/person/create`, datas)
            // console.log(response.data);

            // sortingOrderCreate({type:"peopleSort", data: response.data.people});

            dispatch({
                type: PEOPLE_CREATE_SUCCESS,
                payload: {
                    people: response.data.people,
                    peopleSuccessMessage: response.data.message,
                    cuPeopleId: response.data.data._id
                }
            });
            
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: PEOPLE_CREATE_FAIL,
                    payload: {
                        peopleError: data
                    }
                })
            }else if(error.message){
                dispatch({
                    type: PEOPLE_CREATE_FAIL,
                    payload: {
                        peopleError: error.message
                    }
                })
            }
        }
    }
}

export const getPeople = (datas) => {
    return async (dispatch) => {
        // const url = 'http://localhost:8080'

        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'adminId': datas.userId,
                'teamId': datas.teamId,
                'curPeople': datas.curPeople,
                'url': `${SERVER_URI}/user/person/get`
            }
        } 
        // console.log(config)
        try {
            const token = localStorage.getItem("authToken");
            // console.log(token)
            userAuth(token)
            const response = await axios.get(`${SERVER_URI}/user/person/get`, config)
            // console.log( response.data.data)

            dispatch({
                type: PEOPLE_GET_SUCCESS,
                payload: {
                    peopleSuccessMessage: response.data.message,
                    people: response.data.data
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: PEOPLE_GET_FAIL,
                    payload: {
                        peopleError: data
                    }
                })
            }else if(error.message){
                dispatch({
                    type: PEOPLE_GET_FAIL,
                    payload: {
                        peopleError: error.message
                    }
                })
            }
        }
    }
}

export const updatePeople = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        // const url = 'http://localhost:8080'
        let token = localStorage.getItem('authToken')
        try {
            // console.log(datas)
            userAuth(token)
            const response = await axios.put(`${SERVER_URI}/user/person/update`, datas);

            // sortingOrderCreate({type:"peopleSort", data: response.data.people});

            dispatch({
                type: PEOPLE_UPDATE_SUCCESS,
                payload: {
                    people: response.data.people,
                    peopleSuccessMessage: response.data.message,
                }
            })

        } catch (error) {
            if (error && error.response) {
                let data = error.response.data.message
                dispatch({
                    type: PEOPLE_UPDATE_FAIL,
                    payload: {
                        peopleError: data
                    }
                })
            }else if(error.message){
                dispatch({
                    type: PEOPLE_UPDATE_FAIL,
                    payload: {
                        peopleError: error.message
                    }
                })
            }
        }
    }
}

export const dropUpdatePeople = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        let token = localStorage.getItem('authToken')
        try {
            userAuth(token)
            const response = await axios.put(`${SERVER_URI}/user/person/update`, datas);

            // sortingOrderCreate({type:"peopleSort", data: response.data.people});

            dispatch({
                type: PEOPLE_DROP_UPDATE_SUCCESS,
                payload: {
                    people: response.data.people,
                    peopleSuccessMessage: response.data.message,
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: PEOPLE_DROP_UPDATE_FAIL,
                    payload: {
                        peopleError: data
                    }
                })
            }else if(error.message){
                dispatch({
                    type: PEOPLE_DROP_UPDATE_FAIL,
                    payload: {
                        peopleError: error.message
                    }
                })
            }
        }
    }
}

export const deletePeople = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        let token = localStorage.getItem('authToken')
        let peopleSort = localStorage.getItem('peopleSort')
        
        // console.log(peopleSort);

        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'adminId': datas.adminId,
                'teamId': datas.teamId,
                'url': `${SERVER_URI}/user/person/delete`,
                'personId': datas.personId,
                'peopleSort': peopleSort,
                'softdelete': datas.softdelete
            }
        }
        try {
            // console.log(datas)
            userAuth(token)
            const response = await axios.delete(`${SERVER_URI}/user/person/delete`, config);
            // console.log(response)
            // sortingOrderCreate({type:"peopleSort", data: response.data.people});

            dispatch({
                type: PEOPLE_DELETE_SUCCESS,
                payload: {
                    people: response.data.people,
                    peopleSuccessMessage: response.data.message,
                }
            });

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: PEOPLE_DELETE_FAIL,
                    payload: {
                        peopleError: data
                    }
                })
            }else if(error.message){
                dispatch({
                    type: PEOPLE_DELETE_FAIL,
                    payload: {
                        peopleError: error.message
                    }
                })
            }
        }
    }
}