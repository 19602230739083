import axios from 'axios'
import {
    HISTORY_CREATE_SUCCESS, HISTORY_CREATE_FAIL, HISTORY_GET_SUCCESS, HISTORY_GET_FAIL, HISTORY_DELETE_SUCCESS, HISTORY_UPDATE_SUCCESS,
    HISTORY_UPDATE_FAIL, HISTORY_DELETE_FAIL, HISTORY_SUCCESS_MESSAGE_CLEAR, HISTORY_ERROR_CLEAR, SIGN_OUT
} from '../../store/types/historyType'

import { SERVER_URI } from '../../config/keys'
import { DispatchBlocker } from '../DispatchBlocker/DispatchBlocker'

// const token = localStorage.getItem("authToken");

export const userAuth = (token) => {
    axios.interceptors.request.use(
        config => {
            config.headers.authorization = `Bearer ${token}`;
            return config;
        },
        error => {
            return Promise.reject(error)
        }
    )
}

export const historyCreate = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        let token = localStorage.getItem('authToken')
        try {
            userAuth(token)
            const response = await axios.post(`${SERVER_URI}/user/history/create`, datas)

        } catch (error) {
            if(error.message){
                // console.log(error.message)
            }
        }
    }
}

export const historyGet = (datas) => {
    return async (dispatch) => {
        try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'cardId': datas.cardId,
                'url': `${SERVER_URI}/user/link/get`
            }
        }
        //    let token = localStorage.getItem('authToken');
        //    if(!token) {
        //     console.log("report token")
        //     token = localStorage.getItem('reportTokens');
        //    }
        
            // userAuth(token)
            const response = await axios.get(`${SERVER_URI}/user/history/get`, config);
            dispatch({
                type: HISTORY_GET_SUCCESS,
                payload: {
                    historys: response.data.data,
                    historyMessage: response.data.message
                }
            })

        } catch (error) {
                if(error.message){
                    console.log(error.message)
                // dispatch({
                //     type: DOMAIN_DELETE_FAIL,
                //     payload: {
                //         historyError: error.message
                //     }
                // })
            }
        }
    }
}