export const LINK_CREATE_SUCCESS = 'LINK_CREATE_SUCCESS'
export const LINK_CREATE_FAIL = 'LINK_CREATE_FAIL'
export const LINK_GET_SUCCESS = 'LINK_GET_SUCCESS'
export const LINK_GET_FAIL = 'LINK_GET_FAIL'
export const LINK_DELETE_SUCCESS = 'LINK_DELETE_SUCCESS'
export const LINK_UPDATE_SUCCESS = 'LINK_UPDATE_SUCCESS'
export const LINK_UPDATE_FAIL = 'LINK_UPDATE_FAIL'
export const LINK_DELETE_FAIL = 'LINK_DELETE_FAIL'
export const LINK_DROP_UPDATE_SUCCESS = 'LINK_DROP_UPDATE_SUCCESS'
export const LINK_DROP_UPDATE_FAIL = 'LINK_DROP_UPDATE_FAIL'

export const LINK_ID_CLEAR = 'LINK_ID_CLEAR'

export const LINK_SUCCESS_MESSAGE_CLEAR = 'LINK_SUCCESS_MESSAGE_CLEAR'
export const LINK_ERROR_CLEAR = 'LINK_ERROR_CLEAR'
export const SIGN_OUT = 'SIGN_OUT'

export const LINKS_MODIFICATIONS = 'LINKS_MODIFICATIONS'