// JavaScript
export const unfreezeWindow = () => {
  const overlay = document.querySelector(".overlay");
  if (overlay) {
    document.body.removeChild(overlay);
  }
};

// export const freezeWindow = (auto=true) => {
//     const overlayExist = document.querySelector('.overlay');
//     if (!overlayExist) {
//         const overlay = document.createElement('div');
//         overlay.className = 'overlay';
//         document.body.appendChild(overlay);
//     }

//     setTimeout(() => {
//         if(auto)unfreezeWindow();
//     }, 500);
// }

export const freezeWindow = (auto = true, loader = false, background=false) => {
  if(localStorage.getItem("mode")=== "View"){
    return;
  }
  const overlayExist = document.querySelector(".overlay");
  if (!overlayExist) {
    const overlay = document.createElement("div");
    overlay.className = "overlay";
    if (loader) {
      const loader = document.createElement("div");
      loader.className = "loader";
      if(background){
      const loaderBg = document.createElement("div");
      loaderBg.className = "loader-bg";
      overlay.appendChild(loaderBg);
      loaderBg.appendChild(loader);
      }else{
        overlay.appendChild(loader);
      }
    }
    document.body.appendChild(overlay);
  }

  setTimeout(() => {
    if (auto) unfreezeWindow();
  }, 500);
};
