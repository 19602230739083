import {
    MEMBER_CREATE_SUCCESS, MEMBER_CREATE_FAIL, MEMBER_GET_SUCCESS, MEMBER_DELETE_SUCCESS,
    MEMBER_UPDATE_SUCCESS, MEMBER_UPDATE_FAIL, MEMBER_DELETE_FAIL, MEMBER_GET_FAIL,
    MEMBER_SUCCESS_MESSAGE_CLEAR, MEMBER_ERROR_CLEAR, SIGN_OUT, MEMBER_MODIFICATIONS
}
    from '../types/memberType'



const memberState = {
    members: [],
    memberMessagee: '',
    memberError: '',
    memberMessage: ""
}


export const memberReducer = (state = memberState, action) => {
    const { type, payload } = action
    // const {users, successMessage } = payload
    // console.log(payload.users)
    
    if (type === MEMBER_CREATE_SUCCESS) {
        return {
            ...state,
            members: payload.members,
            memberMessagee: payload.membersuccessMessage,
            memberError: ""
        }
    }
    if (type === MEMBER_GET_SUCCESS) {
        return {
            ...state,
            members: payload.members,
            memberMessage: payload.membersuccessMessage,
            memberError: ""
        }
    }
    if (type === MEMBER_DELETE_SUCCESS) {
        return {
            ...state,
            members: payload.members,
            memberMessagee: payload.membersuccessMessage,
            memberError: ""
        }
    }
    if (type === MEMBER_UPDATE_SUCCESS) {
        // console.log(payload.members);
        return {
            ...state,
            members: payload.members,
            memberMessagee: payload.membersuccessMessage,
            memberError: ""
        }
    }
    if (type === MEMBER_CREATE_FAIL) {
        return {
            ...state,
            memberError: payload.errorMember
        }
    }
    if (type === MEMBER_DELETE_FAIL) {
        return {
            ...state,
            memberError: payload.errorMember
        }
    }
    if (type === MEMBER_UPDATE_FAIL) {
        return {
            ...state,
            memberError: payload.errorMember
        }
    }
    if (type === MEMBER_GET_FAIL) {
        return {
            ...state,
            memberError: payload.errorMember
        }
    }
    if (type === MEMBER_SUCCESS_MESSAGE_CLEAR) {
        return {
            ...state,
            memberMessagee: ''
        }
    }
    if (type ===  MEMBER_ERROR_CLEAR) {
        return {
            ...state,
            memberError: ''
        }
    }

    if (type === SIGN_OUT) {
        return {
            ...state,
            members: [],
            memberMessagee: '',
            memberError: '',
            memberMessage: ""
        }
    }

    if(type === MEMBER_MODIFICATIONS){
        return {
            ...state,
            members: payload,
            memberMessagee: '',
            memberError: '',
            memberMessage: ""
        }
    }

    return state;
}