import React from 'react'
import Login from './Login'
import Navbar from './Navbar';

export default function BaseComponent(bgImg) {
    return (
        <>
            {/* <!-- NAVBAR --> */}
            <Navbar />
            {/* <!-- END NAVBAR --> */}
            <Login bgImg={bgImg} />
        </>
    )
}
