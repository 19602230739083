import {
    PROJECT_CREATE_SUCCESS, PROJECT_CREATE_FAIL, PROJECT_GET_SUCCESS, PROJECT_DELETE_SUCCESS,
    PROJECT_UPDATE_SUCCESS, PROJECT_UPDATE_FAIL, PROJECT_DELETE_FAIL, PROJECT_GET_FAIL,
    PROJECT_SUCCESS_MESSAGE_CLEAR, PROJECT_ERROR_CLEAR, SIGN_OUT, PROJECT_DROP_UPDATE_SUCCESS, PROJECT_DROP_UPDATE_FAIL,
    PROJECT_ID_CLEAR
} from '../types/projectType'

import { areArraysEqual } from '../../HelperFunctions/ArraysAreEqual';

const userState = {
    projects: [],
    cuProjectId: "",
    projectMessagee: '',
    projectMessage: '',
    projectError: ''
}

export const projectReducer = (state = userState, action) => {
    const { type, payload } = action
    // const {users, successMessage } = payload
    // console.log(payload.users)

    if (type === PROJECT_CREATE_SUCCESS) {
        return {
            ...state,
            projects: payload.projects,
            projectMessagee: payload.ProjectSuccessMessage,
            cuProjectId: payload.cuProjectId,
            projectError: ""
        }
    }
    if (type === PROJECT_GET_SUCCESS) {
        const result = areArraysEqual(state.projects, payload.projects);
        // console.log(result,payload.projects,state.projects)
        return {
            ...state,
            projects: result ? state.projects : payload.projects,
            projectMessage: payload.projectSuccessMessage,
            projectError: ""
        }
    }
    if (type === PROJECT_DELETE_SUCCESS) {
        return {
            ...state,
            projects: payload.projects,
            projectMessagee: payload.ProjectSuccessMessage,
            projectError: ""
        }
    }
    if (type === PROJECT_UPDATE_SUCCESS) {
        return {
            ...state,
            projects: payload.projects,
            projectMessagee: payload.ProjectSuccessMessage,// payload.ProjectSuccessMessage,
            projectError: ""
        }
    }
    if (type === PROJECT_DROP_UPDATE_SUCCESS) {
        return {
            ...state,
            projects: payload.projects,
            projectMessagee: "",// payload.ProjectSuccessMessage,
            projectError: ""
        }
    }
    if (type === PROJECT_CREATE_FAIL) {
        return {
            ...state,
            projectError: payload.projectError
        }
    }
    if (type === PROJECT_DELETE_FAIL) {
        return {
            ...state,
            projectError: payload.projectError
        }
    }
    if (type === PROJECT_UPDATE_FAIL) {
        return {
            ...state,
            projectError: payload.projectError,// payload.error
        }
    }
    if (type === PROJECT_DROP_UPDATE_FAIL) {
        return {
            ...state,
            projectError: "",// payload.error
        }
    }
    if (type === PROJECT_GET_FAIL) {
        return {
            ...state,
            projectError: payload.projectError
        }
    }
    if (type === PROJECT_SUCCESS_MESSAGE_CLEAR) {
        return {
            ...state,
            projectMessagee: ""
        }
    }
    if (type === PROJECT_ERROR_CLEAR) {
        return {
            ...state,
            projectError: ""
        }
    }
    if (type === PROJECT_ID_CLEAR) {
        return {
            ...state,
            cuProjectId: ""
        }
    }

    if (type === SIGN_OUT) {
        return {
            ...state,
            projects: [],
            projectMessagee: '',
            projectMessage: '',
            projectError: ''
        }
    }

    return state;
}