import React from 'react'

export default function FPrPeopleList({ tagFilteredMegedData, GetAliasesName, teams, PrcorfoPeople, PrExtfoPeoples, clickedOnList, toggleEXTnNOCards, getSortIcon, isInfos, pCardHideOnchange, note, pnoteRef, handleSave, noteSaveData, pFPonDrop, pFPonDragOver, focused, projectsSorted, skillsSorted, rolesSorted, PrFPeoplePills, isAddedAsPersonPrF, setPepole, cardOpensPf, toggleTagCards, PfToCAndP, pFpeopleSub, prfIsDuplicatePerson, ScrollIntoView, PrextfoPeople, pfPeopleSearch, setPfPeopleSearch, filteredPFPDatas, OverlayTrigger, popover, cFPeopleHandel, lFromState, peopleSubmit, pref, fName, handleNameChange, pfNameHandel, setFName, setPepoles, peopleFrom, tagFilteredObj, extNoFilteredObj, PrFPeoplePillsE, fpPref, PrcorfoPeoples, isTag, isEXT, isNO, TagWiseSorting, pFPonDrag }) {
    return (
        <div
            className={
                !clickedOnList.listName.includes("people") && Object.keys(tagFilteredMegedData).length > 0 &&
                    tagFilteredMegedData.people &&
                    tagFilteredMegedData.people.length === 0
                    ? "board-col hide"
                    : "board-col"
            }
            data-board_id="people"
        >
            <div className="list list-bg-yellow" data-list_id="people">
                <div className="dropdown" style={{ position: "static" }}>
                    <h4 className="list-title">
                        <img alt="" src="images/icon-people.png" /> {GetAliasesName(teams, "People")} (
                        <span>{PrcorfoPeople.length + PrExtfoPeoples.length}</span>){" "}
                        <a
                            className={
                                clickedOnList.isClicked &&
                                    clickedOnList.listName.includes("people")
                                    ? "list-menu refresh-list"
                                    : "list-menu refresh-list hide"
                            }
                            href="#"
                            onClick={() => {
                                toggleEXTnNOCards("people", "", true, "list");
                            }}
                        >
                            <i className="fas fa-sync-alt"></i>
                        </a>{" "}
                        <a
                            className={
                                clickedOnList.isClicked &&
                                    clickedOnList.listName.includes("people")
                                    ? "list-menu dropdown-toggle hide"
                                    : "list-menu dropdown-toggle"
                            }
                            data-toggle="dropdown"
                            href="#"
                        >
                            {getSortIcon("People")}
                            <i className="fas fa-ellipsis-v"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-right" role="menu">
                            <li role="presentation">
                                <a
                                    onClick={() => isInfos("People")}
                                    role="menuitem"
                                    className="mytpt-list-info"
                                >
                                    Info
                                </a>
                            </li>
                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    data-toggle="modal"
                                    data-target="#peopleModal"
                                >
                                    Add new person
                                </a>
                            </li>
                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    onClick={() => pCardHideOnchange("People")}
                                    className="hide_list"
                                >
                                    Hide list
                                </a>
                            </li>
                            <li role="presentation" className="divider"></li>
                            <li
                                role="presentation"
                                onClick={() => {
                                    toggleEXTnNOCards("people", "EXT", false);
                                }}
                            >
                                <a role="menuitem" className="show-external">
                                    Show external people
                                </a>
                            </li>
                        </ul>
                    </h4>
                </div>

                <div className="card-list scrollbar">
                    <div
                        className="form-group notes"
                        style={note.People ? { display: "block" } : { display: "none" }}
                    >
                        <textarea
                            className="notes"
                            ref={pnoteRef}
                            name="People"
                            onBlur={(e) => handleSave(e)}
                            placeholder="Notes"
                            maxLength="100"
                            autoFocus={null}
                            defaultValue={noteSaveData.People}
                        ></textarea>
                    </div>
                    <h6 style={{ borderBottom: "1px solid #000" }}>Core members</h6>

                    <div className="panel panel-default">
                        <div
                            onDrop={() => pFPonDrop("pFpCore")}
                            onDragOver={(event) => pFPonDragOver(event)}
                            id="core"
                            className="panel-body box-container"
                        >
                            {PrcorfoPeoples && PrcorfoPeoples.length > 0
                                ? PrcorfoPeoples.map((e, index) => {
                                    if (isTag || isEXT || isNO) {
                                        let Proceed = TagWiseSorting(
                                            e,
                                            isTag,
                                            isEXT,
                                            isNO,
                                            tagFilteredObj,
                                            extNoFilteredObj,
                                            "people"
                                        );
                                        if (!Proceed) return;
                                    }
                                    let flagRed = false;
                                    let flagGray = false;
                                    let flagLGray = false;
                                    if (focused && focused.card === "Projects") {
                                        let focusedProject = projectsSorted.find(
                                            (p) => p._id === focused.id
                                        );
                                        let leadOfCicleRedInHtml = focusedProject.lead;
                                        let leadOfCicleGrayInHtml = focusedProject.standIn;
                                        if (leadOfCicleRedInHtml) {
                                            if (leadOfCicleRedInHtml === e._id) {
                                                flagRed = true;
                                            } else {
                                                let curPLead = "";
                                                if (
                                                    skillsSorted &&
                                                    skillsSorted.length > 0 &&
                                                    leadOfCicleRedInHtml
                                                ) {
                                                    for (let j = 0; j < skillsSorted.length; j++) {
                                                        if (
                                                            skillsSorted[j].ownerType === "Single owner" &&
                                                            skillsSorted[j].owners &&
                                                            skillsSorted[j].owners.length > 0
                                                        ) {
                                                            if (
                                                                skillsSorted[j]._id === leadOfCicleRedInHtml
                                                            ) {
                                                                curPLead = skillsSorted[j].owners[0];
                                                                break;
                                                            }
                                                        }
                                                    }
                                                }
                                                if (!curPLead) {
                                                    if (
                                                        rolesSorted &&
                                                        rolesSorted.length > 0 &&
                                                        leadOfCicleRedInHtml
                                                    ) {
                                                        for (let j = 0; j < rolesSorted.length; j++) {
                                                            if (
                                                                rolesSorted[j].ownerRole === "Single owner" &&
                                                                rolesSorted[j].owners &&
                                                                rolesSorted[j].owners.length > 0
                                                            ) {
                                                                if (
                                                                    rolesSorted[j]._id === leadOfCicleRedInHtml
                                                                ) {
                                                                    curPLead = rolesSorted[j].owners[0];
                                                                    break;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                if (curPLead && curPLead.length > 0) {
                                                    if (e._id === curPLead) {
                                                        flagLGray = true;
                                                    }
                                                }
                                            }
                                        }
                                        if (leadOfCicleGrayInHtml === e._id) {
                                            flagGray = true;
                                        }
                                    }
                                    let curPills = [];
                                    if (PrFPeoplePills && PrFPeoplePills.length > 0) {
                                        // console.log("Initial PrFPeoplePills: ",PrFPeoplePills)
                                        for (let i = 0; i < PrFPeoplePills.length; i++) {
                                            let duplicate = false;
                                            if (curPills.length > 0) {
                                                for (let j = 0; j < curPills.length; j++) {
                                                    if (
                                                        curPills[j].peopleId ===
                                                        PrFPeoplePills[i].peopleId &&
                                                        curPills[j].Id === PrFPeoplePills[i].Id
                                                    ) {
                                                        // console.log("Duplicate found: ",curPills[j], PrFPeoplePills[i]);
                                                        duplicate = true;
                                                        break;
                                                    }
                                                }
                                            }

                                            if (
                                                PrFPeoplePills[i].peopleId === e._id &&
                                                !duplicate
                                            ) {
                                                curPills.push(PrFPeoplePills[i]);
                                            }
                                        }
                                    }
                                    // console.log("Current pills: ",curPills);
                                    let addAsPerson = isAddedAsPersonPrF(e._id, "Core");

                                    return (
                                        <div
                                            onDrag={() => pFPonDrag(e, "pFpCore")}
                                            key={index}
                                            draggable
                                            className="card"
                                            data-list_id="people"
                                            onClick={(event) => {
                                                if (
                                                    event.target.classList.contains("card") ||
                                                    event.target.classList.contains("card-edit")
                                                ) {
                                                    setPepole(e, PrcorfoPeoples, index);
                                                }
                                            }}
                                        >
                                            <div className="static-relations">
                                                {e.workRole === "External" ? (
                                                    <span
                                                        onClick={() => {
                                                            toggleEXTnNOCards(e._id, "EXT", false, "pill");
                                                        }}
                                                        className="custom-badge blue ext-owner-pill"
                                                    >
                                                        EXT
                                                    </span>
                                                ) : null}
                                                {curPills && curPills.length > 0
                                                    ? curPills.map((d, index) => {
                                                        return (
                                                            <span onClick={() => cardOpensPf(d)}>
                                                                {!d.standIn ? (
                                                                    <span className="custom-badge lightgray">
                                                                        {d.name}
                                                                    </span>
                                                                ) : (
                                                                    <span className="custom-badge gray-o">
                                                                        {d.name}
                                                                    </span>
                                                                )}
                                                            </span>
                                                        );
                                                    })
                                                    : null}
                                                {e.tags && e.tags.length > 0
                                                    ? e.tags.map((r, index) => (
                                                        <span
                                                            onClick={() => toggleTagCards(r)}
                                                            key={index}
                                                            className="custom-badge green tags"
                                                        >
                                                            {r}
                                                        </span>
                                                    ))
                                                    : null}
                                            </div>
                                            <div className="relations">
                                                {addAsPerson ? (
                                                    <span className="custom-badge lightgray text-red person-pill">
                                                        Added as person
                                                    </span>
                                                ) : null}
                                                {flagRed ? (
                                                    <span className="custom-badge red-o direct-lead">
                                                        LEAD
                                                    </span>
                                                ) : null}
                                                {flagGray ? (
                                                    <span className="custom-badge gray-o">
                                                        LEAD (Stand-In)
                                                    </span>
                                                ) : null}
                                                {flagLGray ? (
                                                    <span className="custom-badge gray-o">LEAD</span>
                                                ) : null}
                                            </div>

                                            <a
                          /* onClick={() => setPepole(e, PrcorfoPeoples, index)} */ className="card-edit"
                                            >{`${e.fname} ${e.lname}`}</a>
                                            <a className="card-focus">
                                                <img
                                                    onClick={() => PfToCAndP("People", e)}
                                                    src="./images/newicons/focus.svg"
                                                />
                                            </a>
                                            {addAsPerson ? (
                                                <a
                                                    className="card-unlink"
                                                    style={{ display: "block", color: "red" }}
                                                >
                                                    <i
                                                        onClick={() => pFpeopleSub(e, "core")}
                                                        className="icon far fa-times-circle"
                                                    ></i>
                                                </a>
                                            ) : null}
                                        </div>
                                    );
                                })
                                : null}
                        </div>
                    </div>

                    <h6 style={{ borderBottom: "1px solid #000" }}>Extended members</h6>
                    <div className="panel panel-default">
                        <div
                            onDrop={() => pFPonDrop("pFpExtend")}
                            onDragOver={(event) => pFPonDragOver(event)}
                            id="extended"
                            className="panel-body box-container"
                        >
                            {PrExtfoPeoples && PrExtfoPeoples.length > 0
                                ? PrExtfoPeoples.map((e, index) => {
                                    if (isTag || isEXT || isNO) {
                                        let Proceed = TagWiseSorting(
                                            e,
                                            isTag,
                                            isEXT,
                                            isNO,
                                            tagFilteredObj,
                                            extNoFilteredObj,
                                            "people"
                                        );
                                        if (!Proceed) return;
                                    }
                                    let curPills = [];
                                    if (PrFPeoplePillsE && PrFPeoplePillsE.length > 0) {
                                        for (let i = 0; i < PrFPeoplePillsE.length; i++) {
                                            if (PrFPeoplePillsE[i].peopleId === e._id) {
                                                curPills.push(PrFPeoplePillsE[i]);
                                            }
                                        }
                                    }
                                    let flag = true;
                                    if (e && Object.keys(e).length > 0) {
                                        flag = prfIsDuplicatePerson(e);
                                    }
                                    let addAsPerson = isAddedAsPersonPrF(e._id, "Extended");
                                    return (
                                        <div
                                            // onDragStart={(e) => peopleDragStart(e, index)}
                                            // onDragEnter={(e) => peopleDragEnter(e, index)}
                                            // onDragEnd={peopleDrop}
                                            onDrag={() => pFPonDrag(e, "pFpExtend")}
                                            key={index}
                                            draggable
                                            className="card"
                                            data-list_id="people"
                                            onClick={(event) => {
                                                ScrollIntoView();
                                                if (
                                                    event.target.classList.contains("card") ||
                                                    event.target.classList.contains("card-edit")
                                                ) {
                                                    setPepole(e, PrextfoPeople, index);
                                                }
                                            }}
                                        >
                                            <div className="static-relations">
                                                {e.workRole === "External" ? (
                                                    <span
                                                        onClick={() => {
                                                            toggleEXTnNOCards(e._id, "EXT", false, "pill");
                                                        }}
                                                        className="custom-badge blue ext-owner-pill"
                                                    >
                                                        EXT
                                                    </span>
                                                ) : null}

                                                {curPills && curPills.length > 0
                                                    ? curPills.map((d, index) => {
                                                        return (
                                                            <span onClick={() => cardOpensPf(d)}>
                                                                {!d.standIn ? (
                                                                    <span className="custom-badge lightgray">
                                                                        {d.name}
                                                                    </span>
                                                                ) : (
                                                                    <span className="custom-badge gray-o">
                                                                        {d.name}
                                                                    </span>
                                                                )}
                                                            </span>
                                                        );
                                                    })
                                                    : null}

                                                {e.tags && e.tags.length > 0
                                                    ? e.tags.map((r, index) => (
                                                        <span
                                                            onClick={() => toggleTagCards(r)}
                                                            key={index}
                                                            className="custom-badge green tags"
                                                        >
                                                            {r}
                                                        </span>
                                                    ))
                                                    : null}
                                            </div>

                                            <div className="relations">
                                                {addAsPerson ? (
                                                    <span className="custom-badge lightgray text-red person-pill">
                                                        Added as person
                                                    </span>
                                                ) : null}
                                            </div>

                                            <a
                          /* onClick={() => setPepole(e, PrextfoPeople, index)} */ className="card-edit"
                                            >{`${e.fname} ${e.lname}`}</a>

                                            {flag ? (
                                                <a className="card-focus">
                                                    <img
                                                        onClick={() => PfToCAndP("People", e)}
                                                        src="./images/newicons/focus.svg"
                                                    />
                                                </a>
                                            ) : null}

                                            {addAsPerson ? (
                                                <a
                                                    className="card-unlink"
                                                    style={{ display: "block", color: "red" }}
                                                >
                                                    <i
                                                        onClick={() => pFpeopleSub(e, "extend")}
                                                        className="icon far fa-times-circle"
                                                    ></i>
                                                </a>
                                            ) : null}
                                        </div>
                                    );
                                })
                                : null}
                        </div>
                    </div>

                    <span className="clearable2">
                        <input
                            type="text"
                            ref={fpPref}
                            style={{ color: "black" }}
                            value={pfPeopleSearch}
                            onChange={(e) => setPfPeopleSearch(e.target.value)}
                            className="form-control search_list2 transparent"
                            name="search_list"
                            placeholder="Search to select more"
                            autoComplete="off"
                        />
                        {pfPeopleSearch.length > 0 ? (
                            <i
                                className="clearable__clear"
                                onClick={() => setPfPeopleSearch("")}
                                style={{ display: "inline", color: "black" }}
                            >
                                ×
                            </i>
                        ) : null}
                    </span>

                    <div className="panel panel-default">
                        <div
                            onDrop={() => pFPonDrop("pFpDefult")}
                            onDragOver={(event) => pFPonDragOver(event)}
                            id="notmatched"
                            className="panel-body box-container"
                        >
                            {filteredPFPDatas && filteredPFPDatas.length > 0
                                ? filteredPFPDatas.map((e, index) => {
                                    if (isTag || isEXT || isNO) {
                                        let Proceed = TagWiseSorting(
                                            e,
                                            isTag,
                                            isEXT,
                                            isNO,
                                            tagFilteredObj,
                                            extNoFilteredObj,
                                            "people"
                                        );
                                        if (!Proceed) return;
                                    }
                                    return (
                                        <div
                                            // onDragStart={(e) => peopleDragStart(e, index)}
                                            // onDragEnter={(e) => peopleDragEnter(e, index)}
                                            // onDragEnd={peopleDrop}
                                            onDrag={() => pFPonDrag(e, "pFpDefult")}
                                            key={index}
                                            draggable
                                            className={`card notmatched ${localStorage.getItem("latest-created") === e._id
                                                ? " latest-created "
                                                : ""
                                                }`}
                                            data-list_id="people"
                                            onClick={(event) => {
                                                ScrollIntoView();
                                                if (
                                                    event.target.classList.contains("card") ||
                                                    event.target.classList.contains("card-edit")
                                                ) {
                                                    setPepole(e, filteredPFPDatas, index);
                                                }
                                            }}
                                        >
                                            <div className="static-relations">
                                                {e.workRole === "External" ? (
                                                    <span
                                                        onClick={() => {
                                                            toggleEXTnNOCards(e._id, "EXT", false, "pill");
                                                        }}
                                                        className="custom-badge blue ext-owner-pill"
                                                    >
                                                        EXT
                                                    </span>
                                                ) : null}
                                                {e.tags && e.tags.length > 0
                                                    ? e.tags.map((r, index) => (
                                                        <span
                                                            onClick={() => toggleTagCards(r)}
                                                            key={index}
                                                            className="custom-badge green tags"
                                                        >
                                                            {r}
                                                        </span>
                                                    ))
                                                    : null}
                                            </div>

                                            <a
                          /* onClick={() => setPepole(e, filteredPFPDatas, index)} */ className="card-edit"
                                            >{`${e.fname} ${e.lname}`}</a>
                                            <OverlayTrigger
                                                trigger="click"
                                                placement="right"
                                                overlay={popover}
                                            >
                                                <a
                                                    className="card-link"
                                                    variant="success"
                                                    style={{ display: "block" }}
                                                >
                                                    <i
                                                        onClick={() => cFPeopleHandel(e, "People")}
                                                        className="icon fa fa-plus-circle"
                                                    ></i>
                                                </a>
                                            </OverlayTrigger>
                                        </div>
                                    );
                                })
                                : null}
                        </div>
                    </div>
                    <div
                        className="card2"
                        style={
                            lFromState.Peoples ? { display: "block" } : { display: "none" }
                        }
                    >
                        <form className="form-new-card" role="form" onSubmit={peopleSubmit}>
                            <div className="form-group">
                                <input
                                    type="text"
                                    ref={pref}
                                    name="card_name"
                                    placeholder="Add first name"
                                    data-mode="Project focus mode"
                                    className="form-control"
                                    value={fName}
                                    onChange={handleNameChange}
                                    onKeyPress={pfNameHandel}
                                    autoComplete="off"
                                    required
                                />
                            </div>
                            <div className="form-group" style={{ marginTop: "10px" }}>
                                <button type="submit" className="btn btn-mytpt add-quick">
                                    Save
                                </button>
                                <a
                                    href="#"
                                    type="button"
                                    // onClick={() => {setPepoles({ fname: fName })}}
                                    onClick={() => {
                                        // Added by Debashis on 13-12-2023 (updated 18-12-2023)
                                        let lastname = "";
                                        let firstname = "";
                                        if (fName.trim().indexOf(" ") >= 0) {
                                            let nameArray = fName.split(" ");
                                            lastname = nameArray.pop();
                                            firstname = nameArray.join(" ");
                                        } else {
                                            lastname = "";
                                            firstname = fName;
                                        }
                                        setFName("");
                                        setPepoles({ fname: firstname, lname: lastname });
                                        lFromState.Peoples = false;
                                    }}
                                    className="btn btn-link underline open-tool"
                                >
                                    Edit details
                                </a>
                                <a
                                    href="#"
                                    onClick={() => peopleFrom()}
                                    className="btn btn-link underline close-add-card"
                                >
                                    Close
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
                <a
                    className="btn-list add-card"
                    onClick={() => peopleFrom()}
                    data-list_id="people"
                    style={
                        !lFromState.Peoples ? { display: "block" } : { display: "none" }
                    }
                >
                    + Add new person
                </a>
            </div>
        </div>
    )
}
