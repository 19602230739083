import React from 'react'

export default function () {
    return (
        <div className="container">
            <div className="row align-items-end">
                <div className="col-md-12 text-center copyright">
                    <p><a className="imprint" rel="noopener nofollow noreferrer" target="_blank" href="https://legal.teamdecoder.com/terms-of-service">Terms of Service</a> | <a className="imprint" rel="noopener nofollow noreferrer" target="_blank" href="https://legal.teamdecoder.com/privacy-policy">Privacy Policy</a> | <a className="imprint" href="/legal-notice/">Legal Notice</a> | Copyright (2024) by teamdecoder GmbH</p>
                </div>
            </div>
        </div>
    )
}