import { areArraysEqual } from '../../HelperFunctions/ArraysAreEqual'
import {
    PEOPLE_CREATE_SUCCESS, PEOPLE_CREATE_FAIL, PEOPLE_GET_SUCCESS, PEOPLE_DELETE_SUCCESS,
    PEOPLE_UPDATE_SUCCESS, PEOPLE_UPDATE_FAIL, PEOPLE_DELETE_FAIL, PEOPLE_GET_FAIL,
    PEOPLE_SUCCESS_MESSAGE_CLEAR, PEOPLE_ERROR_CLEAR, SIGN_OUT, PEOPLE_DROP_UPDATE_SUCCESS, PEOPLE_DROP_UPDATE_FAIL,
    PEOPLE_ID_CLEAR
}
    from '../types/peopleType'

const userState = {
    peoples: [],
    cuPeopleId: "",
    peopleMessagee: '',
    peopleError: '',
    peopleMessage: ""
}

export const peopleReducer = (state = userState, action) => {
    const { type, payload } = action
    // const {users, successMessage } = payload
    // console.log("reducer",action)

    if (type === PEOPLE_CREATE_SUCCESS) {
        // console.log("here in codn reducer",payload.peopleSuccessMessage)
        return {
            ...state,
            peoples: payload.people,
            peopleMessagee: payload.peopleSuccessMessage,
            cuPeopleId: payload.cuPeopleId,
            peopleError: ""
        }
    }
    if (type === PEOPLE_GET_SUCCESS) {
        // console.log(payload);
        const result = areArraysEqual(state.peoples, payload.people);

        return {
            ...state,
            peoples: result ? state.peoples : payload.people,
            // peoples: payload.people,
            peopleMessage: payload.peopleSuccessMessage,
            peopleError: ""
        }
    }
    if (type === PEOPLE_DELETE_SUCCESS) {
        return {
            ...state,
            peoples: payload.people,
            peopleMessagee: payload.peopleSuccessMessage,
            peopleError: ""
        }
    }
    if (type === PEOPLE_UPDATE_SUCCESS) {
        // console.log("inside reducer",payload);
        return {
            ...state,
            peoples: payload.people,
            peopleMessagee: payload.peopleSuccessMessage,//payload.peopleSuccessMessage,
            peopleError: ""
        }
    }
    if (type === PEOPLE_DROP_UPDATE_SUCCESS) {
        return {
            ...state,
            peoples: payload.people,
            peopleMessagee: "",//payload.peopleSuccessMessage,
            peopleError: ""
        }
    }
    if (type === PEOPLE_CREATE_FAIL) {
        return {
            ...state,
            peopleError: payload.peopleError
        }
    }
    if (type === PEOPLE_DELETE_FAIL) {
        return {
            ...state,
            peopleError: payload.peopleError
        }
    }
    if (type === PEOPLE_UPDATE_FAIL) {
        return {
            ...state,
            peopleError: payload.peopleError,// payload.error
        }
    }
    if (type === PEOPLE_DROP_UPDATE_FAIL) {
        return {
            ...state,
            peopleError: "",// payload.error
        }
    }
    if (type === PEOPLE_GET_FAIL) {
        return {
            ...state,
            peopleError: payload.peopleError
        }
    }
    if (type === PEOPLE_SUCCESS_MESSAGE_CLEAR) {
        // console.log("k daklo ----???")
        return {
            ...state,
            peopleMessagee: ''
        }
    }
    if (type === PEOPLE_ERROR_CLEAR) {
        return {
            ...state,
            peopleError: ''
        }
    }
    if (type === PEOPLE_ID_CLEAR) {
        return {
            ...state,
            cuPeopleId: ''
        }
    }

    if (type === SIGN_OUT) {
        return {
            ...state,
            peoples: [],
            peopleMessagee: '',
            peopleError: '',
            peopleMessage: ""
        }
    }

    return state;
}