import axios from 'axios'
import {INFO_GET_SUCCESS, INFO_GET_FAIL, INFO_CREATE_SUCCESS, INFO_CREATE_FAIL ,  INFO_DELETE_SUCCESS , INFO_DELETE_FAIL,
     INFO_UPDATE_SUCCESS , INFO_UPDATE_FAIL, 
    INFO_SUCCESS_MESSAGE_CLEAR ,INFO_ERROR_CLEAR} from '../types/infoType'


import {SERVER_URI} from '../../config/keys'
import { DispatchBlocker } from '../DispatchBlocker/DispatchBlocker'

export const userAuth = (token)=>{
    axios.interceptors.request.use(
        config => {
          config.headers.authorization = `Bearer ${token}`;
          return config;
        },
        error => {
          return Promise.reject(error)
        }
      )
}     



export const infoCreate = (datas) =>{
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        try{
            const token = localStorage.getItem("authToken");
            userAuth(token)
            const response = await axios.post(`${SERVER_URI}/user/info/create`, datas)
            
            dispatch({
                type : INFO_CREATE_SUCCESS,
                payload : {
                    successMessage : response.data.message,
                    // info : response.data.data
                }
            })

        }catch(error){
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: INFO_CREATE_FAIL,
                    payload: {
                        infoError: data
                    }
                })
            }else if(error.message){
                dispatch({
                    type: INFO_CREATE_FAIL,
                    payload: {
                        infoError: error.message
                    }
                })
            }
        }
    }
}



export const getInfo = (datas) =>{
    return async (dispatch) =>{
        const config = {
            headers : {
                'Content-Type': 'application/josn',
                'adminId' : datas.userId,
                'teamId': datas.teamId,
                'url' : `${SERVER_URI}/user/info/get`
            }
        }
        try{
         
            const response = await axios.get(`${SERVER_URI}/user/info/get` , config)
            
            dispatch({
                type : INFO_GET_SUCCESS,
                payload : {
                    successMessage : response.data.message,
                    info : response.data.data
                }
            })

        }catch(error){
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: INFO_GET_FAIL,
                    payload: {
                        infoError: data
                    }
                })
            }else if(error.message){
                dispatch({
                    type: INFO_GET_FAIL,
                    payload: {
                        infoError: error.message
                    }
                })
            }
        }
    }
}



export const updateInfo = (datas) =>{
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        try{
            const token = localStorage.getItem("authToken");
            // console.log(token)
            userAuth(token)
            const response = await axios.post(`${SERVER_URI}/user/info/update`, datas)
            
            dispatch({
                type : INFO_UPDATE_SUCCESS,
                payload : {
                    successMessage : response.data.message
                }
            })

        }catch(error){
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: INFO_UPDATE_FAIL,
                    payload: {
                        infoError: data
                    }
                })
            }else if(error.message){
                dispatch({
                    type: INFO_UPDATE_FAIL,
                    payload: {
                        infoError: error.message
                    }
                })
            }
        }
    }
}



export const deleteInfo = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if(!proceed) return;
        let token = localStorage.getItem('authToken')
        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'url': `${SERVER_URI}/user/info/delete`,
                'adminId': datas.adminId,
                'teamId': datas.teamId,
                'infoId': datas.infoId
            }
        }
        // console.log(datas.adminId);
        try {
            // console.log(datas)
            userAuth(token)
            const response = await axios.delete(`${SERVER_URI}/user/info/delete`, config)

            dispatch({
                type: INFO_DELETE_SUCCESS,
                payload: {
                    infoSuccessMessage: response.data.message,
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: INFO_DELETE_FAIL,
                    payload: {
                        infoError: data
                    }
                })
            }else if(error.message){
                dispatch({
                    type: INFO_DELETE_FAIL,
                    payload: {
                        infoError: error.message
                    }
                })
            }
        }
    }
}